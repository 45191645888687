import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Modal,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";

import FirebaseService from "../../services/firebaseService";
import  { parsCollections,qualityGroup,requestedProductType}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

 
const AddDensities = ({ onClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
     
      };
      fetchData();
    }, []);

    const onFinish = async (values) => {
      setLoading(true)
        const currentDate =new Date()
        const densityValue = parseFloat(values.density);
        
        try {
            const all = await FirebaseService.getDensitiesData();
            const newItems = all.filter((entry) => entry.quality.toLowerCase() === values.quality.toLowerCase());
            if(newItems.length > 0){
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Bu kaliteye ait özkütle değeri daha önceden girilmiştir.",
                placement: 'topRight'
              });
              setLoading(false)
              return;
              
             }  else {
                const data = {
                    firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    density: densityValue,
                    quality: values.quality,
                    fuel: values.fuel
                };
    
                const result = await FirebaseService.addRecording(parsCollections.densities, data);
                if (result.success) {
                    NotificationService.openSuccessNotification({
                        title: 'İşlem Başarılı',
                        description: result.message,
                        placement: 'topRight'
                    });
                    setLoading(false)
                   onClose();
                   setTimeout(() => {
                    window.location.reload();
                  }, 500);
                } else {
                    NotificationService.openErrorNotification({
                        title: 'İşlem Başarısız',
                        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                        placement: 'topRight'
                    });setLoading(false)

                }
            }
        } catch (error) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Bilinmeyen bir hata ile karşılaşıldı.",
                placement: 'topRight'
            });
            setLoading(false)
        }
        
        
     
    }; 


    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
      };
      
    
      const onChange = (value) => {
        //console.log(`selected ${value}`);
        
    };



   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        

<Form.Item
                 name="quality"
                 rules={[
                   {
                     required: true,
                     message: 'Lütfen kalite giriniz!',
                   },
                 ]}
                 wrapperCol={{
                    span: 10,
                    }}
                    style={{
                    maxWidth: "100%",
                    textAlign:"left"
                    }}
               >
                 <Input placeholder="Kalite Giriniz" />
               </Form.Item>

            <Form.Item
            name="density"
            rules={[
                {
                    required: true,
                    message: 'Lütfen özkütle giriniz!',
                },
                {
                    pattern: /^[0-9.]+$/,
                    message: 'Sadece rakam ve nokta (.) girişi yapınız.',
                },
               
            ]}
            

            wrapperCol={{
              span: 10,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"left"
              }}
            >
           <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Özkütle"/>
            </Form.Item>
            <Form.Item
          name="fuel"
          rules={[
            {
              required: true,
              message: 'Lütfen seçiniz!',
            },
          ]}
          wrapperCol={{
            span: 10,
            }}
            style={{
            maxWidth: "100%",
            textAlign:"left"
            }}
        >
          <Select
            onChange={onChange}
            placeholder="Kalite Grupu Seçiniz"
            style={{ borderRadius: 0, borderColor: "#1A446C80" }}
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={qualityGroup.map(item => ({ label: item.label, value: item.value }))}
          />
            </Form.Item>
         
       <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>



        </Form>

        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

            
        
    </div>
    
  )

};

AddDensities.propTypes = {
    onClose: PropTypes.func.isRequired, 
  };
  
export default AddDensities;