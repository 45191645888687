import React, { useEffect, useState } from "react";
import { Button, Form, Select } from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

const StockFilter = () => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const companiesData = await FirebaseService.getCompaniesData();
            const filteredCompanies = companiesData.filter(item => !item.isDeleted);
            setCompanies(filteredCompanies);
        };
        fetchData();
    }, []);

    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({
            title: 'Geçersiz Form',
            description: 'Lütfen form bilgilerini kontrol ediniz.',
            placement: 'topRight'
        });
    };

    const onFinish = async (record) => {
        navigate('/stocklist', { state: { record } });
    };

    const handleCompanyName = (value) => {
        setCompanyName(value);
    };

    const getSortedCompanies = (companies) => {
        return companies
            .map(item => ({ label: item.companyName, value: item.companyName })) // Map to the desired structure
            .sort((a, b) => {
                if (a.label.toLowerCase() === "pars" || a.label.toLowerCase() === "pars makina" ) return -1; // "Pars" comes first
                if (b.label.toLowerCase() === "pars" || b.label.toLowerCase() === "pars makina") return 1; // "Pars" comes first
                return a.label.localeCompare(b.label); // Otherwise, sort alphabetically
            });
    };

    return (
        <div>
            <Form
                name="basic"
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 20,
                }}
                style={{
                    maxWidth: "100%",
                    padding: "5% 0 0 5%"
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Firma"
                    name="companyName"
                    rules={[{ required: true, message: 'Herhangi bir firma seçmediniz. Lütfen firma seçiniz.' }]}
                >
                    <Select
                        onChange={handleCompanyName}
                        placeholder="Firma seçiniz"
                        showSearch // Enable search feature
                        options={getSortedCompanies(companies)} // Sorted options
                        className="select-style"
                        style={{ width: '100%', minWidth: '300px' }} // Size settings
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 15,
                        span: 18,
                    }}
                >
                    <Button htmlType="submit" style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500" }}>
                        Devam Et
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default StockFilter;
