import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import UserInfo from './userInfo';
import ForeignCurrency from './foreignCurrency';
import WeatherData from './weatherData';
import { colors } from '../../environments/environment';
import Notifications from './notifications';
import Pdf from './pages/ProgrammerPage.pdf';

const WebNavbar = () => {
  const location = useLocation();

  useEffect(() => {
    // useEffect içeriği
  }, []);

  const navbarContainerStyle = {
    display: 'flex',
    padding: '10px',
    boxSizing: 'border-box',
    width: '100%',
    height: 'auto', // Yüksekliği otomatik olarak ayarlayın
    alignItems: 'center' // Öğeleri dikey olarak ortalar
  };

  const leftSectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 50%',
    justifyContent: 'space-around',
    alignItems: 'center'
  };

  const rightSectionStyle = {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 50%',
    justifyContent: 'space-around',
    alignItems: 'center'
  };

  const navbarItemStyle = {
    margin: '10px',
    flex: '1 1 auto', // Kutuların genişlemesini sağlar
    minWidth: '150px' // Minimum genişlik
  };

  const pdfLinkStyle = {
    marginTop: '25px'
  };

  return (
    <div style={navbarContainerStyle}>
      <div style={leftSectionStyle}>
        <div style={navbarItemStyle}>
          <UserInfo />
        </div>

        <div style={navbarItemStyle}>
          <ForeignCurrency />
        </div>
      </div>

      <div style={rightSectionStyle}>
        <div style={navbarItemStyle}>
          <WeatherData />
        </div>

        <div style={navbarItemStyle}>
          <div style={pdfLinkStyle}>
            <a href={Pdf} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
              <img alt="Yardım" src="/images/help.svg" />
              <p style={{ color: colors.blue, fontWeight: "bold", fontFamily: "Helvetica", textDecoration: "none" }}>
                Yardım
              </p>
            </a>
          </div>
        </div>

        <div style={navbarItemStyle}>
          <Notifications />
        </div>
      </div>
    </div>
  );
};

export default WebNavbar;
