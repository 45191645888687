import React, { useState } from 'react';
import moment from 'moment';
import { colors } from '../../../environments/environment';

const menuData = [
    { date: '2024-08-26', menu: 'DOMATES CORBA, ACILI TAVUK ERISTE MAKARNA, KOLA, YESIL SALATA, 2 BARDAK YOGURT, YESIL SALATA LIGHT' },
    { date: '2024-08-27', menu: 'ARABASI CORBASI, TAZE FASULYE, ARPA SEHRIYELI PIRINC PILAVI, CEVIZLI KALBURABASTI, MEVSIM SALATA, 2 BARDAK YOGURT, SOGUS ROKA' },
    { date: '2024-08-28', menu: 'YILDIZ SEHRIYELI CORBA, PILIC DONER, NOHUTLU PIRINC PILAVI, MEYVE (MEVSIM), MEVSIM SALATA, 2 BARDAK YOGURT, YESIL SALATA LIGHT' },
    { date: '2024-08-29', menu: 'MAHLUTA CORBA, MENGEN MUSAKKA, KOYLUM PILAVI, MEYVE (MEVSIM), KARISIK SALATA, 2 BARDAK YOGURT, YESIL SALATA LIGHT' },
    { date: '2024-08-30', menu: 'MERCIMEK CORBA, BIBER DOLMA, CATAL BOREK PEYNIRLI, KOMPOSTO, MEVSIM SALATA, 2 BARDAK YOGURT, YESIL SALATA LIGHT' },
    { date: '2024-09-01', menu: 'KISIR, DOMATES SALATA, KUMPIR, KOLA, MEVSIM SALATA, 2 BARDAK YOGURT, MEYVE' },
    { date: '2024-09-02', menu: 'SEBZE CORBASI, KOFTE PATTES PILAVI, MEVSIM SALATA, 2 BARDAK YOGURT, KAHVALTI' },
    { date: '2024-09-03', menu: 'EZOGELİN CORBA, KUMPIR, VEIZ KEBABI, ARPA SEHRIYELI PILAVI, MEVSIM SALATA, 2 BARDAK YOGURT' },
    { date: '2024-09-04', menu: 'MANTI YANINDA YOĞURT, MEYVE (MEVSIM), KARIŞIK SALATA, KOLA, 2 BARDAK YOGURT' },
    { date: '2024-09-05', menu: 'ETLİ NOHUT, KURU FASULYE, MEYVE (MEVSIM), MEVSIM SALATA, 2 BARDAK YOGURT, TATLI' },
    { date: '2024-09-06', menu: 'MERCIMEK ÇORBASI, GÜVEÇ YANINDA PILAV, KOLA, MEVSIM SALATA, 2 BARDAK YOGURT' },
];

const getDaysInMonth = (year, month) => {
    const daysInMonth = [];
    const firstDay = moment(`${year}-${month + 1}-01`).startOf('month').day();
    const daysInMonthCount = moment(`${year}-${month + 1}-01`).endOf('month').date();
    for (let i = 1; i <= daysInMonthCount; i++) {
        daysInMonth.push(i);
    }
    return { daysInMonth, firstDay };
};

const formatMenu = (menu) => {
    return menu.split(',').map((item, index) => (
        <div key={index}>
            {item.trim()}
        </div>
    ));
};

const MenusList = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [mealForDate, setMealForDate] = useState('');
    const year = moment().year();
    const month = moment().month(); // 0-based month index

    const handleDateClick = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        setSelectedDate(formattedDate);
        const meal = menuData.find(meal => meal.date === formattedDate);
        setMealForDate(meal ? formatMenu(meal.menu) : 'Bu tarihte yemek listesi bulunmamaktadır.');
    };

    const { daysInMonth, firstDay } = getDaysInMonth(year, month);
    window.scrollTo(0, 0);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '94vh',
            padding: '3%',
        }}>
            <h3 style={{
                color: colors.blue,
                fontWeight: '700',
                border: 'none',
                marginBottom: '20px',
                textAlign: 'left',
                width: '100%'
            }}>
                Ağustos Ayı Takvimi
            </h3>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gap: '2px',
                width: "900px",
                marginBottom: '20px',
                fontSize: '18px', // Takvim yazı boyutu
            }}>
                <div style={{ textAlign: 'center', color:"#2B7199"  }}>Pazartesi</div>
                <div style={{ textAlign: 'center',  color:"#2B7199"}}>Salı</div>
                <div style={{ textAlign: 'center',  color:"#2B7199"}}>Çarşamba</div>
                <div style={{ textAlign: 'center' , color:"#2B7199" }}>Perşembe</div>
                <div style={{ textAlign: 'center', color:"#2B7199" }}>Cuma</div>
                <div style={{ textAlign: 'center',  color:"#2B7199"}}>Cumartesi</div>
                <div style={{ textAlign: 'center',  color:"#2B7199" }}>Pazar</div>
                {[...Array(firstDay)].map((_, i) => <div key={`empty-${i}`}></div>)}
                {daysInMonth.map(day => {
                    const date = `${year}-${month + 1}-${day < 10 ? `0${day}` : day}`;
                    const isSelected = selectedDate === moment(date).format('YYYY-MM-DD');
                    return (
                        <div
                            key={day}
                            style={{
                                textAlign: 'center',
                                cursor: 'pointer',
                                padding: '5px',
                                border: `1px solid ${colors.blue}`,
                                height: '55px', // Daha büyük hücre yüksekliği
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '20px', // Gün numarası font boyutu
                                borderRadius:"5px",
                                backgroundColor: isSelected ? colors.blue : 'white', // Seçili tarih kutusunun rengi
                                color: isSelected ? 'white' : 'black', // Seçili olduğunda yazı rengi
                            }}
                            onClick={() => handleDateClick(date)}
                        >
                            {day}
                        </div>
                    );
                })}
            </div>
            <div style={{
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                backgroundColor: '#F9F9F9',
                width: '100%',
                maxWidth: '1200px',
                textAlign: 'center'
            }}>
                <h4>{selectedDate ? moment(selectedDate).format('DD MMMM YYYY') : 'Tarih Seçin'}</h4>
                <div>{mealForDate}</div>
            </div>
        </div>
    );
};

export default MenusList;
