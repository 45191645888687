import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal,Tooltip} from 'antd';
import NotificationService from "../../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../../environments/environment"
import SetupAdd from "../setupAdd";
import FolderAdd from "../folderAdd";
import WaitingOpenOrderUpdateDetail from "./waitingOpenOrderUpdateDetail";


const WaitingOpenOrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const record = location.state && location.state.record;
    console.log("record",record);
  
    const [selectedItemsDetails, setSelectedItemsDetails] = useState([])
   
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    const handleRequestRemoval = (record) => {
      setRecordForModal(record);
      setIsModalVisible(true);
  };
  
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleRequestRemovalFinish = () => {
      setIsModalVisible(false); 
    };
  

    const [isModalVisibleSetup, setIsModalVisibleSetup] = useState(false);
    const [recordForModalSetup, setRecordForModalSetup] = useState(null);

    const handleSetup = (record) => {
      console.log("setup yükleye basıldı record",record)
      setRecordForModalSetup(record);
      setIsModalVisibleSetup(true);
  };
  
    const handleModalCancelSetup = () => {
      setIsModalVisibleSetup(false);
    };
  
    const handleFinishSetup = () => {
      setIsModalVisibleSetup(false); 
    };
  
    const handleOpenOrderContinue = (record) => {
     //open ordercontine olmalı
     console.log("siparişe basla açıldı",record)

     //isOfferApproved vs sonradan eklendi
     const selectedOpenOrderInfo = {
      companyName: record.companyName || null,
      orderType: record.orderType || null,
      projectCode: record.projectCode || null,
      warehouseName: null,
      isOfferApproved: record.isOfferApproved  || false,
      isOffer: record.isOffer  || false,
      isSetup: record.isSetup  || false,
      isOfferSetupUpdate: record.isOfferSetupUpdate  || false,
      
    };
    
  
    console.log("selectedOpenOrderInfo", selectedOpenOrderInfo);
     navigate('/openorderaddcontinue', { state: { record: selectedOpenOrderInfo, jobCode:record.jobCode} });

  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
    
      
    };
  
    fetchData();
  }, [record.requestedProductInfo]);
  
  const getProductDetailById = async (productId) => {
    try {
      const productDetail = await FirebaseService.getRequestedProductDataById(productId);
      return productDetail;
    } catch (error) {
      console.error('Hata:', error);
      return null;
    }
  };
  
  const goPage = (record) => {
    navigate('/purchaserequestsendmail', { state: {selectedItemsDetails, record } })
  };
   
  const columnsRaw = [
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        render: (text) => <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        width: 200,
      },
      {
        title : '',
        dataIndex : 'isSendPurchaseicon',
        key : 'isSendPurchaseicon',
  
  render: (text, record) => {
      return (
        <>
          {record.isSendPurchase ? (
            <Tooltip title="Satın Alma Talebi Gönderildi">
              <div >
                <img width={20} height={20} alt="logo" src="/images/isSendPurchaseicon.png" style={{ paddingBottom: "5%" }} />
              </div>
            </Tooltip>
          ) : null
          }
        </>
      );
    },
    
        
          
      
          
             
      },
      
    ];

   
   return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Bekleyen Sipariş Detay</h2>
          
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Setup İsmi</p>
          {record && record.setupName ?  record.setupName  : "-"}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
          {record && record.status}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
          {record && record.deadline && typeof record.deadline === 'object' ?
            new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
            record.deadline
          }
        </div>
      </div>

      {record.orderType === "order" && (
        <>
          <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px" }}>Seçilen Hammadeler</div>
          <Table
            style={{width:"70%", margin:"auto"}}
            locale={{
              emptyText: 'Seçilen hammade bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
              dataSource={record.stock}
              columns={columnsRaw}
              showHeader={true}
              pagination={false} 
              className="custom-news-table"
              rowClassName="custom-news-row"
              
          />
        </>
       
      )}
      <div style={{display:"flex",justifyContent:"space-between", marginTop:"50px"}}>      
        <div>
          <Button
            style={{
              backgroundColor: "#9D321A",
              color: "white",
              fontWeight: "500",
              float:"right",
              marginRight:"100px",
              display : record.orderType === "offer" && record.noOfferApprovedDescription !=null ? "flex" : "none"
            }}  
            onClick={() => handleRequestRemoval(record)}
            >
              Güncelleme Detay
          </Button>

          <Modal
            title="Güncelleme Detay"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
          >
            <WaitingOpenOrderUpdateDetail  record={recordForModal}  onClose={handleRequestRemovalFinish} />
          </Modal>
        </div>

        <div >
        {record.isOfferSetupUpdate && (
          <>
           <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleSetup(record)} >
            Setup Yükle
          </Button>
            <Modal
            title="Setup Yükle"
            visible={isModalVisibleSetup}
            onCancel={handleModalCancelSetup}
            footer={null}
          >
            <SetupAdd  recordx={recordForModalSetup}  onClose={handleFinishSetup} />
          </Modal></>
         
        )}

{(record.orderType === "order" && !record.isSetup && record.isWaiting && record.stock != null
) && (
          <>
           <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleSetup(record)} >
            Setup Yükle
          </Button>
            <Modal
            title="Setup Yükle"
            visible={isModalVisibleSetup}
            onCancel={handleModalCancelSetup}
            footer={null}
          >
            <SetupAdd  recordx={recordForModalSetup}  onClose={handleFinishSetup} />
          </Modal></>
         
        )}
       
       {record.isOfferApproved && (
          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleOpenOrderContinue(record)} >
            Siparişe Başla
           </Button>
        )}


        {(record.orderType === "order" && !record.isSetup && record.stock === null ) && (
          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleOpenOrderContinue(record)} >
            Stok Seçimi
           </Button>
        )}


{(record.orderType === "offer" && !record.isSetup && record.isWaiting && !record.isOfferApproved ) && (
          <>
           <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={() => handleSetup(record)} >
            Setup Yükle
          </Button>
            <Modal
            title="Setup Yükle"
            visible={isModalVisibleSetup}
            onCancel={handleModalCancelSetup}
            footer={null}
          >
            <SetupAdd  recordx={recordForModalSetup}  onClose={handleFinishSetup} />
          </Modal></>
         
        )}

        </div>
        </div>


    </div>


  )};
export default WaitingOpenOrderDetail;