import React, { useEffect, useState } from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors } from "../../../environments/environment";

const InvoicePurchaseListLimited = () => {
  const [itemsLimited, setItemsLimited] = useState([]);
  const navigate = useNavigate();

  const groupByOfferJobCode = (data) => {
    return data.reduce((groupedData, item) => {
      const { offerJobCode, companyName, deadline, totalKg, supplierName, ...rest } = item;
      const newItem = { offerJobCode, companyName, deadline, totalKg, supplierName, data: [{ ...rest }] };
      if (!groupedData[offerJobCode]) {
        groupedData[offerJobCode] = newItem;
      } else {
        groupedData[offerJobCode].data.push({ ...rest });
      }
      return groupedData;
    }, {});
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await FirebaseService.getInvoicePurchaseData();
      const filteredData = data.filter((item) => !item.isDeleted);
      const limitedData = filteredData.slice(0, 5);
      setItemsLimited(limitedData);
    };

    fetchData();
  }, []);

  const groupedItemsLimited = Object.values(groupByOfferJobCode(itemsLimited));
  console.log("groupedItemsLimited ", groupedItemsLimited);

  const columns = [
    {
      title: 'Sipariş Kod',
      dataIndex: 'offerJobCode',
      key: 'offerJobCode',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
    },
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Tedarikçi',
      dataIndex: 'supplierName',
      key: 'supplierName',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Termin Tarihi',
      dataIndex: 'deadline',
      key: 'deadline',
      render: (text, record) => {
        const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
        return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
      },
    },
    {
      title: 'Toplam Fiyat',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
          {(record.data.reduce((acc, dataItem) => acc + (parseFloat(dataItem.price) || 0), 0))} TL
        </span>
      ),
    },
  ];

  const goPage = (record) => {
    navigate('/invoicepurchaselist');
  };

  return (
    <div style={{ justifyContent: 'center', alignItems: 'center', minHeight: '94vh', padding: "3%", border: '2px solid rgb(175, 211, 231)',
        borderRadius: '15px' }}>
      <Button onClick={() => goPage()} className="title-limited-list" >
        Satın Alma Faturaları
      </Button>
      <Table
        locale={{
          emptyText: 'Henüz bulunmamaktadır...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={groupedItemsLimited}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
    </div>
  );
};

export default InvoicePurchaseListLimited;
