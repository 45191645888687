import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Input, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import { colors, parsCollections, placeholderMap} from "../../../environments/environment"
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
const InvoiceScrapsList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
   
    useEffect(() => {
     window.scrollTo(0, 0);
    
     const fetchData = async () => {
      const data = await FirebaseService.getScrapsData();
      const filteredData = data.filter(item => !item.isDeleted);
    
      const sortedData = filteredData.sort((a, b) => {
        const isEqualA = a.invoiceNumber ? true : false;
        const isEqualB = b.invoiceNumber ? true : false;
    
        if (!isEqualA && isEqualB) return -1;
        if (isEqualA && !isEqualB) return 1;
        return 0;
      });
    
      setFilteredItems(sortedData);
      setItems(sortedData);
    };
    
     fetchData();
    
    }, []);
   
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const handleCancel = () => {
     setIsModalVisible(false);
    };
    const showModal = (record) => {
     setSelectedRecord(record);
     setIsModalVisible(true);
    };
    const handleInputChange = (e) => {
     setInvoiceNumber(e.target.value);
    };
    const handleSave = async () => {
     if (!selectedRecord) return;
     console.log("selectedRecord",selectedRecord)
     const newItemWithAdditionalProperties = {
      ...selectedRecord,
      invoiceNumber: Number(invoiceNumber),
     };
     console.log("Yeni öğe:", newItemWithAdditionalProperties);
     try {
      const result = await FirebaseService.updateRecording(parsCollections.scraps,newItemWithAdditionalProperties.key,newItemWithAdditionalProperties);
      if (result.success) {
       NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: "Başarıyla kaydedildi",
        placement: 'topRight'
       });
       setIsModalVisible(false);
       setInvoiceNumber('');
       setTimeout(() => {
        window.location.reload();
       }, 500);
      } else {
       NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
       });
      }
     } catch (error) {
      console.log('Firebase Güncelleme Hatası:', error);
      NotificationService.openErrorNotification({
       title: 'İşlem Başarısız',
       description: "Bilinmeyen bir hata ile karşılaşıldı.",
       placement: 'topRight'
      });
     }
    };
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
     confirm();
     const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return !item.isDeleted && (value ? itemValue.includes(value) : true);
     });
     setFilteredItems(filteredData);
    };
    const handleReset = clearFilters => {
     clearFilters();
     setFilteredItems(items);
    };
    const getColumnSearchProps = dataIndex => ({
     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
       <Input
        id="search-input"
        placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
       />
       <Space>
        <Button
         type="primary"
         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
         icon={<SearchOutlined />}
         size="small"
         style={{ width: 90, background: colors.blue }}
        >
         Ara
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
         Sıfırla
        </Button>
       </Space>
      </div>
     ),
     filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890FF' : undefined }} />,
     onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
     onFilterDropdownVisibleChange: visible => {
      if (visible) {
       setTimeout(() => document.getElementById('search-input').select(), 100);
      }
     },
    });
    const calculateTotalPrice = (materials) => {
     let totalPrice = 0;
     for (const key in materials) {
      if (materials.hasOwnProperty(key)) {
       const material = materials[key];
       totalPrice += material.price * material.kg;
      }
     }
     return totalPrice;
    };
    const columns = [
     {
      title: 'Verilen Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
       const companyNameA = a && a.companyName ? a.companyName : '';
       const companyNameB = b && b.companyName ? b.companyName : '';
       return companyNameA.localeCompare(companyNameB);
      }
     },
     {
      title: 'Durum',
      dataIndex: '',
      key: '',
      render: (text, record) => {
       const isEqual = record.invoiceNumber ? true : false;
       return (
        <span style={{ color: isEqual ? colors.green : colors.red }}>
         {isEqual ? 'Kesilmiş' : 'Bekleyen'}
        </span>
       );
      },
     },
     {
      title: 'Toplam Fiyat',
      dataIndex: '',
      key: 'totalPrice',
      render: (text, record) => {
       const totalPrice = calculateTotalPrice(record.materials);
       return (
        <span style={{ }}>
         {totalPrice}
        </span>
       );
      },
     },
     /*
     {
      title: 'Siyah',
      dataIndex: 'materials',
      key: 'black',
      render: (text, record) => `${record.materials.black.kg} kg / ${record.materials.black.price} TL`,
      sorter: (a, b) => a.materials.black.kg - b.materials.black.kg,
     },
     {
      title: 'Alüminyum',
      dataIndex: 'materials',
      key: 'aluminum',
      render: (text, record) => `${record.materials.aluminum.kg} kg / ${record.materials.aluminum.price} TL`,
      sorter: (a, b) => a.materials.aluminum.kg - b.materials.aluminum.kg,
     },
     {
      title: 'Krom',
      dataIndex: 'materials',
      key: 'chromium',
      render: (text, record) => `${record.materials.chromium.kg} kg / ${record.materials.chromium.price} TL`,
      sorter: (a, b) => a.materials.chromium.kg - b.materials.chromium.kg,
     },
     {
      title: 'Demir Tozu',
      dataIndex: 'materials',
      key: 'ironDust',
      render: (text, record) => `${record.materials.ironDust.kg} kg / ${record.materials.ironDust.price} TL`,
      sorter: (a, b) => a.materials.ironDust.kg - b.materials.ironDust.kg,
     },
     */
     {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => {
       if (record.invoiceNumber) {
        return null;
       }
       return (
        <Button
        style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
        onClick={() => showModal(record)}
       >
        Fatura No Gir
       </Button>
       );
      },
     }
    ];
    return <div style={{
       justifyContent: 'center',
       alignItems: 'center',
       minHeight: '94vh',
       padding:"3%",
      }}>
       <h3 style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Hurda Faturaları</h3>
        <Table
         locale={{
          emptyText: 'Henüz bekleyen faturalar bulunmamaktadır...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
         }}
         dataSource={filteredItems}
         columns={columns}
         showHeader={true}
         pagination={false}
         className="custom-news-table"
         rowClassName="custom-news-row"
       />
<   Modal
       title="Fatura Numarası Gir"
       visible={isModalVisible}
       onCancel={handleCancel}
       footer={[
        <Button key="cancel" onClick={handleCancel}>
         İptal
        </Button>,
        <Button key="save"  style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={handleSave}>
         Kaydet
        </Button>,
       ]}
      >
       <p style={{ fontWeight: "bold", color: colors.blue }}>Fatura Numarası :
        <Input
        placeholder="Fatura Numarası"
        value={invoiceNumber}
        onChange={handleInputChange}
        style={{ marginTop: '10px', marginLeft:"20px" }}
        className="input-style"
       />
       </p>
      </Modal>
     </div>
    };
    export default InvoiceScrapsList;
  
  
  
  
  
  
  
  
  












