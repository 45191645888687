import React, { useEffect, useState } from "react";
import { Table, Button, Spin, Input, Space, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";
import { colors } from "../../environments/environment"; // Renk paleti

const StockListAdmin = () => {
  const [items, setItems] = useState([]); // Stok verileri
  const [filteredItems, setFilteredItems] = useState([]); // Filtrelenmiş stok verileri
  const [wastageItems, setWastageItems] = useState([]); // Fire verileri
  const [filteredWastageItems, setFilteredWastageItems] = useState([]); // Filtrelenmiş Fire verileri
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null); // Seçilen stok kaydı
  const [selectedRecordWastage, setSelectedRecordWastage] = useState(null); // Seçilen Fire kaydı
  const [isModalVisible, setIsModalVisible] = useState(false); // Stok modal görünürlüğü
  const [isWastageModalVisible, setIsWastageModalVisible] = useState(false); // Fire modal görünürlüğü
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      // Stok verilerini al
      const stockData = await FirebaseService.getStocksData();
      const filteredStockData = stockData.filter(item => !item.isDeleted);
      setItems(filteredStockData);
      setFilteredItems(filteredStockData);

      // Fire verilerini al
      const wastageData = await FirebaseService.getWastagesData();
      const filteredWastageData = wastageData.filter(item => !item.isDeleted);
      setWastageItems(filteredWastageData);
      setFilteredWastageItems(filteredWastageData);
    };
    fetchData();
  }, []);

  const goEditPage = (record) => {
    navigate('/stocklistadminedit', { state: { record } });
  };
  const goEditPageWastage = (record) => {
    navigate('/wastagelistadminedit', { state: { record } });
  };


  const showDeleteConfirm = (record) => {
    setSelectedRecord(record); // Seçilen stok kaydını ayarla
    setIsModalVisible(true); // Stok modalını aç
  };

  const showWastageDeleteConfirm = (record) => {
    setSelectedRecordWastage(record); // Seçilen Fire kaydını ayarla
    setIsWastageModalVisible(true); // Fire modalını aç
  };

  const handleDelete = async () => {
    // waitingPiece ve reservedPiece değerlerini 0, null, undefined ve "" kontrolü yaparak silme işlemini gerçekleştir
    const waitingPieceValid = selectedRecord.waitingPiece == null || selectedRecord.waitingPiece === "" || selectedRecord.waitingPiece === 0;
    const reservedPieceValid = selectedRecord.reservedPiece == null || selectedRecord.reservedPiece === "" || selectedRecord.reservedPiece === 0;

    // Eğer waitingPiece veya reservedPiece değeri 0 değilse stok silme işlemi yapılmaz
    if (!waitingPieceValid || !reservedPieceValid) {
      NotificationService.openErrorNotification({
        title: 'Silme Başarısız',
        description: 'Silmek işleminin gerçekleşmesi için beklenen ve rezerve adet sıfır olmalıdır.',
        placement: 'topRight'
      });
      setIsModalVisible(false);
      return;
    }

    setLoading(true);
    await FirebaseService.deleteStock(selectedRecord.key);
    const newItems = items.filter(item => item.key !== selectedRecord.key);
    setItems(newItems);
    setFilteredItems(newItems);
    setLoading(false);
    setIsModalVisible(false); // Stok modalını kapat
    NotificationService.openSuccessNotification({
      title: 'Silme Başarılı',
      description: 'Stok başarıyla silindi.',
      placement: 'topRight'
    });
  };


  const handleWastageDelete = async () => {
    // waitingPiece ve reservedPiece değerlerini 0, null, undefined ve "" kontrolü yaparak silme işlemini gerçekleştir
    const waitingPieceValid = selectedRecordWastage.waitingPiece == null || selectedRecordWastage.waitingPiece === "" || selectedRecordWastage.waitingPiece === 0;
    const reservedPieceValid = selectedRecordWastage.reservedPiece == null || selectedRecordWastage.reservedPiece === "" || selectedRecordWastage.reservedPiece === 0;

    // Eğer waitingPiece veya reservedPiece değeri 0 değilse Fire silme işlemi yapılmaz
    if (!waitingPieceValid || !reservedPieceValid) {
      NotificationService.openErrorNotification({
        title: 'Silme Başarısız',
        description: 'Silmek işleminin gerçekleşmesi için beklenen ve rezerve adet sıfır olmalıdır.',
        placement: 'topRight'
      });
      setIsWastageModalVisible(false);
      return;
    }

    setLoading(true);
    await FirebaseService.deleteWastage(selectedRecordWastage.key); // Fire silme işlemi
    const newWastageItems = wastageItems.filter(item => item.key !== selectedRecordWastage.key);
    setWastageItems(newWastageItems);
    setFilteredWastageItems(newWastageItems);
    setLoading(false);
    setIsWastageModalVisible(false); // Fire modalını kapat
    NotificationService.openSuccessNotification({
      title: 'Silme Başarılı',
      description: 'Fire başarıyla silindi.',
      placement: 'topRight'
    });
  };


  const handleCancel = () => {
    setIsModalVisible(false); // Stok modalını kapat
    setIsWastageModalVisible(false); // Fire modalını kapat
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  
    // Eğer selectedKeys[0] boşsa tüm öğeleri göster
    if (!selectedKeys[0]) {
      setFilteredItems(items);
      return;
    }
  
    const filteredData = items.filter(item => {
      const value = selectedKeys[0].toLowerCase(); // Kullanıcı girişi
  
      // Boş veya undefined kontrolü
      const itemValue = item[dataIndex];
  
      // Eğer itemValue null veya undefined ise false döndür
      if (itemValue === null || itemValue === undefined) {
        return false;
      }
  
      // Eğer beklenen veya rezerve adet ise sayısal karşılaştırma yap
      if (dataIndex === 'waitingPiece' || dataIndex === 'reservedPiece') {
        return itemValue.toString().includes(value);
      }
  
      // Diğer durumlar için string karşılaştırma
      return itemValue.toString().toLowerCase().includes(value);
    });
  
    setFilteredItems(filteredData);
  };

  const handleWastageSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  
    // Eğer selectedKeys[0] boşsa tüm öğeleri göster
    if (!selectedKeys[0]) {
      setFilteredWastageItems(wastageItems);
      return;
    }
  
    const filteredData = wastageItems.filter(item => {
      const value = selectedKeys[0].toLowerCase(); // Kullanıcı girişi
  
      // Boş veya undefined kontrolü
      const itemValue = item[dataIndex];
  
      // Eğer itemValue null veya undefined ise false döndür
      if (itemValue === null || itemValue === undefined) {
        return false;
      }
  
      // Eğer beklenen veya rezerve adet ise sayısal karşılaştırma yap
      if (dataIndex === 'waitingPiece' || dataIndex === 'reservedPiece') {
        return itemValue.toString().includes(value);
      }
  
      // Diğer durumlar için string karşılaştırma
      return itemValue.toString().toLowerCase().includes(value);
    });
  
    setFilteredWastageItems(filteredData);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };

  const handleWastageReset = clearFilters => {
    clearFilters();
    setFilteredWastageItems(wastageItems);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? colors.blue : undefined }} />,
    onFilter: (value, record) => {
      // Değerin null kontrolü
      const itemValue = record[dataIndex];
      if (itemValue === null || itemValue === undefined) {
        return false; // Eğer değer null veya undefined ise false döndür
      }
      return itemValue.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
    sorter: (a, b) => {
        // Eğer her iki değer de sayısal ise sayısal karşılaştırma yap
        if (typeof a[dataIndex] === 'number' && typeof b[dataIndex] === 'number') {
          return a[dataIndex] - b[dataIndex];
        }
        
        // Diğer durumlarda string karşılaştırma yap
        if (typeof a[dataIndex] === 'string' && typeof b[dataIndex] === 'string') {
          return a[dataIndex].localeCompare(b[dataIndex]);
        }
      
        // Farklı türlerde değerler varsa (örn. biri null diğeri string) sıfır döndür
        return 0;
      }
      
  });

  const getWastageColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input-wastage"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleWastageSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleWastageSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
          >
            Ara
          </Button>
          <Button onClick={() => handleWastageReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? colors.blue : undefined }} />,
    onFilter: (value, record) => {
      const itemValue = record[dataIndex];
      if (itemValue === null || itemValue === undefined) {
        return false;
      }
      return itemValue.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input-wastage').select(), 100);
      }
    },
    sorter: (a, b) => {
        // Eğer her iki değer de sayısal ise sayısal karşılaştırma yap
        if (typeof a[dataIndex] === 'number' && typeof b[dataIndex] === 'number') {
          return a[dataIndex] - b[dataIndex];
        }
        
        // Diğer durumlarda string karşılaştırma yap
        if (typeof a[dataIndex] === 'string' && typeof b[dataIndex] === 'string') {
          return a[dataIndex].localeCompare(b[dataIndex]);
        }
      
        // Farklı türlerde değerler varsa (örn. biri null diğeri string) sıfır döndür
        return 0;
      }
      
  });

  const columns = [
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('thickness'),
    },
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('quality'),
    },
    {
      title: 'En',
      dataIndex: 'width',
      key: 'width',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('width'),
    },
    {
      title: 'Boy',
      dataIndex: 'height',
      key: 'height',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('height'),
    },
    {
      title: 'Adet',
      dataIndex: 'piece',
      key: 'piece',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('piece'),
    },
    {
      title: 'Rezerve Adet',
      dataIndex: 'reservedPiece',
      key: 'reservedPiece',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('reservedPiece'),
    },
    {
      title: 'Beklenen Adet',
      dataIndex: 'waitingPiece',
      key: 'waitingPiece',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getColumnSearchProps('waitingPiece'),
    },
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <a onClick={() => goEditPage(record)}>
          <img style={{ width: "20px" }} alt="edit" src="/images/newEditButton.png" />
        </a>
      ),
    },
    {
      title: 'Sil',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        <Button
          onClick={() => showDeleteConfirm(record)}
          style={{ background: 'none', border: 'none', padding: 0 }} // Buton stilini özelleştir
        >
          <img src="/images/deletebutton.png" alt="Sil" style={{ width: "20px", height: "20px" }} />
        </Button>
      ),
    }
  ];

  const wastageColumns = [
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getWastageColumnSearchProps('thickness'),
    },
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getWastageColumnSearchProps('quality'),
    },
    {
      title: 'En',
      dataIndex: 'width',
      key: 'width',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getWastageColumnSearchProps('width'),
    },
    {
      title: 'Boy',
      dataIndex: 'height',
      key: 'height',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getWastageColumnSearchProps('height'),
    },
    {
      title: 'Adet',
      dataIndex: 'piece',
      key: 'piece',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getWastageColumnSearchProps('piece'),
    },
    {
      title: 'Rezerve Adet',
      dataIndex: 'reservedPiece',
      key: 'reservedPiece',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getWastageColumnSearchProps('reservedPiece'),
    },
    {
      title: 'Beklenen Adet',
      dataIndex: 'waitingPiece',
      key: 'waitingPiece',
      render: text => <span style={{ fontWeight: "bold" }}>{text}</span>,
      ...getWastageColumnSearchProps('waitingPiece'),
    },
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <a onClick={() => goEditPageWastage(record)}>
          <img style={{ width: "20px" }} alt="edit" src="/images/newEditButton.png" />
        </a>
      ),
    },
    {
      title: 'Sil',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        <Button
          onClick={() => showWastageDeleteConfirm(record)}
          style={{ background: 'none', border: 'none', padding: 0 }} // Buton stilini özelleştir
        >
          <img src="/images/deletebutton.png" alt="Sil" style={{ width: "20px", height: "20px" }} />
        </Button>
      ),
    }
  ];

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%"
    }}>
      <h2 style={{ color: colors.blue, fontSize: '18px', textAlign: 'left' }}>Güncel Stok</h2>
      <Table
        scroll={{ y: 400 }}
        locale={{
          emptyText: 'Henüz stok eklenmedi...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={filteredItems}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />

      <h2 style={{ color: colors.blue, fontSize: '18px', textAlign: 'left' }}>Güncel Fire</h2>
      <Table
        scroll={{ y: 400 }}
        locale={{
          emptyText: 'Henüz Fire eklenmedi...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={filteredWastageItems} // Fire verileri
        columns={wastageColumns} // Fire için sütun yapısı
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
     
      {/* Stok Onay Modalı */}
      <Modal
        title="Silme Onayı"
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
        okButtonProps={{ style: { backgroundColor: colors.blue, borderColor: colors.blue, color: "white" } }}
      >
        <p>Silmek istediğiniz stok bilgileri:</p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div><strong>Kalınlık:</strong> {selectedRecord?.thickness}</div>
          <div><strong>Kalite:</strong> {selectedRecord?.quality}</div>
          <div><strong>En:</strong> {selectedRecord?.width}</div>
          <div><strong>Boy:</strong> {selectedRecord?.height}</div>
          <div><strong>Adet:</strong> {selectedRecord?.piece}</div>
          <div><strong>Rezerve Adet:</strong> {selectedRecord?.reservedPiece}</div>
          <div><strong>Beklenen Adet:</strong> {selectedRecord?.waitingPiece}</div>
        </div>
      </Modal>

      {/* Fire Onay Modalı */}
      <Modal
        title="Silme Onayı"
        visible={isWastageModalVisible}
        onOk={handleWastageDelete}
        onCancel={handleCancel}
        okText="Evet"
        cancelText="Hayır"
        okButtonProps={{ style: { backgroundColor: colors.blue, borderColor: colors.blue, color: "white" } }}
      >
        <p>Silmek istediğiniz Fire bilgileri:</p>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div><strong>Kalınlık:</strong> {selectedRecordWastage?.thickness}</div>
          <div><strong>Kalite:</strong> {selectedRecordWastage?.quality}</div>
          <div><strong>En:</strong> {selectedRecordWastage?.width}</div>
          <div><strong>Boy:</strong> {selectedRecordWastage?.height}</div>
          <div><strong>Adet:</strong> {selectedRecordWastage?.piece}</div>
          <div><strong>Rezerve Adet:</strong> {selectedRecordWastage?.reservedPiece}</div>
          <div><strong>Beklenen Adet:</strong> {selectedRecordWastage?.waitingPiece}</div>
        </div>
      </Modal>

      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1001,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default StockListAdmin;
