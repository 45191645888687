import React, { useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithCredential, PhoneAuthCredential, PhoneAuthProvider } from "firebase/auth";
import NotificationService from "../../services/antNotificationService";

const Verify = () => {
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState('');

  const handleSubmit = async () => {
    try {
      // Retrieve user data from sessionStorage
      const userData = JSON.parse(sessionStorage.getItem('userData'));

      if (!userData) {
        throw new Error('User data not found');
      }

      const { phone, confirmationResult } = userData;

      // Confirm the phone number with the verification code
      const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);
      await signInWithCredential(getAuth(), credential);

      // TODO: Store user data in Firestore or perform other necessary actions

      NotificationService.openSuccessNotification({
        title: 'Verification Successful',
        description: 'Your phone number has been successfully verified.',
        placement: 'topRight',
      });

      // Navigate to the desired page after successful verification
      navigate('/dashboard');
    } catch (error) {
      console.error(error);

      NotificationService.openErrorNotification({
        title: 'Verification Failed',
        description: 'Please check the verification code and try again.',
        placement: 'topRight',
      });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '94vh' }}>
      <Card style={{ borderRadius: 0, border: "none", borderBottom: "1px solid", borderColor: "#A47B5A", width: "80%" }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1>Phone Number Verification</h1>
          <Form onFinish={handleSubmit}>
            <Form.Item label="Verification Code" name="verificationCode" rules={[{ required: true, message: 'Please enter the verification code' }]}>
              <Input onChange={(e) => setVerificationCode(e.target.value)} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Verify
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Verify;
