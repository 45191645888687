import React, { useState } from "react";
import { Button, Dropdown, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import UserInfo from "../userInfo";
import WeatherData from "../weatherData";
import  { parsCollections, colors}  from "../../../environments/environment"
import AlarmListLimited from "../alarms/alarmListLimited";
import ForeignCurrency from "../foreignCurrency";
import ReservedListLimited from "../reservedListLimited";
import Pdf from './ProgrammerPage.pdf';

import InvoiceOpenOrderListLimited from "../invoices/invoiceOpenOrderListLimited";
import InvoiceScrapsListLimited from "../invoices/invoiceScrapsListLimited";
import InvoiceContractManufacturingListLimited from "../invoices/invoiceContractManufacturingListLimited";
import Notifications from "../notifications";
import WebNavbar from "../webNavbar";

const AccountingPage = () => {

  const navigate = useNavigate();

  
  const handleExpenseList = () => {
    navigate('/expenselist')
  };
  const handleInvoicePurchaseList = () => {
    navigate('/invoicepurchaselist')
  };
  const handleInvoiceReturnRawList = () => {
    navigate('/invoicereturnrawlist')
  };
  
  
  return (
    <div style={{padding:"3%"}}>
       <WebNavbar />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
        <InvoiceOpenOrderListLimited />
        </div>
        <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
          <InvoiceContractManufacturingListLimited />
        </div>
        <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
          <InvoiceScrapsListLimited />
        </div>
        <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
          <div>
          <AlarmListLimited />
          </div>
        
          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "10px" }}>
            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
              
            <Button 
              className="classic-button"
                  onClick={handleExpenseList}>
               Gider Listesi
              </Button>

              </div>
              
              <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
              <Button 
              className="classic-button"
                  onClick={handleInvoicePurchaseList}>
             Satın Alma Faturaları
              </Button>
             
              </div>

            
              <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            
              <Button 
              className="classic-button"
                  onClick={handleInvoiceReturnRawList}>
              İadeler
              </Button>

            

              </div>

              <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            
              </div>



          </div>

    
    
        </div>
      </div>
   
    </div>
  );
};

export default AccountingPage;

