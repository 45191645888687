import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Upload,Modal, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors,openOrderType}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddRequestAdminCompany from "../addRequestAdmin/addRequestAdminCompany";
import SetupAdd from "./setupAdd";


const OpenOrderAdd = ({ onClose }) => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]); 
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);
    const [jobCode, setJobCode] = useState(''); 
    const [companyName, setCompanyName] = useState(''); 
    const [projectCode, setProjectCode] = useState(''); 
    const [orderType, setOrderType] = useState(''); 

    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

      const fetchData = async () => {

        const jobCode= generateRandomCode(5);
        setJobCode(jobCode);
        const companies = await FirebaseService.getCompaniesData();
        const filteredCompanies = companies.filter(item => !item.isDeleted);
        setCompanies(filteredCompanies);
        
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };    
    
    
  const [isModalVisibleCompany, setIsModalVisibleCompany] = useState(false);

  const handleModalCancelCompany = () => {
      setIsModalVisibleCompany(false);
  };

  const handleAddFinishCompany = () => {
      setIsModalVisibleCompany(false);
  };
  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
  
    return result;
  }

  const handleSetup = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSetupFinish = () => {
    setIsModalVisible(false); 
  };

  
  const onFinish = async (record) => {
     console.log("record",record)
      if (record.companyName === "newCompany") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir firma seçiniz.',
          placement: 'topRight'
        });
        return;
      }


        const formattedRecord = {
            ...record,
          //  deadline: new Date(record.deadline),
            jobCode:jobCode
        };


        setRecord(formattedRecord);
        if(record.orderType === "order"){
          navigate('/openorderaddcontinue', { state: { record: formattedRecord, jobCode:jobCode} });

        }else{
          handleSetup(formattedRecord)
        }

    };

    

    const goOnlyJobCode = async () => {
      setLoading(true);
      console.log(companyName, projectCode, orderType)
     
      if (!companyName || !projectCode || !orderType) {
        console.log("boşta")
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: 'Lütfen tüm alanları doldurunuz.',
          placement: 'topRight'
        });
        setLoading(false)
        return;
      }
      else{
        console.log("dolu kayıt")

        const currentDate =new Date()
        const data = {
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
              programmerUserId:localStorage.getItem("uid"),
              isDeleted: false,
              programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
              jobCode : jobCode, 
              companyName: companyName,
              projectCode: projectCode ? projectCode : null,
              orderType :orderType,
              stock: null,
              wastage: null,
              deadline:null,
              isSetup:false,
              isOffer:false,
              isWaiting: true,
              isOfferApproved:false,
              noOfferApprovedDescription:null,
              noQrScanDescription:null,
              isOfferSetupUpdate:false,
              isOfferChange:false,
              isOfferDenied:false,
             // priority:999999,
             isChangedProgrammer:false,
             isShipment: false,
             shipmentPiece: 0,
             invoiceNumber:null,
             setupName: null
             
        };
        console.log("data",data)
  
        try {
            const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
            if (result.success) {
              const item = {
                isDeleted:false,
                item: data.createJobCode,
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
              }
              const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
            
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: "Bekleyen Siparişlere başarıyla kaydedilmitşir.",
                placement: 'topRight'
              });
              setLoading(false);
              onClose();
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
             // console.log("hata",result)
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false)
            }
            
            
        } 
        catch (error) {
            console.log("error",error)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false)
        }
      }
    };


    const handleProjectCode = (e) => {
      setProjectCode(e.target.value);
    };
  
    const handleOrderType = (value) => {
      setOrderType(value);
    };
  
    const handleCompanyName = (value) => {
      setCompanyName(value);
    };
    
   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 9,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            
        >

        <div style={{display:"flex", justifyContent:"space-around"}} >
            <Form.Item
            label="Firma"
            name="companyName"
            rules={[
                {
                required: true,
                message: 'Lütfen firma adı giriniz!',
                },
            ]}
            >
            <Select
                onChange={handleCompanyName}
                placeholder="Firma Adı"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...companies.map(item => ({ label: item.companyName, value: item.companyName })),
                  { label: 'Yeni Firma Ekle', value: 'newCompany' },
                ]}      
                className="select-style"     
                  />
            </Form.Item>
            <Modal
                title="Firma Ekleme Talebi"
                visible={isModalVisibleCompany}
                onCancel={handleModalCancelCompany}
                footer={null}
              >
              <AddRequestAdminCompany onClose={handleAddFinishCompany} />
              </Modal>
          
            <Form.Item
            label="Proje Kodu"
             rules={[
              {
              required: true,
              message: 'Lütfen proje kodu giriniz!',
              },
          ]}
                name="projectCode" >
            <Input   
            className="input-style" 
            placeholder="Proje Kodu"
            onChange={handleProjectCode} 
            />
            </Form.Item>

            <Form.Item
            label="Tür"
          name="orderType"
          rules={[
            {
              required: true,
              message: 'Lütfen seçiniz!',
            },
          ]}
          style={{right:"40px", position:"relative"}}
        >
          <Select
        
            onChange={handleOrderType} 
            placeholder="Sipariş/Teklif"
            className="select-style" 
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={openOrderType.map(item => ({ label: item.label, value: item.value }))}
          />
            </Form.Item>

       </div>
      


       
          <div style={{justifyContent:"right", display:"flex",alignItems:"flex-end"}}>
          {jobCode &&  
             <div style={{display:"block"}}>
              <div style={{ color:colors.blue}}> Atanan İş Kodu</div>
              <div style={{ textAlign: 'center', border:"2px solid #2B7199", width:"100px", borderRadius:"10px",padding:"3px 20px", marginRight:"10px"}}> {jobCode}</div>
             </div>
             }
            <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"200px",marginRight:"10px"}} onClick={() => goOnlyJobCode()}  > İş Kodunu Kaydet </Button>
            <Button htmlType="submit" style={{backgroundColor:colors.blue, color:"white",fontWeight:"500"}}>Devam Et</Button>
          </div>
          <div style={{marginTop:"30px", fontSize:"12px", color:colors.blue}}>
          *Kaydet ve çık denmediği sürece iş kodu kaydedilmeyecektir.
          </div>
        </Form>


        <Modal
          title="Setup Yükle"
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <SetupAdd  recordx={recordForModal}  onClose={handleSetupFinish} />
        </Modal>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

OpenOrderAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

export default OpenOrderAdd;


    /* 
            <Form.Item
            name="warehouseName"
            rules={[
                {
                required: true,
                message: 'Lütfen depo giriniz!',
                },
            ]}
            >
            <Select
                onChange={onChange}
                placeholder="Depo Seçiniz"
                style={{ borderRadius: 0, borderColor: "#1A446C80" }}
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={companies.map(item => ({ label: item.companyName, value: item.companyName }))}
            />
            </Form.Item>

            
            <Form.Item
                name="deadline"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen tarih giriniz!',
                    },
                    
                ]}
                >
                <DatePicker style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY"/>

             </Form.Item>
            
 */