import React, { useState } from 'react';
import { Menu } from 'antd';
import './layouts.css';
import { Link } from 'react-router-dom';

const items = [
  {
    label: 'Anasayfa',
    key: 'home',
    link:'/home'
  },
    {
      label: 'Haber Bülteni',
      key: 'news',
      link:'/newslist'
    },
    {
      label: 'Hizmetler',
      key: 'services',
      link:'/servicelist'
    },
    {
      label: 'Eğitimler',
      key: 'trainings',
      link:'/traininglist'
    },
    ,
    {
      label: 'Blog',
      key: 'blogs',
      link:'/bloglist'
    },
    // {
    //   label: 'Eğitim Talebi',
    //   key: 'trainingrequest',
    //   link:'/trainingrequest'
    // },
    {
      label: 'Kampanyalar',
      key: 'campaigns',
      link:'/campaigns'
    },
  ];
const WebNavi = () => {
    const [current, setCurrent] = useState('home');
    const onClick = (e) => {
     // console.log('click ', e);
      setCurrent(e.key);
    };
    return (
    <Menu
      mode="horizontal"
      selectedKeys={[current]}
      onClick={onClick}
      className="custom-menu"
    >
      {items.map((item) => (
        <Menu.Item key={item.key}>      
          <Link to={item.link}>{item.label}</Link>
        </Menu.Item>
      ))}
    </Menu>
   );
  };
export default WebNavi;