import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import  { colors}  from "../../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const OffersGivenGroupList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("record",record)
   // setItems(record.data);

    if (record) { 
      console.log("record", record);
      setItems(record);
    }
    
   
    const fetchData = async () => {
    };

    fetchData();
  }, [record]);

  const goEditPage = (record) => {
    console.log("record",record)

    navigate('/offersgivenlist', { state: { record } })
  };


  


  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
 
  
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      },

  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Verilen Teklifler</h3>
        <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = items.filter(item => {
            const jobCode = item.jobCode.toLowerCase() ;
            return jobCode.includes(searchValue);
          });
          setItems(filteredData);
        }}
      />

        <Table
            locale={{
              emptyText: 'Henüz verilen teklif bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
           {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>


  };
  export default OffersGivenGroupList;
 