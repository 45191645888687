import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Upload,Modal} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors,months,years}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";


const ExpenseAdd = ({ onClose }) => {
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
       
        
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
          NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };    
        
    const onChange = (value) => {
        console.log(`selected ${value}`);
    };


    const onFinish = async (values) => {
      const record = {
       year:values.year,
       month:values.month
      };
       console.log("record",record)
       navigate('/expenseaddcontinue', { state: { record: record} });
    };
  

   return ( <div>

          <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
    
             
            <Form.Item
            label="Ay"
          name="month"
          rules={[
            {
              required: true,
              message: 'Lütfen seçiniz!',
            },
          ]}
          style={{right:"40px", position:"relative"}}
        >
          <Select
        
            onChange={onChange} 
            placeholder="Ay Seçiniz"
            className="select-style" 
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={months.map(item => ({ label: item.label, value: item.value }))}
          />
            </Form.Item>

            <Form.Item
            label="Yıl"
          name="year"
          rules={[
            {
              required: true,
              message: 'Lütfen seçiniz!',
            },
          ]}
          style={{right:"40px", position:"relative"}}
        >
          <Select
        
            onChange={onChange} 
            placeholder="Yıl Seçiniz"
            className="select-style" 
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={years.map(item => ({ label: item.label, value: item.value }))}
          />
            </Form.Item>


            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Devam Et</Button>
            </Form.Item>

        </Form>



    </div>)

};

ExpenseAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

export default ExpenseAdd;