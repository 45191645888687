import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, placeholderMap } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

const ExitRawList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getExitRawData();
      //const filteredData = data.filter(item => !item.isDeleted);
      console.log("data", data)
      setItems(data);
      setFilteredItems(data);
    };

    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };


  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 ,background:colors.blue}}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Firma Adı',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      width: 200,
      ...getColumnSearchProps('companyName'),
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
      
    },
    {
      title: 'Ürün Adı',
      dataIndex: '',
      key: '',
      width: 200,
      render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text.quality} - {text.surface}- {text.thickness}mm {text.width}x{text.height}</span>,
    },

      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        width: 200,
        ...getColumnSearchProps('piece'),
        sorter: (a, b) => a.piece - b.piece,

      },
      {
        title: 'İrsaliye Numarası',
        dataIndex: 'waybillNumber',
        key: 'waybillNumber',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        width: 200,
        ...getColumnSearchProps('waybillNumber'),
      },
     
    
      {
        title: 'Fatura Numarası',
        dataIndex: 'invoiceNumber',
        key: 'invoiceNumber',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        width: 200,
        ...getColumnSearchProps('invoiceNumber'),
      },
  ];

  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '94vh',
    padding: "3%",
  }}>
    <h3 style={{ textAlign: "left", color: colors.blue }}>Çıkış Yapılan Hammadeler</h3>

    <Table
      locale={{
        emptyText: 'Henüz hammade çıkışı eklemediniz...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItems}
      columns={columns}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
      expandable={{
        expandedRowRender: record => (
          <div style={{ display: 'flex' }}>
          {/* Sol Parça */}
          <div style={{ flex: 1, marginLeft: "55px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Açıklama:</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                {record.description ? record.description : "-"}
              </span>
            </div>
           
          </div>
        
          {/* Orta Parça */}
          <div style={{ flex: 1, marginLeft: "55px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Tarih:</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                {record && record.firstTransactionDate ? (
                  isNaN(Date.parse(record.firstTransactionDate)) ? (
                    "Tarih formatı hatalı"
                  ) : (
                    new Date(record.firstTransactionDate).toLocaleDateString('tr-TR')
                  )
                ) : (
                  "Tarih bulunamadı"
                )}
              </span>
            </div>
          </div>
        
          {/* Sağ Parça */}
         
          <div style={{ flex: 1, marginLeft: "55px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Proje Kodu:</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                {record.projectCode ? record.projectCode : "-"}
              </span>
            </div>
           
          </div>


          <div style={{ flex: 1, marginLeft: "55px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>İş Kodu:</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                {record.jobCode ? record.jobCode : "-"}
              </span>
            </div>
           
          </div>

          <div style={{ flex: 1, marginLeft: "55px" }}>
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>Gönderen Kişi:</span>
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000", marginLeft: "5px" }}>
                {record.firstUserNameSurname ? record.firstUserNameSurname : "-"}
              </span>
            </div>
           
          </div>

        </div>
        
      ),
      
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
          ) : (
            <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
          ),
          expandIconColumnIndex: 5
      }}
    />

  </div>
};

export default ExitRawList;
