import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import  { colors}  from "../../../../environments/environment"
import moment from 'moment';


const ProductionOpenOrderGroupListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const productionData = await FirebaseService.getProductionData();
      const filteredData = productionData.filter(item => !item.isDeleted);
    
      const setupData = await FirebaseService.getSetupData();
      const allOpenOrder = await FirebaseService.getOpenOrderData();

      const updatedData = filteredData.map(item => {
        const jobCode = item.jobCode;
        const setupItem = setupData.find(setup => setup.jobCode === jobCode);


        const filteredOpenOrder = allOpenOrder.filter(item => !item.isDeleted && item.jobCode === jobCode);
        console.log("filteredOpenOrder",filteredOpenOrder)
        const stock = filteredOpenOrder.length > 0 ? filteredOpenOrder[0].stock : [];

        const deadline = filteredOpenOrder.length > 0 ? filteredOpenOrder[0].deadline : null;

        console.log("items", items)
        



          // Eşleşme varsa özellikleri güncelle
        if (setupItem ) {
            return {
              ...item,
              companyName: setupItem.companyName,
              setupDescription: setupItem.setupDescription,
              expectedWastages: setupItem.expectedWastages,
              firstTransactionDate: setupItem.firstTransactionDate,
              documentUrl: setupItem.documentUrl,
              firstUserId: setupItem.firstUserId,
              isDeleted: setupItem.isDeleted,
              isScrapDelivery: setupItem.isScrapDelivery,
              key: setupItem.key,
              machine: setupItem.machine,
              parts: setupItem.parts,
              plateCount: setupItem.plateCount,
              plates: setupItem.plates,
              priorityAbkant: setupItem.priorityAbkant,
              priorityMachine: setupItem.priorityMachine,
              projectCode: setupItem.projectCode,
              programmerName: setupItem.programmerName,
              quality: setupItem.quality,
              setupName: setupItem.setupName,
              setupTime: setupItem.setupTime,
              stock: stock,
              surface: setupItem.surface,
              thickness: setupItem.thickness,
              wastage: setupItem.wastage,
              statusText: "Üretimde", //sonra değişcek
              deadline:deadline
              
            };
        }
        return null;
      }).filter(Boolean); 
    
      const sortedItems = [...updatedData].sort((a, b) => a.priorityMachine - b.priorityMachine);
      console.log("sortedItems",sortedItems)

      const groupedItems = Object.values(groupByJobCode(sortedItems));
      console.log("groupedItems ",groupedItems)
      const limitedData = groupedItems.slice(0, 5); 

      setItemsLimited(limitedData);
      setItems(groupedItems);
    };
    fetchData();
  }, []);


  const groupByJobCode = (data) => {
    console.log("data", data);
    return data.reduce((groupedData, item) => {
      const { jobCode, companyName, projectCode, deadline, ...rest } = item;
      const newItem = { jobCode, companyName, projectCode, deadline, ...rest };
  
      const jobCodePrefix = jobCode.slice(0, 5);
  
      if (!groupedData[jobCodePrefix]) {
        groupedData[jobCodePrefix] = {
          companyName,
          jobCode: jobCodePrefix, 
          projectCode,
          deadline,
          data: [newItem]
        };
      } else {
        groupedData[jobCodePrefix].data.push(newItem);
      }
      
      return groupedData;
    }, {});
  };
  
 
  const goEditPage = (record) => {
    // navigate('/productionopenorderdetail', { state: { record } })
     navigate('/openorderdetail', { state: { record } })
 
   };

  const goPage = (record) => {
    navigate('/productionopenordergrouplist', { state: { record } })
  };

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text.substring(0, 5)}
      </span>,
    },
    
    {
      title: 'Durum',
      dataIndex: '',
      key: '',
      render: (text, record) => {

        return <span style={{ color: record.isDeleted ? "#00000080" :  "#000000" }}>Üretimde</span>;
      },
    },
     /* 
    {
      title: ' ',
      dataIndex: 'important',
      key: 'important',
      render: (text, record) => {
        console.log("record aaa", record.stock);
    
        const showImage = record.stock && record.stock.some(stockItem => stockItem.isSendPurchase === true);
    
        return showImage ? (
          <img
            width={30}
            height={20}
            alt="logo"
            src="/images/important.png"
            style={{ paddingBottom: "5%" }}
          />
        ) : null;
      },
    },*/
    /*
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      }*/
  ];

  return  <div style={{  
       // width:"50%",
       
      }}
      className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
         Açık Siparişler - Üretim
       </Button>

       <Table
            locale={{
              emptyText: 'Henüz üretimde açık sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      
     
    </div>

  };
  export default ProductionOpenOrderGroupListLimited;

    