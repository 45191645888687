import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../environments/environment"


const FutureRawDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const recordComing = location.state && location.state.record;
     console.log("record futureraw",recordComing);
   

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
    
      
    };
  
    fetchData();
  }, [recordComing.requestedProductInfo]);
  
  
  const columnsRaw = [

      {
        title: 'Ürün Adı',
        dataIndex: '',
        key: '',
        
        render: (text,record) => <span style={{ color: record.isDeleted ? '#84BB4C' : '#000000' }}>{text.quality} - {text.surface}- {text.thickness}mm {text.width}x{text.height}</span>,
      },
      {
        title: 'Firma',
        dataIndex: 'companyName',
        key: 'companyName',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{recordComing.companyName}</span>,
    },
      {
        title: 'Tedarikçi',
        dataIndex: '',
        key: '',
        render: (text,record) => <span style={{color: record.isDeleted ? "#84BB4C" : "#000000"}}>{recordComing.supplierName}</span>,
     
      },
     
      {
        title: 'İş Kodu',
        dataIndex: 'jobCode',
        key: 'jobCode',
        render: (text,record) => <span style={{color: record.isDeleted ? "#84BB4C" : "#000000"}}>{text ? text : "-"}</span>,
     
      },
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#84BB4C" : "#000000"}}>{text}</span>,
        
      },
      
      {
        title: 'Birim Kilo',
        dataIndex: 'unitKilo',
        key: 'unitKg',
        render: (text,record) => <span style={{color: record.isDeleted ? "#84BB4C" : "#000000"}}>{text.toFixed(2)} Kg</span>,
       
      },
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKilo',
        key: 'totalKg',
        render: (text,record) => <span style={{color: record.isDeleted ? "#84BB4C" : "#000000"}}>{text.toFixed(2)} Kg</span>,
      
      },
        
    
        
           
           
    
    ];

   
   return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
  <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Gelen Hammade</h2>
        <Table
      style={{ margin:"auto"}}
            locale={{
              emptyText: 'Henüz gelen hammade bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={recordComing.data}
           columns={columnsRaw}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>


  )};
export default FutureRawDetail;