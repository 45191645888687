import React,{useEffect,useState,useRef} from "react";
import { Button, Form, Modal,Table ,Checkbox,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors, emailJs}  from "../../../environments/environment"
//import * as XLSX from 'xlsx';
import emailjs from '@emailjs/browser';
import AddRequestAdminMail from "../addRequestAdmin/addRequestAdminMail";
import XLSX from 'sheetjs-style'; 
import ExportReportCardTemplate from "./Pars_TeklifExcel_Final.xlsx";

const CheckCurrentPriceSendMail = () => {
    const navigate = useNavigate();
    const [mailList, setMailList] = useState([]);
    const [checkedMails, setCheckedMails] = useState([]);
    const [loading, setLoading] = useState(false);
    const form = useRef();
    const location = useLocation();
    const { checkedItemsStock } = location.state;
    const serviceId = emailJs.serviceId;
    const templateId = emailJs.templateIdSatinAlma;
    const publicKey = emailJs.publicKey;
    console.log("checkedItemsStock",checkedItemsStock);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        const data = await FirebaseService.getMailsData();
        const filteredData = data.filter(item => !item.isDeleted);
        setMailList(filteredData);
      };
    
      fetchData();
    }, []);
    
    const handleCheckboxChange = (mailId) => {
      if (mailId === 'selectAll') {
        const allChecked = !selectAllChecked;
        setSelectAllChecked(allChecked);
        
        if (allChecked) {
          // Tüm mailleri seç
          const allMailIds = mailList.map(mail => mail.email);
          setCheckedMails(allMailIds);
        } else {
          // Seçimleri temizle
          setCheckedMails([]);
        }
      } else {
        setCheckedMails((prevCheckedMails) => {
          const isChecked = prevCheckedMails.includes(mailId);
          
          if (isChecked) {
            return prevCheckedMails.filter((id) => id !== mailId);
          } else {
            return [...prevCheckedMails, mailId];
          }
        });
      }
    };
  
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const generateColumns = (detailedSelectedItems) => {
        console.log("detailedSelectedItems", detailedSelectedItems);

        const hasProductString = detailedSelectedItems.length > 0 && 'Ürün' in detailedSelectedItems[0];
        console.log("hasProductString", hasProductString);

        if (hasProductString) {
            console.log("string");
            return [
                'Ürün',
                'Adet',
                'Fiyat'
            ];
        } else {
            console.log("dic");
            return [
                'Kalınlık',
                'Kalite',
                'Yüzey',
                'En',
                'Boy',
                'Adet',
                'Fiyat'
            ];
        }
    };

    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        fetch(file)
          .then((response) => response.blob())
          .then((file) => reader.readAsBinaryString(file));
      });
    };


    const goExcel = async () => {
      try {
      
        const binaryString = await readFile(ExportReportCardTemplate);
        const workbook = XLSX.read(binaryString, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const detailedSelectedItems = checkedItemsStock.map(productInfo => {
          return {
            Ürün: "Sac Lehva",
            Kalite: productInfo.quality || '-',
            Yüzey: productInfo.surface || '-',
            Kalınlık: productInfo.thickness || '-',  
            En: productInfo.width || '-',
            Boy: productInfo.height || '-',
            Adet: productInfo.piece || '-',
            Fiyat: ' ',

          };

        });
        
        const formattedDeadline = new Date();
        const formattedDeadlineString = `${formattedDeadline.getDate().toString().padStart(2, '0')}.${(formattedDeadline.getMonth() + 1).toString().padStart(2, '0')}.${formattedDeadline.getFullYear()}`;
        worksheet['H6'] = { t: 's', v: "-" }; // bugünn tarihi
        
      
        worksheet['H3'] = { t: 's', v: "Pars Makine" }; // şirket adı
        worksheet['H4'] = { t: 's', v: formattedDeadlineString }; // termin tarihi
        worksheet['H5'] = { t: 's', v: "-" }; // ödeme vaadesi

        let rowIndex = 8; 
        const columnHeaders = ['Ürün', 'Kalite', 'Yüzey', 'Kalınlık',  'En', 'Boy', 'Adet', 'Fiyat'];

        detailedSelectedItems.forEach(item => {
          rowIndex++;
          columnHeaders.forEach((key, columnIndex) => {
              const value = item[key] || '-';
              worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex + 1 })] = { t: 's', v: value };
          });
      });
      
    //sağdaki basliklar
      worksheet['F3'].s = {
          font: {
            name: 'Helvetica',
              sz: 10,
              bold: true,
              color: "#000000",
          },
      }
      worksheet['F4'].s = {
          font: {
            name: 'Helvetica',
              sz: 10,
              bold: true,
              color: "#000000",
          },
      }
      worksheet['F5'].s = {
        font: {
          name: 'Helvetica',
            sz: 10,
            bold: true,
            color: "#000000",
        },
      }
      worksheet['F6'].s = {
        font: {
          name: 'Helvetica',
            sz: 10,
            bold: true,
            color: "#000000",
        },
      }

      //sağdaki cevaplar


    //tablodaki baslikklar
    worksheet['B9'].s = {
          font: {
            name: 'Tw Cen MT (Headings)',
              sz: 12,
              bold: true,
              color: "#000000",
              underline: true
          },
    }
    worksheet['C9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
    }
    worksheet['D9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['E9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['F9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['G9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['H9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['I9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
      
    //orta
    worksheet['B36'].s = {
        font: {
            name: 'arial',
            sz: 11,
            bold: true,
            color: "#000000"
        },
       
    }

    worksheet['B38'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B39'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B40'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B41'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
      
    }
    worksheet['B42'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B43'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
      
    }


    //en alt
    worksheet['B45'].s = {
        font: {
            name: 'Tw Cen MT (Headings)',
            sz: 16,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"center"
        },
    }
    worksheet['B46'].s = {
    
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B47'].s = {
     
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B48'].s = {
      
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B49'].s = {
      
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    // Kenarlık stili
    const borderStyle = {
    style: 'thin',
    color: { rgb: '000000' } // Siyah renk
    };

    // Belirtilen aralığa tam kenarlık uygulama
    for (let C = 0; C <= 21; ++C) {
    for (let R = 9; R <= 34; ++R) {
      const cellAddress = { r: R, c: C };
      const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
      if (cell) {
        cell.s = {
          border: {
            left: borderStyle,
            right: borderStyle,
            top: borderStyle,
            bottom: borderStyle
          }
        };
      }
    }
    }




        XLSX.writeFile(workbook, 'Satin_Alma_Talep.xlsx');
      } catch (error) {
        console.log("error:::", error);
      }
    };


    const goMail = async () => { 
      try {
        setLoading(true)
        const mailTo = Array.isArray(checkedMails) ? checkedMails.join(',') : '';
        console.log("checkedMails",checkedMails,mailList)
        if(checkedMails.length < 3){
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Gönderici listesinden miminum 3 mail seçmelisiniz.",
            placement: 'topRight'
          });
          setLoading(false)
          return;
        }

        
        const binaryString = await readFile(ExportReportCardTemplate);
        const workbook = XLSX.read(binaryString, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

      
        const detailedSelectedItems = checkedItemsStock.map(productInfo => {
          return {
            Ürün: "Sac Lehva",
            Kalite: productInfo.quality || '-',
            Yüzey: productInfo.surface || '-',
            Kalınlık: productInfo.thickness || '-',  
            En: productInfo.width || '-',
            Boy: productInfo.height || '-',
            Adet: productInfo.piece || '-',
            Fiyat: ' ',

          };

        });

    
        if (detailedSelectedItems.length === 0) {
          console.error('Excel dosyasını oluşturmak için veri bulunamadı.');
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Seçili ürün bulunmamaktadır.",
            placement: 'topRight'
          });
          setLoading(false)
          return;
        }
        


        const formattedDeadline = new Date();
        const formattedDeadlineString = `${formattedDeadline.getDate().toString().padStart(2, '0')}.${(formattedDeadline.getMonth() + 1).toString().padStart(2, '0')}.${formattedDeadline.getFullYear()}`;
        worksheet['H4'] = { t: 's', v: "-" }; // termin tarihi
        

        worksheet['H3'] = { t: 's', v: "Pars Makine" }; // şirket adı
        worksheet['H4'] = { t: 's', v: formattedDeadlineString }; // termin tarihi
        worksheet['H5'] = { t: 's', v: "-" }; // ödeme vaadesi

        let rowIndex = 8; 
        const columnHeaders = ['Ürün', 'Kalite', 'Yüzey', 'Kalınlık',  'En', 'Boy', 'Adet', 'Fiyat'];

        detailedSelectedItems.forEach(item => {
          rowIndex++;
          columnHeaders.forEach((key, columnIndex) => {
              const value = item[key] || '-';
              worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex + 1 })] = { t: 's', v: value };
          });
      });
      
    //sağdaki basliklar
      worksheet['F3'].s = {
          font: {
            name: 'Helvetica',
              sz: 10,
              bold: true,
              color: "#000000",
          },
      }
      worksheet['F4'].s = {
          font: {
            name: 'Helvetica',
              sz: 10,
              bold: true,
              color: "#000000",
          },
      }
      worksheet['F5'].s = {
        font: {
          name: 'Helvetica',
            sz: 10,
            bold: true,
            color: "#000000",
        },
      }
      worksheet['F6'].s = {
        font: {
          name: 'Helvetica',
            sz: 10,
            bold: true,
            color: "#000000",
        },
      }

      //sağdaki cevaplar
      //tablodaki baslikklar
      worksheet['B9'].s = {
            font: {
              name: 'Tw Cen MT (Headings)',
                sz: 12,
                bold: true,
                color: "#000000",
                underline: true
            },
      }
      worksheet['C9'].s = {
          font: {
            name: 'Tw Cen MT (Headings)',
              sz: 12,
              bold: true,
              color: "#000000",
              underline: true
          },
      }
      worksheet['D9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
      }
      worksheet['E9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
      }
      worksheet['F9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
      }
      worksheet['G9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
      }
      worksheet['H9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
      }
      worksheet['I9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
      }
        
      //orta
      worksheet['B36'].s = {
          font: {
              name: 'arial',
              sz: 11,
              bold: true,
              color: "#000000"
          },
        
      }

      worksheet['B38'].s = {
        font: {
            name: 'Calibri',
            sz: 9,
            color: "#000000"
        },
       
      }
      worksheet['B39'].s = {
        font: {
            name: 'Calibri',
            sz: 9,
            color: "#000000"
        },
        
      }
      worksheet['B40'].s = {
        font: {
            name: 'Calibri',
            sz: 9,
            color: "#000000"
        },
       
      }
      worksheet['B41'].s = {
        font: {
            name: 'Calibri',
            sz: 9,
            color: "#000000"
        },
        
      }
      worksheet['B42'].s = {
        font: {
            name: 'Calibri',
            sz: 9,
            color: "#000000"
        },
       
      }
      worksheet['B43'].s = {
        font: {
            name: 'Calibri',
            sz: 9,
            color: "#000000"
        },
        
      }
      //en alt
      worksheet['B45'].s = {
          font: {
              name: 'Tw Cen MT (Headings)',
              sz: 16,
              bold: true,
              color: "#000000"
          },
          alignment : {
            horizontal :"center"
          },
      }
      worksheet['B46'].s = {
       
        font: {
          name: 'Tw Cen MT (Headings)',
          sz: 10,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
      }
      worksheet['B47'].s = {
       
        font: {
          name: 'Tw Cen MT (Headings)',
          sz: 10,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
      }
      worksheet['B48'].s = {
       
        font: {
          name: 'Tw Cen MT (Headings)',
          sz: 10,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
      }
      worksheet['B49'].s = {
       
        font: {
          name: 'Tw Cen MT (Headings)',
          sz: 10,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
      }
      // Kenarlık stili
      const borderStyle = {
        style: 'thin',
        color: { rgb: '000000' } // Siyah renk
      };

      // Belirtilen aralığa tam kenarlık uygulama
      for (let C = 0; C <= 21; ++C) {
        for (let R = 9; R <= 34; ++R) {
          const cellAddress = { r: R, c: C };
          const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
          if (cell) {
            cell.s = {
              border: {
                left: borderStyle,
                right: borderStyle,
                top: borderStyle,
                bottom: borderStyle
              }
            };
          }
        }
      }
        



    
      // XLSX.writeFile(workbook, 'Satın Alma Teklif.xlsx', { cellStyles: true, bookImages: true });
      
      
      let base64File; 
      const blob = new Blob([s2ab(XLSX.write(workbook, { type: 'binary' }))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      const reader = new FileReader();
      
      reader.onload = function(event) {
        const base64File = event.target.result.split(',')[1];
      
        checkedMails.forEach((email) => {
          const templateParams = {
            user_email: email,
            from_name: 'Pars Makine',
            message: 'webden gidiyor',
            file: base64File,
          };
          
          console.log("templateParams", templateParams);
          
          emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then(
              (response) => {
                console.log('Email sent successfully to ' + email + ':', response);
                NotificationService.openSuccessNotification({
                  title: 'İşlem Başarılı',
                  description: email + " adresine mail başarıyla gönderildi",
                  placement: 'topRight'
                });
                setLoading(false)
                setTimeout(() => {
                  navigate("/buyerpage");
                }, 2000);
                
        
              },
              (error) => {
                console.error('Failed to send email to ' + email + ':', error);
              }
            );
        });
      };
      
      reader.onloadend = function() {
        console.log("Dosya yükleme işlemi tamamlandı.");
      };
      
      reader.readAsDataURL(blob);
      
      

    } catch (error) {
        console.log("error:::", error);
      }
      
    };

/*
  const goMail = async() => {

   
    const detailedSelectedItems = checkedItemsStock.map(productInfo => {
      if (productInfo && productInfo.thickness) {
        return {
          Kalınlık: productInfo.thickness || '-',
          Kalite: productInfo.quality || '-',
          Yüzey: productInfo.surface || '-',
          En: productInfo.width || '-',
          Boy: productInfo.height || '-',
          Adet: productInfo.piece || '-',
        };
      } else {
        return {
          Ürün: productInfo.productString || '-',
          Adet: productInfo.piece || '-',
        };

      }
    });
  
    if (detailedSelectedItems.length === 0) {
      console.error('Excel dosyasını oluşturmak için veri bulunamadı.');
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Seçili ürün bulunmamaktadır.",
        placement: 'topRight'
      });
      return;
    }
  
    const columns = generateColumns(detailedSelectedItems);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([columns, ...detailedSelectedItems.map(item => Object.values(item))]);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const mailTo = Array.isArray(checkedMails) ? checkedMails.join(',') : '';

    console.log("mailtoooo",mailTo)
    const serviceId = emailJs.serviceId;
    const templateId = emailJs.templateId;
    const publicKey = emailJs.publicKey;
    
      const templateParams = {
      user_email: mailTo,
      // user_email: "sena.ozturk@appelier.net",
      from_name: 'Pars Makine',
        message: 'Test',
      };


    emailjs.send(serviceId, templateId, templateParams, publicKey)
    .then(
      (response) => {
        console.log('Email sent successfully:', response);
        NotificationService.openSuccessNotification({
          title: 'Başarılı',
          description: "Mail başarıyla gönderildi",
          placement: 'topRight'
        });
        setTimeout(() => {
          navigate("/buyerpage");
        }, 2000);
      },
      (error) => {
        console.error('Failed to send email:', error);
      }
    );
        
};*/

    const [isModalVisibleMail, setIsModalVisibleMail] = useState(false);

    const handleMail = () => {
      setIsModalVisibleMail(true);
    };
  
    const handleModalCancelMail = () => {
      setIsModalVisibleMail(false);
    };
  
    const handleMailFinish = () => {
      setIsModalVisibleMail(false); 
    };

    const columns = [
      {
        title: '',
        dataIndex: '',
        key: '',
        width: 10,
        render: (text, record) => (
            <Checkbox
            onChange={() => handleCheckboxChange(record.email)}
            checked={checkedMails.includes(record.email)}
            style={{ fontWeight: "500" }}
          />
            
       
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>   {text}</span>,
    },
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>   {text}</span>,
  }
    
    ];
   return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
        <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"75px", textAlign:"left" , padding:"20px" }}>Gönderici Listesi</div>
        
        
        
        <Checkbox
          onChange={() => handleCheckboxChange('selectAll')}
          checked={selectAllChecked}
          style={{ marginBottom: '10px', fontWeight: '500'}}>
          Tümünü Seç
        </Checkbox>
        
        <Table
         style={{}}
            locale={{
              emptyText: 'Henüz email eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={mailList}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
       
       <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }} onClick={handleMail}>
          Yeni Mail Talep Et
        </Button>

             <Modal
                title="Mail Ekleme Talebi"
                visible={isModalVisibleMail}
                onCancel={handleModalCancelMail}
                footer={null}
              >
              <AddRequestAdminMail onClose={handleMailFinish} />
              </Modal> 

        <div>
          <Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }} onClick={goExcel}>
            Excel İndir
          </Button>

          <Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }} onClick={goMail}>
            Mail At
          </Button>
          {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
        </div>
      </div>

    </div>

  )};
export default CheckCurrentPriceSendMail;