import React from "react";
import { Button, Form, Input } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../services/authService";
import {useNavigate} from "react-router-dom"


const ForgotPassword = () => {

  const navigate=useNavigate();
  
const onFinishFailed = (errorInfo) => {
  NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
};

const onFinish = (values) => {
    sendPasswordResetEmail(auth,values.email) .then(() => {
  
      NotificationService.openSuccessNotification({title:'Mail Gönderildi',description:'Mail adresinizi kontrol ediniz.',placement:'topRight'})
      NotificationService.openSuccessNotification({title:'',description:'Girişe Yönlendiriliyorsunuz',placement:'topRight'})

      setTimeout(() => {
        navigate("/login");
      }, 1500);
    })
    .catch((error) => {
      NotificationService.openErrorNotification({title:'',description:`${error.message}`,placement:'topRight'})
    });
  };

   return <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '94vh',
      }}
    >
          <img width={209} height={112} alt="logo" src="/images/odslogo.png" style={{paddingBottom:"5%"}}/>
          <Form
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Lütfen mail adresinizi doğru formatta giriniz!',
                },
              ]}
            >
              <Input placeholder="E-posta Adresi" style={{ width: '450px', height: '50px',borderColor:"#1A446C" }} />
            </Form.Item>

                   
            <Form.Item      
              style={{ marginBottom: 5, textAlign: 'right',float:"right" }}
            >            
              <Button htmlType="submit" style={{background:"#1A446C",color:"white"}}>
                Gönder
              </Button>
            </Form.Item>
          </Form>
  </div>

};
  export default ForgotPassword;
