import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Space, Upload,Modal,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";



const AlarmRemove = ({ record,onClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
       
      

      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };

    const onFinish = async (values) => {
      setLoading(true)
        const data = {
            removalDescription: values.description,
            isDeleted: true,
            removalUserId:localStorage.getItem("uid")

        };
        const result = await FirebaseService.updateAlarmRequestRemovalData(record.key,data);
        if (result) {
         console.log("update oldu")
         NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: "Alarm başarıyla kaldırıldı.",
            placement: 'topRight'
          });
          const data = await FirebaseService.getAlarmsData();
          const record = data.filter(item => !item.isDeleted);
          setLoading(false)
          setTimeout(() => {
            navigate('/alarmlist', { state: { record } })
          }, 1000);
          onClose();
          
        } else {
          console.log("update olmadı")
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });setLoading(false)
    
        }    
    }; 

   return ( <div>
  
    <div style={{ padding: "5% 0px 0px 5%"  }}>
            <p style={{ fontWeight: "bold", color: "black" }}>Talep Nedeni:</p>
            {record && record.requestRemovalDescription !== null ? record.requestRemovalDescription : '-'}

            </div>
   
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        
         
            <Form.Item
            label="Açıklama"
                name="description"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen açıklama giriniz!',
                    },
                ]}
               
                >
            <Input  className="input-style"
         style={{width:"100%"}} placeholder="Açıklama"/>
            </Form.Item>

        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Alarmı Kaldır</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

AlarmRemove.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default AlarmRemove;