import React, { useEffect, useState } from "react";
import { Table, Button, Space, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import { colors } from "../../../../environments/environment";
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const WaitingOpenOrderGroupList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]); // Tüm verileri tutacak state

  useEffect(() => {
    window.scrollTo(0, 0);

    if (record) {
      const sortedItems = record.sort((a, b) => {
        const dateA = a.deadline && a.deadline.length > 0 ? new Date(a.deadline[0].seconds * 1000) : new Date(a.firstTransactionDate);
        const dateB = b.deadline && b.deadline.length > 0 ? new Date(b.deadline[0].seconds * 1000) : new Date(b.firstTransactionDate);

        if (!a.deadline && !b.deadline) {
          return dateB - dateA;
        }

        if (!a.deadline) {
          return 1;
        }

        if (!b.deadline) {
          return -1;
        }
        return dateB - dateA;
      });

      console.log("sortedItems", sortedItems);
      setItems(sortedItems);
      setAllItems(sortedItems); // Tüm verileri burada saklıyoruz
    }
  }, [record]);

  const goEditPage = (record) => {
    navigate('/waitingopenorderlist', { state: { record } })
  };

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },
    {
      title: 'Programcı',
      dataIndex: 'programmerName',
      key: 'programmerName',
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
          {text}
        </span>
      )
    },
    {
      title: 'Termin Tarihi',
      dataIndex: 'deadline',
      key: 'deadline',
      width: 300,
      render: (text, record) => {
        const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
        return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline ? formattedDeadline : "-"}</span>;
      },
      sorter: (a, b) => {
        const deadlineA = a.deadline ? a.deadline.seconds : 0;
        const deadlineB = b.deadline ? b.deadline.seconds : 0;
        return deadlineA - deadlineB;
      },
    },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <Button onClick={() => goEditPage(record)} style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}>
          Detayı Gör
        </Button>
      ),
    }
  ];

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%",
    }}>
      <h3 style={{ textAlign: "left", color: colors.blue }}>Bekleyen Siparişler</h3>
      <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
            <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          if (searchValue === "") {
            setItems(allItems); // Arama kutusu boşsa tüm verileri geri getir
          } else {
            const filteredData = allItems.filter(item => {
              const jobCode = item.jobCode.toLowerCase();
              return jobCode.includes(searchValue);
            });
            setItems(filteredData);
          }
        }}
      />

      <Table
        locale={{
          emptyText: 'Henüz bekleyen sipariş bulunmamaktadır...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={items}
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
    </div>
  );
};

export default WaitingOpenOrderGroupList;
