import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, placeholderMap } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import XLSX from 'sheetjs-style'; 
import ExportReportCardTemplate from "./StockList.xlsx";

const StockList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [itemsWastage, setItemsWastage] = useState([]);
  const [filteredItemsWastage, setFilteredItemsWastage] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [filters, setFilters] = useState({
    thickness: '',
    quality: '',
    width: '',
    height: '',
    surface: '',
    piece: '',
    reservedPiece: '',
    unitkg: '',
    totalkg: '',
});
const [filtersWastage, setFiltersWastage] = useState({
  thickness: '',
  quality: '',
  width: '',
  height: '',
  surface: '',
  piece: '',
  reservedPiece: '',
  unitkg: '',
  totalkg: '',
});


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getStocksData();
      const filteredData = data.filter(item => !item.isDeleted && item.companyName === record.companyName);

      //console.log("filteredData", filteredData)
      setItems(filteredData);
      setFilteredItems(filteredData);

      const dataWastage = await FirebaseService.getWastagesData();
      const filteredDataWastage = dataWastage.filter(item => !item.isDeleted && item.companyName === record.companyName);

      //console.log("filteredDataWastage", filteredDataWastage)
      setItemsWastage(filteredDataWastage);
      setFilteredItemsWastage(filteredDataWastage);
    };

    fetchData();
  }, []);


  const handleSearch = (value, dataIndex) => {
    setFilters(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        [dataIndex]: value, // Sadece değiştirdiğimiz filtreyi güncelle
      };
  
      const searchValue = value?.toLowerCase(); // Arama terimini küçük harfe çevir
      const filteredData = items.filter(item => {
        return (
          !item.isDeleted && // Silinmemiş öğeler
          item.companyName === record.companyName && // Belirli bir şirket adı kontrolü
          Object.keys(updatedFilters).every(key => {
            const filterValue = updatedFilters[key]?.toLowerCase(); // En güncel filtre değerini al
            const itemValue = item[key] !== undefined ? item[key].toString().toLowerCase() : ''; // İlgili değeri küçük harfe çevirerek al
            
            // Burada includes yerine eşitlik kontrolü yapıyoruz
            return filterValue ? itemValue === filterValue : true;
          }) &&
          (searchValue ? item[dataIndex]?.toString().toLowerCase() === searchValue : true) // Eşitlik kontrolü
        );
      });
  
      setFilteredItems(filteredData); // Filtrelenmiş veriyi ayarla
      return updatedFilters; // Güncel filtreleri geri döndür
    });
  };
  
  
  

const handleReset = () => {
    setFilteredItems(items); // Tüm listeyi geri döndür
};

const getColumnSearchProps = dataIndex => ({
    title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{placeholderMap[dataIndex] || dataIndex}</span>
            <Input
                id={`search-input-${dataIndex}`}
                placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
                onChange={e => handleSearch(e.target.value, dataIndex)} // Arama her değişiklikte yapılacak
                style={{ width: 80, marginTop: 8 }} // Genişliği ve üstten boşluğu ayarlayın
                suffix={<SearchOutlined />} // Arama ikonu ekleme
            />
        </div>
    ),
    sorter: (a, b) => {
        const aValue = a[dataIndex] !== undefined ? a[dataIndex].toString().toLowerCase() : '';
        const bValue = b[dataIndex] !== undefined ? b[dataIndex].toString().toLowerCase() : '';
        return aValue.localeCompare(bValue); // Sıralama işlemi
    },
});

  const columns = [
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
      ...getColumnSearchProps('thickness'),
      render: (text, record) => (
<span style={{ 
color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
(record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
fontFamily: "futura" 
}}>
{text} mm
        </span>
      ),       
       sorter: (a, b) => a.thickness - b.thickness,
    },
  
    {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
        ...getColumnSearchProps('quality'),
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
         (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
      },
      
 
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('width'),
        sorter: (a, b) => a.width - b.width,

      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('height'),
        sorter: (a, b) => a.height - b.height,

      },
    
      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('surface'),
        sorter: (a, b) => a.surface.localeCompare(b.surface), 

      },
    
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('piece'),
        sorter: (a, b) => a.piece - b.piece,

      },
         
      {
        title: 'Rezerve Adet',
        dataIndex: 'reservedPiece',
        key: 'reservedPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),   
        ...getColumnSearchProps('reservedPiece'),
        sorter: (a, b) => a.piece - b.piece,  
       },
           
      {
        title: 'Beklenen Adet',
        dataIndex: 'waitingPiece',
        key: 'waitingPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),  ...getColumnSearchProps('waitingPiece'),
        sorter: (a, b) => a.piece - b.piece,
        },
    
    
      {
        title: 'Birim Kilo',
        dataIndex: 'unitKilo',
        key: 'unitKg',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text && text.toFixed(2)} Kg
          </span>
        ),  
        ...getColumnSearchProps('unitKg'),
        sorter: (a, b) => a.unitKg - b.unitKg,

      },
    
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKilo',
        key: 'totalKg',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text && text.toFixed(2)} Kg
          </span>
        ),  
        ...getColumnSearchProps('totalKg'),
        sorter: (a, b) => a.totalKg - b.totalKg,

      },
      {
        title: 'Minimum Adet',
        dataIndex: 'minPiece',
        key: 'minPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),  
            },
            {
              title: 'Açıklama',
              dataIndex: 'isProblem',
              key: 'isProblem',
              render: (text, record) => {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                const formattedDate = record.problemDate ? new Date(record.problemDate.seconds * 1000).toLocaleString('tr-TR', options) : '';
                
                return (
                  <Popover
      content={
        <div style={{ width: '200px', maxHeight: '700px' }}> {/* Sabit yükseklik ve genişlik */}
          <p><strong>Problem Zamanı:</strong> {formattedDate}</p>
          <p><strong>Problem Fotoğrafı:</strong> 
            {record.problemImage ?                 
            <img alt="" src={record.problemImage} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
            : 
            "Fotoğraf bulunmamaktadır."}</p>
                <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Problem Açıklaması:</strong> {record.problemDescription}</p>
              </div>
            }
            title="Sac levhada problem bulunmaktadır."
            trigger="click" // veya hover
          >
                    <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>
                      {record.isProblem ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                    </span>
                  </Popover>
                );
              },
            
            },
    
  ];



  
  const handleSearchWastage = (value, dataIndex) => {
    setFiltersWastage(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        [dataIndex]: value, // Sadece değiştirdiğimiz filtreyi günceller
      };
  
      const searchValue = value?.toLowerCase(); // Arama terimini küçük harfe çevir
      const filteredData = itemsWastage.filter(item => {
        return (
          !item.isDeleted && // Silinmemiş öğeleri dahil et
          Object.keys(updatedFilters).every(key => {
            const filterValue = updatedFilters[key]?.toLowerCase(); // Aktif filtreyi al
            const itemValue = item[key] !== undefined ? item[key].toString().toLowerCase() : ''; // İlgili değeri küçük harfe çevirerek al
  
            // Burada includes yerine eşitlik kontrolü yapıyoruz
            return filterValue ? itemValue === filterValue : true;
          }) &&
          (searchValue ? item[dataIndex]?.toString().toLowerCase() === searchValue : true) // Eşitlik kontrolü
        );
      });
  
      setFilteredItemsWastage(filteredData); // Filtrelenmiş veriyi güncelle
      return updatedFilters; // Yeni filtreleri geri döndür
    });
  };
  
  
  
 
  


  
  // Arama kutusu
  const getColumnSearchPropsWastage = dataIndex => ({
    title: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{placeholderMap[dataIndex] || dataIndex}</span>
        <Input
          id={`search-input-${dataIndex}`}
          placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
          onChange={e => handleSearchWastage(e.target.value, dataIndex)} // Her değişiklikte arama işlemi yapılacak
          style={{ width: "100%", marginTop: 8 }} // Genişliği ve üstten boşluğu ayarlayın
          suffix={<SearchOutlined />} // Arama ikonu ekleme
        />
      </div>
    ),
    sorter: (a, b) => {
      const aValue = a[dataIndex] !== undefined ? a[dataIndex].toString().toLowerCase() : '';
      const bValue = b[dataIndex] !== undefined ? b[dataIndex].toString().toLowerCase() : '';
      return aValue.localeCompare(bValue); // Sıralama işlemi
    },
  });

  
  

  const columnsWastage = [
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
     ...getColumnSearchPropsWastage('thickness'),
      render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text} mm</span>,
      sorter: (a, b) => a.thickness - b.thickness,
    },
    {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
       ...getColumnSearchPropsWastage('quality'),
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
      },
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
       ...getColumnSearchPropsWastage('width'),
        sorter: (a, b) => a.width - b.width,

      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
       ...getColumnSearchPropsWastage('height'),
        sorter: (a, b) => a.height - b.height,

      },
    
      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        ...getColumnSearchPropsWastage('surface'),
        sorter: (a, b) => a.surface.localeCompare(b.surface), 

      },
    
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
       ...getColumnSearchPropsWastage('piece'),
        sorter: (a, b) => a.piece - b.piece,

      },
         
      {
        title: 'Rezerve Adet',
        dataIndex: 'reservedPiece',
        key: 'reservedPiece',
        ...getColumnSearchPropsWastage('reservedPiece'),
        render: (text,record) => <span style={{color: items.isDeleted ? "#00000080" : "#000000"}}>{text ? text : "-"}</span>,
      },

      {
        title: 'Birim Kilo',
        dataIndex: 'unitKilo',
        key: 'unitKg',
        render: (text, record) => (
<span style={{ 
 
}}>
{text && text.toFixed(2)} Kg
          </span>
        ),  
        ...getColumnSearchProps('unitKg'),
        sorter: (a, b) => a.unitKg - b.unitKg,

      },
    
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKilo',
        key: 'totalKg',
        render: (text, record) => (
<span style={{ 
  
}}>
{text && text.toFixed(2)} Kg
          </span>
        ),  
        ...getColumnSearchProps('totalKg'),
        sorter: (a, b) => a.totalKg - b.totalKg,

      },
      {
        title: 'Açıklama',
        dataIndex: 'description',
        key: 'description',
        render: (text, record) => {
          return (
            <Popover
                content={
                  <div style={{ width: '200px', maxHeight: '700px' }}> 
                    <p><strong>Fotoğrafı:</strong> 
                      {record.imageUrl ?                 
                      <img alt="" src={record.imageUrl} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
                      : 
                      "Fotoğraf bulunmamaktadır."}</p>
                          <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Açıklaması:</strong> {record.description}</p>
                        </div>
                      }
                      title="Firede açıklama bulunmaktadır."
                      trigger="click" 
                    >
                  <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>
                    {record.description ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                  </span>
            </Popover>
          );
        },
      
      },
  ];


  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      fetch(file)
        .then((response) => response.blob())
        .then((file) => reader.readAsBinaryString(file));
    });
  };

  const goExcel = async () => {
    try {
      const binaryString = await readFile(ExportReportCardTemplate);
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
  
      const createDetailedItems = (items, productLabel) => {
        return items.map(productInfo => {
          const unitKilo = productInfo.unitKilo || '-';
          const totalKilo = productInfo.totalKilo || '-';
          const problemDescription = productLabel === "Plaka" ? productInfo.problemDescription || '-'  : productInfo.description || '-';
          return {
            Ürün: productLabel,
            Kalite: productInfo.quality || '-',
            Yüzey: productInfo.surface || '-',
            Kalınlık: productInfo.thickness || '-',
            En: productInfo.width || '-',
            Boy: productInfo.height || '-',
            Adet: productInfo.piece || '-',
            'Birim Kilo': unitKilo,
            'Toplam Kilo': totalKilo,
            Açıklama: problemDescription
          };
        });
      };
  
      const detailedSelectedItems = createDetailedItems(filteredItems, "Plaka");
      const detailedWastageItems = createDetailedItems(filteredItemsWastage, "Fire");
      const combinedItems = [...detailedSelectedItems, ...detailedWastageItems];
  
      let rowIndex = 1; 
      const columnHeaders = ['Ürün', 'Kalite', 'Yüzey', 'Kalınlık', 'En', 'Boy', 'Adet', 'Birim Kilo', 'Toplam Kilo', 'Açıklama'];
      columnHeaders.forEach((header, index) => {
          const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
      
          // Set the value of the header
          worksheet[cellAddress] = { t: 's', v: header };
      
          // Apply bold styling, underline, and background color
          worksheet[cellAddress].s = {
              font: {
                  bold: true,        // Makes the text bold
                  underline: true    // Adds underline
              },
              fill: {
                  patternType: 'solid',    // Fill pattern (solid color)
                  fgColor: { rgb: 'D3D3D3' } // Light gray background color
              },
              border: {
                  bottom: {
                      style: 'thin', // Adds a bottom border (underline)
                      color: { rgb: '000000' } // Border color: black
                  }
              }
          };
      });
      
      
  
      combinedItems.forEach(item => {
        columnHeaders.forEach((key, columnIndex) => {
          const value = item[key] || '-';
          worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex })] = { t: 's', v: value };
        });
        rowIndex++;
      });
  
      XLSX.writeFile(workbook, 'Guncel_Stok.xlsx');
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  

  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '94vh',
    padding: "3%",
  }}>

<Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", float:"right" }} onClick={goExcel}>
            Excel İndir
          </Button>


    <h3 style={{ textAlign: "left", color: colors.blue }}>Güncel Stok</h3>
    <h4 style={{ textAlign: "left", color: colors.blue }}>{record.companyName}</h4>

    <Table
    scroll={{ y: 500 }}
      locale={{
        emptyText: 'Henüz stok bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItems}
      columns={columns}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
    />


<h3 style={{ textAlign: "left", color: colors.blue }}>Güncel Fire</h3>
    <h4 style={{ textAlign: "left", color: colors.blue }}>{record.companyName}</h4>

    <Table
    scroll={{ y: 500 }}
        locale={{
        emptyText: 'Henüz fire bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItemsWastage}
      columns={columnsWastage}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
    />
       

  </div>
};

export default StockList;
