import React, { useState } from "react";
import { Button } from 'antd';
import JSZip from 'jszip';

const FolderAdd = () => {
    const [imageFiles, setImageFiles] = useState([]);
    const handleFolderChange = async (event) => {
        const selectedFile = event.target.files[0];
    
        if (selectedFile) {
            const zip = new JSZip();
            await zip.loadAsync(selectedFile);
    
            const partsFolder = zip.folder('parts');
            if (partsFolder) {
                console.log('Parts klasörü bulundu:', partsFolder);
    
                // Klasör içeriğini kontrol et
                const filesInPartsFolder = partsFolder.files;
                console.log('Parts klasörü dosyaları:', filesInPartsFolder);
    
                const imageFilesArray = [];
    
                // Klasördeki dosyaları döngüye al
                for (const [name, fileInfo] of Object.entries(filesInPartsFolder)) {
                    // Sadece klasörleri kontrol et
                    if (fileInfo.dir) {
                        // Klasör adı "partid_" ile başlıyorsa ve içinde .geo dosyaları varsa işlemleri gerçekleştir
                        if (name.endsWith('/') && name.startsWith('206/parts/partid_')) {
                            console.log("Klasör adı uygun:", name);
                            const subFolder = zip.folder(name); // Klasörün içine gir
                            if (subFolder && subFolder.files) {
                                console.log("Alt klasör bulundu:", subFolder);
    



const geoFilesInFolder = Object.entries(subFolder.files)
.filter(([fileName, file]) => fileName.endsWith('.geo'))
.map(([fileName, file]) => file); // Dosya nesnelerini almak için map kullanabilirsiniz

console.log("Geometri dosyaları:", geoFilesInFolder);

// .geo dosyalarını işleyerek imageFilesArray'e ekleyebilirsiniz
geoFilesInFolder.forEach((file) => {
    imageFilesArray.push(file);
});

                            }
                        }
                    }
                }
    
                console.log('imageFilesArray:', imageFilesArray);
    
                // Elde edilen dosyaları işleme fonksiyonuna gönder
                setImageFiles(imageFilesArray);
            } else {
                console.log('Error: "parts" folder not found.');
            }
        }
    };
    
    
    
    
    return (
        <div>
            <input
                type="file"
                style={{ display: 'none' }}
                onChange={handleFolderChange}
            />
            <Button style={{ borderRadius: 0, borderColor: "#1A446C80" }} onClick={() => document.querySelector('input[type=file]').click()}>
                206 Numaralı Klasörü Seç
            </Button>

            {/* İşlenen "image" dosyalarını görüntüleme veya başka bir işlem */}
        </div>
    );
};

export default FolderAdd;
