import React from "react";
import { Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { colors } from "../../../environments/environment";

const OperationDetailProductManager = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const record = location.state && location.state.record;
    const parts = record ? record.parts : [];
    const jobCode = location.state && location.state.jobCode;

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Yazdır</title>
                    <style>
                        body { font-family: Arial, sans-serif; margin: 0; padding: 0; }
                        .print-container {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            padding: 10px;
                        }
                        .setup-item {
                            border: 1px solid ${colors.blue};
                            padding: 10px;
                            width: calc(33.33% - 20px); /* Match the initial dimensions */
                            margin-bottom: 20px;
                            box-sizing: border-box;
                            page-break-inside: avoid;
                        }
                        .setup-item img {
                            width: 100%;
                            height: 150px; /* Fixed height for images */
                            object-fit: contain;
                        }
                        .setup-item p {
                            border-bottom: 1px solid ${colors.blue};
                            margin: 5px 0;
                            font-size: 12px;
                            padding: 0;
                        }
                        @media print {
                            .setup-item {
                                width: calc(33.33% - 20px); /* Keep the same width in print */
                                page-break-inside: avoid;
                            }
                            @page {
                                margin: 0.5cm;
                            }
                        }
                    </style>
                </head>
                <body>
                    <div class="print-container">
                        ${parts.map(part => `
                            <div class="setup-item">
                                <img src="${part.imageUrl ? part.imageUrl : '/images/parca_fotografi_bulunamamaktadır.svg'}" 
                                     alt="${part.imageFileName}" 
                                     onerror="this.onerror=null;this.src='/images/parca_fotografi_görüntülenmemektedir.svg';" />
                                <p><strong>Firma:</strong> ${part.customerName}</p>
                                <p><strong>Part No:</strong> ${part.partNo}</p>
                                <p><strong>Parça Sayısı:</strong> ${part.piece}</p>
                                <p><strong>Ebat:</strong> ${part.height} x ${part.width} ${part.unit}</p>
                                <p><strong>Kalite:</strong> ${part.quality}</p>
                                <p><strong>İş Kodu:</strong> ${jobCode}</p>
                            </div>
                        `).join('')}
                    </div>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.onload = () => {
            printWindow.focus();
            setTimeout(() => {
                printWindow.print();
                printWindow.onafterprint = () => printWindow.close();
            }, 250);
        };
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <Button
                    style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
                    onClick={handlePrint}
                >
                    Yazdır
                </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
                    {parts.map((part, index) => (
                        <div
                            className="setup-item"
                            key={index}
                            style={{ border: "1px solid", borderColor: colors.blue, padding: "10px", width: 'calc(33.33% - 20px)', marginBottom: '20px' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img
                                    src={part.imageUrl ? part.imageUrl : '/images/parca_fotografi_bulunamamaktadır.svg'}
                                    alt={part.imageFileName}
                                    style={{ width: '100%', height: 'auto', maxHeight: '150px', objectFit: 'contain' }}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/images/parca_fotografi_görüntülenmemektedir.svg'; // Yüklenemeyen resim
                                    }}
                                />
                                <div style={{ width: '100%' }}>
                                    <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Firma:</strong> {part.customerName}</p>
                                    <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Part No:</strong> {part.partNo}</p>
                                    <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Parça Sayısı:</strong> {part.piece}</p>
                                    <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Ebat:</strong> {part.height} x {part.width} {part.unit}</p>
                                    <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>Kalite:</strong> {part.quality}</p>
                                    <p style={{ borderBottom: "1px solid", borderColor: colors.blue }}><strong>İş Kodu:</strong> {jobCode}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default OperationDetailProductManager;
