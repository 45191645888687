import React, { useEffect ,useState} from "react";
import { Button, Form, Input, Card,Space ,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import { useNavigate } from "react-router-dom"; 
import FirebaseService from "../../services/firebaseService";
import { createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import { auth } from "../../services/authService";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true)
    try {
      const userEmail = values.email;
      const userData = await FirebaseService.getUserData();
      
      const isEmailExists = userData.some(user => user.email === userEmail);
      if (isEmailExists) {
      //  console.log('Email zaten mevcut.');
        NotificationService.openErrorNotification({
          title: '',
          description: 'Bu mail adresiyle mevcut bir hesap bulunmaktadır. Lütfen başka bir mail adresi kullanınız.',
          placement: 'topRight',
        });setLoading(false)
      } else {
       // console.log('Email geçerli, devam edebilirsiniz.');
        
        if (values.password !== values.passwordagain) {
          NotificationService.openErrorNotification({
            title: 'Şifreler eşleşmiyor',
            description: '',
            placement: 'topRight',
          });setLoading(false)
          return;
        }
        if (values.password.length < 8) {
          NotificationService.openErrorNotification({
            title: 'Geçersiz Şifre',
            description: 'Şifre minimum 8 karakter olmalıdır.',
            placement: 'topRight',
          });setLoading(false)
          return;
        }
        const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
        const user = userCredential.user;
        
       // await sendEmailVerification(user);
      
        NotificationService.openSuccessNotification({
          title: 'İşleminiz başarıyla kaydedilmiştir.',
          description: '',
          placement: 'topRight',
        });setLoading(false)

        //Onay e-postası mailinize iletildi. Lütfen maile gelen linkten devam edin.
        window.localStorage.setItem('emailForSignIn', values.email);

        await FirebaseService.createUser({
          name: values.name,
          surname: values.surname,
          email: values.email,
          password: values.password,
          userUnit: [],
          isManager : false,
          title: []
        });
        setLoading(false)
        navigate('/login');
             
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'Invalid Form',
        description: 'Please check your form information.',
        placement: 'topRight',
      });setLoading(false)
     // console.error('Registration error:', error.message);
    }
  };
  
  
  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: 'Invalid Form',
      description: 'Please check your form information.',
      placement: 'topRight',
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
      }}
    >
        <Card style={{borderRadius:0, border: "none",borderBottom:"1px solid", borderColor:"#A47B5A",width:"80%" }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <img alt="logo" width={209} height={112} src="/images/odslogo.png" style={{paddingBottom:"5%"}}/>
          <Form
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
             // remember: true,
           name: '',
            surname: '', 
            companyName: '',
            email: '',
            password: '',
            passwordagain: '',  
          }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen adınızı giriniz!',
                  },
                ]}
                wrapperCol={{
                  span: 23,
                }}
                style={{ flex: 1 }}
              >
                <Input placeholder="Ad" style={{ height: '43px', borderColor: "#1A446C" }} />
              </Form.Item>

              <Form.Item
                name="surname"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen soyadınızı giriniz!',
                  },
                ]}
                wrapperCol={{
                  span: 24,
                }}
                style={{ flex: 1}}
              >
                <Input placeholder="Soyad" style={{ height: '43px', borderColor: "#1A446C" }} />
              </Form.Item>
            </div>

            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Lütfen mail adresinizi doğru formatta giriniz!',
                },
              ]}
            >
              <Input placeholder="E-posta" style={{ width: '450px', height: '43px',borderColor:"#1A446C" }} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Lütfen şifrenizi giriniz!',
                },
              ]}
            >
              <Input.Password placeholder="Şifre" style={{ width: '450px', height: '43px',borderColor:"#1A446C" }} />
            </Form.Item>

            <Form.Item
              name="passwordagain"
              rules={[
                {
                  required: true,
                  message: 'Lütfen şifrenizi tekrar giriniz!',
                },
              ]}
            >
              <Input.Password placeholder="Şifre Tekrar" style={{ width: '450px', height: '43px',borderColor:"#1A446C" }} />
            </Form.Item>

            <Form.Item      
              style={{ marginBottom: 5, textAlign: 'right',float:"right",marginRight:25 }}
            >
               
              <Button htmlType="submit" style={{background:"#1A446C",color:"white"}}>
                Kayıt Ol
              </Button>
            </Form.Item>
          </Form>
          </div>
        </Card>
         
          
          
        <span style={{ marginTop: '16px' }}>Hesabınız var mı? <Button href="/login" type="link" htmlType="button" style={{ color: '#A47B5A' }}>Giriş Yap</Button></span>

        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
  </div>

);
};

  export default Register;

  /*
<Button htmlType="submit" style={{background:"transparent", borderColor:"#000000",borderRadius:"4px", width:"300px",marginBottom:"1%"}}>
          <img alt="plus" style={{width:"25px",float:"left"}}  src="/images/appleicon.png" />  Apple İle Giriş Yap
          </Button>
  */