import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Table, Upload,Modal,Checkbox, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";
import StoregeService from "../../../services/storegeService";
import AddRequestAdminDensities from "../addRequestAdmin/addRequestAdminDensities";
import { UploadOutlined, FilePdfOutlined } from '@ant-design/icons';

const SetupAdd = ({ recordx,onClose }) => {
    const navigate = useNavigate();
    const [xmlFile, setXmlFile] = useState(null);
    const [jobCode, setJobCode] = useState(null);
    let [xmlInfo, setXmlInfo] = useState({});
    const [qualities, setQualities] = useState([]); 
    const [selectedThickness, setSelectedThickness] = useState(0); 
    const [isCustomerRaw, setIsCustomerRaw] = useState(false);
    const [values, setValues] = useState(false);
    const [record, setRecord] = useState(null);
    const [surfaces, setSurfaces] = useState([]); 
   
   
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [updatedRecord, setUpdatedRecord] = useState({});
    const [fileListPartImage, setFileListPartImage] = useState([]);
    const [fileListPlateImage, setFileListPlateImage] = useState([]);
    const [fileListSetupPdf, setFileListSetupPdf] = useState([]);
  
    const [loading, setLoading] = useState(false);


  // console.log("record setup en baş recordx",recordx)

    useEffect(() => {
      const fetchData = async () => {
       
        const qualities = await FirebaseService.getDensitiesData();
        const filteredQualities = qualities.filter(item => !item.isDeleted);
        setQualities(filteredQualities);

        const surfaces = await FirebaseService.getSurfacesData();
        const filteredSurfaces = surfaces.filter(item => !item.isDeleted);
        setSurfaces(filteredSurfaces);

        const processedData = typeof recordx === 'object' && !Array.isArray(recordx) && recordx.stock && recordx.stock.length > 0
        ? {
            ...recordx,
            stock: recordx.stock.map(stockItem => ({
              ...stockItem,
              isSendPurchase: checkSendPurchase(stockItem)
            })),
          }
        : recordx;
      
      
    
    
      console.log("processedData", processedData);
      setRecord(processedData);
        
      };
      fetchData();
    }, []);

    const checkSendPurchase = (stockItem) => {
        const checkedItemsStockExcess = recordx.checkedItemsStockExcess || [];
      
        return checkedItemsStockExcess.some(checkedItem =>
          checkedItem.id === stockItem.id
        );
      };
      
      
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };

    const handleXmlFileChange = (event) => {
        const file = event.file;
        const reader = new FileReader();
        reader.onload = (e) => {
            const xmlContent = e.target.result;           
            parseXml(xmlContent);
        };
        reader.readAsText(file);
    };

    function convertTo60Base(seconds) {
        return seconds * 60; // 10'luk tabandan 60'a çevirme
    }
      
    function convertToTimeFormat(seconds) {
        // Saatleri hesapla
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
      
        // Dakikaları hesapla
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;
      
        // Saniyeleri düzeltilmiş şekilde al
        seconds = Math.floor(seconds);
      
        // Sonuçları iki haneli sayılara dönüştür
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
      
    function parseXml(xmlContent) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlContent, "text/xml");
      
      
        if (!xmlDoc || xmlDoc.getElementsByTagName('parsererror').length > 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Lütfen uygun bir xml dosyası yükleyiniz.',
                placement: 'topRight'
            });
    
            if (xmlFile) {
                xmlFile.value = '';
                setXmlFile(null);
            }
            return;
        }
    

        const productionOrders = xmlDoc.querySelectorAll("ProductionOrder");
        const maxLaserPowerElement = xmlDoc.querySelector("LaserTechnoTable MaxLaserPower").textContent;

        const parts = xmlDoc.querySelectorAll("Parts Part");
        const partsArray = [];

        parts.forEach(part => {
            const partNo = part.getAttribute("PartNo");
          
            const cadFilePath = part.querySelector("CadFilePath").textContent;
            const cadFileName = part.querySelector("CadFileName").textContent;
            const imageFilePath = part.querySelector("ImageFilePath").textContent;
            const imageFileName = part.querySelector("ImageFileName").textContent;
            const descriptionForImageName = part.querySelector("Description").textContent;

            const weightUnit = part.querySelector("WeightUnit").textContent;
            const piece = parseInt(part.querySelector("TotalQuantityInJob").textContent);
            const weightSetup = parseFloat(part.querySelector("Weight").textContent);
            const timeSetup = parseFloat(part.querySelector("TargetProcessingTimePerPiece").textContent);

            const dimensions = part.querySelector("Dimensions");
            const unit = dimensions.querySelector("Unit").textContent;
            const height = parseFloat(dimensions.querySelector("Length").textContent);
            const width = parseFloat(dimensions.querySelector("Width").textContent);
            const thicknessSetup = parseFloat(dimensions.querySelector("Thickness").textContent);
    
    
            const matchingProductionOrders = Array.from(productionOrders).filter(order => {
                return order.querySelector("PartoNo").textContent === partNo;
            });
    

            const manufacturingOrders = xmlDoc.querySelectorAll("ManufacturingOrders TcBoManufacturingOrder");

            matchingProductionOrders.forEach(order => {
                const customerName = order.querySelector("CustomerName").textContent;
        
                const manufacturingOrder = Array.from(manufacturingOrders).find(manufacturingOrder => {
                    return Array.from(manufacturingOrder.querySelectorAll("Order")).some(productionOrder => {
                        return productionOrder.querySelector("PartoNo").textContent === partNo;
                    });
                });
        
                let manufacturingOrderNo = manufacturingOrder ? manufacturingOrder.getAttribute("ManufacturingOrderNo") : null;

                if (manufacturingOrderNo) {
                  manufacturingOrderNo = manufacturingOrderNo.replace(/_0(\d)$/, '_$1');
                }
               
                partsArray.push({
                    partNo,
                    customerName,
                    cadFilePath,
                    cadFileName,
                    imageFilePath,
                    imageFileName,
                    weightUnit,
                    piece,
                    timeSetup,
                    time: convertToTimeFormat(convertTo60Base(timeSetup)),
                    unit,
                    height,
                    width,
                    thicknessSetup,
                    weightSetup,
                    machine: maxLaserPowerElement,
                    programNo: manufacturingOrderNo,
                    descriptionForImageName 
                }); 
            });
                
                
           
        });
    
      console.log("partsarray",partsArray)
  
        const requiredSheets = xmlDoc.querySelectorAll("RequiredSheets Sheet");
        const plates = xmlDoc.querySelectorAll("NcPrograms NcProgram");
        const platesArray = [];
        
        plates.forEach(program => {
            const programNo = program.getAttribute("ProgramNo");
            const sheetIdentNo = program.querySelector("Sheet").textContent;
            const processingTime = convertToTimeFormat(convertTo60Base(parseInt(program.querySelector("ProcessingTime").textContent)));
            const piece = parseInt(program.querySelector("TotalNoOfRuns").textContent);
        
            const requiredSheet = Array.from(requiredSheets).find(sheet => {
                return sheet.getAttribute("SheetIdentNo") === sheetIdentNo;
            });
        
            if (requiredSheet) {
                const sheetWeightUnit = requiredSheet.querySelector("SheetWeightUnit").textContent;
                const sheetWeight = parseFloat(requiredSheet.querySelector("SheetWeight").textContent);
                const dimensions = requiredSheet.querySelector("Dimensions");
                const unit = dimensions.querySelector("Unit").textContent;
                const height = parseFloat(dimensions.querySelector("Length").textContent);
                const width = parseFloat(dimensions.querySelector("Width").textContent);
        
                //   console.log("unit türü:", typeof unit, "Değer:", unit);
               // console.log("height türü:", typeof height, "Değer:", height);
              //  console.log("width türü:", typeof width, "Değer:", width);


              let breaks= {
                userId:null,
                nameSurname:null,
                reason:null,
                firstTransactionDate:null,
                lastTransactionDate:null,
                description:null
              }
              

                platesArray.push({
                    programNo,
                    processingTime,
                    unit,
                    height,
                    width,
                    piece,
                    sheet: sheetIdentNo,
                    
                    actualTime:null,
                    actualPiece:0,
                    totalTime:null,
                    timeForPlate:null,
                    breaks:breaks,
                    lazerUserId:null,
                    lazerUserNameSurname:null,
                });
            } else {
                console.log("Required sheet not found for program:", programNo);
            }
        });
        
      //  console.log(platesArray);
        
     
        


        const productionPackage = xmlDoc.querySelector("ProductionPackage");
        const jobName = productionPackage.getAttribute("JobName");

        const totalRuntime = xmlDoc.querySelector("ProductionPackage TotalRuntime").textContent;
        const totalPartCount = xmlDoc.querySelector("ProductionPackage TotalPartCount").textContent;
        
       // console.log("Total Runtime:", totalRuntime);
       // console.log("Total Part Count:", totalPartCount);
      
      
      
        let xmlInfo ={
            setupName: jobName,
            parts: partsArray,
            plates: platesArray,
            totalRuntime: convertToTimeFormat(convertTo60Base(totalRuntime)),
            totalPartCount:totalPartCount,
            machine:maxLaserPowerElement,
         
          
        }
       console.log("xmlInfo",xmlInfo)
        setXmlInfo(xmlInfo)

       
       
        
    }
    
    const [visibleContinue, setVisibleContinue] = useState(false);

    function generateRandomCode(length) {
        const characters = '0123456789';
        let result = '';
      
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters[randomIndex];
        }
      
        return result;
    }

    const handleCancelContinue = () => {
        setVisibleContinue(false);
    };

    const handleOkContinue = async () => {
        console.log("record",record)
      console.log("satın alma talebi gidiyr",record.checkedItemsStockExcess,jobCode)

      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(0, 0, 0, 0); 
      console.log(currentDate);

      const updatedPurchaseList = record.checkedItemsStockExcess.map(item => ({
        ...item,
        jobCode: jobCode,
        description:null, 
        code: item.code !== undefined ? item.code : 0,
        minPiece: item.minPiece !== undefined ? item.minPiece : 0,
      }));
      console.log("updatedPurchaseList",updatedPurchaseList)


      //min stok kontrolü için satın alma isteği atılıyor
      for (const item of updatedPurchaseList) {
        //console.log("updatedPurchaseList",updatedPurchaseList)
        const updatedPurchaseListFixed = updatedPurchaseList.map(item => ({  //burda jobcode null olabilir ve currentpiece göndermeye gerek yok
            ...item,
            piece : item.minPiece- item.currentPiece,
            jobCode:null
          }));
        //  console.log("updatedPurchaseListFixed",updatedPurchaseListFixed)
        //  console.log(",item.currentPiece",item.currentPiece,item.minPiece,updatedPurchaseListFixed)
        if (item.currentPiece < item.minPiece) {
          try {
            const data = { 
                jobCode: jobCode,
                companyName: record.companyName,
                productType: "raw",
                unitName: "teknik ressam",
                deadline: currentDate,
              //  requestedProductInfo: checkedItemsStockExcess,
                requestedProductInfo:updatedPurchaseListFixed,
                offerJobCode: generateRandomCode(5),
                deadlineForPayment:null,
                paymentMethod:null,
                mailList:null,
                sentExcelList:null,
                incomingExcelList:null,
                acceptExcelDocumentUrl:null,
                description:"Minimum stok altına düşmüştür." ,
                isSendSetup: false
              };

             const result = await FirebaseService.addPurchaseRequest(data);
            if (result.success) {
              console.log(`Purchase request created successfully for item with id ${item.id}.`);
            } else {
              console.log(`Failed to create purchase request for item with id ${item.id}.`);
            }
            
          } catch (error) {
            console.error(`Error creating purchase request for item with id ${item.id}:`, error);
          }
        }
      }
    
      const data = { 
        jobCode: jobCode,
        companyName: record.companyName,
        productType: "raw",
        unitName: "teknik ressam",
        deadline: currentDate,
      //  requestedProductInfo: checkedItemsStockExcess,
        requestedProductInfo:updatedPurchaseList,
        offerJobCode: generateRandomCode(5),
        deadlineForPayment:null,
        paymentMethod:null,
        mailList:null,
        sentExcelList:null,
        incomingExcelList:null,
        acceptExcelDocumentUrl:null,
        description:"Teknik ressam tarafından seçilmiştir, setupda kullanılacaktır." ,
        isSendSetup: true
      };
        console.log("satın alma talebi data",data)
      try {
        const result = await FirebaseService.addPurchaseRequest(data);
        if (result.success) {
          setVisibleContinue(false);
        console.log( "satın alma talebi gönderildi.")


        let updatedCheckedItemsStockExcess;
      console.log("record.checkedItemsStockExcess",record.checkedItemsStockExcess)

      if (record.checkedItemsStockExcess && record.checkedItemsStockExcess.length > 0) {
        for (const x of record.checkedItemsStockExcess) {
          const { id, piece } = x;
          const result = await FirebaseService.updateStockWaitingPiece(id, piece);
          console.log(result);
        }
        console.log("Updated checkedItemsStock:", updatedCheckedItemsStockExcess);
      }

      const all = await FirebaseService.getDensitiesData();
      const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());

        const data = {
            surface: values.surface,
            thickness: Number(values.thickness),
            quality:values.quality,
            isCustomerRaw:isCustomerRaw,
            fuel: matchedItem.fuel

        };
        console.log("data",data)

        console.log("xmlınfo",xmlInfo)
        const updatedRecord = {
            ...record,
           jobCode: record.jobCode + "-" + xmlInfo.setupName
          };

          showModal(data, updatedRecord);
/*
        navigate('/setuppartsdetail', { 
        state: { 
            data: data,
            xmlInfo: xmlInfo,
            record:updatedRecord, 
            
        } 
        });
*/


        
        }
        else{
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
        }
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
      }
      
    };

    const onFinish = async (values) => {  

        console.log("xmlInfo",xmlInfo)

        const data = await FirebaseService.getOpenOrderData();
        const isAlreadySetup = data.filter(item => !item.isDeleted 
            && item.setupName === xmlInfo.setupName 
            && item.jobCode.substring(0,5) === record.jobCode 
            && item.projectCode === record.projectCode );
       
            console.log("isAlreadySetup",isAlreadySetup,record.jobCode ,data )
       
       
        if (!xmlInfo || Object.keys(xmlInfo).length === 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Lütfen uygun bir xml dosyası yükleyiniz.',
                placement: 'topRight'
            });
            return;
        }


        if (isAlreadySetup.length !== 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Daha önce bu xml dosyasyı yüklenmiştir. Lütfen başka xml yükleyiniz.',
                placement: 'topRight'
            });
            return;
        }

        const uniqueCustomerNames = new Set(xmlInfo.parts.map(part => part.customerName));
        const mergedCompanyNames = recordx.mergedCompanyNames || [];
        
        console.log("Number of unique customer names:", uniqueCustomerNames.size);
        console.log("recordx.mergedCompanyNames:", mergedCompanyNames);
        
        if (recordx && Array.isArray(recordx.mergedJobCodes)) {
          console.log("Unique customer names:", Array.from(uniqueCustomerNames).length, recordx.mergedJobCodes.length);
        
          // Şirket adlarını büyük/küçük harf duyarlılığını göz ardı ederek karşılaştırma
          const uniqueCustomerNamesArray = Array.from(uniqueCustomerNames).map(name => name.toLowerCase());
          const mergedCompanyNamesLower = mergedCompanyNames.map(name => name.toLowerCase());
        
          // Eksik müşteri isimlerini ve şirket adlarını bul
          const unmatchedCompanies = mergedCompanyNamesLower.filter(companyName => 
            !uniqueCustomerNamesArray.includes(companyName.toLowerCase())
          );
        
          // Eksik müşteri isimlerini kontrol et
          if (uniqueCustomerNamesArray.length !== mergedCompanyNamesLower.length) {
            const unmatchedCustomers = uniqueCustomerNamesArray.filter(
              customerName => !mergedCompanyNamesLower.includes(customerName)
            );
        
            // Eksik müşteri isimlerini hata mesajında göster
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: `Birleşen işler sayısı eşit değildir. Eşleşmeyen müşteri isimleri: ${unmatchedCustomers.join(', ')}`,
              placement: 'topRight',
            });
            return;
          }
        
          // Eksik şirket adlarını hata mesajında göster
          if (unmatchedCompanies.length > 0) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: `Şirket adları eşleşmiyor. Eksik olanlar: ${unmatchedCompanies.join(', ')}`,
              placement: 'topRight',
            });
            return;
          }
        }
         else {
          console.log("recordx.mergedJobCodes is undefined or not an array.");
        }
        
       

        setValues(values)
        if (values.quality === "newQuality") {
            NotificationService.openErrorNotification({
              title: 'Hata',
              description: 'Lütfen var olan bir kalite seçiniz.',
              placement: 'topRight'
            });
            return;
        }
 
       // console.log("valeus,",values,);
       // console.log("xmlInfo.parts",xmlInfo.parts);

        const all = await FirebaseService.getDensitiesData();
        const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());
  
        //teklif için parçalarda gerekiyo
        const updatedParts = xmlInfo.parts.map(part => {
            const updatedPart = { ...part };
            updatedPart.quality = values.quality;
            updatedPart.surface = values.surface;
            updatedPart.thickness = Number(values.thickness);
            updatedPart.fuel = matchedItem.fuel
            return updatedPart;
          });
          
          xmlInfo.parts = updatedParts;
          
          console.log("Updated xmlInfo.parts:", xmlInfo.parts);

        //eğer siparişse
        if (record.orderType ==="order" && ((record.stock && record.stock.length > 0) || (record.wastage && record.wastage.length > 0))) {
            console.log("sipariş wastage geldi")
            console.log("record.stock",record.stock,"record.wastage",record.wastage)

            const groupedPlates = {};
            let matchedPlates = [];
            let unmatchedPlates = [];
            xmlInfo.plates.forEach(plate => {
                const { width, height, piece } = plate;
                const key = `${width}-${height}`;
                if (groupedPlates[key]) {
                    groupedPlates[key].piece += piece;
                } else {
                    groupedPlates[key] = { width, height, piece };
                }
            });

            console.log("groupedPlates",groupedPlates);

            //grup var 
            const matchedProgramNos = [];

            if (Object.keys(groupedPlates).length >= 1) {
                console.log("1 gruptan fazla");

                Object.values(groupedPlates).forEach(group => {
                
                
                        // Eşleşen stock öğesini bul
                        const matchedStock = record.stock.find(stockItem => {
                            return (
                                stockItem.height === group.height &&
                                stockItem.width === group.width &&
                                stockItem.thickness === selectedThickness &&
                                stockItem.piece === group.piece  &&
                                stockItem.quality === values.quality &&
                                stockItem.surface === values.surface
                            );
                        });
                        console.log("gruplanmış matchedStock",matchedStock)

                        const matchedWastage = record.wastage.find(wastageItem => {
                            return (
                                wastageItem.height === group.height &&
                                wastageItem.width === group.width &&
                                wastageItem.thickness === selectedThickness &&
                                wastageItem.piece === group.piece  &&
                                wastageItem.quality === values.quality &&
                                wastageItem.surface === values.surface
                            );
                        });

                        console.log("gruplanmış matchedWastage",matchedWastage)

                        // Eğer eşleşen bir stock öğesi yada wastage bulunduysa
                        if (matchedStock ||matchedWastage ) {

                                xmlInfo.plates.forEach(plate => {
                                    const matchedStock = record.stock.find(stockItem => {
                                        return (
                                            stockItem.height === plate.height &&
                                            stockItem.width === plate.width &&
                                            stockItem.thickness === selectedThickness &&
                                            stockItem.quality === values.quality &&
                                            stockItem.surface === values.surface
                                        );
                                    });
                        
                                    if (!matchedStock) {
                                    // console.log("Eşleşme bulunamadı: wastagea bakcak");
                        
                                        const matchedWastage = record.wastage.find(wastageItem => {
                                            return (
                                                wastageItem.height === plate.height &&
                                                wastageItem.width === plate.width &&
                                                wastageItem.thickness === selectedThickness &&
                                                wastageItem.quality === values.quality &&
                                                wastageItem.surface === values.surface
                                            );
                                        });
                        
                                        if (matchedWastage) {
                                        // console.log("Eşleşme bulundu wastage'de:", plate);
                        
                                            // Eğer program numarası daha önce eklendiyse tekrar ekleme
                                            if (!matchedProgramNos.includes(plate.programNo)) {
                                                const matchedPlate = {
                                                    ...plate,
                                                    id: matchedWastage.id,
                                                // isQrScan: matchedWastage.isQrScan,
                                                    quality: matchedWastage.quality,
                                                    surface: matchedWastage.surface,
                                                    thickness: matchedWastage.thickness,
                                                    type: "wastage",
                                                    actualTime: null,
                                                    wastagesKg: 0
                                                };
                                                matchedPlates.push(matchedPlate);
                        
                                                // Program numarasını diziye ekle
                                                matchedProgramNos.push(plate.programNo);
                                            }
                                            return; // Plaka eşleştiğinde döngüden çık
                                        }
                        
                                        console.log("Eşleşme bulunamadı:", plate);
                                        unmatchedPlates.push(plate);
                                    } else {
                                    //  console.log("Eşleşme bulundu:", plate);
                        
                                        // Eğer program numarası daha önce eklendiyse tekrar ekleme
                                        if (!matchedProgramNos.includes(plate.programNo)) {
                                            console.log("daha önce eklenmemiş ekleniyor",matchedStock, matchedProgramNos)
                                            const matchedPlate = {
                                                ...plate,
                                                id: matchedStock.id,
                                            //  isQrScan: matchedStock.isQrScan,
                                                quality: matchedStock.quality,
                                                surface: matchedStock.surface,
                                                thickness: matchedStock.thickness,
                                                type: "stock",
                                                actualTime: null,
                                                wastagesKg: 0
                                            };
                                            matchedPlates.push(matchedPlate);
                        
                                            // Program numarasını diziye ekle
                                            matchedProgramNos.push(plate.programNo);
                                        }
                                        return;
                                    }
                                });
                                
                        } 
                        
                        else {
                            //eğer gruplanmış birden fazla varsa hem wastage hem stockta bulamadıysa 
                            console.log("Eşleşme bulunamadı:", group);
                            unmatchedPlates.push(group);
                            return;
                        }
                
                            
                });
            }


            //grup yok
            else {
                console.log("Gruplanmış plaka bulunamadı.");
                xmlInfo.plates.forEach(plate => {
                    // Eşleşen stock öğesini bul
                    const matchedStock = record.stock.find(stockItem => {
                        return (
                            stockItem.height === plate.height &&
                            stockItem.width === plate.width &&
                            stockItem.thickness === selectedThickness &&
                            stockItem.piece === plate.piece  &&
                            stockItem.quality === values.quality &&
                            stockItem.surface === values.surface
                        );
                    });

                    // Eğer eşleşen bir stock öğesi bulunduysa
                        if (matchedStock) {
                            // Yeni bir nesne oluştur ve özellikleri kopyala
                            const matchedPlate = {
                                ...plate,
                                id: matchedStock.id,
                            //  isQrScan: matchedStock.isQrScan,
                                quality: matchedStock.quality,
                                surface: matchedStock.surface,
                                thickness: matchedStock.thickness,
                                type:"stock",
                                actualTime:null,
                                wastagesKg:0

                            };

                            // Eşleşenleri yeni listede sakla
                            matchedPlates.push(matchedPlate);
                        } else {
                            // Eşleşmeyenleri logla
                            console.log("Eşleşme bulunamadı:", plate);
                            unmatchedPlates.push(plate);
                        }
                });

                // Sonuçları kullanabilirsin veya işleyebilirsin
                console.log("Eşleşen Plakalar:", matchedPlates);
                console.log("Eşleşmeyen Plakalar:", unmatchedPlates);     
            
            }

            if(unmatchedPlates.length > 0){
                NotificationService.openErrorNotification({
                    title:'Geçersiz Form',
                    description:'Stoktan seçilenlerle XMl dosyasındaki plakalar eşlememektir. Lütfen düzenleyiniz.' ,
                    placement:'topRight'
                })
                setTimeout(() => {
                    onClose();
                }, 2000);

            }

            else if(unmatchedPlates.length === 0){

                let density=1;
                const selectedQuality = qualities.find(item => item.quality === values.quality);
                if (selectedQuality) {
                density = selectedQuality.density;
                }

            matchedPlates.forEach((plate, index) => {
                    console.log("plaka ağırlığıııııı",plate.height,plate.width,density, Number(values.thickness))
                    const area = plate.height * plate.width;
                    const weight = area * Number(values.thickness) * density;
                    const weightInKg = (weight / 1000000).toFixed(3); 
                    matchedPlates[index].kg = weightInKg;
                });

            xmlInfo = {
                ...xmlInfo,
                plates: matchedPlates
                };
                setXmlInfo(xmlInfo);
                console.log("kntrol ",xmlInfo.plates);




                //bekleyen siparişden eğer sipariş seçip sadece güncel stoktan rezerve vs yapıldıysa
                if(record.isSetup === false && record.orderType === "order"){

                        const all = await FirebaseService.getDensitiesData();
                        const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());
                  
                        const data = {
                            surface: values.surface,
                            thickness: Number(values.thickness),
                            quality:values.quality,
                            isCustomerRaw:isCustomerRaw,
                            fuel:matchedItem.fuel
                
                        };
                    
                        console.log("xmlınfo",xmlInfo)
                        const updatedRecord = {
                            ...record,
                        jobCode: record.jobCode
                        };
                        console.log("updatedRecord",updatedRecord)
                
                        showModal(data, updatedRecord);

/*
                        navigate('/setuppartsdetail', { 
                        state: { 
                            data: data,
                            xmlInfo: xmlInfo,
                            record:updatedRecord, 
                            
                        } 
                        });
                        */


                }

                //güncel sotoktan seçip setup yüklediyse direkt
                else{
                     //fazla varsa satın alma talebi gönderiyor
                        const updatedRecord = {
                            ...record,
                           jobCode: record.jobCode + "-" + xmlInfo.setupName
                          };
                
                        console.log("updatedRecord",updatedRecord)
                        setJobCode(updatedRecord.jobCode);
                
                        const excessValuesKeys = Object.keys(record.checkedItemsStockExcess);
                        const excessValuesCount = excessValuesKeys.length;
                        console.log("fazla ürün var",record,excessValuesCount,record.checkedItemsStockExcess) 

                        if (excessValuesCount > 0) {
                            console.log("Fazla talep edilen ürün var setup yükle baısldı");
                            excessValuesKeys.forEach((key) => {
                                const value = record.checkedItemsStockExcess[key];
                                console.log(`Key: ${key}, Value: ${value}`);
                                
                            });
                            
                            setVisibleContinue(true);
                        }
                        else{
                            const all = await FirebaseService.getDensitiesData();
                            const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());
                      
                            const data = {
                                surface: values.surface,
                                thickness: Number(values.thickness),
                                quality:values.quality,
                                isCustomerRaw:isCustomerRaw,
                                fuel:matchedItem.fuel
                    
                            };
                        
                            console.log("xmlınfo",xmlInfo)
                            const updatedRecord = {
                                ...record,
                            jobCode: record.jobCode + "-" + xmlInfo.setupName
                            };
                            console.log("updatedRecord",updatedRecord)
                    
                            showModal(data, updatedRecord);
/*
                            navigate('/setuppartsdetail', { 
                            state: { 
                                data: data,
                                xmlInfo: xmlInfo,
                                record:updatedRecord, 
                                
                            } 
                            });
                            */
                        }
                }

               
            }


        }

        //eğer teklifse yada teklif onaylanmayıp setup güncellenme geldiyse 
        else if(record.orderType ==="offer"){ 
            console.log("teklif mi")

            let platesUpdateKg = xmlInfo.plates;
            let density=1;
            const selectedQuality = qualities.find(item => item.quality === values.quality);
            if (selectedQuality) {
            density = selectedQuality.density;
            }

            platesUpdateKg.forEach((plate, index) => {
                console.log("plaka ağırlığıııııı",plate.height,plate.width,density, Number(values.thickness))
                const area = plate.height * plate.width;
                const weight = area * Number(values.thickness) * density;
                const weightInKg = (weight / 1000000).toFixed(3); 
                platesUpdateKg[index].kg = weightInKg;
            });

             xmlInfo = {
            ...xmlInfo,
            plates: platesUpdateKg
            };
            setXmlInfo(xmlInfo);
            console.log("kntrol teklifte plakaya ağırlık eklendi ",xmlInfo.plates);


            let updatedRecord = {};
            console.log("record aaaaaaaaa",record)
                
            if(record.orderType === "offer" && record.isOffer && record.isOfferSetupUpdate){
                console.log("burda iş kodu değiştirme")
                 updatedRecord = {
                    ...record,
                jobCode: record.jobCode
                };
                
            }
            //teklife setup yükleme sadece iş kodu almış
            else if(record.orderType === "offer" && !record.isOffer && !record.isSetup && !record.isOfferApproved ){
                console.log("teklif srtup yüklendi")
                 updatedRecord = {
                    ...record,
                jobCode: record.jobCode,
                };
                
            }
//teklif onaylanmış setup yüklendi jobcode güncellenmeli
            else if(record.orderType === "offer" && record.isOffer && !record.isSetup  && record.isOfferApproved ){
                console.log("teklif srtup yüklendi şimdi yüklendi iş kdu güncellendi onaylanan teklif")
                 updatedRecord = {
                    ...record,
                jobCode: record.jobCode + "-" + xmlInfo.setupName, 
                };
                
            }
            else{
                //iş kodu setup ismine göre ayarlanıyor
                 updatedRecord = {
                    ...record,
                jobCode: record.jobCode + "-" + xmlInfo.setupName
                };
            }
            
            const all = await FirebaseService.getDensitiesData();
            const matchedItem = all.find(entry => entry.quality.toLowerCase() === values.quality.toLowerCase());
      
            const data = {
                surface: values.surface,
                thickness: Number(values.thickness),
                quality:values.quality,
                isCustomerRaw:isCustomerRaw,
                fuel: matchedItem.fuel

            };
            console.log("data updatedRecord",data,updatedRecord)
        
            showModal(data, updatedRecord);
/*
            navigate('/setuppartsdetail', { 
            state: { 
                data: data,
                xmlInfo: xmlInfo,
                record:updatedRecord, 
                
            } 
            });
            */
            
        
        
        }   
      
        else{
            console.log("hammade seçimi yapılmamış sipariş")
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Lütfen güncel stok seçimi yapınız.",
                placement: 'topRight'
              });
            onClose();
            setTimeout(() => {
                window.location.reload();
              }, 500);
            
        }
        
        
    }; 

    const [isModalVisibleDensities, setIsModalVisibleDensities] = useState(false);

    const handleModalCancelDensities = () => {
        setIsModalVisibleDensities(false);
    };
  
    const handleAddFinishDensities = () => {
        setIsModalVisibleDensities(false);
    };

    const handleChangeThickness = (e) => {
        const newValue = parseInt(e.target.value);
        setSelectedThickness(newValue);
      };
    
    const onChange = (value) => {
        if (value === 'newQuality') {
          //  console.log('Yeni kalite Ekle seçildi');
            setIsModalVisibleDensities(true);
        } 
        else{
          
        }
    };


    const columnsStockExcess = [
        {
          title: 'Kalite',
          dataIndex: 'quality',
          key: 'quality',
        
         // ...getColumnSearchPropsStock('quality'),
          sorter: (a, b) => a.quality.localeCompare(b.quality), 
          render: (text) => <span style={{  }}>{text}</span>,
        },
    
          {
            title: 'Kalınlık',
            dataIndex: 'thickness',
            key: 'thickness',
           
          //  ...getColumnSearchPropsStock('thickness'),
            sorter: (a, b) => a.thickness - b.thickness,
            render: (text) => <span style={{ }}>{text}</span>,
          },
    
         
          {
            title: 'En',
            dataIndex: 'width',
            key: 'width',
           
           // ...getColumnSearchPropsStock('width'),
            sorter: (a, b) => a.width - b.width,
            render: (text) => <span style={{  }}>{text}</span>,
          },
          {
            title: 'Boy',
            dataIndex: 'height',
            key: 'height',
           // ...getColumnSearchPropsStock('height'),
            sorter: (a, b) => a.height - b.height,
            render: (text) => <span style={{  }}>{text}</span>,
          },
    
          {
            title: 'Yüzey',
            dataIndex: 'surface',
            key: 'surface',
           // ...getColumnSearchPropsStock('surface'),
            sorter: (a, b) => a.surface.localeCompare(b.surface), 
            render: (text) => <span style={{  }}>{text}</span>,
          },
     
          {
            title: 'Adet',
            dataIndex: 'piece',
            key: 'piece',
            render: (text,record) => <span style={{}}>{text}</span>,
           
            sorter: (a, b) => a.piece - b.piece,
           // ...getColumnSearchPropsStock('piece'),
          },         
        
    ];


    const showModal = (data, updatedRecord) => {
        setModalData(data);
        setUpdatedRecord(updatedRecord);
        setIsModalVisible(true); // Modal açılıyor
      };

      const getFileNameWithoutPrefixAndSuffixPlates = (fileName) => {
        // "TNT_" ve ".bmp" uzantılarını kes
        if (fileName.startsWith('TMT_') && fileName.endsWith('.bmp')) {
          return fileName.slice(4, -4); // "TNT_" prefix'i ve ".bmp" suffix'i keser
        }
        return fileName; // Eğer dosya adı belirtilen formatta değilse, olduğu gibi döner
      };

      const convertDxfToSvgAndUpdateParts = async (fileListPartImage, xmlInfo) => {
        // DXF dosyalarını ConvertAPI ile SVG'ye dönüştürme
        const uploadPromises = fileListPartImage.map(async (file) => {
          try {
            // DXF dosyasını Base64 formatına çeviriyoruz
            const toBase64 = (file) => new Promise((resolve, reject) => {
              const reader = new FileReader();
              
              // Kontrol: file nesnesinin type ve instanceof uygunluğunu doğrulama
              if (file.originFileObj instanceof Blob || file.originFileObj instanceof File) {
                console.log("burdaaaa ")
                reader.readAsDataURL(file.originFileObj);
              } else {
                reject(new TypeError('FileReader expects a Blob or File instance'));
              }
              
              reader.onload = () => resolve(reader.result.split(',')[1]); // Base64 içeriği
              reader.onerror = error => reject(error);
            });
            
            const base64FileContent = await toBase64(file);
            console.log("File Name for Conversion:", file.originFileObj.name); // Log file name
            
            // ConvertAPI'ye POST isteği gönderiyoruz
            const response = await fetch('https://v2.convertapi.com/convert/dxf/to/svg?Secret=secret_vWKE11DsF8fk3sQj', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "Parameters": [
                  {
                    "Name": "File",
                    "FileValue": {
                      "Name": file.originFileObj.name,
                      "Data": base64FileContent
                    }
                  },
                  {
                    "Name": "StoreFile",
                    "Value": true
                  }
                ]
              }),
            });
        
            // API yanıtını işleme
            const result = await response.json();
            const svgUrl = result.Files[0].Url; // SVG dosyasının URL'si
            console.log("Converted SVG File URL:", svgUrl);
            
            return {
              fileName: file.originFileObj.name,
              svgUrl
            };
          } catch (err) {
            console.error("Error converting DXF to SVG:", err);
            return null;
          }
        });
      
        // Tüm dosyalar için dönüşüm işlemini bekle
        const convertedFiles = await Promise.all(uploadPromises);
        console.log("Converted Files:", convertedFiles);
      
        // Parçaları güncelleme işlemi
        xmlInfo.parts.forEach((part) => {
          console.log("Processing Part:", part.descriptionForImageName);
      
          // ConvertAPI'den dönen SVG URL'sini bulma
          const matchingFile = convertedFiles.find((file) => {
            console.log("Comparing:", file ? file.fileName : "null", "with", part.descriptionForImageName);
            return file && file.fileName.includes(part.descriptionForImageName);
          });
      
          if (matchingFile) {
            part.imageUrl = matchingFile.svgUrl; // Parçanın imageUrl alanını SVG URL'si ile güncelle
            console.log("Updated part.imageUrl:", part.imageUrl);
          } else {
            console.log("No matching URL found for:", part.descriptionForImageName);
          }
        });
      };
      
      
      
      // Bu fonksiyon, ConvertAPI ve parça güncelleme işlemini tek bir akışta gerçekleştirir.
      
      const handleOk = async() => {

        setLoading(true);
        const uploadedFileNamesParts = fileListPartImage.map(file => file.name);

        if (uploadedFileNamesParts.length === 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Parça fotoğrafları yükleyiniz.',
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }
        const cleanedFileNamesParts = uploadedFileNamesParts.map(name => 
          name.replace(/\.(jpg|svg|pdf|png|dxf)$/i, '').trim()
      );
        const imageUrlsParts = xmlInfo.parts.map(part => part.descriptionForImageName.trim());
        const missingInUploaded = imageUrlsParts.filter(name => !cleanedFileNamesParts.includes(name));
        
        console.log("uploadedFileNamesParts Length:", cleanedFileNamesParts.length);
        console.log("imageUrlsParts Length:", imageUrlsParts.length);
        console.log("cleanedFileNamesParts:", cleanedFileNamesParts);
        console.log("missingInUploaded:", missingInUploaded); // Eşleşmeyen parçalar burada
        
        if (missingInUploaded.length > 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Aşağıdaki parçalar yüklenmedi: ${missingInUploaded.join(', ')}`,
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }
        
        if (cleanedFileNamesParts.length !== imageUrlsParts.length) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: `Yüklenilen parça fotoğrafları eksik. Eksik parçalar: ${missingInUploaded.join(', ')}`,
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }
        


        const uploadedFileNamesPlates = fileListPlateImage.map(file => getFileNameWithoutPrefixAndSuffixPlates(file.name));
        if (uploadedFileNamesPlates.length === 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: 'Plaka fotoğrafları yükleyiniz.',
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }


      
        const imageUrlsPlates = xmlInfo.plates.map(plate => plate.programNo);
        const missingImagesPlates = uploadedFileNamesPlates.filter(name => !imageUrlsPlates.includes(name));
        console.log("uploadedFileNamesPlates",uploadedFileNamesPlates)
        console.log("imageUrlsPlates",imageUrlsPlates)
        console.log("missingImagesPlates",missingImagesPlates)

         if (missingImagesPlates.length > 0) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description:`Plaka için aşağıdaki dosyalar eşleşmiyor: ${missingImagesPlates.join(', ')}`,
                placement: 'topRight'
            });
            setLoading(false);
            return;
        } 
        if(uploadedFileNamesPlates.length !== imageUrlsPlates.length){
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description:"Yüklenilen plaka fotoğrafları eksik.",
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }

        const folderName = 'setupDocuments';

        if (fileListSetupPdf && fileListSetupPdf.length > 0) {
            const selectedDocument = fileListSetupPdf[0].originFileObj; 
            const fileName = selectedDocument.name;

            const documentUrl = await StoregeService.uploadDocumentAndGetURL(folderName, selectedDocument, fileName);

            if (documentUrl && typeof documentUrl === 'string') {
                modalData.documentUrl = documentUrl; 
                console.log("Document URL başarıyla kaydedildi:", documentUrl);
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description:"Yüklenilen hata oluştu.",
                placement: 'topRight'
            });
            setLoading(false);
            return;
            }
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description:"Yüklenecek PDF dosyası bulunamadı.",
            placement: 'topRight'
          });
          setLoading(false);
          return;
        }

       
        
      console.log("Updated xmlInfo.parts:", xmlInfo.parts);
      

        const uploadPromisesPlates = fileListPlateImage.map(file => FirebaseStorage.uploadImageAndGetURL('setupPlatesImages', file.originFileObj));
        const urlsPlates = await Promise.all(uploadPromisesPlates);
  
        console.log("Uploaded File Names: uploadedFileNamesPlates", uploadedFileNamesPlates);
        console.log("urlsPlates:", urlsPlates);

        xmlInfo.plates.forEach((plate) => {
            console.log("Processing plate:", plate);
            
            if (uploadedFileNamesPlates.includes(plate.programNo)) {
            console.log("File matches:", plate.programNo);
            const matchingUrl = urlsPlates.find(url => url.includes(plate.programNo));
            if (matchingUrl) {
                plate.imageUrl = matchingUrl;
                console.log("Updated plates.imageUrl:", plate.imageUrl);
            }
            }
        });

        console.log("updated, xml plates",xmlInfo.plates)


        await convertDxfToSvgAndUpdateParts(fileListPartImage, xmlInfo);

   
      
        navigate('/setuppartsdetail', {
            state: {
              data: modalData,
              xmlInfo: xmlInfo,
              record: updatedRecord,
            },
        });

      

      
      
       
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };


      
      const handleFileChangePartImage = ({ fileList: newFileList }) => {
      
        setFileListPartImage(newFileList);
      };

      const handleFileChangePlateImage = ({ fileList: newFileList }) => {
        setFileListPlateImage(newFileList);
      };

      const handleFileChangeSetupPdf = ({ fileList: newFileList }) => {
        setFileListSetupPdf(newFileList);
      };


   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 6,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            <Form.Item
            label="XML Dosyası"
            name="xml"
            rules={[
                {
                required: true,
                message: 'Lütfen xml dosyası yükleyiniz!',
                },
            ]}
            wrapperCol={{
                span: 6,
            }}
            style={{
                maxWidth: "100%",
                textAlign: "left"
            }}
            >
            <Upload
                beforeUpload={(file) => {
                setXmlFile(file);
                return false; 
                }}
                showUploadList={false} 
                onChange={handleXmlFileChange} 
            >
                <Button
                icon={<UploadOutlined />}
                style={{ width:"100%" }}
                className="input-style" 
                >
                XML Dosyası Yükle
                </Button>
            </Upload>
            {xmlFile && <div style={{ marginTop: 8 , width:"400px"}}>{xmlFile.name}</div>}

            </Form.Item>

        

            <Form.Item
            label="Kalite"
            name="quality"
            rules={[
                {
                required: true,
                message: 'Lütfen kalite giriniz!',
                },
            ]}
            >
            <Select
                onChange={onChange}
                placeholder="Kalite Seçiniz"
             
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...qualities.map(item => ({ label: item.quality, value: item.quality })),
                  { label: 'Yeni Kalite Ekle', value: 'newQuality' },
                ]}
                className="input-style" 
            />
            </Form.Item>

           
            <Modal
                title="Kalite Ekleme Talebi"
                visible={isModalVisibleDensities}
                onCancel={handleModalCancelDensities}
                footer={null}
              >
              <AddRequestAdminDensities onClose={handleAddFinishDensities} />
              </Modal>
            

            <Form.Item
            label="Kalınlık"
                name="thickness"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen kalınlık giriniz!',
                    },
                    {
                        pattern: /^\d+(\.\d+)?$/, // Ondalık sayıları da kabul eden desen
                        message: 'Lütfen geçerli bir kalınlık değeri giriniz.',
                      },
                ]}
              
                >
            <Input    className="input-style"  onChange={handleChangeThickness } style={{width:"100%"}} placeholder="Kalınlık"/>
            </Form.Item>

            <Form.Item
            label="Yüzey"
                name="surface"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen Yüzey Giriniz!',
                    },
                ]}
            
                >
                      <Select
                onChange={onChange}
                placeholder="Yüzey Seçiniz"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...surfaces.map(item => ({ label: item.surface, value: item.surface })),
                //  { label: 'Yeni Firma Ekle', value: 'newCompany' },
                ]}
                className="input-style"     

            />
            </Form.Item>
            

            <Form.Item
              name="isCustomerRaw">
              <Checkbox   onChange={() => setIsCustomerRaw(!isCustomerRaw)} style={{fontWeight:"500"}}>
             Müşteri Sacı kullanılacak.
              </Checkbox>
            </Form.Item>

        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>İleri</Button>
            </Form.Item>

        </Form>


        <div>           
          <Modal
                  title="Satın Alma Talebi Gönder"
                  visible={visibleContinue}
                  onOk={handleOkContinue}
                  onCancel={handleCancelContinue}
                  okText="Evet, Gönder ve Açık Sipariş Oluşturmaya Devam Et"
                  cancelText="Vazgeç"
                  className="columnsStockExcess popup-modal-button"
                
              >
              <div> 
                <p>Satın alma talebi göndermek istediğinizden emin misiniz?</p>
                <Table
                    dataSource={recordx.checkedItemsStockExcess}
                    columns={columnsStockExcess}
                    showHeader={true}
                    pagination={false} 
                    className="custom-news-table"
                    rowClassName="custom-news-row"
                    locale={{
                        emptyText: 'Henüz eklemediniz...',
                        filterReset: 'Sıfırla',
                        filterTitle: 'Filtre Menüsü',
                        selectAll: 'Hepsini Seç',
                        selectInvert: 'Tersini Seç',
                        selectionAll: 'Tümünü Seç',
                        sortTitle: 'Sıralama',
                        triggerDesc: 'Azalan sıralama için tıklayın',
                        triggerAsc: 'Artan sıralama için tıklayın',
                        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                      }}
                />
            </div>
        </Modal>


        <div>
      {/* Modal tanımı */}
      <Modal
        title="Setup İçeriği Yükle"
        visible={isModalVisible}
        onOk={handleOk} // "Evet"e basıldığında yönlendirme yapılır
        onCancel={handleCancel} // "Hayır" butonuna basıldığında modal kapanır
        okText="Yükle"
        cancelText="Vazgeç"
      
        className=" setup-content popup-modal-button"

      >
        <div style={{display:"flex"}}>
        <Upload
          action="/upload.do"
          listType="picture"
          fileList={fileListPartImage}
          onChange={handleFileChangePartImage}
          multiple
          beforeUpload={() => false}
        >
          <Button icon={<UploadOutlined />}>Parça Fotoğrafı Yükle</Button>
        </Upload>

        {/* Plaka fotoğrafı yükleme */}
        <Upload
          action="/upload.do"
          listType="picture"
          fileList={fileListPlateImage}
          onChange={handleFileChangePlateImage}
          multiple
          beforeUpload={() => false}
        >
          <Button icon={<UploadOutlined />}>Plaka Fotoğrafı Yükle</Button>
        </Upload>

        {/* PDF yükleme */}
        <Upload
          action="/upload.do"
          listType="picture"
          fileList={fileListSetupPdf}
          onChange={handleFileChangeSetupPdf}
         
          beforeUpload={() => false}
        >
          <Button icon={<FilePdfOutlined />}>PDF Yükle</Button>
        </Upload>
        </div>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

      </Modal>
     
    </div>

    
        </div>
    </div>)

};

SetupAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default SetupAdd;