import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import FirebaseService from "../../services/firebaseService";
import  { parsCollections, qualityGroup}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

 
const AllItemsAddAdmin = ({ record,onClose }) => {
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
     
      };
      fetchData();
    }, []);
    function generateRandomCode(length) {
      const characters = '0123456789';
      let result = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    
      return result;
  }
  
    const [loading, setLoading] = useState(false);
    const onFinish = async (values) => {
      
        setLoading(true)
        const { tableName, description, key, firstTransactionDateEdit, firstUserName, firstUserSurname,firstTransactionDate, ...newItem } = record;
        const newItemWithAdditionalProperties = {
            ...newItem,
            firstTransactionDate:new Date(),
            minPiece : Number(values.minPiece),
            code: generateRandomCode(5),

        };

    console.log("Yeni öğe:", newItemWithAdditionalProperties);
    try {
      const result = await FirebaseService.addRecording(record.tableName,newItemWithAdditionalProperties);
      if (result.success) {

        const deleteItem = await FirebaseService.deleteAddRequest(record.key);
        if (deleteItem.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setLoading(false)
        //  const data = await FirebaseService.getAddRequestsData();
        onClose();
          setTimeout(() => {
            window.location.reload();
           // navigate("/addrequestslist");
        }, 2000);
       
            
            
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false)
        }
      } 
      else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false)
      }
      
      
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
      setLoading(false)
    }
    

        
     
    }; 


    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };
    
    
   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        > 
            <Form.Item
            name="minPiece"
            rules={[
                {
                    required: true,
                    message: 'Lütfen minimum adet giriniz!',
                },
                {
                    pattern: /^[0-9.]+$/,
                    message: 'Sadece rakam ve nokta (.) girişi yapınız.',
                },
               
            ]}
            >
           <Input className="input-style" style={{width:"100%"}} placeholder="Minimum Adet"/>
            </Form.Item>

       <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" loading={loading} style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

    </div>)

};

AllItemsAddAdmin.propTypes = {
    onClose: PropTypes.func.isRequired, 
  };
  
export default AllItemsAddAdmin;