import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Space, Upload,Modal,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";
import OneSignalService from "../../../services/oneSignalService";



const AlarmRequestRemoval = ({ record,onClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [recordx, setRecordx] = useState(record);

    useEffect(() => {
      const fetchData = async () => {
       
      

      };
      fetchData();
    }, []);
    
    const getOneSignalPlayerIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.isManager);
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIds = async () => {
      try {
        const dataCollection = await FirebaseService.getUserData();
        const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.isManager);
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };

    const onFinish = async (values) => {
      setLoading(true)
        const data = {
            requestRemovalDescription: values.description,
            isRequestRemoval: true,
            requestRemovalUserId:localStorage.getItem("uid")

        };
        const result = await FirebaseService.updateAlarmRequestRemovalData(record.key,data);
        if (result) {
         console.log("update oldu")
         NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: "Alarm kaldırma talebiniz başarıyla gönderildi.",
            placement: 'topRight'
          });setLoading(false)

            //bildirim 
            const ids = await getOneSignalPlayerIds();
            const userIds = await getOneSignalUserIds();

            const sentUserIdsMap = new Map();
            userIds.forEach(userId => {
              sentUserIdsMap.set(userId, false);
            });
            
            const sentUserIds = Object.fromEntries(sentUserIdsMap);
            //console.log("sentUserIds mapppp", sentUserIds);

            if (ids.length > 0) {
              const oneSignalResult = await OneSignalService.sendNotification("", "Alarm Kaldırma Talebi", ids);
              console.log("OneSignal notification result:", oneSignalResult);
              const currentDate =new Date()
              const data = {
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
                isDeleted: false,
                sentUserIds:sentUserIds,
                firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                unitName:localStorage.getItem('userUnit'),
                title:"Alarm Kaldırma Talebi",
                companyName:null,
                jobCode:recordx.jobCode,
                projectCode:null,
                machine:null,
                type:null,
                notice:"Alarm kaldırma talebi gelmiştir.",
                description:values.description ? values.description : null
  
              };
              const result = await FirebaseService.addRecording(parsCollections.notifications,data);
              console.log("result",result)
            } else {
              console.log("No OneSignal Player IDs found.");
            }

          onClose();
          
          const data = await FirebaseService.getAlarmsData();
          const record = data.filter(item => !item.isDeleted);
      
          setTimeout(() => {
            navigate('/alarmlist', { state: { record } })
          }, 1000);
        } else {
          console.log("update olmadı")
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });setLoading(false)
    
        }    
    }; 

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        
         
            <Form.Item
            label="Açıklama"
                name="description"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen açıklama giriniz!',
                    },
                ]}
               
                >
            <Input  className="input-style"
         style={{width:"100%"}} placeholder="Açıklama"/>
            </Form.Item>

        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Talebi Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

AlarmRequestRemoval.propTypes = {
 
  record: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired, 
};

  export default AlarmRequestRemoval;