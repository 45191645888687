import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const OffersGivenList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("record",record)
   // setItems(record.data);

    if (record) { 
      console.log("record", record);
      setItems(record);
    }
    
   
    const fetchData = async () => {
    };

    fetchData();
  }, [record]);

  const goEditPage = (record) => {
    navigate('/waitingopenorderdetail', { state: { record } })
  };


  const goDelete = async(record) => {
    console.log("delete",record);
    let success = true; 
     setLoading(true);

    const allOpenOrder = await FirebaseService.getOpenOrderData();
    const filteredOpenOrder = allOpenOrder.filter(item => !item.isDeleted && item.jobCode.includes(record.jobCode));
    
    const ordersWithAllItemsQrScanFalse = filteredOpenOrder.filter(order => {
        const stockItems = order.stock || [];
        const wastageItems = order.wastage || [];
    
        const allStockItemsQrScanFalse = stockItems.every(item => item.isQrScan === false);
        const allWastageItemsQrScanFalse = wastageItems.every(item => item.isQrScan === false);
    
        return allStockItemsQrScanFalse && allWastageItemsQrScanFalse;
    });
    
    console.log("ordersWithAllItemsQrScanFalse", ordersWithAllItemsQrScanFalse);
    if (ordersWithAllItemsQrScanFalse.length === 0) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bu açık sipariş silinemez.",
        placement: 'topRight'
      });
      setLoading(false);
      return;
    }
    else{
      let combinedStockItems = [];

      ordersWithAllItemsQrScanFalse.forEach(order => {
        if (order.stock) {
            order.stock.forEach(stockItem => {
                const existingItemIndex = combinedStockItems.findIndex(item => item.id === stockItem.id);
    
                if (existingItemIndex !== -1) {
                    combinedStockItems[existingItemIndex].piece += stockItem.piece;
                } else {
                    combinedStockItems.push({ ...stockItem });
                }
            });
        }
    });
    
      console.log("Combined Stock Items:", combinedStockItems);
      
      //stock listesindekileri update etmek için
      async function updateCombinedStockItems(stockItems) {
        for (const item of stockItems) {
            const { id, piece,isSendPurchase } = item;
            if(isSendPurchase){
              console.log("satın alma talebi gitmiş waiting değişcek");
              const resultPurchase = await FirebaseService.updateStockWaitingPieceRemove(id, piece);
              console.log("Result for item", id, ":", resultPurchase);
            }
            else{
              console.log("reserve değişcek");
              const result = await FirebaseService.updateStockReservedPieceRemove(id, piece);
              console.log("Result for item", id, ":", result);
  
            }
        }
      }
      updateCombinedStockItems(combinedStockItems);
      let combinedWastageItems = [];
  
      ordersWithAllItemsQrScanFalse.forEach(order => {
        if (order.wastage) {
          order.wastage.forEach(wastageItem => {
              const existingItemIndex = combinedWastageItems.findIndex(item => item.id === wastageItem.id);
  
              if (existingItemIndex !== -1) {
                combinedWastageItems[existingItemIndex].piece += wastageItem.piece;
              } else {
                combinedWastageItems.push({ ...wastageItem });
              }
          });
        }
      });
  
      console.log(" combinedWastageItems Items:",combinedWastageItems);
  
      //wastage listesindekileri update etmek için
      async function updateCombinedWastageItems(wastageItems) {
        for (const item of wastageItems) {
            const { id, piece } = item;
            const result = await FirebaseService.updateWastageReservedPieceRemove(id, piece);
            console.log("Result for item", id, ":", result);
        }
      }
      updateCombinedWastageItems(combinedWastageItems);
  
      //açık siparişler silme kısmı
     
      const openOrderData = await FirebaseService.getOpenOrderData();
      const filteredOpenOrder = openOrderData.filter(item => {
          const jobCodeMatch = item.jobCode && item.jobCode.includes(record.jobCode);
          const setupNameMatch = (item.setupName && item.setupName.includes(record.setupName)) || 
                                 (item.setupName === null && record.setupName === null);
          
          return !item.isDeleted && jobCodeMatch && setupNameMatch;
      });
      console.log("filteredOpenOrder", filteredOpenOrder);
      
     
      for (const item of filteredOpenOrder) {
        try {
          const result = await FirebaseService.deleteOpenOrderData(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
      }
  
    //setup silme kısmı
    const setupData = await FirebaseService.getSetupData();
    const filteredSetup = setupData.filter(item => {
        const jobCodeMatch = item.jobCode && item.jobCode.includes(record.jobCode);
        const setupNameMatch = (item.setupName && item.setupName.includes(record.setupName)) || 
                              (item.setupName === null && record.setupName === null);
        
        return !item.isDeleted && jobCodeMatch && setupNameMatch;
    });
    console.log("filteredSetup", filteredSetup);
  
    for (const item of filteredSetup) {
        try {
          const result = await FirebaseService.deleteSetupData(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
    }
    
    //iş kodu silme kısmı
      const jobCodes = await FirebaseService.getJobCodesData();
      const filteredJobCodes = jobCodes.filter(item => {
        return !item.isDeleted  &&
        item.jobCode && item.item.includes(record.jobCode)
      });

      console.log("filteredJobCodes",filteredJobCodes)
      
      for (const item of filteredJobCodes) {
        try {
          const result = await FirebaseService.deleteJobCodeData(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
      }
  
      //fason üretim silme 
      const processes = await FirebaseService.getContractManufacturingData();
      const filteredProcesses = processes.filter(item => {
        return !item.isDeleted && 
        item.jobCode && item.jobCode.includes(record.jobCode);
      });
     
      console.log("filteredProcesses",filteredProcesses)
      
      for (const item of filteredProcesses) {
        try {
          const result = await FirebaseService.deleteContractManufacturingData(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
      }
  
        //satın alma talebi silme 
        const purchaseRequests = await FirebaseService.getPurchaseRequestData();
      //  console.log("purchaseRequests", purchaseRequests);
        
        const filteredPurchaseRequests = purchaseRequests.filter(item => {
            return !item.isDeleted && 
            item.requestedProductInfo && item.requestedProductInfo.some(info => info.jobCode === record.jobCode);
        });
        
        console.log("filteredPurchaseRequests", filteredPurchaseRequests);
        
        
        for (const item of filteredPurchaseRequests) {
          try {
            const result = await FirebaseService.deletePurchaseRequestData(item.key);
            if (result) {
              console.log(`Item with key ${item.key} deleted successfully.`);
            } else {
             success=false;
              console.log(`Failed to delete item with key ${item.key}.`);
            }
          } catch (error) {
            success=false;
            console.error(`Error deleting item with key ${item.key}:`, error);
          }
        }
    


    //part silme kısmı
      const partsData = await FirebaseService.getPartsData();
      const filteredPart = partsData.filter(item => {
        return item.jobCode && item.jobCode.includes(record.jobCode) &&
         item.setupName && item.setupName.includes(record.setupName);
      });

      console.log("filteredPart",filteredPart)
      
      for (const item of filteredPart) {
        try {
          const result = await FirebaseService.deletePartData(item.key);
          if (result) {
            console.log(`Item with key ${item.key} deleted successfully.`);
          } else {
            success=false;
            console.log(`Failed to delete item with key ${item.key}.`);
          }
        } catch (error) {
          success=false;
          console.error(`Error deleting item with key ${item.key}:`, error);
        }
      }
    
  
  
      if (success) {
        NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: 'Bekleyen sipariş başarıyla silindi.',
            placement: 'topRight'
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/programmerpage");
        }, 2000);
    } else {
        NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: 'Bekleyen sipariş silinemedi. Lütfen daha sonra tekrar deneyiniz',
            placement: 'topRight'
        }); 
        setLoading(false);
    };

    }

   
  }


  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Programcı',
      dataIndex: 'programmerName',
      key: 'programmerName',
     
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
         { text }
        </span>
        
      )
 },
 {
  title: 'Setup İsmi',
  dataIndex: 'setupName',
  key: 'setupName',
 
  render: (text, record) => (
    <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
     { text ? text : "-" }
    </span>
    
  )
  },
  {
    title: 'Durum',
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      let statusColor = "#000000"; 

      switch (text) {
        case "Teklif Bekleniyor":
          statusColor = colors.orange; 
          break;
        case "Teklif Verildi":
          statusColor = colors.green; 
          break;
        case "Onaylanmadı, Teklif Değişikliği":
          statusColor = colors.gray
          break;
        case "Onaylanmadı, Reddedildi":
          statusColor = colors.gray
          break;
        default:
          statusColor = "#000000"; 
      }

      return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
    },
  },

   
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        width: 300,
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
        sorter: (a, b) => {
          const deadlineA = a.deadline ? a.deadline.seconds : 0;
          const deadlineB = b.deadline ? b.deadline.seconds : 0;
          return deadlineA - deadlineB;
      },
      },
      
         
    {
        title: '',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      },
/*
      {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, record) => (
          <img
          src="/images/delete.svg"
            alt="Detayı Gör"
            onClick={() => goDelete(record)}
            style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
          />
        ),
      }*/
      
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Verilen Teklifler</h3>
        <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = items.filter(item => {
            const jobCode = item.jobCode.toLowerCase() ;
            return jobCode.includes(searchValue);
          });
          setItems(filteredData);
        }}
      />

        <Table
            locale={{
              emptyText: 'Henüz verilen teklif bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
           {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>


  };
  export default OffersGivenList;
 