import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Spin, InputNumber } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const WastageListAdminEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const record = location.state && location.state.record; // Seçilen fire kaydı
  const [loading, setLoading] = useState(false);
  
  // Wastage verilerinden dinamik olarak çekilecek seçenekler
  const [wastageSurfaceOptions, setWastageSurfaceOptions] = useState([]);
  const [wastageQualityOptions, setWastageQualityOptions] = useState([]);

  // Wastage verilerini yükleme
  useEffect(() => {
    const fetchWastageData = async () => {
      const wastageData = await FirebaseService.getWastagesData();
      
      // Benzersiz yüzey ve kalite değerlerini alalım
      const wastageSurfaceSet = new Set();
      const wastageQualitySet = new Set();

      wastageData.forEach(item => {
        if (item.surface) wastageSurfaceSet.add(item.surface);
        if (item.quality) wastageQualitySet.add(item.quality);
      });

      setWastageSurfaceOptions([...wastageSurfaceSet]); // Yüzey seçenekleri
      setWastageQualityOptions([...wastageQualitySet]); // Kalite seçenekleri
    };

    fetchWastageData();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const updatedData = { ...record, ...values }; // Güncellenmiş verileri oluştur
    try {
      await FirebaseService.updateWastage(record.key, updatedData); // Wastage güncelle
      NotificationService.openSuccessNotification({
        title: 'Başarılı',
        description: 'Fire başarıyla güncellendi.',
        placement: 'topRight'
      });
      setLoading(false);
      navigate('/stocklistadmin'); // Stok listesini göster
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Fire güncellenemedi, lütfen tekrar deneyin.',
        placement: 'topRight'
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: 'Geçersiz Form',
      description: 'Lütfen form bilgilerini kontrol ediniz.',
      placement: 'topRight'
    });
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{
          span: 2,
        }}
        wrapperCol={{
          span: 8,
        }}
        style={{
          maxWidth: "100%",
          padding: "5% 0 0 5%",
        }}
        initialValues={{
          thickness: record?.thickness || "",
          surface: record?.surface || "",
          quality: record?.quality || "",
          width: record?.width || "",
          height: record?.height || "",
          piece: record?.piece || 0,
          reservedPiece: record?.reservedPiece || 0,
          waitingPiece: record?.waitingPiece || 0,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* Kalınlık */}
        <Form.Item
          label="Kalınlık"
          name="thickness"
          rules={[{ required: true, message: 'Lütfen kalınlık giriniz!' }]}
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Kalınlık (mm)" />
        </Form.Item>

        {/* Kalite */}
        <Form.Item
          label="Kalite"
          name="quality"
          rules={[{ required: true, message: 'Lütfen kalite seçiniz!' }]}
        >
          <Select placeholder="Kalite Seçiniz" className="input-style" style={{ width: "100%" }}>
            {wastageQualityOptions.map(option => (
              <Select.Option key={option} value={option}>{option}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* Yüzey */}
        <Form.Item
          label="Yüzey"
          name="surface"
          rules={[{ required: true, message: 'Lütfen yüzey seçiniz!' }]}
        >
          <Select placeholder="Yüzey Seçiniz" className="input-style" style={{ width: "100%" }}>
            {wastageSurfaceOptions.map(option => (
              <Select.Option key={option} value={option}>{option}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* En */}
        <Form.Item
          label="En"
          name="width"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="En" />
        </Form.Item>

        {/* Boy */}
        <Form.Item
          label="Boy"
          name="height"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Boy" />
        </Form.Item>

        {/* Adet */}
        <Form.Item
          label="Adet"
          name="piece"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Adet" />
        </Form.Item>

        {/* Rezerve Adet */}
        <Form.Item
          label="Rezerve Adet"
          name="reservedPiece"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Rezerve Adet" />
        </Form.Item>

        {/* Beklenen Adet */}
        <Form.Item
          label="Beklenen Adet"
          name="waitingPiece"
        >
          <InputNumber className="input-style" style={{ width: "100%" }} placeholder="Beklenen Adet" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 15,
            span: 18,
          }}
        >
          <Button htmlType="submit" loading={loading} style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500" }}>
            Kaydet
          </Button>
        </Form.Item>
      </Form>

      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default WastageListAdminEdit;
