import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';


const OffersGivenListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => !item.isDeleted && item.isWaiting );
      if (filteredData) {
        let updatedItems = filteredData.map(item => {
        
          let newStatus = "Unknown";
             if (item.isWaiting && !item.isSetup && item.orderType === "order" && item.stock != null ) {
            newStatus = "Sipariş,Setup Bekleniyor";
          } 
          else if (item.isWaiting && !item.isSetup && item.orderType === "order" &&  item.stock == null) {
            newStatus = "Sipariş, Güncel Stok Seçimi Bekleniyor";
          } 
          else if (item.isWaiting && !item.isSetup && item.orderType === "offer" && item.isOfferApproved && item.isOffer ) {
            newStatus = "Onaylandı, Setup Bekleniyor";
          }
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && !item.isOffer) {
            newStatus = "Teklif Bekleniyor";
          } 
          else if (item.isWaiting && !item.isSetup && item.orderType === "offer" && !item.isOffer) {
            newStatus = "Teklif, Setup Bekleniyor";
          } 
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && !item.isOfferSetupUpdate  && !item.isOfferDenied) {
            newStatus = "Teklif Verildi";
          }
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && item.isOfferApproved) {
            newStatus = "Onaylandı"; //bu durumun görünmeesi lazım onaylanan tekliflere düşcek
          }

          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && item.isOfferChange  && !item.isOfferSetupUpdate  && !item.isOfferDenied) {
            newStatus = "Onaylanmadı,Teklif Değişikliği";
          }
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && item.isOfferSetupUpdate  && !item.isOfferDenied) {
            newStatus = "Onaylanmadı,Setup Düzenleme";
          }
          else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && !item.isOfferSetupUpdate  && item.isOfferDenied) {
            newStatus = "Onaylanmadı,Reddedildi";
          }
          
  
          return { ...item, status: newStatus };
        });
  
        const desiredStatuses = [
          "Teklif Bekleniyor",
          "Teklif Verildi",
          "Onaylanmadı, Teklif Değişikliği",
          "Onaylanmadı, Reddedildi"
        ];
        
        const filteredStatusItems = updatedItems.filter(item => desiredStatuses.includes(item.status));
        const limitedData = filteredStatusItems.slice(0, 5); 
        setItemsLimited(limitedData);
        setItems(filteredStatusItems);
      }
    
    };

    fetchData();
  }, []);

  const goEditPage = (record) => {
    navigate('/waitingopenorderdetail', { state: { record } })
  };


  const goPage = (record) => {
    navigate('/offersgivenlist', { state: { record } })
  };

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        let statusColor = "#000000"; 
  
        switch (text) {
          case "Teklif Bekleniyor":
            statusColor = colors.orange; 
            break;
          case "Teklif Verildi":
            statusColor = colors.green; 
            break;
          case "Onaylanmadı, Teklif Değişikliği":
            statusColor = colors.gray
            break;
          case "Onaylanmadı, Reddedildi":
            statusColor = colors.gray
            break;
          default:
            statusColor = "#000000"; 
        }
  
        return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
      },
    },
  
      
    {
        title: '',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      }
  ];

  return  <div style={{  
       // width:"50%",
       
      }}
      className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
        Verilen Teklifler
       </Button>

       <Table
            locale={{
              emptyText: 'Henüz bekleyen sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      
     
    </div>

  };
  export default OffersGivenListLimited;

    