import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors, placeholderMap}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';


const OpenOrderGroupListLimited = () => {

  const navigate = useNavigate();
  const [items, setItems] = useState();
  const [itemsLimited, setItemsLimited] = useState([]);

  const groupByJobCode = (data) => {
    console.log("data", data);
    return data.reduce((groupedData, item) => {
      const { jobCode, companyName, projectCode, deadline, ...rest } = item;
      const newItem = { jobCode, companyName, projectCode, deadline, ...rest };
  
      const jobCodePrefix = jobCode.slice(0, 5);
  
      if (!groupedData[jobCodePrefix]) {
        groupedData[jobCodePrefix] = {
          companyName,
          jobCode: jobCodePrefix, 
          projectCode,
          deadline,
          data: [newItem]
        };
      } else {
        groupedData[jobCodePrefix].data.push(newItem);
      }
      
      return groupedData;
    }, {});
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => !item.isDeleted && !item.isWaiting && item.isSetup && item.orderType === "order");

      const processedData = filteredData.map(item => {
          let statusText = "Bilinmiyor";
          const combinedList = [...item.stock, ...item.wastage];
        //  console.log("combinedList",combinedList)
          const allQrScansFalse = combinedList.every(item => !item.isQrScan);
        //  console.log("allQrScansFalse",allQrScansFalse)
          const allQrScansTrue = combinedList.every(item => item.isQrScan);
        //  console.log("allQrScansTrue",allQrScansTrue)
          const anyQrScanTrue = combinedList.some(item => item.isQrScan);
        //  console.log("anyQrScanTrue",anyQrScanTrue)

          if (allQrScansFalse) {
              statusText = "Depoda";
          } else if (allQrScansTrue) {
              statusText = "Depodan Çıktı";
          } else  {
              statusText = "Kısmı Çıkış";
          }
      
          return { ...item, statusText };
      });
      
    // console.log("filteredData",filteredData)

    const sortedRecords = processedData.sort((a, b) => {
      const dateA = a.deadline && a.deadline.length > 0 ? new Date(a.deadline[0].seconds * 1000) : new Date(0);
      const dateB = b.deadline && b.deadline.length > 0 ? new Date(b.deadline[0].seconds * 1000) : new Date(0);
      return dateA - dateB; 
    });

    const groupedItems = Object.values(groupByJobCode(sortedRecords));
        
    setItems(groupedItems);
    setItemsLimited(groupedItems);
    
    
    };
    fetchData();
    
  }, []);


  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      
  },
  
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text.substring(0, 5)}
      </span>,
      
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
      
    },
/*
    {
      title: 'Durum',
      dataIndex: 'statusText',
      key: 'statusText',
      render: (text, record) => 
      {
        let statusColor = "#000000";
        let statusText = "Unknown";
  
        return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
      },
      sorter: (a, b) => {
        const itemA = a && a.statusText ? a.statusText : '';
        const itemB = b && b.statusText ? b.statusText : '';
        return itemA.localeCompare(itemB);
      },
      ...getColumnSearchProps('statusText'),


     
  },*/
   
  {
    title: 'Termin Tarihi',
    dataIndex: 'deadline',
    key: 'deadline',
    render: (text, record) => {
      const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
      return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
    },
   
  
  },      
 
  ];

 
  const goPage = (record) => {
    navigate('/openordergrouplist', { state: { record } })
  };

  return <div 
          className="limited-list-border">
          <Button onClick={()=>goPage(items)} className="title-limited-list">
         Açık Siparişler
       </Button>

        <Table
            //locale={{ emptyText: 'Henüz açık sipariş bulunmamaktadır.' }}
            locale={{
              emptyText: 'Henüz eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

    </div>

  };
  export default OpenOrderGroupListLimited;
 