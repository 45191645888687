import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { Button, Form, Input} from 'antd';
import {  colors, parsCollections } from "../../environments/environment"
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const GenerateQr = () => {
    const getCurrentDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0'); 
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); 
        const year = currentDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;
    };

  const [qrData, setQrData] = useState(`Pars Makine ${getCurrentDate()}`);

  const handlePrint = async () => {
    console.log('Yazdır butonuna basıldı.', qrData);
    try {
        const currentDate = getCurrentDate().toString();
        console.log(currentDate);
        const result = await FirebaseService.addRecordingForDays(parsCollections.days,currentDate);
        window.print();

        if (result.success) {
          console.log("başarılı")
        } else {
            console.log("başarısız2",result.message,)
        }
      } catch (error) {
        console.log("başarısız,",)
      }
  };


  return (
    <div className="qr-container" style={{display:"block", textAlign:"center"}}>
      <div><QRCode value={qrData} className="qr-image" size={300}/></div>
     <div style={{ textAlign:"right"}}> <Button className="print-button" style={{ backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={handlePrint}>Yazdır</Button></div>
    </div>
  );
};

export default GenerateQr;
