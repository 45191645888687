import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Input,Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors, parsCollections}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const InvoiceContractManufacturingList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
   
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getContractManufacturingData();
      const filteredData = data.filter(item => !item.isDeleted);
    
      const sortedData = filteredData.sort((a, b) => {
        const isEqualA = a.invoiceNumber ? a.invoiceNumber.length === a.shipmentPiece : false;
        const isEqualB = b.invoiceNumber ? b.invoiceNumber.length === b.shipmentPiece : false;
    
        if (!isEqualA && isEqualB) return -1;
        if (isEqualA && !isEqualB) return 1;
        return 0;
      });
      setItems(sortedData);
    };
    

    fetchData();
  }, []);



  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleInputChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handleSave = async () => {
    setLoading(true)
    if (!selectedRecord) return;
    console.log("selectedRecord",selectedRecord)
  
    const newItemWithAdditionalProperties = {
      ...selectedRecord,
      invoiceNumber: Number(invoiceNumber),
   
    };

    console.log("Yeni öğe:", newItemWithAdditionalProperties);
    try {
      const result = await FirebaseService.updateRecording(parsCollections.contractManufacturing,newItemWithAdditionalProperties.key,newItemWithAdditionalProperties);
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: "Başarıyla kaydedildi",
          placement: 'topRight'
        });setLoading(false)
        
        setIsModalVisible(false);
        setInvoiceNumber('');

        setTimeout(() => {
          window.location.reload();
        }, 500); 
      
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });setLoading(false)
      }
      
      
    } catch (error) {
      console.log('Firebase Güncelleme Hatası:', error);
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });setLoading(false)
    }



   
  
  };

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      sorter: (a, b) => {
        const companyNameA = a && a.companyName ? a.companyName : '';
        const companyNameB = b && b.companyName ? b.companyName : '';
        return companyNameA.localeCompare(companyNameB);
      }
  },
  
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
      </span>,
       sorter: (a, b) => {
        const itemA = a && a.jobCode ? a.jobCode : '';
        const itemB = b && b.jobCode ? b.jobCode : '';
        return itemA.localeCompare(itemB);
      },
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
      </span>,
       sorter: (a, b) => {
        const itemA = a && a.projectCode ? a.projectCode : '';
        const itemB = b && b.projectCode ? b.projectCode : '';
        return itemA.localeCompare(itemB);
      },
    },
    {
      title: 'Durum',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        const isEqual = record.invoiceNumber ? true : false;
        return (
          <span style={{ color: isEqual ? colors.green : colors.red }}>
            {isEqual ? 'Kesilmiş' : 'Bekleyen'}
          </span>
        );
      },
    },

    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => {
        if (record.invoiceNumber) {
          return null; 
        }
  
        return (
          <Button
          style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
          onClick={() => showModal(record)}
        >
          Fatura No Gir
        </Button>
        );
      },
    }

  ];


  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Fason Faturaları</h3>
          <Table
            locale={{
              emptyText: 'Henüz fason faturaları bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
           /*
            expandable={{
              expandedRowRender: record => (
                <div style={{ display: 'flex' }}>
                   <div style={{ marginLeft: "55px" }}>
                    <div style={{ marginBottom: "10px" }}>
                      <span style={{ fontWeight: "bold", color: colors.gray }}>Fatura Numaraları:</span>
                  
                    </div>
                  </div>

                  <div style={{ marginLeft: "55px" }}>
      <div style={{ marginBottom: "10px" }}>
        <div style={{display:"flex"}}>
          {record.invoiceNumber && record.invoiceNumber.map((invoice, index) => (
            <span key={index}>
              <span style={{ marginLeft: "20px", color: colors.gray, fontWeight: "bold" }}>
                {index + 1}. Sevkiyat:
              </span>
              <br />
              <span style={{marginLeft: "20px"}}>{invoice}</span>
              {index < record.invoiceNumber.length - 1 && <br />} 
            </span>
          ))}
        </div>
      </div>
    </div>
                </div>
              ),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/uparrow.png" /></a>
                ) : (
                  <a type="link" onClick={e => onExpand(record, e)} style={{border:"none",backgroundColor:"transparent"}}><img style={{width:"20px"}} alt="edit" src="/images/downarrow.png" /></a>
                ),
                expandIconColumnIndex: 5
            }}
            */
        />

<Modal
        title="Fatura Numarası Gir"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            İptal
          </Button>,
          <Button key="save"   style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}  onClick={handleSave}>
            Kaydet
          </Button>,
        ]}
      >
         
       

        <p  style={{ fontWeight: "bold", color: colors.blue }}>Fatura Numarası : 
          <Input
          
          placeholder="Fatura Numarası"
          value={invoiceNumber}
          onChange={handleInputChange}
          style={{ marginTop: '10px', marginLeft:"20px" }}
          className="input-style" 

        />
        </p>
      </Modal>
      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

       
    </div>

  };
  export default InvoiceContractManufacturingList;

  