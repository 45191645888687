import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker,Modal, Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  {parsCollections,requestedProductType,colors}  from "../../../environments/environment"
import PropTypes from 'prop-types';
import AddRequestAdminCompany from "../addRequestAdmin/addRequestAdminCompany";
import AddRequestAdminRaw from "../addRequestAdmin/addRequestAdminRaw";
import AddRequestAdminOther from "../addRequestAdmin/addRequestAdminOther";
import AddRequestAdminUnit from "../addRequestAdmin/addRequestAdminUnit";
import OneSignalService from "../../../services/oneSignalService";
import moment from 'moment';
 
const PurchaseRequestAdd = ({ onClose }) => {
  const [raws, setRaws] = useState([]); 
  const [others, setOthers] = useState([]); 
  const [units, setUnits] = useState([]); 
  const [companies, setCompanies] = useState([]); 
  const [jobCodes, setJobCodes] = useState([]); 
  const [isTypeDisabled, setIsTypeDisabled] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  const fetchData = async () => {
        try {
          const data = await FirebaseService.getAllItemsData();
          const rawItems = [];
          const otherItems = [];
    
          data.forEach(item => {
            if (!item.isDeleted ) { //&& item.isVisible
              if (item.productType === 'raw') {
                rawItems.push(item);
              } else if (item.productType === 'other') {
                otherItems.push(item);
              }
            }
          });
    
          setRaws(rawItems);
          setOthers(otherItems);
    
        // console.log('Raw Data:', rawItems);
         //console.log('Other Data:', otherItems);

          const units = await FirebaseService.getUnitsData();
          const filteredUnits = units.filter(item => !item.isDeleted);

          setUnits(filteredUnits);

          const companies = await FirebaseService.getCompaniesData();
          const filteredCompanies = companies.filter(item => !item.isDeleted);
          setCompanies(filteredCompanies);

          const jobCodes = await FirebaseService.getJobCodesData();
          const filteredJobCodes = jobCodes.filter(item => !item.isDeleted && item.item.length > 5 && item.item.includes('-'));
          console.log("jobCodes,",filteredJobCodes)
          setJobCodes(filteredJobCodes);


        } catch (error) {
          console.error('Firebase veri çekme hatası:', error);
        }
  };
    
  useEffect(() => {
        fetchData();
  }, []);

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  const [isModalVisibleCompany, setIsModalVisibleCompany] = useState(false);

  const handleModalCancelCompany = () => {
      setIsModalVisibleCompany(false);
  };

  const handleAddFinishCompany = () => {
      setIsModalVisibleCompany(false);
  };

  const [isModalVisibleRaw, setIsModalVisibleRaw] = useState(false);

  const handleModalCancelRaw = () => {
      setIsModalVisibleRaw(false);
  };

  const handleAddFinishRaw = () => {
      setIsModalVisibleRaw(false);
  };



  const [isModalVisibleOther, setIsModalVisibleOther] = useState(false);

  const handleModalCancelOther = () => {
      setIsModalVisibleRaw(false);
  };

  const handleAddFinishOther = () => {
      setIsModalVisibleOther(false);
  };


  const [isModalVisibleUnit, setIsModalVisibleUnit] = useState(false);

  const handleModalCancelUnit = () => {
      setIsModalVisibleUnit(false);
  };

  const handleAddFinishUnit = () => {
      setIsModalVisibleUnit(false);
  };

  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  const isDateBeforeToday = (dateString) => {
    const selectedDate = new Date(dateString);
    const today = new Date(); 
    selectedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
      return selectedDate < today;
  };

  const getOneSignalPlayerIds = async () => {
    try {
      const dataCollection = await FirebaseService.getUserData();
      const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
      
      console.log("filteredUsers", filteredUsers);
      
      const oneSignalPlayerIds = [];
      filteredUsers.forEach(user => {
        if (user.oneSignalPlayerId) {
          oneSignalPlayerIds.push(user.oneSignalPlayerId);
        }
      });
      
      console.log("oneSignalPlayerIds", oneSignalPlayerIds);
      
      return oneSignalPlayerIds;
    } catch (error) {
      console.error("Error getting OneSignal Player IDs:", error);
      return [];
    }
  };

  const getOneSignalUserIds = async () => {
    try {
      const dataCollection = await FirebaseService.getUserData();
      const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userUnit.includes("satın alma"));
      
      console.log("filteredUsers", filteredUsers);
      
      const oneSignalPlayerIds = [];
      filteredUsers.forEach(user => {
        if (user.key) {
          oneSignalPlayerIds.push(user.key);
        }
      });
      
      console.log("oneSignalPlayerIds", oneSignalPlayerIds);
      
      return oneSignalPlayerIds;
    } catch (error) {
      console.error("Error getting OneSignal Player IDs:", error);
      return [];
    }
  };
  
  const onFinish = async (values) => {
    setLoading(true);
    
   
    if (isDateBeforeToday(values.deadline) || !values.deadline ){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Teslim tarihi bugünün tarihinden küçük veya boş olamaz.",
        placement: 'topRight'
      });
      setLoading(false);
      return;
    }


    if (values.companyName === "newCompany") {
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Lütfen var olan bir firma seçiniz.',
        placement: 'topRight'
      });
      setLoading(false);
      return;
    }

    if (values.unitName === "newUnit") {
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Lütfen var olan bir birim seçiniz.',
        placement: 'topRight'
      });
      setLoading(false);
      return;
    }

/*
    const combinedValues = additionalContents.map((index) => {
      const selectedItem = selectedItems[index];
      console.log("selectedItem",selectedItem)
      if ( selectedItem.id === "newRaw" || selectedItem.id === "newOther") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir sac levha/ürün seçiniz.',
          placement: 'topRight'
        });
        return null;
      }
      return {
        id: selectedItem.id,
        piece: Number(values[`piece-${index}`]),
        description: values[`description-${index}`] || null,
        jobCode: values[`jobCode-${index}`] || null,
        code: selectedItem.code,
        minPiece: selectedItem.minPiece,
      };
    }).filter(Boolean); // Remove null entries

    console.log('Combined Values:', combinedValues);
    */

    const combinedValues = [];

  for (const index of additionalContents) {
  const selectedItem = selectedItems[index];
  console.log("selectedItem", selectedItem);

  if (selectedItem.id === "newRaw" || selectedItem.id === "newOther") {
    NotificationService.openErrorNotification({
      title: 'Hata',
      description: 'Lütfen var olan bir sac levha/ürün seçiniz.',
      placement: 'topRight'
      });
      setLoading(false);
      return; 
    }

    combinedValues.push({
      id: selectedItem.id,
      piece: Number(values[`piece-${index}`]),
      description: values[`description-${index}`] || null,
      jobCode: values[`jobCode-${index}`] || null,
      code: selectedItem.code,
      minPiece: selectedItem.minPiece,
      companiesAndPrice:null
    });
  }

  console.log('Combined Values:', combinedValues);


    const data = {
      companyName: values.companyName,
      productType: values.productType,
      unitName: values.unitName,
      deadline:new Date(values.deadline),
      requestedProductInfo: combinedValues,
      checkedRequestedProductInfo:combinedValues,
      offerJobCode: generateRandomCode(5),
      deadlineForPayment:null,
      paymentMethod:null,
      mailList:null,
      sentExcelList:null,
      incomingExcelList:null,
      acceptExcelDocumentUrl:null  ,
      description:null,
      isSendSetup: false
    };

    console.log("data",data)

    if(combinedValues.length === 0 || data.requestedProductInfo.length === 0){
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Lütfen ürün seçiniz.',
        placement: 'topRight'
        });
        setLoading(false);
        return; 
    }

    try {
      const result = await FirebaseService.addPurchaseRequest(data);
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
         //bildirim 
         const ids = await getOneSignalPlayerIds();
         const userIds = await getOneSignalUserIds();
        
         const sentUserIdsMap = new Map();
         userIds.forEach(userId => {
           sentUserIdsMap.set(userId, false);
         });
         
         const sentUserIds = Object.fromEntries(sentUserIdsMap);
        
         if (ids.length > 0) {
           const oneSignalResult = await OneSignalService.sendNotification("", "Satın Alma Talebi", ids);
           console.log("OneSignal notification result:", oneSignalResult);
           const currentDate =new Date()
           const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            isDeleted: false,
            sentUserIds:sentUserIds,
            firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
            unitName:localStorage.getItem('userUnit'),
            title:"Satın Alma Talebi",
            companyName:values.companyName,
            jobCode:null,
            projectCode:null,
            machine:null,
            type:values.productType,
            notice:"Satın Alma Taleplerinizi kontrol ediniz.",
            description:null

          };
          const result = await FirebaseService.addRecording(parsCollections.notifications,data);
          console.log("result",result)
         } else {
           console.log("No OneSignal Player IDs found.");
         }
         setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
        onClose(); 

      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false);
      }
      
      
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
      setLoading(false);
    }
  

    
  }; 

  const [additionalContents, setAdditionalContents] = useState([1]);
  const [typeValue, setTypeValue] = useState(null);

 
  const addAdditionalContent = () => {
    const newIndex = additionalContents.length > 0 ? Math.max(...additionalContents) + 1 : 0;
    setAdditionalContents([...additionalContents, newIndex]);
    setSelectedItems([...selectedItems, null]); // Add a placeholder for the new item
  };

  const removeAdditionalContent = (indexToRemove) => {
    setAdditionalContents((prevContents) => prevContents.filter((index) => index !== indexToRemove));
    setSelectedItems((prevItems) => prevItems.filter((_, idx) => idx !== indexToRemove));
  };


  const onChange = (value) => {

    if (value === 'newRaw') {
      console.log('Yeni sac Ekle seçildi');
      setIsModalVisibleRaw(true);

    } else if (value === 'newCompany') {
      console.log('Yeni Şirket Ekle seçildi');
      setIsModalVisibleCompany(true);
    }
    else if (value === 'newOther') {
      console.log('Yeni ürün Ekle seçildi');
      setIsModalVisibleOther(true);
    }
    /*
    else if (value === 'newUnit') {
      console.log('Yeni Birim Ekle seçildi');
      setIsModalVisibleUnit(true);
    }
    */
  };
   
  const formatProductDictionary = (productDictionary) => {
        const orderedKeys = ['quality', 'thickness', 'surface', 'width', 'height'];
        const formattedValues = orderedKeys.map(key => productDictionary[key]);
        return formattedValues.join(', ');
  };
    
  const handleTypeChange = (value) => {
    setTypeValue(value);
    setAdditionalContents([]); 
    setIsTypeDisabled(true); 

  };

  const handleChange = (index, value, option) => {
    if (value === 'newRaw') {
      console.log('Yeni sac Ekle seçildi');
      setIsModalVisibleRaw(true);

    }
    else if (value === 'newOther') {
      console.log('Yeni ürün Ekle seçildi');
      setIsModalVisibleOther(true);
    }
    if (value !== 'newRaw' || value !== 'newOther') {
      const { code, minPiece } = option;
      setSelectedItems((prev) => {
        const newItems = [...prev];
        newItems[index] = { id: value, code, minPiece };
        return newItems;
      });
    }
  };
  const today = moment().startOf('day'); 

  const disabledDate = (current) => {
    return current && current < today;
  }

  return ( 
   <div>
      <Form
            name="basic"
            labelCol={{
            span: 5,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 0%"
            }}
      
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        <Form.Item
          label="Firma"
          name="companyName"
          rules={[
            {
              required: true,
              message: 'Lütfen firma adı giriniz!',
            },
          ]}
        >
          <Select
            onChange={onChange}
            placeholder="Firma Adı Seçiniz"
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={[
              ...companies.map(item => ({ label: item.companyName, value: item.companyName })),
              { label: 'Yeni Firma Ekle', value: 'newCompany' },
            ]}   
            className="input-style"     
             />
        </Form.Item>

         
              <Modal
                title="Firma Ekleme Talebi"
                visible={isModalVisibleCompany}
                onCancel={handleModalCancelCompany}
                footer={null}
              >
              <AddRequestAdminCompany  onClose={handleAddFinishCompany} />
              </Modal> 
            



        <Form.Item
          label="Birim"
          name="unitName"
          rules={[
            {
              required: true,
              message: 'Lütfen birim adı giriniz!',
            },
          ]}
        >
            <Select
              onChange={onChange}
              placeholder="Birim Adı Seçiniz"
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
             /* options={[
                ...units.map(item => ({ label: item.unitName, value: item.unitName })),
                { label: 'Yeni Birim Ekle', value: 'newUnit' },
              ]} */  
              options={units.map(item => ({ label: item.unitName, value: item.unitName }))}
              className="input-style"     
            />
          </Form.Item>

            <Modal
                title="Birim Ekleme Talebi"
                visible={isModalVisibleUnit}
                onCancel={handleModalCancelUnit}
                footer={null}
              >
              <AddRequestAdminUnit  onClose={handleAddFinishUnit} />
              </Modal> 
            

        <Form.Item
          label="Termin Tarihi"
          name="deadline"
          rules={[
            {
              required: true,
              message: 'Lütfen tarih giriniz!',
            },
            
          ]}
        >
        <DatePicker style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY"   className="input-style"  disabledDate={disabledDate}   />

        </Form.Item>

     
        <Form.Item
         label="Tür"
          name="productType"
          rules={[
            {
              required: true,
              message: 'Lütfen tür giriniz!',
            },
          ]}
        >
          <Select
           // onChange={onChange}
            onChange={handleTypeChange}
            disabled={isTypeDisabled} // Ürün türünü seçildikten sonra değiştirilemez yap
            placeholder="Tür Seçiniz"
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={requestedProductType.map(item => ({ label: item.label, value: item.value }))}
            className="input-style"     
          />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.productType !== currentValues.productType
          }
        >
          {({ getFieldValue }) => {
          return typeValue === 'raw' ? (
          <>
             
            {additionalContents.map((index) => (
                  <div  style={{display:"flex"}}>
                    <React.Fragment key={index}>
                    
                      <Form.Item
                          name={`productDictionary-${index}`}
                          rules={[
                              {
                                  required: true,
                                  message: 'Lütfen sac levha seçiniz!',
                              },
                          ]}
                      >
                      <Select
                          placeholder="Sac Levha Seçiniz"
                          style={{ width:"250px" }}
                          suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                          options={[
                            ...raws.map(item => ({
                            label: formatProductDictionary(item.productDictionary),
                            value: item.key,
                            code: item.code, 
                            minPiece: item.minPiece, 
                          })),
                          { label: 'Yeni Sac Ekle', value: 'newRaw' },
                          ]}  
                         // onChange={onChange}           
                          onChange={(value, option) => handleChange(index, value, option)}
                          className="input-style"   

                      />
                    </Form.Item>
                   
                    <Modal
                      title="Sac Ekleme Talebi"
                      visible={isModalVisibleRaw}
                      onCancel={handleModalCancelRaw}
                      footer={null}
                    >
                    <AddRequestAdminRaw  onClose={handleAddFinishRaw} />
                    </Modal> 
            

                   
                    <Form.Item
                     name={`jobCode-${index}`}
                >
                <Select
                    onChange={onChange}
                    placeholder="İş Kodu"
                    style={{ width:"100px" }}
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={jobCodes.map(item => ({ label: item.item, value: item.item }))}
                    className="input-style"   
                />
                    </Form.Item>


                  <Form.Item
                    name={`piece-${index}`}
                    rules={[
                      {
                        pattern: /^\d+$/,
                        message: 'Sadece rakam girişi yapınız.',
                      },
                      {
                        required: true,
                        message: 'Lütfen adet giriniz!',
                    },
                    ]}
                  >
                    <Input style={{ width:"70px"}} placeholder="Adet"  className="input-style"    />
                  </Form.Item>
                

                  <Button onClick={() => removeAdditionalContent(index)}>-</Button>

                  
                    </React.Fragment>
                  </div>
            ))}
               {/* Artı butonu */}
              <Form.Item>
                <Button onClick={addAdditionalContent}>+</Button>
              </Form.Item>
          </>
          ) :
            
          typeValue === 'other' ? (
            <>

                {additionalContents.map((index) => (
                  <div  style={{display:"flex"}}>
                    <React.Fragment key={index}>
                    
                      <Form.Item
                          name={`productDictionary-${index}`}
                          rules={[
                              {
                                  required: true,
                                  message: 'Lütfen ürün seçiniz!',
                              },
                          ]}
                      >
                      <Select
                        //  mode="tags"
                          placeholder="Ürün Seçiniz"
                          style={{ width:"150px" }}
                          suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                          options={[
                            ...others.map(item => ({
                            label:  item.productString,
                            value: item.key,
                            code: item.code, 
                            minPiece: item.minPiece, 
                          })),
                          { label: 'Yeni Ürün Ekle', value: 'newOther' },
                          ]}  
                          //onChange={onChange}
                          onChange={(value, option) => handleChange(index, value, option)}
                          className="input-style"   

                      />
                    </Form.Item>

                    <Modal
                      title="Diğer Ekleme Talebi"
                      visible={isModalVisibleOther}
                      onCancel={handleModalCancelOther}
                      footer={null}
                    >
                    <AddRequestAdminOther  onClose={handleAddFinishOther} />
                    </Modal> 

                    <Form.Item
                     name={`jobCode-${index}`}
                >
                <Select
                    onChange={onChange}
                    placeholder="İş Kodu"
                    style={{ width:"100px" }}
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={jobCodes.map(item => ({ label: item.item, value: item.item }))}
                    className="input-style"   
                />
                    </Form.Item>


                  <Form.Item
                    name={`piece-${index}`}
                    rules={[
                      {
                        pattern: /^\d+$/,
                        message: 'Sadece rakam girişi yapınız.',
                      },
                      {
                        required: true,
                        message: 'Lütfen adet giriniz!',
                    },
                    ]}
                  >
                    <Input style={{ width:"70px"}} placeholder="Adet"  className="input-style"    />
                  </Form.Item>
                  <Form.Item name={`description-${index}`}>
                    <Input style={{ width:"70px" }} placeholder="Açıklama"   className="input-style"   />
                  </Form.Item>
                  <Button onClick={() => removeAdditionalContent(index)}>-</Button>


                    </React.Fragment>
                  </div>
            
            ))}
               {/* Artı butonu */}
              <Form.Item>
                <Button onClick={addAdditionalContent}>+</Button>
              </Form.Item>

              

            </>
          ) : 
            
          null;
          }}
        </Form.Item>
      
        <Form.Item
              wrapperCol={{
                  offset: 15,
                  span: 18,
              }}
              >
              <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Gönder</Button>
        </Form.Item>

      </Form>
      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
)};


PurchaseRequestAdd.propTypes = {
  onClose: PropTypes.func.isRequired, // onClose prop'u bir fonksiyon olmalı
};

export default PurchaseRequestAdd;


