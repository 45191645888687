import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Space, Upload,Modal, Spin, DatePicker} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors, permissionType}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';


const PermissionAdd = ({ onClose }) => {
    const navigate = useNavigate();
    const today = moment().startOf('day'); 
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
       
        
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };

   
    const onFinish = async (values) => {
      setLoading(true);
      console.log( "values",values)
     
      const currentDate =new Date()
        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          firstUserNameSurname:localStorage.getItem('name') + " " + localStorage.getItem('surname'),
          type: values.type,
          startDate: new Date(values.startDate),
          endDate: new Date(values.endDate),
          description: values.description,
       
        };
        
      try {
     
          const result = await FirebaseService.addRecording(parsCollections.permissons,data);
          if (result.success) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: result.message,
                placement: 'topRight'
              });
                  
            
      
            setLoading(false);
              onClose();
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);

            }  
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);

      }
    }; 


    const onChange = (value) => {
        console.log(`selected ${value}`);    
   };
   const disabledDate = (current) => {
    // Bugünden önceki tarihleri devre dışı bırak
    return current && current < today;
  }


   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 7,
            }}
            wrapperCol={{
            span: 14
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        
            <Form.Item
            label="İzin Türü"
            name="type"
            rules={[
                {
                required: true,
                message: 'Lütfen firma adı giriniz!',
                },
            ]}
            >
            <Select
                onChange={onChange}
                placeholder="Mazeret/Yıllık İzin"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={permissionType.map(item => ({ label: item.label, value: item.value }))}
                className="input-style" 
                            />
            </Form.Item>
        
              <Form.Item
             label="Başlangıç Tarihi"
          name="startDate"
          rules={[
            {
              required: true,
              message: 'Lütfen termin tarihi giriniz!',
            },
            
          ]}
        >
        <DatePicker style={{ width: '100%' }} placeholder="Başlangıç Tarihi" format="DD-MM-YYYY" className="input-style"    disabledDate={disabledDate}    
        />

            </Form.Item>

            <Form.Item
             label="Bitiş Tarihi"
          name="endDate"
          rules={[
            {
              required: true,
              message: 'Lütfen termin tarihi giriniz!',
            },
            
          ]}
        >
        <DatePicker style={{ width: '100%' }} placeholder="Bitiş Tarihi" format="DD-MM-YYYY" className="input-style"    disabledDate={disabledDate}  
        />

            </Form.Item>

            <Form.Item
              label="Açıklama"
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen açıklama giriniz!',
                },
            ]}
          
            >
           <Input  className="input-style"  style={{width:"100%"}} placeholder="Açıklama"/>
            </Form.Item>


        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500", position:"relative",display:"flex",alignItems:"center",padding:"20px 30px"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

PermissionAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

export default PermissionAdd;