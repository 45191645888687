import React,{useEffect,useState} from "react";
import { Table, Button, Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';

const PersonnelPerfomanceListLimited = () => {
    const [items, setItems] = useState([]);
    const [itemsLimited, setItemsLimited] = useState([]);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
        const data = await FirebaseService.getUserData();
        const filteredData = data.filter(item => !item.isDeleted && (item.userUnit.includes("abkant") || item.userUnit.includes("lazer")   || item.userUnit.includes("teknik ressam") ));
        const sortedData = filteredData.sort((a, b) => {
          // userUnit dizilerini bir dizeye dönüştür ve sıralama yap
          const unitA = a.userUnit.join(', ');
          const unitB = b.userUnit.join(', ');
          return unitA.localeCompare(unitB);
        })
        
        const limitedData = sortedData.slice(0, 5);
         
        setItemsLimited(limitedData);
        setItems(sortedData);
      };
      
      fetchData();
    }, []);
  
   
  
    const goPage = (record) => {
        navigate('/personnelperformancelist', { state: { record } })
    };
    
    const calculateAveragePercentage = (performance) => {
        const data = performance?.data || [];
        if (data.length === 0) return 0;
        const total = data.reduce((sum, perf) => sum + (perf.averagePercentage || 0), 0);
        return total / data.length;
    };
      
    const getColor = (averagePercentage) => {
      if (averagePercentage > 80) return 'green';
      if (averagePercentage > 50) return 'yellow';
      if (averagePercentage > 30) return 'orange';
      return 'red';
    };

    const calculateAveragePercentageLazer = (performance) => {
      console.log("performance", performance);
      if (!performance) {
        return 0;
      }
      const data = Object.values(performance);
      if (data.length === 0) return 0;
    
      const total = data.reduce((sum, perf) => sum + (perf.lazerPerformans || 0), 0);
      const average = total / data.length;
      return average;
    };

    const calculateAveragePercentageAbkant = (performance) => {
      console.log("performance", performance);
      if (!performance) {
        return 0;
      }
      const data = Object.values(performance);
      if (data.length === 0) return 0;
    
      const total = data.reduce((sum, perf) => sum + (perf.abkantPerformans || 0), 0);
      const average = total / data.length;
      return average;
    };
    
    const calculateAveragePercentageProgrammer = (performance) => {
      if (!performance) {
        console.error("Performance data is null or undefined");
        return 0;
      }
    
      if (!Array.isArray(performance) || performance.length === 0) {
        console.error("Invalid performance data");
        return 0;
      }
    
      const today = new Date().toISOString().split('T')[0]; 
    
      const todayPerformance = performance.filter(perf => {
        if (!perf.firstTransactionDate || !perf.firstTransactionDate.seconds) {
          return false;
        }
        const perfDate = new Date(perf.firstTransactionDate.seconds * 1000).toISOString().split('T')[0];
        return perfDate === today;
      });
    
      console.log("todayPerformance", todayPerformance);
    
      if (todayPerformance.length === 0) {
        console.warn("No performance data for today");
        return 0;
      }
    
      const totalSeconds = todayPerformance.reduce((sum, perf) => {
        if (!perf.setupTime) {
          console.error("Invalid setupTime format", perf);
          return sum;
        }
        const timeParts = perf.setupTime.split(':');
        if (timeParts.length !== 3) {
          console.error("Invalid setupTime format", perf.setupTime);
          return sum;
        }
        const seconds = parseInt(timeParts[0]) * 3600 + parseInt(timeParts[1]) * 60 + parseInt(timeParts[2]);
        return sum + seconds;
      }, 0);
    
      const totalMinutes = totalSeconds / 60;
      const averagePercentage = (totalMinutes / 500) * 100;
    
      return averagePercentage;
    };
  
    const columns = [
      {
        title: '',
        dataIndex: '',
        key: '',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>
        {record.imageUrl ? (
        <img alt="" src={record.imageUrl} style={{ marginRight: '10px', width: '30px',height:"30px", borderRadius:"100%",objectFit:"cover" ,marginTop:"20px"}} />
          ) : (
            <img alt="" src="/images/menuusericon.png" style={{ marginRight: '10px', width: '30px',height:"30px", borderRadius:"100%",objectFit:"cover" , background:"#1A446C"}} />
          )}        
            </span>,
        },

      {
        title: 'Kullanıcı Adı',
        dataIndex: 'offerJobCode',
        key: 'offerJobCode',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.name}  {record.surname} </span>,
    },
      {
        title: 'Birim',
        dataIndex: 'userUnit',
        key: 'userUnit',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>  {text.join(', ')} 
        </span>,

        /*sorter: (a, b) => {
          // userUnit dizisinin ilk elemanını karşılaştır
          const unitA = a.userUnit.join(', ');
          const unitB = b.userUnit.join(', ');
          return unitA.localeCompare(unitB);
        },*/
      
      },
      {
        title: 'Performans',
        dataIndex: 'performance',
        key: 'performance',
        render: (text, record) => {
          const averagePercentage = record.userUnit.includes("lazer")
          ? calculateAveragePercentageLazer(record.lazerPerformance)
          : record.userUnit.includes("teknik ressam")
              ? calculateAveragePercentageProgrammer(record.setupPerformance)
              : calculateAveragePercentageAbkant(record.abkantPerformance);
      
          const formattedPercentage = parseFloat(averagePercentage.toFixed(2));
          const color = getColor(formattedPercentage);
      
          return (
            <Progress
              percent={formattedPercentage}
              status={record.isDeleted ? 'exception' : 'normal'}
              strokeColor={color}
              strokeWidth={15}
            />
          );
        },
      },
    ];
  
    return  <div style={{
        //  justifyContent: 'center',
        //  alignItems: 'center',
       //   minHeight: '94vh',
         
        //  width:"60%",
         height:"730px"

        }}
        className="limited-list-border">
        <Button onClick={()=>goPage(items)} className="title-limited-list">
          Personel Performans Listesi
         </Button>
            <Table
              locale={{
                emptyText: 'Henüz personel perfomans bulunmamaktadır..',
                filterReset: 'Sıfırla',
                filterTitle: 'Filtre Menüsü',
                selectAll: 'Hepsini Seç',
                selectInvert: 'Tersini Seç',
                selectionAll: 'Tümünü Seç',
                sortTitle: 'Sıralama',
                triggerDesc: 'Azalan sıralama için tıklayın',
                triggerAsc: 'Artan sıralama için tıklayın',
                cancelSort: 'Sıralamayı iptal etmek için tıklayın',
              }}
              dataSource={itemsLimited}
              columns={columns}
              showHeader={true}
              pagination={false} 
              className="custom-news-table"
              rowClassName="custom-news-row"
          />
      </div>
};
export default PersonnelPerfomanceListLimited;
