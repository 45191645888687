import React,{useEffect,useState} from "react";
import { Table, Button,Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const SurfaceList = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getSurfacesData();
      const filteredData = data.filter(item => !item.isDeleted);

      const sortedData = filteredData.sort((a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate));
      setItems(sortedData);
     
    };
    fetchData();
  }, []);

  const goAddPage = () => {
    navigate('/surfaceadd')
  };

  const goEditPage = (record) => {
    navigate('/surfaceedit', { state: { record } })
  };

  const handleDelete = async (record, isDeleted) => {
    setLoading(true)
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makeSurfaceVisible(record.key);
      } else {
        result = await FirebaseService.deleteSurface(record.key);
      }
  
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });setLoading(false)
        const data = await FirebaseService.getSurfacesData();
        const filteredData = data.filter(item => !item.isDeleted);
        setItems(filteredData);      
       } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });setLoading(false)
      }
    } catch (error) {
     // console.log(error);
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });setLoading(false)
    }
  };  

  const columns = [
   
    {
      title: 'Yüzey',
      dataIndex: 'surface',
      key: 'surface',
      render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 400,
    },
   
    {
      title: ' ',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        
          record.isDeleted ?    
           <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
          :
          <Button style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Sil</Button>
      ),
    },
    /*
    {
      title: 'Güncelle',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <a type="link" onClick={()=>goEditPage(record)}  style={{
          border: "none",
          backgroundColor: "transparent",
          pointerEvents: record.isDeleted ? "none" : "auto", 
          opacity: record.isDeleted ? 0.5 : 1, 
        }}><img style={{width:"20px"}} alt="edit" src="/images/newEditButton.png" /></a>
      ),
    }*/
  ];
  
  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} className='admin-add-button'>
          Yeni Ekle
        </Button>
          <Table
            locale={{
              emptyText: 'Henüz yüzey eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>

    };
  export default SurfaceList;