import React, { useEffect, useState } from "react";
import { Input } from 'antd';
import { useLocation } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors } from "../../../environments/environment";

const ExpenseDisplay = () => {
  const location = useLocation();
  const record = location.state && location.state.record;

  const [inputValues, setInputValues] = useState({});
  const [previousExpenses, setPreviousExpenses] = useState({}); // Bir önceki ayın giderleri

  useEffect(() => {
    if (record && record.prices) {
      setInputValues(record.prices);
      
      const currentMonth = record.month;
      const currentYear = record.year;

      let previousMonth, previousYear;
      if (currentMonth === 1) { // January
        previousMonth = 12;
        previousYear = currentYear - 1;
      } else {
        previousMonth = currentMonth - 1;
        previousYear = currentYear;
      }
      fetchPreviousExpenses(previousMonth, previousYear);
    }
  }, [record]);

  const fetchPreviousExpenses = async (previousMonth, previousYear) => {
    try {
        const fetchedData = await FirebaseService.getExpensesData();

        const filteredData = fetchedData.filter( (data) => data.month === previousMonth && data.year === previousYear);

        const expenseData = {};

        filteredData.forEach(data => {
            if (data.prices) {
                Object.entries(data.prices).forEach(([category, items]) => {
                    if (!expenseData[category]) {
                        expenseData[category] = [];
                    }
                    items.forEach(item => {
                        const price = parseFloat(item.price);
                        if (!isNaN(price)) {
                            expenseData[category].push({
                                ...item,
                                price,
                            });
                        } else {
                            console.warn("Geçersiz fiyat verisi:", item.price);
                        }
                    });
                });
            }
        });

        setPreviousExpenses(expenseData);
    } catch (error) {
        console.error("Gider verileri alınırken hata oluştu:", error);
    }
  };

  const calculateCreditMonth = (startDate, endDate) => {
    console.log("credit month u da hesapladı")
    if (!startDate || !endDate) return null;

    const start = new Date(startDate.seconds * 1000);
    const end = new Date(endDate.seconds * 1000);
    const current = new Date();

    const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    const currentMonth = (current.getFullYear() - start.getFullYear()) * 12 + (current.getMonth() - start.getMonth()) + 1;

    return `${currentMonth}/${totalMonths}`;
  };

  const calculatePercentageDifference = (current, previous) => {
    if (previous === 0) return " ";
    const difference = ((current - previous) / previous) * 100;
    return `${difference.toFixed(2)}%`;
  };

  const getPreviousPrice = (category, index) => {
    return previousExpenses[category]?.[index]?.price || 0;
  };

  const getPreviousTotal = (category) => {
    const previousTotal = (previousExpenses[category] || []).reduce((total, item) => {
        const price = parseFloat(item.price) || 0;
        return total + price;
    }, 0);
    return previousTotal;
  };

  const calculateTotal = (category) => {
    const existingEntriesTotal = (inputValues[category] || []).reduce((total, item) => {
      const inputValue = parseFloat(item.price) || 0;
      return total + inputValue;
    }, 0);
    return existingEntriesTotal;
  };

  return (
    <div style={{ padding: "10px 100px" }}>
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}>Gider Listesi</h2>

      <div style={{ display: "flex" }}>
        {Object.keys(inputValues).map(category => (
          <div key={category} style={{ marginBottom: '20px', marginRight: "40px" }}>
            <h3 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}>{category}</h3>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {inputValues[category].map((item, index) => {
                const previousPrice = parseFloat(getPreviousPrice(category, index));
                const currentPrice = parseFloat(item.price) || 0;
                const percentageDifference = calculatePercentageDifference(currentPrice, previousPrice);

                return (
                  <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span style={{ marginRight: "10px", width: "200px", textAlign: "left" }}>{item.contentName}:</span>
                    <Input
                      style={{ width: "80px" }}
                      className="input-style"
                      value={item.price}
                      disabled
                    />
                    {category === "Banka/ Kredi Giderleri" && (
                      <div style={{ marginLeft: "10px" }}>
                        <span>{calculateCreditMonth(item.contentStartDate, item.contentEndDate)}</span>
                      </div>
                    )}
                    <span style={{ marginLeft: "10px", color: colors.red }}>
                      {percentageDifference}
                    </span>
                  </div>
                );
              })}
            </div>
            <div style={{ marginTop: '10px', fontWeight: 'bold', textAlign: "left", display: "flex", alignItems: "center" }}>
              Toplam Giderler:
              <Input
                className="input-style"
                value={calculateTotal(category)}
                style={{ width: "80px", background: colors.lightGray, marginLeft: "80px" }}
                readOnly
              />
            </div>
            <div style={{ marginTop: '10px', fontWeight: 'bold', textAlign: "left", display: "flex", alignItems: "center" }}>
              Bir Önceki Aya Göre Artışı:
              <span style={{ marginLeft: "10px", color: colors.red }}>
                {calculatePercentageDifference(calculateTotal(category), getPreviousTotal(category))}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExpenseDisplay;
