import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';



const ReservedListLimited = () => {
    const [items, setItems] = useState([]);
    const [itemsLimited, setItemsLimited] = useState([]);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
       
      };
  
      fetchData();
    }, []);
  
    const goAdd = (record) => {
      //navigate('/wantedofferadd', { state: { record } })
    };
  
    const goPage = (record) => {
      //  navigate('/wantedofferlist', { state: { record } })
      };
    
  
    const goDisplay = (record) => {
      navigate('/wantedofferview', { state: { record } })
    };
  
    const columns = [
     /* {
        title: 'Teklif İş Kodu',
        dataIndex: 'offerJobCode',
        key: 'offerJobCode',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
    },*/
      {
        title: 'Ürün',
        dataIndex: 'productType',
        key: 'productType',
     
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
        </span>,
      },
      {
        title: 'Durum',
        dataIndex: '',
        key: '',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}></span>,
       
      },
      {
          title: 'Termin Tarihi',
          dataIndex: 'deadline',
          key: 'deadline',
        
          render: (text, record) => {
            const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
            return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
          },
        },
        
        
       
        
     
    ];
  
    return  <div style={{
        //  justifyContent: 'center',
        //  alignItems: 'center',
       //   minHeight: '94vh',
         
        //  width:"60%",
          
        }}
        className="limited-list-border">
        <Button onClick={()=>goPage(items)} className="title-limited-list">
          Rezerve
         </Button>
            <Table
              locale={{
                emptyText: 'Henüz  eklemediniz...',
                filterReset: 'Sıfırla',
                filterTitle: 'Filtre Menüsü',
                selectAll: 'Hepsini Seç',
                selectInvert: 'Tersini Seç',
                selectionAll: 'Tümünü Seç',
                sortTitle: 'Sıralama',
                triggerDesc: 'Azalan sıralama için tıklayın',
                triggerAsc: 'Artan sıralama için tıklayın',
                cancelSort: 'Sıralamayı iptal etmek için tıklayın',
              }}
              dataSource={itemsLimited}
             // columns={columns}
              showHeader={true}
              pagination={false} 
              className="custom-news-table"
              rowClassName="custom-news-row"
          />
      </div>
};
export default ReservedListLimited;
