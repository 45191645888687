import React,{useEffect,useState} from "react";
import { Button, Form, Input,Spin } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

 

const CompanyEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const record = location.state && location.state.record;
    const [loading, setLoading] = useState(false);
   

    useEffect(() => {
      const fetchData = async () => {
     
      };
      fetchData();
    }, []);

  
   
    const onFinish = async (values) => {
      setLoading(true)
      const currentDate =new Date()
      const data = {
        firstTransactionDate: record.firstTransactionDateEdit??currentDate,
        firstUserId: record.firstUserId??localStorage.getItem("uid"),
        lastTransactionDate: currentDate,
        lastUserId: localStorage.getItem("uid"),
        isDeleted: record.isDeleted,
      
        item: values.item,
      
      };
  
      try {
          
          const result = await FirebaseService.updateCompany(record.key,data);
          if (result.success) {
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });
            setLoading(false)
            setTimeout(() => {
              navigate("/companylist");
            }, 2000);
          } else {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });setLoading(false)}
          
      }
      catch (error) {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false)
      }
      
    };

    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };
 

   return ( <div>
    
         <Form
            name="basic"
            initialValues={{
              item: record.item ,
            }}
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
       

            <Form.Item
            name="item"
            rules={[
                {
                required: true,
                message: 'Lütfen item giriniz!',
                },
            ]}
            wrapperCol={{
              span: 6,
              }}
              style={{
              maxWidth: "100%",
              textAlign:"left"
              }}
            >
           <Input style={{borderRadius:0, borderColor:"#1A446C80"}} placeholder="Firma Adı"/>
            </Form.Item>

        
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" loading={loading} style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

    </div>)

        };
  export default CompanyEdit;