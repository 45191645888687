import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,DatePicker, Upload,Modal} from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";


const OpenOrderMergeJobCodes = ({ onClose }) => {
    const navigate = useNavigate();
    const [jobCodes, setJobCodes] = useState([]);
    const [companyName , setCompanyName] = useState([]) 

    useEffect(() => {
      const fetchData = async () => {
        const jobCodes = await FirebaseService.getOpenOrderData();
        const filteredJobCodes = jobCodes.filter(item => !item.isDeleted && item.orderType ==="order" && !item.isSetup && item.setupName === null && item.stock === null && item.wastage === null  );
        console.log("mevcut orderlar",filteredJobCodes)
        setJobCodes(filteredJobCodes);
        
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };    
    
  const onChange = (value) => {
    console.log(`selected ${value}`);
  
  };

  const onFinish = async (value) => {
    console.log("value",value)

  const selectedJobCode = value.jobCodes[0];
  const regex = /^[^-]*/;
  const match = selectedJobCode.match(regex);
  const firstPart = match ? match[0] : selectedJobCode;
  console.log("firstPart", firstPart); // "30883"

  const data = await FirebaseService.getOpenOrderData();
  const openOrderInfo = data.filter(item => item.jobCode === selectedJobCode);
  

  const openOrderInfoCompany = value.jobCodes.map(jobCode => 
    data.find(item => item.jobCode === jobCode)
  );
  const mergedCompanyNames = openOrderInfoCompany
  .map(item => item?.companyName || 'Bilinmiyor'); // Bilinmiyorsa 'Bilinmiyor' olarak ekler


  const selectedOpenOrderInfo = {
    companyName: openOrderInfo[0]?.companyName || null,
    orderType: openOrderInfo[0]?.orderType || null,
    projectCode: openOrderInfo[0]?.projectCode || null,
    warehouseName: openOrderInfo[0]?.warehouseName || null,
    mergedJobCodes: value.jobCodes,
    mergedCompanyNames,

  };
  
  console.log("selectedOpenOrderInfo", selectedOpenOrderInfo);

   navigate('/openorderaddcontinuemergejobcodes', { state: { record: selectedOpenOrderInfo, jobCode:firstPart} });


  };
  
   return ( <div>

<Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
    
              <Form.Item  
                label="İş Kodu"
                name="jobCodes"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen iş kodu seçiniz!',
                    },
                ]}
                >
                <Select
                    onChange={onChange}
                    placeholder="İş Kodu Seçiniz"
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={jobCodes.map(item => ({ label: `${item.companyName} - ${item.jobCode}`, value: item.jobCode }))}
                    className="input-style" 
                     mode="tags"
                />
            </Form.Item>

           



            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
  

    </div>)

};

OpenOrderMergeJobCodes.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default OpenOrderMergeJobCodes;