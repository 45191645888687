import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";

const OrderOperationPrice = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.updatedRecord;
 console.log("record",record)

  const [items, setItems] = useState([]);

  const [abkantTwistShape, setAbkantTwistShape] = useState(false);
  const [abkantCounts, setAbkantCounts] = useState({}); 

  
  useEffect(() => {

    window.scrollTo(0, 0);

    const fetchData = async () => {
     
    //kalite için fiyat
    record.items.forEach(item => {
      item.parts.forEach(part => {
        const kgPrice = record.pricesQuality[part.quality];
        part.priceQuality = kgPrice * part.newWeight; 
      });
    });
            
   //makine için fiyat
    record.items.forEach(item => {
      const machine = item.machine;
      const fuel = item.fuel;
      item.parts.forEach(part => {
        const key = `${machine}-${fuel}`;
        const pricePerMinute = record.pricesTime[key];
        const partTimeInMinutes = convertToMinutes(part.newTime);
        const partCost = partTimeInMinutes * pricePerMinute;
        part.priceMachine = partCost; 
       // console.log(`Cost for part (${machine}-${matchedItem.fuel}):`, partCost);
       
      });
    });
  
    //plazma için fiyat 
    record.items.forEach(item => {
      if (item.isPlazma) { // Eğer item'ın isPlazma alanı true ise
        item.parts.forEach(part => {
        //  console.log("part.newWeight", part.newWeight, record.pricesPlazma[true]);
          part.pricePlazma = record.pricesPlazma[true] * part.newWeight;
        //  console.log("part.pricePlazma", part.pricePlazma);
        });
      }
    });
    
    //abkant için fiyat
    if(record.abkantSelectedOperation === "Adet"){
        console.log("adet geldi")
        setAbkantTwistShape(true);
    }
   
    else{
      for (let i = 0; i < record.parts.length; i++) {
        const part = record.parts[i];
        if (part.operations && part.operations.includes('Abkant')) {
          const abkantPrice = record.pricesAbkant && record.pricesAbkant.abkant ? record.pricesAbkant.abkant : 0;
          const totalAbkantPrice = part.newWeight * abkantPrice;
        //  console.log("abkanktpriceeee",abkantPrice,totalAbkantPrice,part.newWeight)
          part.pricesAbkant = totalAbkantPrice;
          record.parts[i] = part;
        }
      }
       
    }
   
    console.log("en sonki parts",record.parts)
   


    const updatedItems = record.parts.map(item => {
      const unitPrice = parseFloat(calculateUnitPrice(item));
      const totalPrice = unitPrice * item.piece;
  
      return {
        ...item,
        unitPrice: unitPrice,
        totalPrice: totalPrice, 
      };
    });

    console.log("updatedItems",updatedItems)
    const filteredItems = updatedItems.filter(item => 
      item.customerName.toLowerCase() === record.companyName.toLowerCase()
    );
    console.log("Filtered Items:", filteredItems);

    setItems(filteredItems);

      
      
    };

    fetchData();
  }, []);

  

  const calculateTotalWeight = (items) => {
    let totalWeight = 0;
  
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const itemWeight = item.newWeight || 0;
      const itemPiece = item.piece || 0;
      totalWeight += itemWeight * itemPiece;
    }
  
    return totalWeight.toFixed(2);
  };
  
  // Toplam Kesim Sürelerinin Hesaplanması
  const calculateTotalTime = (items) => {
    let totalTimeInSeconds = 0;
  
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const itemTime = item.newTime || '00:00:00';
      const { hours, minutes, seconds } = parseTime(itemTime);
      const totalItemSeconds = hours * 3600 + minutes * 60 + seconds;
      const itemPiece = item.piece || 0;
      totalTimeInSeconds += totalItemSeconds * itemPiece;
    }
  
    return totalTimeInSeconds; // Saniye cinsinden toplam süreyi döndür
  };
  

  const handleContractManufacturingChange = (partKey, operation, value) => {
    setItems(prevParts => {
      return prevParts.map(part => {
        if (part.partNo === partKey) {
          const updatedOperationPrices = { ...(part.priceOperations || {}) };
          if (!updatedOperationPrices[operation]) {
            updatedOperationPrices[operation] = { profit: 0, contractManufacturing: 0 };
          }
          updatedOperationPrices[operation].contractManufacturing = Number(value);
          
          const updatedPart = { ...part, priceOperations: updatedOperationPrices };
          updatedPart.unitPrice = calculateUnitPrice(updatedPart);
          updatedPart.totalPrice = updatedPart.unitPrice * updatedPart.piece;
          
          return updatedPart;
        }
        return part;
      });
    });
  };

  const handleProfitChange = (partKey, operation, value) => {
    setItems(prevParts => {
      return prevParts.map(part => {
        if (part.partNo === partKey) {
          const updatedOperationPrices = { ...(part.priceOperations || {}) };
          if (!updatedOperationPrices[operation]) {
            updatedOperationPrices[operation] = { profit: 0, contractManufacturing: 0 };
          }
          updatedOperationPrices[operation].profit = Number(value);
          
          const updatedPart = { ...part, priceOperations: updatedOperationPrices };
          updatedPart.unitPrice = calculateUnitPrice(updatedPart);
          updatedPart.totalPrice = updatedPart.unitPrice * updatedPart.piece;
          
          return updatedPart;
        }
        return part;
      });
    });
  };


  const calculateUnitPrice = (item) => {
    const priceMachine = item.priceMachine || 0;
    const pricePlazma = item.pricePlazma || 0;
    const priceQuality = item.priceQuality || 0;
    const pricesAbkant = item.pricesAbkant || 0;

    let totalOperationsPrice = 0;
    if (item.priceOperations) {
      Object.values(item.priceOperations).forEach(op => {
        const contractManufacturing = parseFloat(op.contractManufacturing) || 0;
        const profit = parseFloat(op.profit) || 0;
        totalOperationsPrice += contractManufacturing + profit;
      });
    }

    const totalUnitPrice = parseFloat(priceMachine + totalOperationsPrice + pricePlazma + priceQuality + pricesAbkant);
    return totalUnitPrice.toFixed(2);
  };

  const handleAbkantCountChange = (partNo, value) => {
  // console.log("partNo",partNo,"value",value)
    setAbkantCounts({
      ...abkantCounts,
      [partNo]: value
    });
    const calculatedPrice = value * record.pricesAbkant["Abkant"]; 
    //console.log("calculatedPrice",calculatedPrice)

    setItems(prevParts => {
      const updatedParts = prevParts.map(part => {
        if (part.partNo === partNo) {
          return {
            ...part,
            pricesAbkant: calculatedPrice 
          };
        }

        return part;
      });
      console.log("Updated part: abkant", updatedParts);

      return updatedParts;
    });
  };

  const handleOperationChange = (partKey, operation, value) => {
  // console.log(partKey, operation, value);
    setItems(prevParts => {
      return prevParts.map(part => {
        if (part.partNo === partKey) {
          const updatedOperationPrices = { ...(part.priceOperations || {}) };
          updatedOperationPrices[operation] = Number(value);
        //  console.log("Updated priceOperations for part:", partKey, updatedOperationPrices);
  
          const updatedPart = { ...part, priceOperations: updatedOperationPrices };
          console.log("Updated part:", updatedPart);
  
          return updatedPart;
        }
        return part;
      });
    });
  };

  
  function convertToMinutes(timeString) {
    const { hours, minutes, seconds } = parseTime(timeString);
    return hours * 60 + minutes + seconds / 60;
  }
  
  function parseTime(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return { hours, minutes, seconds };
  }
  
  function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  
  function addTimes(time1, time2) {
    const t1 = parseTime(time1);
    const t2 = parseTime(time2);
    
    const totalSeconds1 = t1.hours * 3600 + t1.minutes * 60 + t1.seconds;
    const totalSeconds2 = t2.hours * 3600 + t2.minutes * 60 + t2.seconds;
    
    const totalSeconds = totalSeconds1 + totalSeconds2;
    return formatTime(totalSeconds);
  }

  const goTotalPriceCustomerPage = () => {
    const record = location.state && location.state.updatedRecord;
    console.log("redcord customer",record)
    navigate('/totalpriceordercustomerpage', { state: { items, record} });
  };

  const goThicknessPriceCustomerPage = () => {
    const record = location.state && location.state.updatedRecord;
    console.log("redcord customer",record)
    navigate('/thicknesspriceordercustomerpage', { state: { items, record} });
  }; 

  const goUnitPriceCustomerPage = () => {
    const record = location.state && location.state.updatedRecord;
    console.log("redcord customer",items,record)
    navigate('/unitpriceordercustomerpage', { state: { items, record} });

  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'partNo',
      key: 'partNo',
      width:300,
      render: (text, record, index) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{index + 1}</span>,
    },
    {
      title: 'Müşteri',
      dataIndex: 'customerName',
      key: 'customerName',    
      width : 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Parça No',
      dataIndex: 'partNo',
      key: 'partNo', 
      width:200,   
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
        width:200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
      },
      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width:200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
     },
    {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        width:200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
    
      },
      {
        title: 'Kesim Süresi',
        dataIndex: 'time',
        key: 'time',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap'  , color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
      },
      {
        title: 'Hesaplanan Kesim Süresi',
        dataIndex: 'newTime',
        key: 'newTime',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap'  , color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
      },
      {
      title: 'Hesaplanan Toplam Kesim Süresi',
      dataIndex: '',
      key: '',
      width : 200,
      render: (text, record) => {
        const timeInMinutes = convertToMinutes(record.newTime) || 0;
    const piece = parseFloat(record.piece) || 0;
    const totalMinutes = timeInMinutes * piece;
    const totalSeconds = totalMinutes * 60;

    // HH:MM:SS formatında süreyi formatla
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(Math.floor(totalSeconds % 60)).padStart(2, '0');

    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return (
      <span style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', color: record.isDeleted ? "#00000080" : "#000000" }}>
        {formattedTime}
      </span>
    );
 
      },
      
    },
      {
        title: 'Birim Ağırlık',
        dataIndex: 'weightSetup',
        key: 'weightSetup',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>{text} kg</span>,

      },
      
      {
        title: 'Hesaplanan Birim Ağırlık',
        dataIndex: 'newWeight',
        key: 'newWeight',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>  {text && text.toFixed(2)} kg </span>,

      },
      {
        title: 'Toplam Ağırlık',
        dataIndex: '',
        key: '',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
      {(record.weightSetup * record.piece).toFixed(2)} kg
          </span>
        ),
      },
      {
        title: 'Hesaplanan Toplam Ağırlık',
        dataIndex: '',
        key: '',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
      {(record.newWeight * record.piece).toFixed(2)} kg
          </span>
        ),
      },
   
      {
        title: 'Ebatlar',
        dataIndex: 'partNo',
        key: 'partNo',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}> {record.width} x  {record.height} </span>,
      }, 
      {
        title: 'Malzeme Fiyatı',
        dataIndex: 'priceQuality',
        key: 'priceQuality',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} TL
          </span>
        ),
      },
      
      {
        title: 'Makine Fiyatı',
        dataIndex: 'priceMachine',
        key: 'priceMachine',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} TL
          </span>
        ),
      },
      {
        title: 'Plazma Fiyatı',
        dataIndex: 'pricePlazma',
        key: 'pricePlazma',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text !== null && text !== undefined ? `${text.toFixed(2)} TL` : '-'}
          </span>
        ),
      },
      
      
      
      {
        title: 'Operasyonlar',
        dataIndex: 'operations',
        key: 'operations',
        width: 450,
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.length > 0 ? (
              text.map((operation) => (
                <div key={operation} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  {operation !== 'Abkant' && (
                    <>
                      <span style={{ width: '100px' }}>{operation}</span>
                      <Input
                      type="number"
                      value={record.priceOperations?.[operation]?.contractManufacturing || ''}
                      onChange={(e) => handleContractManufacturingChange(record.partNo, operation, e.target.value)}
                      placeholder="Fason Fiyatı"
                      style={{ marginLeft: '10px', width: '100px' }}
                    />
                    <Input
                      type="number"
                      value={record.priceOperations?.[operation]?.profit || ''}
                      onChange={(e) => handleProfitChange(record.partNo, operation, e.target.value)}
                      placeholder="Kar Marjı"
                      style={{ marginLeft: '10px', width: '100px' }}
                    />
                    </>
                  )}
                </div>
              ))
            ) : (
              '-'
            )}
          </span>
        ),
      },
      {
        title: 'Abkant Fiyatı',
        dataIndex: 'pricesAbkant',
        key: 'pricesAbkant',
        width:200,
        render: (text, item) => {
          const abkantSelectedOperation = record.abkantSelectedOperation;
      
          if (!abkantSelectedOperation) {
            return null; // Don't render the column if abkantSelectedOperation is null or empty
          }
      
          return (
            <span>
              {abkantTwistShape ? (
                <>
                  <Input
                    type="number"
                    placeholder="Büküm Sayısı"
                    style={{ marginLeft: '10px', width: '80px' }}
                    onChange={(e) => handleAbkantCountChange(item.partNo, e.target.value)}
                  />
                  <span>
                    {text !== null && text !== undefined ? `${text.toFixed(2)} TL` : '-'}
                  </span>
                </>
              ) : (
                <span>
                  {text !== null && text !== undefined ? `${text.toFixed(2)} TL` : '-'}
                </span>
              )}
            </span>
          );
        }

      },
      
      {
        title: 'Birim Fiyat',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
            <span>{text && Number(text).toFixed(2)} TL</span>
          </div>
        ),
      },
      {
        title: 'Toplam Fiyat',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
            <span>{text && Number(text).toFixed(2)} TL</span>
          </div>
        ),
      },
      
      
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Operasyon Fiyatları Gir</h3>
       

        <Table
            locale={{
              emptyText: 'Henüz bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
            style={{width:"200px"}}
           
        />

    <div style={{ marginTop: '5px', textAlign: 'left' }}>
        <div>
          <strong>Hesaplanan Toplam Ağırlıkların Toplamı:</strong> {calculateTotalWeight(items)} kg
        </div>
        <div>
          <strong>Hesaplanan Toplam Kesim Sürelerinin Toplamı:</strong> {(formatTime(calculateTotalTime(items)))}
        </div>
      </div>

        <div style={{float:"right"}}>
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"250px", marginLeft:"20px"}} onClick={() => goUnitPriceCustomerPage()}  >
       Birim Fiyat Müşteri Dökümanı
        </Button>
        </div>


        <div style={{float:"right"}}>
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"250px"}} onClick={() => goTotalPriceCustomerPage()}  >
        Takım Fiyat Müşteri Dökümanı
        </Button>
        </div>


        <div style={{float:"right"}}>
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"250px"}} onClick={() => goThicknessPriceCustomerPage()}  >
        Kalınlık Fiyat Müşteri Dökümanı
        </Button>
        </div>
    </div>

  };
  export default OrderOperationPrice;
 
 