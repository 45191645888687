import React from 'react';
import { useLocation } from 'react-router-dom';
import { Table } from 'antd';

const DayListDetail = () => {
  const location = useLocation();
  const { state } = location;
  const record = state ? state.record : null;
  console.log("gelen reocır",record);


  const recordArray = record ? Object.entries(record).map(([key, value]) => {
    if (key === 'key') {
      return null; // key özelliğini filtrele
    }
    return { key, ...value };
  }).filter(item => item !== null) : [];
  
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };
  const columns = [
    {
      title: 'İsim Soyisim',
      dataIndex: 'nameSurname',
      key: 'nameSurname',
      render: (text, record) => (
        <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
          {text}
        </span>
      ),
      width: 400,
    },
    {
      title: 'Giriş Saati',
      dataIndex: 'checkIn',
      key: 'checkIn',
      render: (text, record) => (
        <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
            {record.checkIn && formatTimestamp(record.checkIn)}
        </span>
      ),
      width: 400,
    },
    {
        title: 'Çıkış Saati',
        dataIndex: 'checkOutx',
        key: 'checkOutx',
        render: (text, record) => (
            <span style={{ fontWeight: "bold", color: record.isDeleted ? "#00000080" : "#000000" }}>
           {record.checkOut && formatTimestamp(record.checkOut)}
          </span>
        ),
        width: 400,
      },
  ];

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '94vh',
      padding: "3%"
    }}>
    <h2>{record.key} tarihinie ait mesai listesi </h2>
      <Table
        locale={{
          emptyText: 'Henüz kayıt bulunmamaktadır...',
          filterReset: 'Sıfırla',
          filterTitle: 'Filtre Menüsü',
          selectAll: 'Hepsini Seç',
          selectInvert: 'Tersini Seç',
          selectionAll: 'Tümünü Seç',
          sortTitle: 'Sıralama',
          triggerDesc: 'Azalan sıralama için tıklayın',
          triggerAsc: 'Artan sıralama için tıklayın',
          cancelSort: 'Sıralamayı iptal etmek için tıklayın',
        }}
        dataSource={recordArray}  
        columns={columns}
        showHeader={true}
        pagination={false}
        className="custom-news-table"
        rowClassName="custom-news-row"
      />
    </div>
  );
};

export default DayListDetail;
