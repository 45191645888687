import React,{useEffect,useState} from "react";
import {  Form, Input} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import  {parsCollections,colors}  from "../../../environments/environment"
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const ContractManufacturingDisplayDetail = ({ record,onClose }) => {
  
  console.log("record", record);

 
  useEffect(() => {
      const fetchData = async () => {
      
      };
      fetchData();
  }, []);
    

  
   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 5,
            }}
            wrapperCol={{
            span: 5,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 0%",
            textAlign:"left"
            }}
            initialValues={{
              companyName: record.companyName,
              supplierName: record.supplierName,
              jobCode: record.jobCode,
         
              processName: record.processName,
               isContractManufacturing :record.isContractManufacturing,
               isShipment :record.isShipment,
               isRequestCompleted :record.isRequestCompleted,
               parts:record.parts
            }}
            autoComplete="off"
        >

        
        <div style={{ display:"flex", }}>
          <p style={{  }}>Firma : </p>
         <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"140px"}}> {record && record.companyName}</p>
        </div>     

        <div style={{ display:"flex"}}>
            <p style={{  }}>Tedarikçi : </p>
            <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"120px"}}> {record && record.supplierName ? record.supplierName : "-" }</p>
         </div>   
                 
            
              <div style={{ display:"flex", }}>
          <p style={{  }}>İş Kodu :</p>
          <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"130px", width:"200px"}}>   {record && record.jobCode ? record.jobCode : '-'}
          </p>
        </div>

        <div style={{ display:"flex", }}>
          <p style={{  }}>Proje Kodu :</p>
          <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"110px", width:"200px"}}> {record && record.projectCode}</p>
        </div>

           
        <div style={{ display:"flex", }}>
              <p style={{  }}>Açık Sipariş Termin Tarihi:</p>
              <p className="contract-manufacturing-edit-input input-style"  style={{marginLeft:"20px"}} >
                {record && record.openOrderDeadline && typeof record.openOrderDeadline === 'object' ?
                new Date(record.openOrderDeadline.seconds * 1000).toLocaleDateString('tr-TR') :
               "-"
              }</p>
            </div>


         
            <div style={{ display:"flex",}}>
            <p style={{  }}>Termin Tarihi:</p>
            <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"100px"}}>
              {record && record.deadline && typeof record.deadline === 'object' ?
              new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
              "-"
            }</p>
            </div>
         
      

          <div style={{ display:"flex",}}>
            <p style={{ }}>Prosesler</p>
            <p className="contract-manufacturing-edit-input input-style" style={{marginLeft:"125px"}}> {record && record.processName}</p>


          
           
          </div>
   
      <div style={{   }}>
          <p style={{}}>Parçalar</p>
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", fontWeight: "bold" }}>
            <div style={{ width: "230px", marginRight: "10px" }}>Parça Adı</div>
            <div style={{ width: "70px", marginRight: "10px" }}>Adet</div>
            <div style={{ width: "70px", marginRight: "10px" }}>Fason Fiyatı (TL)</div>
            <div style={{ width: "70px", marginRight: "10px" }}>Kar Marjı (TL)</div>

          </div>
          {record.parts.map((part, index) => (
            <div key={index} style={{ marginBottom: "10px", textAlign:"left" }}>
              <Input
              className= {part.partCode ? "contract-manufacturing-edit-input input-style pointer-none" : null}
              value={part.partCode}
                placeholder="Parça Kodu"
                style={{ width: "230px", marginRight: "10px" }}
                disabled = {part.partCode ? true : false}
              />

              <Input
              className= {part.piece ? "contract-manufacturing-edit-input  input-style pointer-none" : null}
              value={part.piece}
                placeholder="Adet"
                style={{ width: "70px", marginRight: "2px" }}
                disabled = {part.piece ? true : false}
              />
              <Input
              className=  "contract-manufacturing-edit-input  input-style pointer-none" 
                value={part.unitPrice ? part.unitPrice : "-"}
                placeholder="Fason Fiyatı"
                style={{ width: "70px", marginRight: "2px" }}
                disabled = {part.unitPrice ? true : false}
              />
              <Input
              className=  "contract-manufacturing-edit-input  input-style pointer-none" 
                value={part.profit ? part.profit : "-"}
                placeholder="Kar Marjı"
                style={{ width: "70px", marginRight: "2px" }}
                disabled = {part.profit ? true : false}
              />
            </div>
          ))}
        </div>
        </Form>
    </div>)

};

ContractManufacturingDisplayDetail.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default ContractManufacturingDisplayDetail;