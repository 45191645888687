import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input,Select,Form,Modal,message } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import OperationDetail from "./operationDetail";
import NotificationService from "../../../../services/antNotificationService";

const { Option } = Select;

const OrderDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const record = location.state && location.state.record;
  const [visible, setVisible] = useState(false);
  const [jobCodes, setJobCodes] = useState([]); 

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]); 
  const [uniqueOperations, setUniqueOperations] = useState([]); 
  const [abkant, setAbkant] = useState([]); 

  const [qualityWeights, setQualityWeights] = useState([]);
  const [plazmaWeights, setPlazmaWeights] = useState([]);
  const [abkantWeight, setAbkantWeights] = useState([]);


  const [machineTimes, setMachineTimes] = useState([]);
  const [selectedJobCode, setSelectedJobCode] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordForModal, setRecordForModal] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedTeamCount, setSelectedTeamCount] = useState(1);
  const [description, setDescription] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(null);
  const [unit, setUnit] = useState(null);
  const [editableTimes, setEditableTimes] = useState({});

  console.log("record",record);
    
  useEffect(() => {
    const fetchData = async () => {
        const paymentMethods = await FirebaseService.getPaymentMethodsData();
        console.log("paymentMethods",paymentMethods)
        const filteredpaymentMethods = paymentMethods.filter(item => !item.isDeleted);
        setPaymentMethods(filteredpaymentMethods);
        const jobCodes = record.items
        .filter(item => item.jobCode) 
        .map(item => ({ label: item.jobCode, value: item.jobCode })); 

        setJobCodes(jobCodes);


        record.items.forEach(item => {
          if (Array.isArray(item.parts)) {
            item.parts.forEach(part => {
              if (Array.isArray(part.operations)) {
                part.operations.forEach(operation => {
                  if (!uniqueOperations.includes(operation)) {
                    uniqueOperations.push(operation);
                  }
                });
              }
            });
          }
        });
        //sadece abkant olcak.....
        setAbkant(["Abkant"]);
        setUniqueOperations(uniqueOperations);
        console.log("Benzersiz Operations:", uniqueOperations);
        
        const qualityWeights = {}; 
        record.items.forEach(item => {
          const quality = item.quality;
          const weight = item.totalKg; 
        
          if (!qualityWeights[quality]) { 
            qualityWeights[quality] = weight; 
          } else {
            qualityWeights[quality] += weight; 
          }
        });
      
        console.log("Quality Weights:", qualityWeights);
        setQualityWeights(qualityWeights);
        
      const machineData = {}; 

      record.items.forEach(item => {
          const machine = item.machine;
          const setupTime = item.setupTime;
          const fuel = item.fuel;

          if (!machineData[machine]) {
            machineData[machine] = {
              fuels: {} 
            };
          }
            if (!machineData[machine].fuels[fuel]) {
              machineData[machine].fuels[fuel] = setupTime;
            } else {
              machineData[machine].fuels[fuel] = addTimes(machineData[machine].fuels[fuel], setupTime);
            }  
      });

      console.log("Machine Data:", machineData);
      setMachineTimes(machineData);

           
      const plazmaWeights = {}; 
      record.items.forEach(item => {
          if (item.isPlazma) { // Sadece item.isPlazma true olduğunda işlemleri yapar
            const weight = item.totalKg;
        
            if (!plazmaWeights[item.isPlazma]) { 
              plazmaWeights[item.isPlazma] = weight; 
            } else {
              plazmaWeights[item.isPlazma] += weight; 
            }
          }
        });
        
       console.log("plazmaWeights", plazmaWeights);
      setPlazmaWeights(plazmaWeights);
        

     // console.log("record.parts",record.parts)
      const totalAbkantWeight = record.parts.reduce((total, part) => {
        if (part.operations.includes('Abkant')) {
          total += part.weightSetup || 0;
        }
        return total; 
      }, 0); 
     // console.log("Toplam Abkant Ağırlığı:", totalAbkantWeight);
      setAbkantWeights(totalAbkantWeight)
      



      const totalKgSetup = {};
      for (let i = 0; i < record.parts.length; i++) {
        const part = record.parts[i];
        const quality = part.quality;
        const weight = part.weightSetup;
        const piece = part.piece || 1; 
      
        if (weight && quality) {
          if (!totalKgSetup[quality]) {
            totalKgSetup[quality] = 0;
          }
          totalKgSetup[quality] += weight * piece;
        }
      }
      
     Object.keys(totalKgSetup).forEach(quality => {
      const weightDifference = record.totalKg[quality] - totalKgSetup[quality];
      if (weightDifference > 0) {
        const partsToUpdateWeight = record.parts.filter(part => part.quality === quality && part.weightSetup > 0); 
        partsToUpdateWeight.forEach(part => {
          const weightRatio = part.weightSetup / totalKgSetup[quality]; 
          part.newWeight = part.weightSetup + weightDifference * weightRatio;    
        });
      }
    });
    
    const totalSetupTimeByMachineAndFuel = record.items.reduce((acc, item) => {
      const machine = item.machine;
      const fuel = item.fuel;

      item.parts.forEach(part => {
        const key = `${machine}-${fuel}`;
        if (!acc[key]) {
          acc[key] = "00:00:00"; 
        }
        const totalPartTime = multiplyTime(part.time, part.piece); 
        acc[key] = addTimes(acc[key], totalPartTime);
      });
      return acc;
    }, {});
    
    const setupTimeKeys = Object.keys(totalSetupTimeByMachineAndFuel);
    setupTimeKeys.forEach(key => {
      if (record.setupTime[key]) {
        const timeDifference = subtractTimes(totalSetupTimeByMachineAndFuel[key], record.setupTime[key]);
       // console.log(`Key: ${key}, Time Difference: ${timeDifference}`);
        let totalPartTime = 0;
        record.parts.forEach(part => {
            if (part.machine === key.split("-")[0] && part.fuel === key.split("-")[1]) {
                totalPartTime += parseTime(part.time).hours * 3600 + parseTime(part.time).minutes * 60 + parseTime(part.time).seconds;
            }
        });

        record.parts.forEach(part => {
            if (part.machine === key.split("-")[0] && part.fuel === key.split("-")[1]) {
                const partSeconds = parseTime(part.time).hours * 3600 + parseTime(part.time).minutes * 60 + parseTime(part.time).seconds;
                const timeDifferenceSeconds = parseTime(timeDifference).hours * 3600 + parseTime(timeDifference).minutes * 60 + parseTime(timeDifference).seconds;

                const partTimeDifference = (partSeconds / totalPartTime) * timeDifferenceSeconds;
              //console.log("partTimeDifference",partTimeDifference)
                const partTimeDifferenceFormatted = formatTime2(partTimeDifference);
              //  console.log("partTimeDifferenceFormatted",partTimeDifferenceFormatted)
                part.newTime = addTimes(part.time, partTimeDifferenceFormatted);
            }
        });
         }
    });



    };
  
    fetchData();
  }, []);

  const validateTimeFormat = (timeString) => {
    if (timeString === '') return true;
    const regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    return regex.test(timeString);
  };

  const handleMachineTimeChange = (machine, fuelType, value) => {
    const key = `${machine}-${fuelType}`;
    setEditableTimes(prev => ({
      ...prev,
      [key]: value
    }));

    if (validateTimeFormat(value)) {
      const newMachineTimes = JSON.parse(JSON.stringify(machineTimes));
      newMachineTimes[machine].fuels[fuelType] = value;
      setMachineTimes(newMachineTimes);
    }
  };
  const handleButtonClick = () => {
    let allValid = true;
    Object.entries(editableTimes).forEach(([key, value]) => {
      if (!validateTimeFormat(value)) {
        allValid = false;
        message.error(`Geçersiz zaman formatı: ${value}`);
      }
    });
  
    if (allValid) {
      // Proceed to the next page or perform other actions
      goUnitPricePage();
    }
  };

  function formatTime2(totalSeconds) {
    totalSeconds = Math.round(totalSeconds); // Kesirli saniyeleri tam sayıya yuvarlama

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  }

  function subtractTimes(time1, time2) {
    const t1 = parseTime(time1);
    const t2 = parseTime(time2);

    const totalSeconds1 = t1.hours * 3600 + t1.minutes * 60 + t1.seconds;
    const totalSeconds2 = t2.hours * 3600 + t2.minutes * 60 + t2.seconds;

    const differenceSeconds = Math.abs(totalSeconds1 - totalSeconds2);

    return formatTime(differenceSeconds);
}

  function multiplyTime(timeString, multiplier) {
    const { hours, minutes, seconds } = parseTime(timeString);
    const totalSeconds = (hours * 3600 + minutes * 60 + seconds) * multiplier;
    return formatTime(totalSeconds);
  }
  
  function parseTime(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return { hours, minutes, seconds };
  }
  
  function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  
  function addTimes(time1, time2) {
    const t1 = parseTime(time1);
    const t2 = parseTime(time2);
    
    const totalSeconds1 = t1.hours * 3600 + t1.minutes * 60 + t1.seconds;
    const totalSeconds2 = t2.hours * 3600 + t2.minutes * 60 + t2.seconds;
    
    const totalSeconds = totalSeconds1 + totalSeconds2;
    return formatTime(totalSeconds);
  }

  const timeToMinutes = (timeString) => {
    if (typeof timeString !== 'string') {
      if (timeString.setupTime && typeof timeString.setupTime === 'string') {
        timeString = timeString.setupTime; 
      } else {
        console.error('Invalid timeString object:', timeString);
        return 0; 
      }
    }
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 60 + minutes + seconds / 60;
  };
   
  const onChange = (value) => {
    console.log("value",value)
    setSelectedJobCode(value);
    setPaymentMethod(value)
     if (value === 'newPaymentMethod') {
      console.log('Yeni ödeme vaadesi Ekle seçildi');
     // setIsModalVisibleCompany(true);
    }
  };


//kalite için 
  const [pricesQuality, setPricesQuality] = useState({});
  const [totalPricesQuality, setTotalPricesQuality] = useState({});

  const handlePriceChangeQuality = (quality, e) => {
   // console.log("totalPricesQuality",totalPricesQuality)
   // console.log("pricesQuality",pricesQuality);
    const value = parseFloat(e.target.value) || 0;
    setPricesQuality(prevPrices => ({
      ...prevPrices,
      [quality]: value,
    }));
  
    const weight = qualityWeights[quality]; 
    const total = value * weight; 
    setTotalPricesQuality(prevTotalPrices => ({
      ...prevTotalPrices,
      [quality]: total,
    }));
  };
  
//makine saat için
  const [pricesTime, setPricesTime] = useState({});
  const [totalPricesTime, setTotalPricesTime] = useState({});

  const handlePriceChangeTime = (key, e) => {
   // console.log("totalPricesTime", totalPricesTime);
   // console.log("pricesTime", pricesTime);
    const value = parseFloat(e.target.value) || 0;
    setPricesTime(prevPrices => ({
      ...prevPrices,
      [key]: value,
    }));
  
    const [machine, fuelType] = key.split('-'); 
  
    const machineData = machineTimes[machine];
    if (!machineData || typeof machineData !== 'object') {
      console.error('Invalid machine data:', machineData);
      return;
    }
  
    const setupTime = machineData.fuels[fuelType]; 
    if (!setupTime || typeof setupTime !== 'string') {
      console.error('Invalid setupTime:', setupTime);
      return;
    }
  
    const totalMinutes = timeToMinutes(setupTime); 
    const total = value * totalMinutes; 
    setTotalPricesTime(prevTotalPrices => ({
      ...prevTotalPrices,
      [key]: total,
    }));
  };
  
//plazma için
  const [pricesPlazma, setPricesPlazma] = useState({});
  const [totalPricesPlazma, setTotalPricesPlazma] = useState({});

  const handlePriceChangePlazma = (quality, e) => {
    //console.log("totalPricesPlazma",totalPricesPlazma)
    //console.log("pricesPlazma",pricesPlazma);
    const value = parseFloat(e.target.value) || 0;
    setPricesPlazma(prevPrices => ({
      ...prevPrices,
      [quality]: value,
    }));
  
    const weight = plazmaWeights[quality]; 
    const total = value * weight; 
    setTotalPricesPlazma(prevTotalPrices => ({
      ...prevTotalPrices,
      [quality]: total,
    }));
  };
  
 
  //abkant için 
  const [pricesAbkant, setPricesAbkant] = useState({});
  const [quantitiesAbkant, setQuantitiesAbkant] = useState({});
  const [totalPricesAbkant, setTotalPricesAbkant] = useState({});


  const handlePriceChangeAbkant= (operation, e) => {
  //  console.log("totalPricesPlazma",totalPricesAbkant)
  // console.log("pricesPlazma",pricesAbkant);
  // console.log("selectedOperation",selectedOperation[abkant]);
    const value = parseFloat(e.target.value) || 0;
    setPricesAbkant(prevPrices => ({
      ...prevPrices,
      [operation]: value,
    }));

    const total = value * (quantitiesAbkant[operation] || 0);
    setTotalPricesAbkant(prevTotalPrices => ({
      ...prevTotalPrices,
      [operation]: total,
    }));
  };

  const handleQuantityChangeAbkant = (operation, e) => {
    const value = parseFloat(e.target.value) || 0;
    setQuantitiesAbkant(prevQuantities => ({
      ...prevQuantities,
      [operation]: value,
    }));

    const total = value * (pricesAbkant[operation] || 0);
    setTotalPricesAbkant(prevTotalPrices => ({
      ...prevTotalPrices,
      [operation]: total,
    }));
  };
    

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleSetupFinish = () => {
    setIsModalVisible(false); 
  };
  

  const handleOk = async () => {
    console.log("yönlendrime lazım",selectedJobCode)
   
   console.log("record.items",record.items)
   const selectedItem = record.items.find(item => item.jobCode === selectedJobCode);

   if(selectedItem === undefined ){
    console.log( "buraya geldi")
    NotificationService.openErrorNotification({
      title: 'İşlem Başarısız',
      description: "Lütfen setup seçiniz",
      placement: 'topRight'
    });
    return;

  }

   console.log("selectedItem",selectedItem)
  
    setIsModalVisible(true); 
    setRecordForModal(selectedItem);

   };
  
  const handleCancel = () => {
    setVisible(false);
  };

  const goUnitPricePage = () => {
    console.log("pricesQuality",pricesQuality)
    if (Object.keys(pricesQuality).length === 0) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Kalite fiyat giriniz.",
        placement: 'topRight'
      });
      return;
    }
    if (Object.keys(pricesTime).length === 0) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Makine fiyat giriniz.",
        placement: 'topRight'
      });
      return;
    }
    //plazma varsa fiyat girmeli
    if(Object.keys(plazmaWeights).length > 0){
      if (Object.keys(pricesPlazma).length === 0) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Plazma fiyat giriniz.",
          placement: 'topRight'
        });
        return;
      }
    }

    const hasPriorityAbkant = record.items.some(item => item.priorityAbkant != null );

    if (hasPriorityAbkant) {

      if (Object.keys(pricesAbkant).length === 0) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Abkant fiyat giriniz.",
          placement: 'topRight'
        });
        return;
      }
      
      if(selectedOperation === null){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Abkant için dk/kg/adet seçip, bilgileri doldurunuz.",
          placement: 'topRight'
        });
        return;
      }
      
    }

    if(paymentMethod === null){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Ödeme Vaadesini Giriniz.",
        placement: 'topRight'
      });
      return;
    }

    if(deliveryMethod === null){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Teslim Şekli Giriniz.",
        placement: 'topRight'
      });
      return;
    }

    if(unit === null){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Birim Giriniz.",
        placement: 'topRight'
      });
      return;
    }

    const updatedRecord = {
      ...record,
    
      totalPricesQuality: totalPricesQuality || 0,
      pricesQuality: pricesQuality || {},
    
      totalPricesTime: totalPricesTime || 0,
      pricesTime: pricesTime || {},
    
      totalPricesAbkant: totalPricesAbkant || 0,
      pricesAbkant: pricesAbkant || {},
      quantitiesAbkant: quantitiesAbkant || 0,
      abkantSelectedOperation: selectedOperation && selectedOperation[abkant] ? selectedOperation[abkant] : null,
    
      totalPricesPlazma: totalPricesPlazma || 0,
      pricesPlazma: pricesPlazma || {},
    
      paymentMethod: paymentMethod || '',
      description: description || '',
      deliveryMethod: deliveryMethod || '',
      teamCount: selectedTeamCount || 0,
      unit: unit || '',
    };
    
    console.log("updatedRecord",updatedRecord)

    navigate('/orderoperationprice', { state: { updatedRecord } });
    
  };


  const handleTeamCountChange = (e) => {
    setSelectedTeamCount(e.target.value);
  };


  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleDeliveryMethod = (e) => {
    setDeliveryMethod(e.target.value);
  };

  const handleUnit = (e) => {
    setUnit(e.target.value);
  };

  const hasAbkantOperation = record && record.items &&
  record.items.some(item =>
    item.parts && item.parts.some(part =>
      part.operations && part.operations.some(operation => operation.toLowerCase().includes('abkant'))
    )
  );
  
  

   return ( 
    <>
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Teklif Detay</h2>
          
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Programcı</p>
          {record && record.programmerName.join(",")}
        </div>
  
       



        <div style={{ textAlign: "left", flex: "1 0 25%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Ödeme Vaadesi</p>
          <Select
                onChange={onChange}
                placeholder="Ödeme Vaadesi Seçiniz"
                style={{  width:"250px" }}
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
             /*   options={[
                  ...paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod })),
                  { label: 'Yeni Ödeme vaadesi Ekle', value: 'newPaymentMethod' },
                ]}*/
                options={paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod }))}
                className="input-style"     
              />

        </div>

        </div>


      <div style={{ display: "flex", justifyContent: "space-between" }}>

      <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Operasyonlar</p>
            {uniqueOperations.map((operation, index) => (
                <span key={index}>
                {operation}
                {index !== uniqueOperations.length - 1 && ' - '}
                </span>
            ))}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Takım Adedi</p>
            <Form.Item
                name="teamCount"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen takım sayısı giriniz!',
                    },
                ]}
                >
            <Input    className="input-style"      style={{width:"200px"}} placeholder="Takım Sayısı"
            onChange={handleTeamCountChange}
            value={selectedTeamCount} 
            defaultValue={1}
            />
            </Form.Item>
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Açıklama</p>
            <Form.Item
                name="description"
              
                >
            <Input   className="input-style"  style={{width:"200px"}} placeholder="Açıklama"
            onChange={handleDescription}
            value={description} 
            />
            </Form.Item>
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Teslim Şekli</p>
            <Form.Item
                name="deliveryMethod"
                rules={[
                  {
                  required: true,
                  message: 'Lütfen teslim şekli giriniz!',
                  },
              ]}
                >
            <Input   className="input-style"  style={{width:"200px"}} placeholder="Teslim Şekli"
            onChange={handleDeliveryMethod}
            value={deliveryMethod} 
            />
            </Form.Item>
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Birim</p>
            <Form.Item
                name="unit"
                rules={[
                  {
                  required: true,
                  message: 'Lütfen  birim giriniz!',
                  },
              ]}
                >
            <Input  className="input-style"  style={{ width:"200px"}} placeholder="Birim"
            onChange={handleUnit}
            value={unit} 
            />
            </Form.Item>
        </div>


        

       </div>

    
    <div style={{marginTop:"100px"}}>

    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left",color:colors.blue ,fontWeight: "bold", }}>
            <div style={{ width: "250px", marginRight: "10px", }}>Kalite</div>
            <div style={{ width: "150px", marginRight: "10px" }}>Kg</div>
            <div style={{ width: "100px", marginRight: "10px" }}>Kg. Fiyat</div>
            <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
        </div>

        {Object.entries(qualityWeights).map(([quality, weight], index) => (
          <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
            <div style={{ width: "250px", marginRight: "10px" }}>{quality}</div>
            <div style={{ width: "150px", marginRight: "10px" }}>{weight.toFixed(2)} kg</div>
          
            <Input
              type="number"
              value={pricesQuality[quality] || ''}
              onChange={(e) => handlePriceChangeQuality(quality, e)}
              placeholder="Fiyat"
              style={{ width: "100px", marginRight: "10px" }}
              className="input-style"     
            />
            <div style={{ width: "100px", marginRight: "10px" }}>{totalPricesQuality[quality]?.toFixed(2) || 0}</div>
          </div>
        ))}

        <div style={{display:"flex"}}>


        {Object.entries(machineTimes).map(([machine, data], index) => (
          <div key={index}>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
              <div style={{ width: "250px", marginRight: "10px" }}>Trumpf {machine}KW</div>
              <div style={{ width: "150px", marginRight: "10px" }}>Dakika</div>
              <div style={{ width: "100px", marginRight: "10px" }}>Fiyat</div>
              <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
            </div>

            {Object.entries(data.fuels).map(([fuelType, time], fuelIndex) => (
              <div key={`${index}-${fuelIndex}`} style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
                <div style={{ width: "250px", marginRight: "10px" }}>{fuelType}</div>
                <Input
                  value={editableTimes[`${machine}-${fuelType}`] !== undefined ? editableTimes[`${machine}-${fuelType}`] : time}
                  onChange={(e) => handleMachineTimeChange(machine, fuelType, e.target.value)}
                  placeholder="HH:MM:SS"
                  style={{ width: "150px", marginRight: "10px" }}
                  className="input-style"
                />
                <Input
                  type="number"
                  value={pricesTime[`${machine}-${fuelType}`] || ''}
                  onChange={(e) => handlePriceChangeTime(`${machine}-${fuelType}`, e)}
                  placeholder="Fiyat"
                  style={{ width: "100px", marginRight: "10px" }}
                  className="input-style"
                />
                <div style={{ width: "100px", marginRight: "10px" }}>
                  {totalPricesTime[`${machine}-${fuelType}`]?.toFixed(2) || 0}
                </div>
              </div>
            ))}
          </div>
        ))}

        <div style={{ textAlign: "left", flex: "1 0 16.66%", marginLeft:"100px" }}>
          <div style={{ fontWeight: "bold", color: colors.blue }}>Gerçekleşen Lazer Süresi</div>
          {record && record.items
            ? (() => {
                // Tüm item'ları gezerek lazer süresini hesapla
                const totalLazerTimeInSeconds = record.items.reduce((totalItem, item) => {
                  // Her item'in içinde plates'i gezerek toplam süreyi hesapla
                  const platesTotalTimeInSeconds = item.plates
                    ? item.plates.reduce((totalPlate, plate) => {
                        // Plate'teki actualTime değerini al ve saniyeye çevir
                        const [hours, minutes, seconds] = plate.actualTime
                          ? plate.actualTime.split(':').map(Number)
                          : [0, 0, 0];
                        
                        // Saat, dakika ve saniyeyi topla
                        const plateTimeInSeconds = (hours * 3600) + (minutes * 60) + seconds;
                        
                        return totalPlate + plateTimeInSeconds;
                      }, 0)
                    : 0;

                  return totalItem + platesTotalTimeInSeconds;
                }, 0);

                // Toplam süreyi saat, dakika ve saniye formatına dönüştür
                const hours = String(Math.floor(totalLazerTimeInSeconds / 3600)).padStart(2, '0');
                const minutes = String(Math.floor((totalLazerTimeInSeconds % 3600) / 60)).padStart(2, '0');
                const seconds = String(totalLazerTimeInSeconds % 60).padStart(2, '0');

                const formattedLazerTime = totalLazerTimeInSeconds > 0 
                  ? `${hours}:${minutes}:${seconds}` 
                  : "Bu bilgi mevcut değil";
                
                return formattedLazerTime;
              })()
            : "Bu bilgi mevcut değil"}
        </div>
        </div>



        {Object.keys(plazmaWeights).length > 0 && (
          <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
            <div style={{ width: "250px", marginRight: "10px" }}>Plazma</div>
            <div style={{ width: "150px", marginRight: "10px" }}>Kg</div>
            <div style={{ width: "100px", marginRight: "10px" }}>Fiyat</div>
            <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
          </div>
        )}


       {Object.entries(plazmaWeights).map(([isPlazma, weight], index) => (
          <div key={index} style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left" }}>
            <div style={{ width: "250px", marginRight: "10px" }}></div>
            <div style={{ width: "150px", marginRight: "10px" }}>{weight.toFixed(2)} kg</div>
          
            <Input
              type="number"
              value={pricesPlazma[isPlazma] || ''}
              onChange={(e) => handlePriceChangePlazma(isPlazma, e)}
              placeholder="Fiyat"
              style={{ width: "100px", marginRight: "10px" }}
              className="input-style"     
            />
            <div style={{ width: "100px", marginRight: "10px" }}>{totalPricesPlazma[isPlazma]?.toFixed(2) || 0}</div>
          </div>
        ))}




      <div style={{marginTop:"50px"}}>
     
<div style={{display:"flex"}}>

<div>
  {record.items.some(item => item.priorityAbkant) && (
    <div>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", textAlign: "left", color: colors.blue, fontWeight: "bold" }}>
        <div style={{ width: "250px", marginRight: "10px" }}>Operasyonlar</div>
        <div style={{ width: "150px", marginRight: "10px" }}>Dakika/Kg/Adet</div>
        <div style={{ width: "200px", marginRight: "10px" }}></div>
        <div style={{ width: "100px", marginRight: "10px" }}>Fiyat</div>
        <div style={{ width: "100px", marginRight: "10px" }}>Toplam Fiyat</div>
      </div>
      {abkant.map((operation, index) => (
        <div key={index} style={{ marginBottom: "10px", display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "row", textAlign: "left" }}>
            <div style={{ width: "250px", marginRight: "10px" }}>{operation}</div>
            <Select
              placeholder="Dk/Kg/Adet"
              style={{ width: "150px", marginRight: "10px" }}
              className="input-style"
              onChange={(value) => setSelectedOperation({ ...selectedOperation, [operation]: value })}
            >
              <Option value="Dakika">Dakika</Option>
              <Option value="Kg">Kg</Option>
              <Option value="Adet">Adet</Option>
            </Select>
          </div>

          {selectedOperation && selectedOperation[operation] && (
            <div style={{ display: "flex", marginBottom: "10px", textAlign: "left" }}>
              {selectedOperation[operation] === 'Adet' && (
                <Input
                  type="number"
                  value={quantitiesAbkant[operation] || ''}
                  onChange={(e) => handleQuantityChangeAbkant(operation, e)}
                  placeholder="Toplam Büküm Sayısı"
                  style={{ width: "200px", marginRight: "10px" }}
                  className="input-style"
                />
              )}

              {selectedOperation[operation] === 'Dakika' && (
                <Input
                  value={quantitiesAbkant[operation] || ''}
                  onChange={(e) => handleQuantityChangeAbkant(operation, e)}
                  placeholder="Süre Giriniz (Dakika)"
                  style={{ width: "200px", marginRight: "10px" }}
                  className="input-style"
                />
              )}

              {selectedOperation[operation] === 'Kg' && (
                <div style={{ width: "200px", marginRight: "10px" }}>{abkantWeight.toFixed(2)} kg</div>
              )}

              <Input
                type="number"
                value={pricesAbkant[operation] || ''}
                onChange={(e) => handlePriceChangeAbkant(operation, e)}
                placeholder="Fiyat"
                style={{ width: "100px", marginRight: "10px" }}
                className="input-style"
              />
              <div style={{ width: "100px", marginRight: "10px" }}>
                {totalPricesAbkant[operation]?.toFixed(2) || 0}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )}
</div>

{hasAbkantOperation && (<div style={{ textAlign: "left", flex: "1 0 16.66%", marginLeft:"30px" }}>
  <div style={{ fontWeight: "bold", color: colors.blue }}>Gerçekleşen Abkant Süresi</div>
  {record && record.items
    ? (() => {
        const totalAbkantTimeInMinutes = record.items.reduce((total, item) => {
          const abkantTime = item.abkantActualTime !== (null || undefined) 
            ? item.abkantActualTime 
            : "00:00:00";
          
          // Abkant süresini saat, dakika ve saniye olarak ayır
          const [hours, minutes, seconds] = abkantTime.split(':').map(Number);
          
          // Toplam süreyi saniye cinsinden hesapla
          const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
          
          return total + totalSeconds;
        }, 0);

        // Toplam süreyi saat, dakika ve saniye formatına dönüştür
        const hours = String(Math.floor(totalAbkantTimeInMinutes / 3600)).padStart(2, '0');
        const minutes = String(Math.floor((totalAbkantTimeInMinutes % 3600) / 60)).padStart(2, '0');
        const seconds = String(totalAbkantTimeInMinutes % 60).padStart(2, '0');

        const formattedAbkantTime = totalAbkantTimeInMinutes > 0 
          ? `${hours}:${minutes}:${seconds}` 
          : "Bu bilgi mevcut değil";
        
        return formattedAbkantTime;
      })()
    : "Bu bilgi mevcut değil"}
</div>
)}

</div>
     


      </div>

    </div>
      <div style={{ marginTop:"80px"}} >
      <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"200px"}}   onClick={() =>   setVisible(true)}  >
        Operasyon Detaylarını Gör
        </Button>

        <div style={{float:"right"}}>
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"200px"}} onClick={handleButtonClick}  >
       Operasyon Fiyatlarını Gir
        </Button>
        </div>

      </div>
      <Modal
            title=""
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
            className="operation-detail-popup"
          >
            <OperationDetail  record={recordForModal}  onClose={handleSetupFinish} />
      </Modal>

      <Modal
                  title="Görmek istediğiniz Setup'ı seçiniz"
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  className="popup-modal-button"
                  okText="Devam Et"      
                  cancelText="İptal"  
              >
              <div> 
                <Form.Item
                name="jobCode"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen iş kodu seçiniz!',
                    },
                ]}
                
                style={{marginBottom:"40px", marginTop:"30px"}}
                >
               <Select
                  onChange={onChange}
                  placeholder="İş Kodu Seçiniz"
                  suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                  options={jobCodes} 
                   className="input-style"
                />
            </Form.Item>

            </div>
     </Modal>




      </div>
</>


)};


export default OrderDetail;


/**
 * 
 * 
   <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
          {record && record.deadline && typeof record.deadline === 'object' ?
            new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
            record.deadline
          }
        </div>




          if ( !paymentMethod) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Ödeme vaadesi boş olamaz",
          placement: 'topRight'
        });
        return;
      }


       
       
       
       
 */
