import React, { useState } from "react";
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { parsCollections, colors}  from "../../../environments/environment"
import AlarmListLimited from "../alarms/alarmListLimited";
import Machine10ListLimited from "./machine10ListLimited";
import Machine5ListLimited from "./machine5ListLimited";
import AbkantListLimited from "./abkantListLimited";

const SortEditList = () => {
  
  const navigate = useNavigate();


  return (
    <div style={{padding:"3%"}}>
   
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      
       <Machine10ListLimited/>
        
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      
       <Machine5ListLimited/>
       
      
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
     
       <AbkantListLimited/>
       
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
       
      </div>
      </div>
      <div style={{color:"gray", textAlign:"center"}}> *Sıralama düzenlemek için lütfen makine seçiniz.</div>

   
    </div>
  );
};

export default SortEditList;

