import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import  { colors}  from "../../../../environments/environment"


const ApprovedOfferOpenOrderListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getOpenOrderData();
      const filteredData = data.filter(item => !item.isDeleted && item.isWaiting && item.orderType === "offer" && item.isOfferApproved && item.isOffer );
      
    // console.log("filteredData",filteredData)
      const limitedData = filteredData.slice(0, 5); 
      setItemsLimited(limitedData)
      setItems(filteredData)
    };

    fetchData();
  }, []);


  const goPage = (record) => {
    navigate('/approvedofferopenorderList', { state: { record } })
  };

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Proje Kodu',
        dataIndex: 'projectCode',
        key: 'projectCode',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
      {
        title: 'Durum',
        dataIndex: '',
        key: '',
  
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
           { record.isChangedProgrammer ? "Atandı" : "Otomatik Atandı" }
          </span>
          
        )
      } ,
      {
        title: 'Programcı',
        dataIndex: 'programmerName',
        key: 'programmerName',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
        </span>,
      },
  ];

  return  <div className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
         Onaylanan Teklifler
       </Button>

       <Table
            locale={{
              emptyText: 'Henüz  onaylanan teklif bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      
     
    </div>

  };
  export default ApprovedOfferOpenOrderListLimited;

    