import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';

const InvoiceScrapsListLimited = () => {
    const [items, setItems] = useState([]);
    const [itemsLimited, setItemsLimited] = useState([]);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
        const data = await FirebaseService.getScrapsData();
        const filteredData = data.filter(item => !item.isDeleted);
      
        const sortedData = filteredData.sort((a, b) => {
          const isEqualA = a.invoiceNumber ? true : false;
          const isEqualB = b.invoiceNumber ? true : false;
      
          if (!isEqualA && isEqualB) return -1;
          if (isEqualA && !isEqualB) return 1;
          return 0;
        });
      
        setItemsLimited(sortedData);
        setItems(sortedData);
      };
      
  
      fetchData();
    }, []);
  
    const goPage = (record) => {
        navigate('/invoicescrapslist')
    };
  
    const columns = [
        {
          title: 'Firma',
          dataIndex: 'companyName',
          key: 'companyName',
          render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      },
      
       
        {
          title: 'Durum',
          dataIndex: '',
          key: '',
          render: (text, record) => {
            const isEqual = record.invoiceNumber ? true : false;
            return (
              <span style={{ color: isEqual ? colors.green : colors.red }}>
                {isEqual ? 'Kesilmiş' : 'Bekleyen'}
              </span>
            );
          },
        },
      
    ];
    
    return  <div style={{
        //  justifyContent: 'center',
        //  alignItems: 'center',
       //   minHeight: '94vh',
         
        //  width:"60%",
         

        }}
        className="limited-list-border">
        <Button onClick={()=>goPage(items)} className="title-limited-list">
        Hurda Faturaları
         </Button>
            <Table
              locale={{
                emptyText: 'Henüz hurda faturaları bulunmamaktadır...',
                filterReset: 'Sıfırla',
                filterTitle: 'Filtre Menüsü',
                selectAll: 'Hepsini Seç',
                selectInvert: 'Tersini Seç',
                selectionAll: 'Tümünü Seç',
                sortTitle: 'Sıralama',
                triggerDesc: 'Azalan sıralama için tıklayın',
                triggerAsc: 'Artan sıralama için tıklayın',
                cancelSort: 'Sıralamayı iptal etmek için tıklayın',
              }}
              dataSource={itemsLimited}
              columns={columns}
              showHeader={true}
              pagination={false} 
              className="custom-news-table"
              rowClassName="custom-news-row"
       
          />
      </div>
};
export default InvoiceScrapsListLimited;
