import React,{useEffect,useState} from "react";
import { Button, Form, Input, Select,Modal,Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import OneSignalService from "../../../services/oneSignalService";
import  { parsCollections,colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddRequestAdminDensities from "./addRequestAdminDensities";

 
const AddRequestAdminRaw = ({ onClose }) => {
  const navigate = useNavigate();
  const [qualities, setQualities] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [surfaces, setSurfaces] = useState([]); 

  useEffect(() => {
      const fetchData = async () => {
        const qualities = await FirebaseService.getDensitiesData();
        const filteredQualities = qualities.filter(item => !item.isDeleted);
        setQualities(filteredQualities);

        const surfaces = await FirebaseService.getSurfacesData();
        const filteredSurfaces = surfaces.filter(item => !item.isDeleted);
        setSurfaces(filteredSurfaces);
      };
      fetchData();
  }, []);
  const getOneSignalPlayerIds = async () => {
    try {
      const dataCollection = await FirebaseService.getUserData();
      const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userType.includes("headAdmin"));
      
      console.log("filteredUsers", filteredUsers);
      
      const oneSignalPlayerIds = [];
      filteredUsers.forEach(user => {
        if (user.oneSignalPlayerId) {
          oneSignalPlayerIds.push(user.oneSignalPlayerId);
        }
      });
      
      console.log("oneSignalPlayerIds", oneSignalPlayerIds);
      
      return oneSignalPlayerIds;
    } catch (error) {
      console.error("Error getting OneSignal Player IDs:", error);
      return [];
    }
  };

  const getOneSignalUserIds = async () => {
    try {
      const dataCollection = await FirebaseService.getUserData();
      const filteredUsers = dataCollection.filter(user => !user.isDeleted && user.userType.includes("headAdmin"));
      
      console.log("filteredUsers", filteredUsers);
      
      const oneSignalPlayerIds = [];
      filteredUsers.forEach(user => {
        console.log("user",user)
        if (user.key) {
         
          oneSignalPlayerIds.push(user.key);
        }
      });
      
      console.log("oneSignalPlayerIds", oneSignalPlayerIds);
      
      return oneSignalPlayerIds;
    } catch (error) {
      console.error("Error getting OneSignal Player IDs:", error);
      return [];
    }
  };


  const onFinish = async (values) => {
    setLoading(true)
      if (values.quality === "newQuality") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir kalite seçiniz.',
          placement: 'topRight'
        });
        setLoading(false)
        return;
      }

        const customValues = {
            width:  Number(values.width),
            height:  Number(values.height),
            quality: values.quality,
            thickness:  Number(values.thickness),
            surface: values.surface,
          };
          console.log('Custom Values:', customValues);

        const currentDate =new Date()
        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          firstUserName: localStorage.getItem("name"), 
          firstUserSurname: localStorage.getItem("surname"),
          isDeleted: false,
          tableName: parsCollections.allItems,
          description: values.description ? values.description : null,
          productType: "raw",
          productDictionary : customValues ,
          productString:  null,
        //  isVisible: true

        };
        
      try {
        const result = await FirebaseService.addRecording(parsCollections.addRequestsAdmin,data);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });setLoading(false)
           //bildirim 
           const ids = await getOneSignalPlayerIds();
           const userIds = await getOneSignalUserIds();

           const sentUserIdsMap = new Map();
           userIds.forEach(userId => {
             sentUserIdsMap.set(userId, false);
           });
           
           const sentUserIds = Object.fromEntries(sentUserIdsMap);
           //console.log("sentUserIds mapppp", sentUserIds);

           if (ids.length > 0) {
             const oneSignalResult = await OneSignalService.sendNotification("", "Talep Eklendi", ids);
             console.log("OneSignal notification result:", oneSignalResult);
             const data = {
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
              isDeleted: false,
              sentUserIds:sentUserIds,
              firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
              unitName:localStorage.getItem('userUnit'),
              title:"Talep Eklendi",
              companyName:null,
              jobCode:null,
              projectCode:null,
              machine:null,
              type:"Sac Levha Ekleme Talebi",
              notice:"Yeni bir sac levha ekleme talebi gelmiştir.",
              description:values.description ? values.description : null

            };
            const result = await FirebaseService.addRecording(parsCollections.notifications,data);
            console.log("result",result)
           } else {
             console.log("No OneSignal Player IDs found.");
           }
          onClose();
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });setLoading(false)}
        
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });setLoading(false)
      }
  }; 

  const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };
    
  
  const [isModalVisibleDensities, setIsModalVisibleDensities] = useState(false);

  const handleModalCancelDensities = () => {
          setIsModalVisibleDensities(false);
  };
    
  const handleAddFinishDensities = () => {
          setIsModalVisibleDensities(false);
  };

  const onChange = (value) => {
        if (value === 'newQuality') {
          //  console.log('Yeni kalite Ekle seçildi');
            setIsModalVisibleDensities(true);
        } 
      
        else{
          
        }
  };

   return ( <div>
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >


            <Form.Item
               label="Kalite"
            name="quality"
            rules={[
                {
                required: true,
                message: 'Lütfen kalite giriniz!',
                },
            ]}
            >
            <Select
                onChange={onChange}
                placeholder="Kalite Seçiniz"
             
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...qualities.map(item => ({ label: item.quality, value: item.quality })),
                  { label: 'Yeni Kalite Ekle', value: 'newQuality' },
                ]}
                className="input-style" 
             
            />
            </Form.Item>

           
            <Modal
                title="Kalite Ekleme Talebi"
                visible={isModalVisibleDensities}
                onCancel={handleModalCancelDensities}
                footer={null}
              >
              <AddRequestAdminDensities onClose={handleAddFinishDensities} />
              </Modal>
            
               
            <Form.Item
               label="Kalınlık"
                 name="thickness"
                 rules={[
                   {
                     required: true,
                     message: 'Lütfen kalınlık giriniz!',
                   },
                   {
                    pattern: /^\d+$/,
                    message: 'Sadece rakam girişi yapınız.',
                  },
                 ]}
               >
                 <Input  className="input-style" style={{width:"100%"}}  placeholder="Kalınlık Giriniz" />
            </Form.Item>
            
            <Form.Item
               label="Yüzey"
                 name="surface"
                 rules={[
                   {
                     required: true,
                     message: 'Lütfen Yüzey Giriniz!',
                   },
                 ]}
               >
              <Select
                onChange={onChange}
                placeholder="Yüzey Seçiniz"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...surfaces.map(item => ({ label: item.surface, value: item.surface })),
                //  { label: 'Yeni Firma Ekle', value: 'newCompany' },
                ]}
                className="input-style"     
              />           
            </Form.Item>
  
            <Form.Item
               label="En"
              name="width"
              rules={[
                {
                  required: true,
                  message: 'Lütfen en giriniz!',
                },
                {
                  pattern: /^\d+$/,
                  message: 'Sadece rakam girişi yapınız.',
                },
              ]}
            >
              <Input  className="input-style" style={{width:"100%"}} placeholder="En Giriniz" />
            </Form.Item>
            
            <Form.Item
               label="Boy"
                 name="height"
                 rules={[
                   {
                     required: true,
                     message: 'Lütfen boy giriniz!',
                   },
                   {
                    pattern: /^\d+$/,
                    message: 'Sadece rakam girişi yapınız.',
                  },
                 ]}
               >
                 <Input className="input-style" style={{width:"100%"}}  placeholder="Boy Giriniz" />
            </Form.Item>

            <Form.Item
            label="Açıklama"
            name="description"   
            >
           <Input className="input-style" style={{width:"100%"}}   placeholder="Açıklama Giriniz (zorunlu değildir) "/>
            </Form.Item>
            
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

AddRequestAdminRaw.propTypes = {
    onClose: PropTypes.func.isRequired, 
  };
  
export default AddRequestAdminRaw;