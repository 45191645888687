import React, { useEffect, useState } from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors } from "../../../environments/environment";
import moment from 'moment';
const BirthdayListLimited = () => {
    const [items, setItems] = useState([]);
    const [itemsLimited, setItemsLimited] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const data = await FirebaseService.getUserData();
                const filteredData = data.filter(item => item.birthday);
                  const formattedData = filteredData.map(item => {
                    const date = moment(item.birthday, 'DD-MM-YYYY'); // Mevcut tarih formatı
                    const formattedDate = date.format('DD-MM'); // Sadece gün ve ay
                    return { ...item, birthday: formattedDate };
                });
                // Doğum günlerini bugünden itibaren sıralama
                const today = moment().startOf('day');
                const sortedData = formattedData.sort((a, b) => {
                    const dateA = moment(a.birthday, 'DD-MM').year(today.year()); // Bu yıl
                    const dateB = moment(b.birthday, 'DD-MM').year(today.year()); // Bu yıl
                    // Tarihleri gün ve ay olarak karşılaştır, yıl dikkate alınmaz
                    if (dateA.isBefore(today, 'day')) dateA.add(1, 'year');
                    if (dateB.isBefore(today, 'day')) dateB.add(1, 'year');
                    return dateA - dateB;
                });
                // İlk 3 veriyi seçin
                const limitedData = sortedData.slice(0, 5);
                setItemsLimited(limitedData);
                setItems(sortedData);
            } catch (error) {
                console.error('Error fetching users data', error.message);
            }
        };
        fetchData();
    }, []);
    const goPage = () => {
        navigate('/birthdayList', { state: { record: items } });
    };
    const columns = [
        {
            title: "Kullanıcı Adı",
            dataIndex: '',
            key: '',
            width: 100,
            render: (text, record) => (
                <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                    {record.name} {record.surname}
                </span>
            ),
        },
        {
            title: "Doğum Günü",
            dataIndex: 'birthday',
            key: 'birthday',
            width: 100,
            render: (text, record) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        }
    ];
    return (
        <div style={{
            border: "2px solid #AFD3E7",
            borderRadius: "15px",
            height: "620px",
            padding: "10px",
        }}>
            <Button onClick={goPage} className="title-limited-list">
                Doğum Günleri
            </Button>
            <Table
                locale={{
                    emptyText: 'Henüz bir doğum günü eklenmedi...',
                    filterReset: 'Sıfırla',
                    filterTitle: 'Filtre Menüsü',
                    selectAll: 'Hepsini Seç',
                    selectInvert: 'Tersini Seç',
                    selectionAll: 'Tümünü Seç',
                    sortTitle: 'Sıralama',
                    triggerDesc: 'Azalan sıralama için tıklayın',
                    triggerAsc: 'Artan sıralama için tıklayın',
                    cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                }}
                dataSource={itemsLimited}
                columns={columns}
                showHeader={true}
                pagination={false}
                className="custom-news-table"
                rowClassName="custom-news-row"
            />
        </div>
    );
};
export default BirthdayListLimited;