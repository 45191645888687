import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Form, DatePicker, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors, currency, parsCollections,emailJs}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import StoregeService from "../../../../services/storegeService";
import NotificationService from "../../../../services/antNotificationService";
import XLSX from 'sheetjs-style'; 
import emailjs from '@emailjs/browser';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';
import ExportReportCardTemplate from "../Pars_Satis_Son2.xlsx";

import moment from 'moment';

const UnitPriceCustomerPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const itemsx= location.state && location.state.items;
  const record= location.state && location.state.record;
  const [items, setItems] = useState([]);
 
  const [showPriceInput, setShowPriceInput] = useState(false); 
  const [showDiscount, setShowDiscount] = useState(true); 

  const [currencyRate, setCurrencyRate] = useState(null); 
  const [discount, setDiscount] = useState(0); 

  const [selectedCurrency, setSelectedCurrency] = useState("TL"); 
  const [selectedCurrencyTemproray, setSelectedCurrencyTemporary] = useState("TL"); 

  const [isCurrencyChanged, setIsCurrencyChanged] = useState(false); 
  
  const [allTotalPrice, setAllTotalPrice] = useState(0);
  const [allTotalPriceKdv, setAllTotalPriceKdv] = useState(allTotalPrice);
  

  const serviceId = emailJs.serviceId;
  //const templateId = emailJs.templateIdPdf;
  const templateId = emailJs.templateIdSatisTeklif;

  const publicKey = emailJs.publicKey;

  const [companyInfo, setCompanyInfo] = useState(null);
  const [totalItem, setTotalItem] = useState(null);
  const [kdvRate, setKdvRate] = useState(20);

  const today = moment().startOf('day'); 


  //console.log("record",record)
  // console.log("itemsx",itemsx)

  
   useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      console.log("itemsx",itemsx)
        const updatedItems = itemsx.map(item => {
          const unitPrice = Number(calculateUnitPrice(item));
          const totalPrice = unitPrice * item.piece;
      
          return {
            ...item,
            unitPrice: unitPrice,
            totalPrice: totalPrice, 
          };
        });
      
       console.log("updatedItems", updatedItems);
        setItems(updatedItems);

        const allTotalPrice = updatedItems.reduce((acc, item) => acc + item.totalPrice, 0);
       // console.log("allTotalPrice", allTotalPrice);
        setAllTotalPrice(allTotalPrice.toFixed(2))

      

        const totalItem = {
          projectCode: record.projectCode,
          unitPrice:  Number (allTotalPrice.toFixed(2) / record.teamCount),
          teamCount: Number (record.teamCount),
          totalPrice: Number(allTotalPrice.toFixed(2)),
        };
  
        console.log("totalItem",totalItem)
        setTotalItem([totalItem]);

        const newTotalPrice = allTotalPrice  * (1 + kdvRate/100);
        setAllTotalPriceKdv(newTotalPrice.toFixed(2));



        const allCompanies = await FirebaseService.getCompaniesData();
        const companyInfo = allCompanies.find((entry) => entry.companyName.toLowerCase() === record.companyName.toLowerCase());
        console.log("companyInfo  ",companyInfo)
        setCompanyInfo(companyInfo);
        
      };
      
    fetchData();
  }, []);


  const disabledDate = (current) => {
    // Bugünden önceki tarihleri devre dışı bırak
    return current && current < today;
  }

  const calculateUnitPrice = (item) => {
    const priceMachine = parseFloat(item.priceMachine) || 0;
    const pricePlazma = parseFloat(item.pricePlazma) || 0;
    const priceQuality = parseFloat(item.priceQuality) || 0;
    const pricesAbkant = parseFloat(item.pricesAbkant) || 0;

    let totalOperationsPrice = 0.00;
    if (item.priceOperations) {
        Object.values(item.priceOperations).forEach(op => {
            const contractManufacturing = parseFloat(op.contractManufacturing) || 0;
            const profit = parseFloat(op.profit) || 0;
            totalOperationsPrice += contractManufacturing + profit;
        });
    }

    const totalUnitPrice = parseFloat(priceMachine + totalOperationsPrice + pricePlazma + priceQuality + pricesAbkant);

    return totalUnitPrice.toFixed(2); 
  };
  
  const columns = [
    {
      title: 'No',
      dataIndex: 'partNo',
      key: 'partNo',
      render: (text, record, index) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{index + 1}</span>,
    },
    {
      title: 'Parça No',
      dataIndex: 'partNo',
      key: 'partNo',    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
   
    {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
    
      },
      /*
      
      {
        title: 'Malzeme Fiyatı',
        dataIndex: 'priceQuality',
        key: 'priceQuality',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} {selectedCurrency}
          </span>
        ),
      },
      
      {
        title: 'Makine Fiyatı',
        dataIndex: 'priceMachine',
        key: 'priceMachine',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} {selectedCurrency}
          </span>
        ),
      },
    
      {
        title: 'Plazma Fiyatı',
        dataIndex: 'pricePlazma',
        key: 'pricePlazma',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text !== null && text !== undefined ? `${text.toFixed(2)} ${selectedCurrency}` : '-'}
          </span>
        ),
      },
      
      
      {
        title: 'Operasyonlar',
        dataIndex: 'operations',
        key: 'operations',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {record.priceOperations && Object.keys(record.priceOperations).length > 0 ? (
              Object.entries(record.priceOperations).map(([operation, price]) => (
                <div key={operation}>
                  {operation}: {price.toFixed(2)} {selectedCurrency}
                </div>
              ))
            ) : (
              '-'
            )}
          </span>
        ),
      },
      
      

      {
        title: 'Abkankt Fiyatı',
        dataIndex: 'pricesAbkant',
        key: 'pricesAbkant',
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text !== null && text !== undefined ? `${text.toFixed(2)} ${selectedCurrency}` : '-'}
          </span>        ),
      },
      */
      {
        title: 'Birim Fiyat',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (text, record) => (
            <span> {text && text.toFixed(2)} {selectedCurrency} </span>
        ),
      },
      {
        title: 'Toplam Fiyat',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: (text, record) => (
            <span> {text && text.toFixed(2)} {selectedCurrency} </span>
        ),
      },
      
      
      
  ];

  const handleKdvChange = (e) => {
    const { value } = e.target;
    const updatedKdvRate = value ? value : 0;
    setKdvRate(updatedKdvRate); // KDV oranını güncelleyin

    if (updatedKdvRate >= 0) {
        const newTotalPriceWithKdv = allTotalPrice * (1 + updatedKdvRate / 100);
        setAllTotalPriceKdv(newTotalPriceWithKdv.toFixed(2));
    } else {
        setAllTotalPriceKdv(allTotalPrice.toFixed(2));
    }
  };

  const handleDiscountChange = (e) => {
    const { value } = e.target;
    setDiscount(value);
  };

  const handleCurrentRateChange = (e) => {
    const { value } = e.target;
    setCurrencyRate(value);
  };

  const handleCurrency = () => {
    if (currencyRate && currencyRate !== 0) {
      const updatedItems = items.map(item => ({
        ...item,
        unitPrice: item.unitPrice / currencyRate,
        totalPrice: item.totalPrice / currencyRate,
      }));
  
      const newAllTotalPrice =  totalItem[0].totalPrice / currencyRate
      setAllTotalPrice(newAllTotalPrice);

      console.log("updatedItems", updatedItems);

      setItems(updatedItems);
      
      const updatedTotalItems = totalItem.map(item => ({
        ...item,
        unitPrice: item.unitPrice / currencyRate,
        totalPrice: item.totalPrice / currencyRate,
      }));

      setTotalItem(updatedTotalItems);
     
      const newTotalPriceKdv = newAllTotalPrice  * (1 + kdvRate/100);
      setAllTotalPriceKdv(newTotalPriceKdv.toFixed(2));
    

      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: "Girdiğiniz fiyat başarıyla uygulandı",
        placement: 'topRight'
      });
      setShowPriceInput(false);
      setIsCurrencyChanged(true);
      setSelectedCurrency(selectedCurrencyTemproray);

    }
    
  };

  const handleCurrencyChange = (value) => {
    setShowPriceInput(value === 'EUR' || value === 'USD');
    setSelectedCurrencyTemporary(value);
  };

  const handleDiscount = () => {
    if (discount && discount !== 0) {
      const discountRate = 1 - (discount / 100); 
      const newTotalPrice = totalItem[0].totalPrice * discountRate;
      setAllTotalPrice(newTotalPrice);

      // İskonto uygulandıktan sonra KDV'yi yeniden hesapla
      const newTotalPriceWithKdv = newTotalPrice * (1 + kdvRate / 100);
      setAllTotalPriceKdv(newTotalPriceWithKdv.toFixed(2));

      NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: 'İskonto başarıyla uygulandı ve KDV yeniden hesaplandı',
          placement: 'topRight'
      });

      setShowDiscount(false);
  }
  };
  

  const onFinish = async (values) => {
    //console.log("values burda",values)


     //mail atılcakkkkk
  //   await goMail();


    
    const currentDate = new Date();

    const data = {
      firstTransactionDate: currentDate,
      firstUserId: localStorage.getItem("uid"),
      firstUserName: localStorage.getItem("name"),
      firstUserSurname: localStorage.getItem("surname"),
      isDeleted: false,
      kdvPercent: values.kdvPercent ? Number(values.kdvPercent) : 0 ,
      currency: values.currency,
      discount:values.discount  ? Number(values.discount) : 0 ,
      deadline: new Date(values.deadline),
      jobCode: record.jobCode,
      projectCode:record.projectCode,
      paymentMethod: record.paymentMethod,
      abkantSelectedOperation:record.abkantSelectedOperation,
      companyName:record.companyName,
      pricesAbkant:record.pricesAbkant,
      pricesPlazma:record.pricesPlazma,
      pricesQuality:record.pricesQuality,
      pricesTime:record.pricesTime,
      programmerName:record.programmerName,
      quantitiesAbkant:record.quantitiesAbkant,
      setupTime:record.setupTime,
      totalKg:record.totalKg,
      totalKgs:record.totalKgs,
      totalPricesAbkant:record.totalPricesAbkant,
      totalPricesPlazma:record.totalPricesPlazma,
      totalPricesQuality:record.totalPricesQuality,
      totalPricesTime:record.totalPricesTime,
      items:items,
      allTotalPrice:  values.kdvPercent  ? allTotalPriceKdv : totalItem[0].totalPrice ,
      type:"offer"
     
    };

    console.log("Data", data);
   /*
    const result = await FirebaseService.addRecording(parsCollections.invoiceOpenOrder, data);
    if (result.success) {
      console.log("İşlem Başarılı:", result.message);
      
      const allOpenOrder = await FirebaseService.getOpenOrderData();
      const filteredOpenOrder = allOpenOrder.filter(item => (!item.isDeleted && item.isWaiting  && !item.isOfferApproved && item.orderType === "offer" && item.jobCode.includes(record.jobCode)) );
  
      console.log("filteredOpenOrder",filteredOpenOrder)
    //buraya kod eklencekkkkkk
    
      const updatePromises = filteredOpenOrder.map(async (item) => {
          let updateOpenOrderItem ;
          if(item.isOfferChange && item.isOffer){  //onaylanmadı teklif değişikliğine gitti diyelim tekrar teklif isterse 
            console.log("isOfferChange",item.isOfferChange)
            updateOpenOrderItem = {
              isOffer: true,
              isOfferChange:false

            };
          }
          else{  //teklif veriliyor ilk kez
            console.log("else isOfferChange",item.isOfferChange)

            updateOpenOrderItem = {
              isOffer: true,
              isOfferChange:false 
            };
          }


      

        console.log("updateOpenOrderItem", updateOpenOrderItem, item.key);

        const resultx = await FirebaseService.updateOpenOrder(item.key, updateOpenOrderItem);
        console.log(resultx);

        if (resultx) {
        console.log("başarılı")
        } else {
          console.log("başarısız");
        }

        return resultx;
        
      });

      const results = await Promise.all(updatePromises);

      if (results.every(result => result)) {
        NotificationService.openSuccessNotification({
          title: 'Tüm İşlemler Başarılı',
          description: "Teklif başarıyla gönderilmiştir.",
          placement: 'topRight',
        });
        
        setTimeout(() => {
          navigate("/salesmanpage");
        }, 2000);
        

        
      } else {
        NotificationService.openErrorNotification({
          title: 'Bazı İşlemler Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight',
        });
      }
      

    } else {
      NotificationService.openErrorNotification({
        title: 'Bazı İşlemler Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight',
      });
      return;
    }

    
    */
  
  }

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      fetch(file)
        .then((response) => response.blob())
        .then((file) => reader.readAsBinaryString(file));
    });
  };

  const goMail = async () => {
    //const mailTo = companyInfo.email;
    const mailTo = "sena.ozturk@appelier.net";

    console.log("mailtoooo", mailTo);
  
    const binaryString = await readFile(ExportReportCardTemplate);
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];


    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
  };

  let formattedDeadlineString = "N/A";
  if (record.deadline && record.deadline.length > 0) {
      const deadlineTimestamp = record.deadline[0];
      const formattedDeadline = new Date(deadlineTimestamp.seconds * 1000);
      if (!isNaN(formattedDeadline.getTime())) {
          formattedDeadlineString = formatDate(formattedDeadline);
      }
  }

    worksheet['I3'] = { t: 's', v: record.jobCode }; //proje kodu
    worksheet['I4'] = { t: 's', v: formattedDeadlineString};  //termin tarihi
    
    worksheet['H9'] = { t: 's', v: record.companyName};  //firma adı
    worksheet['H11'] = { t: 's', v: record.paymentMethod};  //ödeme vaadesi 
    worksheet['H12'] = { t: 's', v: record.deliveryMethod};  //teslim şekli
    worksheet['H13'] = { t: 's', v: selectedCurrency};  //para birimi
    worksheet['H14'] = { t: 's', v: companyInfo.vkn};  //vergi numarası
    worksheet['H15'] = { t: 's', v: companyInfo.address};  //adres

    
    worksheet['A27'] = { t: 's', v: record.description ? record.description : "" };  //önemli notlar
   
    const discountRate = 1 - (discount / 100); 
    const newTotalPrice =  totalItem[0].totalPrice * discountRate;


    worksheet['J20'] = { t: 's', v: parseFloat(newTotalPrice).toFixed(2)};  //tutar
    worksheet['J21'] = { t: 's', v: discount};  //iskonto
    worksheet['J22'] = { t: 's', v: parseFloat(allTotalPrice).toFixed(2)};  //toplam tutar
    worksheet['J23'] = { t: 's', v: kdvRate};  //kdv
    worksheet['J24'] = { t: 's', v: parseFloat(allTotalPriceKdv).toFixed(2)};  //genel toplam

    worksheet['F49'] = { t: 's', v: record.companyName};  //firma adı

/*
    worksheet['A17'] = { t: 's', v: record.projectCode};  //proje kodu
    worksheet['G17'] = { t: 's', v: record.teamCount};  //miktar 
    worksheet['H17'] = { t: 's', v: record.unit};  //birim 
    worksheet['I17'] = { t: 's', v: (newTotalPrice / record.teamCount).toFixed(2)};  //birim fiyat
    worksheet['J17'] = { t: 's', v: parseFloat(newTotalPrice).toFixed(2)};  //toplam fiyat
    */

    const startRow = 17;
  
    items.forEach((item, index) => {
      const row = startRow + index; 
      worksheet[`A${row}`] = { t: 's', v: item.partNo };  // partNo (proje kodu olarak kabul edelim)
      worksheet[`G${row}`] = { t: 's', v: item.piece };  // miktar 
      worksheet[`H${row}`] = { t: 's', v: item.unit };  // birim 
      worksheet[`I${row}`] = { t: 's', v: parseFloat(item.unitPrice).toFixed(2) };  // birim fiyat
      worksheet[`J${row}`] = { t: 's', v: parseFloat(item.totalPrice).toFixed(2) };  // toplam fiyat
    });
  
   // console.log(worksheet);
    
    //tasarım
    worksheet['A2'].s = {
      font: {
          name: 'Helvatica',
          sz: 18,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
      fill: {
        fgColor: { rgb: "D3D3D3" } 
      }
    }
    worksheet['G3'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['G4'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A9'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A11'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['A12'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A13'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A14'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A15'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F9'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F11'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F12'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F13'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F14'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['F15'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
/*
    worksheet['A26'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A34'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
    }

    worksheet['A49'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['F49'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['H20'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H21'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H22'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H23'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['H24'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['C9'].s = {
      alignment : {
        horizontal :"left",
        vertical: "top" ,
        wrapText: true
      },
    }
    worksheet['H9'].s = {
      alignment : {
        horizontal :"left",
        vertical: "top" ,
        wrapText: true
      },
    }
    worksheet['A27'].s = {
      alignment : {
        horizontal :"left",
        vertical: "top" ,
        wrapText: true
      },
    }

    worksheet['A16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['D16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['G16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['I16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['J16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['A50'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F50'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['A35'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['A36'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['A37'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['A38'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F35'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F36'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F37'].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet['F38'].s = {
      alignment : {
        horizontal :"center"
      },
    }
*/




    const updatedBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(updatedBinaryString)], { type: 'application/octet-stream' });


    let base64File;
    const reader = new FileReader();
  
    reader.onload = function (event) {
      base64File = event.target.result.split(',')[1];
  
      const templateParams = {
        user_email: mailTo,
        from_name: 'Pars Makine',
        message: 'webden gidiyor',
        file: base64File,
        projectCode :  record.projectCod
      };
  
  /*
      // EmailJS ile e-posta gönderme
      emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then(
          (response) => {
            console.log('Email sent successfully:', response);
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: "Mail başarıyla gönderildi",
              placement: 'topRight'
            });
          },
          (error) => {
            console.error('Failed to send email:', error);
          }
        );
*/
        
    };
  
    reader.onloadend = function () {
      console.log("Dosya yükleme işlemi tamamlandı.");
  };

  reader.readAsDataURL(blob);

  saveAs(blob, `Satis_Teklif_Sozlesmesi_${record.jobCode}.xlsx`);

  const folderName = 'openOrderOffers';
  const fileName = `Satis_Teklif_Sozlesmesi_${record.jobCode}.xlsx`;
  const sentExcelDocumentUrl = await StoregeService.uploadDocumentAndGetURL(folderName, blob, fileName);
 
  console.log('Belge başarıyla yüklendi. İndirme URL\'si:', sentExcelDocumentUrl);
    
  };
  
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ kdvPercent: 20 });
  }, [form]);



  function splitTextIntoRows(text, maxRows) {
    const words = text.split(" ");
    const rows = [];
    let currentLine = "";

    words.forEach(word => {
        // If adding the next word exceeds 50 characters, start a new line
        if ((currentLine + word).length > 50) {
            rows.push(currentLine.trim());
            currentLine = word + " ";
        } else {
            currentLine += word + " ";
        }
    });

    // Push the last line if it has content
    if (currentLine.trim()) {
        rows.push(currentLine.trim());
    }

    // Ensure the rows array has exactly `maxRows` elements by adding empty strings if necessary
    while (rows.length < maxRows) {
        rows.push("");
    }

    return rows.slice(0, maxRows); // Return exactly maxRows lines
}

  const downloadExcel = async () => {
    const binaryString = await readFile(ExportReportCardTemplate);
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];


    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    let formattedDeadlineString = "N/A";
    if (record.deadline && record.deadline.length > 0) {
        const deadlineTimestamp = record.deadline[0];
        const formattedDeadline = new Date(deadlineTimestamp.seconds * 1000);
        if (!isNaN(formattedDeadline.getTime())) {
            formattedDeadlineString = formatDate(formattedDeadline);
        }
    }

    // Add basic data to worksheet
    worksheet['I3'] = { t: 's', v: record.jobCode }; //proje kodu
    worksheet['I4'] = { t: 's', v: formattedDeadlineString};  //termin tarihi
    worksheet['H9'] = { t: 's', v: record.companyName};  //firma adı
    worksheet['H11'] = { t: 's', v: record.paymentMethod};  //ödeme vaadesi 
    worksheet['H12'] = { t: 's', v: record.deliveryMethod};  //teslim şekli
    worksheet['H13'] = { t: 's', v: selectedCurrency};  //para birimi
    worksheet['H14'] = { t: 's', v: companyInfo.vkn};  //vergi numarası
    worksheet['H15'] = { t: 's', v: companyInfo.address};  //adres
    worksheet['A27'] = { t: 's', v: record.description ? record.description : "" };  //önemli notlar
    

   
    worksheet['A2'].s = {
      font: {
          name: 'Helvatica',
          sz: 18,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
      fill: {
        fgColor: { rgb: "D3D3D3" } 
      }
    }
    worksheet['G3'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['G4'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A9'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A11'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['A12'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A13'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A14'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['A15'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F9'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F11'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F12'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F13'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['F14'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['F15'].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    // Calculate prices
    const discountRate = 1 - (discount / 100); 
    const newTotalPrice = totalItem[0].totalPrice * discountRate;

    worksheet['J20'] = { t: 's', v: parseFloat(newTotalPrice).toFixed(2)};  //tutar
    worksheet['J21'] = { t: 's', v: discount};  //iskonto
    worksheet['J22'] = { t: 's', v: parseFloat(allTotalPrice).toFixed(2)};  //toplam tutar
    worksheet['J23'] = { t: 's', v: kdvRate};  //kdv
    worksheet['J24'] = { t: 's', v: parseFloat(allTotalPriceKdv).toFixed(2)};  //genel toplam


    worksheet['A16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet['D16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['G16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['H16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['I16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet['J16'].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
   
    worksheet['A27'].s = {
      alignment : {
        horizontal :"left",
        vertical: "top" ,
        wrapText: true
      },
    }


    // Start row for item list
    const startRow = 17;
    items.forEach((item, index) => {
        const row = startRow + index; 
        worksheet[`A${row}`] = { t: 's', v: item.partNo };  // partNo (proje kodu)
        worksheet[`G${row}`] = { t: 's', v: item.piece };  // miktar 
        worksheet[`H${row}`] = { t: 's', v: item.unit };  // birim 
        worksheet[`I${row}`] = { t: 's', v: parseFloat(item.unitPrice).toFixed(2) };  // birim fiyat
        worksheet[`J${row}`] = { t: 's', v: parseFloat(item.totalPrice).toFixed(2) };  // toplam fiyat
    });

    const endRow = startRow + items.length; 
   
    // Başlıkları H sütununa ekleme
    worksheet[`H${endRow}`] = { t: 's', v: 'Tutar:' };
    worksheet[`H${endRow + 1}`] = { t: 's', v: 'İskonto %:' };
    worksheet[`H${endRow + 2}`] = { t: 's', v: 'Toplam Tutar:' };
    worksheet[`H${endRow + 3}`] = { t: 's', v: 'KDV:' };
    worksheet[`H${endRow + 4}`] = { t: 's', v: 'Genel Toplam:' };


    worksheet[`H${endRow}`].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet[`H${endRow + 1}`].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet[`H${endRow + 2}`].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
    worksheet[`H${endRow + 3}`].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet[`H${endRow + 4}`].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    // Bilgileri J sütununa ekleme
    worksheet[`J${endRow}`] = { t: 's', v: parseFloat(newTotalPrice).toFixed(2) };  // Tutar
    worksheet[`J${endRow + 1}`] = { t: 's', v: discount };  // İskonto
    worksheet[`J${endRow + 2}`] = { t: 's', v: parseFloat(allTotalPrice).toFixed(2) };  // Toplam Tutar
    worksheet[`J${endRow + 3}`] = { t: 's', v: kdvRate };  // KDV
    worksheet[`J${endRow + 4}`] = { t: 's', v: parseFloat(allTotalPriceKdv).toFixed(2) };  // Genel Toplam

    const headerRow = endRow + 7; //önemli notlar
    worksheet[`A${headerRow}`] = { t: 's', v: 'Önemli Notlar' };
    worksheet[`A${headerRow}`].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
      alignment : {
        horizontal :"left"
      },
    }
    const importantNote = record.description || "";
    const importantNoteRows = splitTextIntoRows(importantNote, 8);
    importantNoteRows.forEach((line, index) => {
        worksheet[`A${endRow + 8 + index}`] = { t: 's', v: line };
    });

    worksheet[`A${endRow + 14}`] = { t: 's', v: 'Banka / İban Bilgileri' };
    worksheet[`A${endRow + 14}`].s = {
      font: {
          name: 'Helvatica',
          sz: 12,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"center"
      },
    }

    // Insert Bank details
    worksheet[`A${endRow + 15}`] = { t: 's', v: "VAKIFBANK" };
    worksheet[`A${endRow + 16}`] = { t: 's', v: "TR31 0001 5001 5800 7305 8984 43" };
    
    worksheet[`F${endRow + 15}`] = { t: 's', v: "HALKBANK" };
    worksheet[`F${endRow + 16}`] = { t: 's', v: "TR54 0001 2001 3190 0100 1009 99" };
    
    worksheet[`A${endRow + 17}`] = { t: 's', v: "İŞ BANKASI" };
    worksheet[`A${endRow + 18}`] = { t: 's', v: "TR75 0006 4000 0013 3850 1286 72" };
    
    worksheet[`F${endRow + 17}`] = { t: 's', v: "ZİRAAT BANKASI" };
    worksheet[`F${endRow + 18}`] = { t: 's', v: "TR08 0001 0001 4590 3870 3650 01" };

    worksheet[`A${endRow + 15}`].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet[`A${endRow + 16}`].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet[`A${endRow + 17}`].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet[`A${endRow + 18}`].s = {
      alignment : {
        horizontal :"center"
      },
    }

    worksheet[`F${endRow + 15}`].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet[`F${endRow + 16}`].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet[`F${endRow + 17}`].s = {
      alignment : {
        horizontal :"center"
      },
    }
    worksheet[`F${endRow + 18}`].s = {
      alignment : {
        horizontal :"center"
      },
    }




// Terimleri tanımlama
const termsLeft = [
  "* Vadeler sipariş tarihinden itibaren geçerlidir.",
  "* Onayı gelmeyen teklifler geçersizdir.",
  "* İrsaliye ve faturada firmamız kantarı geçerlidir.",
  "* Siparişten sonra Ölçü & Fiyat değiştirilemez.",
  "* Dövizli satışlarımızda banka serbest piyasa kuru geçerlidir.",
  "* Geciken ödemeler için Aylık %6 Vade Farkı uygulanır.",
  "* Çek ve peşin satışlarımızda siparişte tahsilat yapılır."
];

const termsRight = [
  "* Ödemesi yapılmayan tekliflerde Fiyat ve Stok değişebilir.",
  "* Sipariş Formunda yapılacak tüm değişikliklerin yazılı olması zorunludur.",
  "* Satış koşullarımız DBS,ÇEK ve BTM karşılığı yapılmaktadır.",
  "* Teklif içeriği ve ürün fiyatları TİCARİ SIR NİTELİĞİNDE OLUP, gizli bilgidir."
];

const startRow2 = endRow + 21;

// Terimleri ekleme ve birleştirme
termsLeft.forEach((term, index) => {
  const row = startRow2 + index;
  worksheet[`A${row}`] = { t: 's', v: term };
});

termsRight.forEach((term, index) => {
  const row = startRow2 + index;
  worksheet[`F${row}`] = { t: 's', v: term };
});

    // Add Signature fields at the bottom
    worksheet[`A${endRow + 28}`] = { t: 's', v:  "PARS ENDÜSTRİYEL MAK. VE MÜH. SAN. VE TİC. A.Ş."};
    worksheet[`F${endRow + 28}`] = { t: 's', v:  record.companyName};
    worksheet[`A${endRow + 29}`] = { t: 's', v: "Kaşe İmza" };
    worksheet[`F${endRow + 29}`] = { t: 's', v: "Kaşe İmza" };
   
  


    worksheet[`A${endRow + 28}`].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }

    worksheet[`F${endRow + 28}`].s = {
      font: {
          name: 'Helvatica',
          sz: 10,
          bold: true,
          color: "#000000"
      },
      alignment : {
        horizontal :"left"
      },
    }
/*
    const existingMerges = worksheet['!merges'] ? [...worksheet['!merges']] : [];
   

    const newMerges = [
      { s: { r: endRow + 13, c: 0 }, e: { r: endRow + 13, c: 9 } },
    //  { s: { r: endRow + 28, c: 0 }, e: { r: endRow + 28, c: 4 } },
    //  { s: { r: endRow + 28, c: 5 }, e: { r: endRow + 28, c: 9 } },
    //  { s: { r: 1, c: 0 }, e: { r:  1, c: 9 } }
    ];
    
    const allMerges = [...existingMerges, ...newMerges];
    
    worksheet['!merges'] = allMerges;
*/
    // Save the updated workbook
    const updatedBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(updatedBinaryString)], { type: 'application/octet-stream' });

    let base64File;
    const reader = new FileReader();

    reader.onload = function (event) {
        base64File = event.target.result.split(',')[1];
    };

    reader.onloadend = function () {
        console.log("Dosya yükleme işlemi tamamlandı.");
    };

    reader.readAsDataURL(blob);

    saveAs(blob, `Satis_Teklif_Sozlesmesi_${record.jobCode}.xlsx`);
};




  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>

        <Form
         form={form}
            name="basic"
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
        >

        <h3 style={{textAlign:"left", color:colors.blue}}>Birim Fiyat Müşteri Dökümanı</h3>

        <div style={{display:"flex", justifyContent: "space-between"}}>
          <div style={{ textAlign: "left", }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}> İskonto </p>
          <Form.Item name="discount" 
          
          onChange={handleDiscountChange} 
          
          >
             <Input    className="input-style"     style={{ width:"100px" }} placeholder="%" />
           </Form.Item>  
           
           <Button 
              onClick={handleDiscount} 
              disabled={showDiscount ? false: true }
              style={{float:"right",color:"white" ,fontWeight:"500",  
              backgroundColor: showDiscount ? colors.blue  :  "gray" 
               }} 
               >
               Uygula
             </Button>
          </div>

          <div style={{ textAlign: "left",  }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Ödeme Şekli</p>
            {record && record.paymentMethod}
          </div>

          <div style={{ textAlign: "left",  }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}> Para Birimi </p>
            <Form.Item
            name="currency"
            rules={[
              {
                required: true,
                message: 'Lütfen seçiniz!',
              },
            ]}
          >
            <Select
              onChange={isCurrencyChanged ?   null : handleCurrencyChange}
              placeholder="Para Birimi Seçiniz"
              style={{ width:"200px" }}
              suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
              options={currency.map(item => ({ label: item.label, value: item.value }))}
              className="input-style"     
            />
              </Form.Item>

          </div>

          <div style={{ textAlign: "left",}}>
          {showPriceInput && (
             <div> 
            <p style={{ fontWeight: "bold", color: colors.blue }}>Para Birimi için Fiyat </p>

            <Form.Item
              name="currencyRate"
              rules={[
                {
                  required: true,
                  message: 'Lütfen fiyatı giriniz!',
                },
              ]}
              onChange={handleCurrentRateChange} 
            >
              <Input    className="input-style"      type="number" placeholder="Fiyatı Giriniz" style={{ width: '200px' }} />
            </Form.Item>

            <Button 
              onClick={handleCurrency} 
              style={{float:"right",color:"white" ,fontWeight:"500",  backgroundColor: colors.blue }} >
               Uygula
             </Button>
            </div>
          )}
          </div>
        
        </div>
        
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
          {record && record.projectCode}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Teklif Veren</p>
          {localStorage.getItem('name') + " " + localStorage.getItem('surname')}
        </div>


     
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
        <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
        <Form.Item 
        name="deadline"
         rules={[
          {
            required: true,
            message: 'Lütfen tarih giriniz!',
          },
        ]} >
            <DatePicker   className="input-style"  style={{ width: '100%' }} placeholder="Teslim Tarihi" format="DD-MM-YYYY" disabledDate={disabledDate}/>
         </Form.Item>
        </div>

        </div>
        

        <Table
            locale={{
              emptyText: 'Henüz bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

        <div style={{ display:"flex"  }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Toplam Fiyat</p>
            {parseFloat(allTotalPrice).toFixed(2)} {selectedCurrency}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}> Kdv % </p>
          <Form.Item name="kdvPercent"
           rules={[
            {
              required: true,
              message: 'Lütfen Kdv giriniz!',
            }
          ]} 
          onChange={handleKdvChange} 

          >
             <Input    className="input-style"     style={{ width:"200px" }} placeholder="" />
           </Form.Item>  
        </div>
        

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Kdvli Fiyat</p>
         {allTotalPriceKdv} {selectedCurrency}
        </div>
        </div>
        
        
        <div style={{float:"right"}}>
        <Form.Item>
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Pdf Oluştur ve Gönder</Button>
            </Form.Item>

        </div>

        <div style={{float:"right"}}>
        <Form.Item>
            <Button onClick={downloadExcel} style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Excel İndir</Button>
            </Form.Item>

        </div>

       

        </Form>
    </div>

  };
  export default UnitPriceCustomerPage;
 