import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';


const ArchieveOpenOrderListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);


  const navigate = useNavigate();

  const fetchData = async () => {
    const data = await FirebaseService.getOpenOrderData();
  console.log("data",data);
    
  const filteredData = data.filter(item => !item.isDeleted && !item.isWaiting && item.isSetup && item.orderType === "order");

  const processedData = filteredData.map(item => {
      let statusText = "Bilinmiyor";
      const combinedList = [
        ...item.stock,
        ...(Array.isArray(item.wastage) ? item.wastage : [])
      ];
          //  console.log("combinedList",combinedList)
      const allQrScansFalse = combinedList.every(item => !item.isQrScan);
    //  console.log("allQrScansFalse",allQrScansFalse)
      const allQrScansTrue = combinedList.every(item => item.isQrScan);
    //  console.log("allQrScansTrue",allQrScansTrue)
      const anyQrScanTrue = combinedList.some(item => item.isQrScan);
    //  console.log("anyQrScanTrue",anyQrScanTrue)

      if (allQrScansFalse) {
          statusText = "Depoda";
      } else if (allQrScansTrue) {
          statusText = "Depodan Çıktı";
      } else  {
          statusText = "Kısmı Çıkış";
      }
  
      return { ...item, statusText };
  });
  


    
    const processedfilterData = processedData.filter(item => {
      return (
        !item.isDeleted &&
        !item.isWaiting &&
        item.isSetup &&
        item.orderType === "order"
      
      );
    });
  
    const limitedData = processedfilterData.slice(0, 5);
    setItemsLimited(limitedData);
    setItems(processedfilterData);
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  
  const goEditPage = (record) => {
    navigate('/openorderdetail', { state: { record } })

   // navigate('/archieveopenorderdetail', { state: { record } })
  };


  const goPage = (record) => {
    navigate('/archieveopenorderlist', { state: { record } })
  };

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Durum',
      dataIndex: 'statusText',
      key: 'statusText',
      render: (text, record) => 
      {
        let statusColor = "#000000";
        let statusText = "Unknown";
  
        return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
      },
      sorter: (a, b) => {
        const itemA = a && a.statusText ? a.statusText : '';
        const itemB = b && b.statusText ? b.statusText : '';
        return itemA.localeCompare(itemB);
      },
      
    },
    {
      title: ' ',
      dataIndex: 'important',
      key: 'important',
      render: (text, record) => {
        const showImage = record.stock && record.stock.some(stockItem => stockItem.isSendPurchase === true);
    
        return showImage ? (
          <img
            width={30}
            height={20}
            alt="logo"
            src="/images/important.png"
            style={{ paddingBottom: "5%" }}
          />
        ) : null;
      },
    },

    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      }
  ];

  return  <div style={{  
       // width:"50%",
       
      }}
      className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
      Depo Hazırlanacak Sac Listesi
       </Button>

       <Table
            locale={{
              emptyText: 'Henüz depo açık sipariş bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      
     
    </div>

  };
  export default ArchieveOpenOrderListLimited;

    