import React,{useEffect,useState} from "react";
import { Table, Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import NotificationService from "../../services/antNotificationService";

const ContractManufacturingProcessList = () => {
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getContractManufacturingProcessesData();
      const filteredData = data.filter(item => !item.isDeleted);
      const sortedData = filteredData.sort((a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate));
      setItems(sortedData);
    
    };
    fetchData();
  }, []);

  const goAddPage = () => {
    navigate('/contractmanufacturingprocessadd')
  };

 
  const handleDelete = async (record, isDeleted) => {
    setLoading(true)
    try {
      let result;
      if (isDeleted) {
        result = await FirebaseService.makeContractManufacturingProcessVisible(record.key);
      } else {
        result = await FirebaseService.deleteContractManufacturingProcess(record.key);
      }
  
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        setLoading(false)
        const data = await FirebaseService.getContractManufacturingProcessesData();
        const filteredData = data.filter(item => !item.isDeleted);
        setItems(filteredData);
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false)
      }
      
    } catch (error) {
     // console.log(error);
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
    }
    setLoading(false)
  };  
  
  
  const columns = [
   
    {
      title: 'Proses',
      dataIndex: 'processName',
      key: 'processName',
      render: (text,record) => <span style={{fontWeight:"bold",color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      width: 400,
    },
   
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      render: (text, record) => (
        
          record.isDeleted ?    
           <Button loading={loading} style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,true)}>Göster</Button>
          :
          <Button loading={loading} style={{backgroundColor:"#1A446C",color:"white",borderRadius:0}} onClick={() => handleDelete(record,false)}>Sil</Button>
      ),
    },
  ];
  
  return  (
  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%"
      }}>
        <Button onClick={()=>goAddPage()} icon={<img alt="plus" src="/images/plusbutton.png" style={{width:"60%"}}/>} className='admin-add-button'>
          Yeni Ekle
        </Button>
          <Table
            locale={{
              emptyText: 'Henüz proses eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}


    </div>

   )};

  export default ContractManufacturingProcessList;