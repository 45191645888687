import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import AlarmAdd from "./alarmAdd";


const AlarmListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        const data = await FirebaseService.getAlarmsData();
        const filteredData = data.filter(item => !item.isDeleted);
       
        filteredData.sort((a, b) => new Date(b.firstTransactionDateEdit) - new Date(a.firstTransactionDateEdit));
        const limitedData = filteredData.slice(0, 3);
    
        setItemsLimited(limitedData);
        setItems(filteredData);
      } catch (error) {
        console.error('Error fetching alarms data:', error.message);
      }
    };
    
    

    fetchData();
  }, []);

  const goEditPage = (record) => {
    navigate('/alarmdetail', { state: { record } })
  };


  const goPage = (record) => {
    navigate('/alarmlist', { state: { record } })
  };

  const [isModalVisibleAlarm, setIsModalVisibleAlarm] = useState(false);

  const handleAlarmAdd = () => {
    setIsModalVisibleAlarm(true);
  };

  const handleModalCancelAlarm = () => {
    setIsModalVisibleAlarm(false);
  };

  const handleAlarmAddFinish = () => {
    setIsModalVisibleAlarm(false); 
  };  


  const columns = [
    {
       
        dataIndex: '',
        key: '',
        width: 300,
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            <div style={{ color: "red" }}>{record.title}</div>
            <div>{record.description}</div>
          </span>
        ),
      },
      
    {
        
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:"white" ,color:"red",fontWeight:"500",border:"none"}}>
          Detayı Gör
       </Button>
        ),
      }
  ];

  return  <div style={{
      //  justifyContent: 'center',
      //  alignItems: 'center',
     //   minHeight: '94vh',
       
      //  width:"50%",
        border:"2px solid #AFD3E7", 
        borderRadius:"15px",
        height:"410px"
      }}>
      <Button onClick={()=>goPage(items)} className="title-limited-list">
         Alarmlar
       </Button>

       <Button  style={{background:colors.blue, color:"white",fontWeight:"500",  border: `2px solid ${colors.blue}`,  display: "flex", alignItems: "center", float:"right",margin:"10px", padding:"3px" }}  onClick={handleAlarmAdd}>
            <span style={{ marginRight: "8px",  }}>Sorun Bildir</span>
            <img alt="plus" src="/images/upload-plus-white.svg"  style={{ width: "10px", height: "10px" }} />
        </Button>


      <Modal
        title="Alarm Ekle"
        visible={isModalVisibleAlarm}
        onCancel={handleModalCancelAlarm}
        footer={null}
      >
        <AlarmAdd  onClose={handleAlarmAddFinish} />
      </Modal> 
      <Table
            locale={{
              emptyText: 'Henüz alarm eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={false}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default AlarmListLimited;

    