import React,{useEffect,useState} from "react";
import { Button, Form, Input,Table ,Modal,Tooltip} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import  {colors}  from "../../../environments/environment"


const OpenOrderDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const record = location.state && location.state.record;
    console.log("record",record);
    const qrScanItemCountStock = record.stock?.filter(item => item.isQrScan).length || 0;
    const totalItemCountStock = record.stock?.length || 0;
    
    const qrScanItemCountWastage = record.wastage?.filter(item => item.isQrScan).length || 0;
    const totalItemCountWastage = record.wastage?.length || 0;
    
    
     const [selectedItemsDetails, setSelectedItemsDetails] = useState([])
   
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    const handleRequestRemoval = (record) => {
      setRecordForModal(record);
      setIsModalVisible(true);
  };
  
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleRequestRemovalFinish = () => {
      setIsModalVisible(false); 
    };
  

  useEffect(() => {
    const fetchData = async () => {
    
      
    };
  
    fetchData();
  }, [record.requestedProductInfo]);
  
  const getProductDetailById = async (productId) => {
    try {
      const productDetail = await FirebaseService.getRequestedProductDataById(productId);
      return productDetail;
    } catch (error) {
      console.error('Hata:', error);
      return null;
    }
  };
  
  const goPage = (record) => {
    navigate('/purchaserequestsendmail', { state: {selectedItemsDetails, record } })
  };
   
  const columnsRaw = [
    {
      title: 'Kalite',
      dataIndex: 'quality',
      key: 'quality',
      width: 200,
      render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
    },

      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },

     
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },

      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        width: 200,
        render: (text,record) => <span style={{ color: record.isQrScan ? '#84BB4C' : '#000000' }}>{text}</span>,
      },
 
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text,record) => <span style={{color: record.isQrScan ? "#84BB4C" : "#000000"}}>{text}</span>,
        width: 200,
      },
      {
      title : ' ',
      dataIndex : 'isSendPurchaseicon',
      key : 'isSendPurchaseicon',

render: (text, record) => {
    return (
      <>
        {record.isSendPurchase ? (
          <Tooltip title="Satın Alma Talebi Gönderildi">
            <div >
              <img width={20} height={20} alt="logo" src="/images/isSendPurchaseicon.png" style={{ paddingBottom: "5%" }} />
            </div>
          </Tooltip>
        ) : null
         
      }
      </>
    );
  },
  
      
        
    
        
           
    },
    
    ];

   
   return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
  <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Açık Sipariş Detay</h2>
          
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Firma</p>
          {record && record.companyName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>İş Kodu</p>
          {record && record.jobCode}
        </div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
  <p style={{ fontWeight: "bold", color: colors.blue }}>Durum</p>
  {
    (() => {
      let statusColor = "#000000"; // Varsayılan renk
      let currentStatus = "Başlamadı"; // Varsayılan durum

      // Lazer durumu
      let allLaserFinish = false;
      let halfLaserFinish = false;

      // record.plates'in var olup olmadığını kontrol et
      if (record.plates && Array.isArray(record.plates)) {
        allLaserFinish = record.plates.every(plate => plate.isFinish);
        halfLaserFinish = record.plates.some(plate => plate.isFinish) && !allLaserFinish;
      }

      if (halfLaserFinish) {
        currentStatus = "Lazerde";
      }
      if (allLaserFinish) {
        currentStatus = "Lazer bitti";
      }

      // Operasyonları kontrol etme
      const operationSet = new Set();
      let otherOperationsOngoing = [];

      if (record.parts?.length > 0) {
        record.parts.forEach(part => {
          part.operations.forEach(operation => {
            if (!operationSet.has(operation)) {
              operationSet.add(operation);

              if (!operation.isFinish) {
                otherOperationsOngoing.push(operation);
              }
            }
          });
        });
      }

      // Eğer lazer tamamlandıysa ve abkant işlemi devam ediyorsa
      if (allLaserFinish && otherOperationsOngoing.some(op => op === "Abkant")) {
        currentStatus = "Abkantta";
      }

      // Eğer lazer ve abkant tamamlandıysa ve başka devam eden işlemler varsa, onları yazdır
      if (allLaserFinish && otherOperationsOngoing.length > 0 && !otherOperationsOngoing.includes("Abkant")) {
        currentStatus = `${otherOperationsOngoing[0]} işleminde`; // İlk devam eden işlemi göster
      }

      return (
        <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>
          {currentStatus}
        </span>
      );
    })()
  }
</div>


        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
            <p style={{ fontWeight: "bold", color: colors.blue }}>Programcı Adı</p>
            {record && record.programmerName}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 16.66%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
          {record && record.deadline && typeof record.deadline === 'object' ?
            new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') :
            record.deadline
          }
        </div>
      </div>

     <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",width:"70%" }}>Gerekli Hammadeler ({qrScanItemCountStock} okutuldu / {totalItemCountStock} toplam) </div>
      <Table
      style={{width:"70%", margin:"auto", border:"2px solid", borderColor:colors.blue, borderRadius:"10px"}}
            locale={{
              emptyText: 'Seçilen hammade bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={record.stock}
           columns={columnsRaw}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />


<div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",width:"70%" }}>Gerekli Fireler ({qrScanItemCountWastage} okutuldu / {totalItemCountWastage} toplam) </div>
      <Table
      style={{width:"70%", margin:"auto", border:"2px solid", borderColor:colors.blue, borderRadius:"10px"}}
      locale={{
        emptyText: 'Seçilen fire bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
           dataSource={record.wastage}
           columns={columnsRaw}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>


  )};
export default OpenOrderDetail;