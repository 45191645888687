import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, DatePicker, message, Spin } from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const ContractManufacturingEditList = ({ record, onClose }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dateFormat = 'DD/MM/YYYY';
    const [formValues, setFormValues] = useState({
        supplierName: '',
        unitPrice: '',
        profit: '',
        deadline: null,
    });

    useEffect(() => {
        if (record) {
            setFormValues({
                supplierName: record.supplierName || '',
                unitPrice: record.unitPrice || '',
                profit: record.profit || '',
                deadline: record.deadline ? dayjs(new Date(record.deadline.seconds * 1000)).format(dateFormat) : null,
            });
        }
    }, [record]);

    const onFinish = async (values) => {
        setLoading(true);
        
        if (!values.deadline) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Termin tarihi boş olamaz.",
                placement: 'topRight'
            });
            setLoading(false);
            return;
        }

        try {
            // Firebase güncelleme işlemi
            const data = {
                supplierName: values.supplierName,
                unitPrice: values.unitPrice,
                profit: values.profit,
                deadline: new Date(values.deadline),
            };

            const result = await FirebaseService.updateRecording('contractManufacturing', record.key, data);
            if (result.success) {
                NotificationService.openSuccessNotification({
                    title: 'İşlem Başarılı',
                    description: "Başarıyla güncellendi",
                    placement: 'topRight'
                });
                setLoading(false);
                onClose();
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                NotificationService.openErrorNotification({
                    title: 'İşlem Başarısız',
                    description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                    placement: 'topRight'
                });
                setLoading(false);
            }
        } catch (error) {
            console.log('Güncelleme Hatası:', error);
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Bilinmeyen bir hata ile karşılaşıldı.",
                placement: 'topRight'
            });
            setLoading(false);
        }
    };

    return (
        <div>
            <Form
                name="editList"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 5 }}
                style={{ maxWidth: "100%", padding: "5% 0 0 0%", textAlign: "left" }}
                initialValues={formValues}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="supplierName"
                    label="Tedarikçi"
                    rules={[{ required: true, message: 'Lütfen tedarikçi giriniz!' }]}
                >
                    <Select
                        className="input-style"
                        placeholder="Tedarikçi Seçiniz"
                        style={{ width: "200px", marginLeft: "85px" }}
                        options={[{ label: 'Tedarikçi 1', value: 'supplier1' }, { label: 'Tedarikçi 2', value: 'supplier2' }]} // Bu kısmı dinamik hale getirebilirsiniz
                    />
                </Form.Item>

                <Form.Item
                    name="unitPrice"
                    label="Fason Fiyatı"
                    rules={[{ required: true, message: 'Lütfen fason fiyatı giriniz!' }]}
                >
                    <Input placeholder="Fason Fiyatı" style={{ width: "200px", marginLeft: "85px" }} />
                </Form.Item>

                <Form.Item
                    name="profit"
                    label="Kar Marjı"
                    rules={[{ required: true, message: 'Lütfen kar marjı giriniz!' }]}
                >
                    <Input placeholder="Kar Marjı" style={{ width: "200px", marginLeft: "85px" }} />
                </Form.Item>

                <Form.Item
                    name="deadline"
                    label="Termin Tarihi"
                    rules={[{ required: true, message: 'Lütfen termin tarihi giriniz!' }]}
                >
                    <DatePicker style={{ width: '200px', marginLeft: "85px" }} format={dateFormat} />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 5, span: 18 }}>
                    <Button htmlType="submit" style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500" }}>Güncelle</Button>
                </Form.Item>
            </Form>

            {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin />
                </div>
            )}
        </div>
    );
};

ContractManufacturingEditList.propTypes = {
    onClose: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
};

export default ContractManufacturingEditList;
