import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space,Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, placeholderMap } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import NotificationService from "../../../services/antNotificationService";

const FaultList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getFaultData();
      //const filteredData = data.filter(item => !item.isDeleted);
      console.log("data", data)
      setItems(data);
      setFilteredItems(data);
    };

    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
       
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };



  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 ,background:colors.blue}}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const handleUpdate = async (record) => {
    setLoading(true)
    const currentDate =new Date()
    const result = await FirebaseService.updateFixedFault(record.key,currentDate,localStorage.getItem("uid"));
    console.log(result);
    if(result.success){
      NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: "Başarıyla güncellendi",
              placement: 'topRight'
          });setLoading(false)
      setTimeout(() => {
          window.location.reload();
      }, 500);
            
    }
    else {
      NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
      });setLoading(false)
  }
  };


  const columns = [
    {
      title: 'Arızayı Listeleyen',
      dataIndex: 'firstUserNameSurname',
      key: 'firstUserNameSurname',
      render: (text, record) => <span style={{ color: record.isFixed ? colors.green : "#000000" }}>{text}</span>,
      ...getColumnSearchProps('firstUserNameSurname'),
      sorter: (a, b) => {
        const companyNameA = a && a.firstUserNameSurname ? a.firstUserNameSurname : '';
        const companyNameB = b && b.firstUserNameSurname ? b.firstUserNameSurname : '';
        return companyNameA.localeCompare(companyNameB);
      }
      
    },
   

      {
        title: 'Makine',
        dataIndex: 'machine',
        key: 'machine',
        render: (text, record) => <span style={{ color: record.isFixed ? colors.green : "#000000" }}>{text}</span>,
        ...getColumnSearchProps('machine'),
       
        sorter: (a, b) => {
            const companyNameA = a && a.machine ? a.machine : '';
            const companyNameB = b && b.machine ? b.machine : '';
            return companyNameA.localeCompare(companyNameB);
          }
      },
      {
        title: 'Tarih',
        dataIndex: 'firstTransactionDate',
        key: 'firstTransactionDate',
        render: (text, record) => (
          <span style={{ color: record.isFixed ? colors.green : "#000000" }}>
                {record && record.firstTransactionDate ? (
                  isNaN(Date.parse(record.firstTransactionDate)) ? (
                    "Tarih formatı hatalı"
                  ) : (
                    new Date(record.firstTransactionDate).toLocaleDateString('tr-TR')
                  )
                ) : (
                  "Tarih bulunamadı"
                )}         
                 </span>
        ),
        sorter: (a, b) => {
            const deadlineA = a.firstTransactionDate ? new Date(a.firstTransactionDate).getTime() : 0;
            const deadlineB = b.firstTransactionDate ? new Date(b.firstTransactionDate).getTime() : 0;
            return deadlineA - deadlineB;
          },
      },
      
    
      {
        title: 'Açıklama',
        dataIndex: 'description',
        key: 'description',
        render: (text, record) => <span style={{ color: record.isFixed ? colors.green : "#000000" }}>{text}</span>,
     
       
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (text, record) => (
            <Button 
            onClick={()=>handleUpdate(record)} 
            style={{
              backgroundColor: record.isFixed  ?  "#00000080" : colors.blue,
              color:"white",fontWeight:"500",border:"none"}}
            disabled = {record.isFixed  ? true : false}
           >
            Arıza Giderildi
          </Button>
       
         
        )
      }
  ];

  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '94vh',
    padding: "3%",
  }}>
    <h3 style={{ textAlign: "left", color: colors.blue }}>Arıza Listesi</h3>

    <Table
      locale={{
        emptyText: 'Henüz arıza eklemediniz...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItems}
      columns={columns}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
    
    />
    {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

  </div>
};

export default FaultList;

