import React, { useState, useEffect } from "react";
import { colors } from "../../environments/environment";
import FirebaseService from "../../services/firebaseService";

const ForeignCurrency = () => {
  const [currentRate, setCurrentRate] = useState(null);
  const [previousRate, setPreviousRate] = useState(null);
  const [error, setError] = useState(null);
  const apiKey = "67b2dfbf323c73e60c3155049cb1de50"; 

  const fetchExchangeRate = async () => {
    try {
      const response = await fetch(`http://api.currencylayer.com/live?access_key=${apiKey}&currencies=EUR,GBP,TRY&format=1`);
      const data = await response.json();
      //console.log("hava durumuuuuuuuuu",data)
      if (data.success) {
        setPreviousRate(currentRate); 
        setCurrentRate(data.quotes.USDTRY);  
      } else {
       //console.log("data.error.info",data.error.info)
        setError(data.error.info);
      } 
    } catch (error) {
     // console.log("errorrrr",error)

      setError("An error occurred while fetching the exchange rate.");
    }
  };

  useEffect(() => {
    /*fetchExchangeRate();
    const interval = setInterval(() => {
      fetchExchangeRate();
    }, 1800000); // Fetch every 30 minutes

    return () => clearInterval(interval);
    */
    const fetchData = async () => {
      try {
        const data = await FirebaseService.getCurrencyData();
        console.log("dolar", data);

        // Verilerin olup olmadığını kontrol et
        if (data && data.length > 0 && data[0].dolar !== undefined) {
          setCurrentRate(data[0].dolar); // Dolar verisi varsa güncelle
        } else {
          setError("Dolar verisi bulunamadı.");
        }
      } catch (error) {
        console.error("Veri çekme hatası:", error);
        setError("Dolar kuru verisi alınamadı.");
      }
    };

    fetchData();

  }, []);

  const compareRates = () => {
    if (previousRate === null) return null;
    if (currentRate > previousRate) return "Kur arttı";
    if (currentRate < previousRate) return "Kur azaldı";
    return "Kur sabit kaldı";
  };

  const getImage = () => {
    const comparison = compareRates();
    if (comparison === "Kur arttı") return "/images/foreign-plus.svg";
    if (comparison === "Kur azaldı") return "/images/foreign-minus.svg";
    if (comparison === "Kur sabit kaldı") return "/images/foreign-still.svg";
    return null;
  };

  return (
    <div style={{ padding: '10px', border: '2px solid #AFD3E7', borderRadius: '15px', alignItems: "center", height: "100px" }}>

     <div style={{ display: "flex", alignItems:"center",marginTop:"30px" }}>
        <img width={40} height={40} alt="logo" src="/images/dolar.svg"  />
        <div style={{ display:"flex" , alignItems:"center"}}>
        <div style={{ fontSize: "20px", fontWeight:"bold" }}>{currentRate}</div>

        </div>
      </div>
    </div>
  );
};

export default ForeignCurrency;
/*
     <div style={{ fontSize: "20px", fontWeight:"bold", textAlign:"left" }}>Kur</div>

       {currentRate ? (
         <>
              <div style={{ fontSize: "20px", fontWeight:"bold" }}>{currentRate.toFixed(2)} TL</div>
             
              </>
          ) : error ? (
            <p style={{ fontSize: "15px", color:colors.gray, marginLeft:"30px"  }}>Kur bilgisi şuanda alınamamaktadır.</p>
          ) : (
            <p style={{ fontSize: "20px" }}>Loading...</p>
          )}

*/