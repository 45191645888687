import React,{useEffect,useState} from "react";
import { Table, Button,Modal  } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';

const AnnouncementsListLimited = () => {
    const [items, setItems] = useState([]);
    const [itemsLimited, setItemsLimited] = useState([]);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
        try {
          const data = await FirebaseService.getAnnouncementData();
          const filteredData = data.filter(items => !items.isDeleted)
          const limitedData = filteredData.slice(0, 3); 
          setItemsLimited(limitedData);
          setItems(filteredData);
        

        } catch (error) {
          console.error('Error fetching users data',error.message)
        }
      };
      
      
  
      fetchData();
    }, []);

    const goPage = (record) => {
        navigate('/announcementsList', { state: { record } })
      };


      const columns = [
        {
            title: "Başlık",
            dataIndex: 'title',
            key: 'title',
            width: 100,
            render: (text, record) => (
                <span style={{ color: record.isDeleted ? "#00000080" : "#000000" , fontWeight:"bold"}}>
                   {text}
                </span>
            ),
          },
        {
            title : "Açıklama",
            dataIndex: 'description',
            key: 'description',
            width: 100,
            render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
          }
      ];

      return  <div style={{
        //  justifyContent: 'center',
        //  alignItems: 'center',
       //   minHeight: '94vh',
         
        //  width:"50%",
          border:"2px solid #AFD3E7", 
          borderRadius:"15px",
          height:"410px",
          overflow:"hidden"
        }}>
        <Button onClick={()=>goPage(items)} className="title-limited-list">
           Duyurular
         </Button>
         <Table
            locale={{
              emptyText: 'Henüz bir duyuru eklenmedi...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default AnnouncementsListLimited;

  