import React, { useEffect, useState } from "react";
import { Input, Button, DatePicker,Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, parsCollections } from "../../../environments/environment";
import NotificationService from "../../../services/antNotificationService";
import { useNavigate } from 'react-router-dom';

const ExpenseAddContinue = () => {
  const location = useLocation();
  const record = location.state && location.state.record;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [groupedData, setGroupedData] = useState({});
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await FirebaseService.getInvoiceCategoryContentsData();
      
      const grouped = data.reduce((acc, item) => {
        const category = item.categoryName || "Diğer";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
        return acc;
      }, {});

     // console.log("grouped",grouped)
      setGroupedData(grouped);
      initializeInputValues(grouped);
    };

    fetchData();
  }, []);

  const calculateCreditMonth = (startDate, endDate) => {
    if (!startDate || !endDate) return null;

    const start = new Date(startDate.seconds * 1000);
    const end = new Date(endDate.seconds * 1000);
    const current = new Date();

    const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    const currentMonth = (current.getFullYear() - start.getFullYear()) * 12 + (current.getMonth() - start.getMonth()) + 1;

    return `${currentMonth}/${totalMonths}`;
  };


  const initializeInputValues = (groupedData) => {
    const initialValues = {};
    Object.keys(groupedData).forEach(category => {
      initialValues[category] = groupedData[category].map(item => ({
        price: item.price || 0,
        contentName: item.contentName || '',
      }));
    });
    setInputValues(initialValues);
  };
  



  const handleInputChange = (category, index, field, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [category]: [
        ...(prevValues[category] || []).map((entry, idx) =>
          idx === index ? { ...entry, [field]: parseFloat(value) } : entry
        )
      ]
    }));
  };
  
  
  const saveChanges = async () => {
    setLoading(true)
    console.log("Input Values:", inputValues);
    const currentDate =new Date()

    const data = {
      firstTransactionDate: currentDate,
      firstUserId: localStorage.getItem("uid"),
      isDeleted: false,
      prices: inputValues,
      month: record.month,
      year: record.year
  };

  const result = await FirebaseService.addRecording(parsCollections.expenses, data);
  if (result.success) {
      NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
      });
      setLoading(false)
      setTimeout(() => {
        navigate("/expenselist");
      }, 2000);
  
  } else {
      NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
      });setLoading(false)
  } 
  
  
  };
  

  const calculateTotal = (category) => {
    const existingEntriesTotal = (groupedData[category] || []).reduce((total, item, index) => {
      const inputValue = (inputValues[category] && inputValues[category][index]) ? parseFloat(inputValues[category][index].price) : 0;
      return total + (isNaN(inputValue) ? item.price || 0 : inputValue);
    }, 0);
  
    return existingEntriesTotal;
  };
  
  

  return (
    <div style={{ padding: "10px 100px" }}>
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}>Gider Listesi</h2>

      <div style={{ display: "flex" }}>
        {Object.keys(groupedData)
         .sort((a, b) => a.localeCompare(b))
         .map(category => (
          <div key={category} style={{ marginBottom: '20px', marginRight: "40px" }}>
            <h3 style={{ fontWeight: "bold", color: colors.blue, textAlign: "left" }}>{category}</h3>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          

            {groupedData[category].map((item, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span style={{ marginRight: "10px", width: "200px", textAlign: "left" }}>{item.contentName}:</span>
                <Input
                  style={{ width: "80px" }}
                  className="input-style"
                  value={(inputValues[category] && inputValues[category][index] && inputValues[category][index].price) || item.price || ''}
                  onChange={(e) => handleInputChange(category, index, 'price', e.target.value)}
                />
                 {category === "Banka/ Kredi Giderleri" && (
                    <div style={{ marginLeft: "10px" }}>
                      <span>{calculateCreditMonth(item.contentStartDate, item.contentEndDate)}</span>
                    </div>
                  )}
              </div>
              
            ))}

      
            </div>
            <div style={{ marginTop: '10px', fontWeight: 'bold' , textAlign:"left", display:"flex", alignItems:"center"}}>
              Toplam Giderler:
            
              <Input 
              className="input-style"
               value={calculateTotal(category)}
               style={{width:"80px", background:colors.lightGray,marginLeft:"80px"}}  /> 
            </div>
          </div>
        ))}
      </div>
      <Button
        style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500", float: "right",  }}
        onClick={saveChanges}>
        Kaydet
      </Button>
      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
  );
};

export default ExpenseAddContinue;
