import React, { useState, useEffect } from "react";
import { Space, Button } from 'antd';
import { ThunderboltOutlined, CloudOutlined, CompassOutlined } from '@ant-design/icons'; // Icons
import { colors } from "../../environments/environment";
import { CameraOutlined } from '@ant-design/icons'; 
import NotificationService from "../../services/antNotificationService";
import FirebaseService from '../../services/firebaseService';
import FirebaseStorage from '../../services/storegeService';
import { useNavigate } from 'react-router-dom';

const UserInfo = () => {
  const name = localStorage.getItem('name');
  const surname = localStorage.getItem('surname');
  const title = localStorage.getItem('title');
  const imageUrl = localStorage.getItem('imageUrl');
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const uid = localStorage.getItem("uid");
  
      // If uid is null, navigate to login page
      if (!uid) {
        navigate('/login');
        console.log('No UID found in localStorage, redirecting to login');
        return; // Stop further execution
      }
  
      console.log("UID found in localStorage:", uid);
  
      try {
        const userData = await FirebaseService.getUserByUid(uid);
        
        if (userData) {
          setUserData(userData);
        } else {
          navigate('/login');
          console.log('User not found in Firestore, redirecting to login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/login'); // Redirect in case of an error
      }
    };
  
    getData();
  }, [navigate]); // Make sure to include navigate in dependency array
  
  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      const imageUrl = await FirebaseStorage.uploadImageAndGetURL("users", file);
      const result = await FirebaseService.updateUserImageUrl(localStorage.getItem("uid"), imageUrl);

      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: result.message,
          placement: 'topRight'
        });
        
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        localStorage.setItem('imageUrl', imageUrl);

      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
      }
    } catch (error) {
      console.error("Hata:", error);
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
    }
  };

  return (
    <>
      <div style={{ height: "100px", padding: '10px', border: `2px solid #AFD3E7`, borderRadius: '15px', display: "flex", alignItems: "center" }}>
        <div>
          {imageUrl ? (
            <label htmlFor="fileInput">
              <Button onClick={() => document.getElementById('fileInput').click()} style={{ border: "none", width: "70px", height: "60px" }}>
                <img alt="" src={imageUrl} style={{ marginRight: '10px', width: '55px', maxWidth: "55px", height: "55px", maxHeight: "55px", borderRadius: "100%", objectFit: "cover" }} />
              </Button>
            </label>
          ) : (
            <label htmlFor="fileInput">
              <Button onClick={() => document.getElementById('fileInput').click()} style={{ border: "none", width: "70px", height: "60px", boxShadow:"none" }}>
                {userData.imageUrl ? (
                  <img alt="" src={userData.imageUrl} style={{ width: '70px', height: "70px", borderRadius: "100%", objectFit: "cover" }} />
                ) : (
                  <img alt="plus" style={{ width: "40px", height: "50px", float: "left" }} src="/images/uploadUserImage.svg" />
                )}
              </Button>
            </label>
          )}
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>

        <div style={{ marginLeft: "30px" }}>
  <div style={{ textAlign: "left", color: colors.blue, fontSize: "18px", fontFamily: "Helvetica" }}>
    <p style={{ marginBottom: "5px" , fontWeight : "bold"}}>
      Hoşgeldin, {name} {surname}
    </p>
  </div>
  <div style={{ textAlign: "left", color: colors.blue, fontSize: "15px" }}>{title}</div>
</div>

      </div>
    </>
  );
};

export default UserInfo;
