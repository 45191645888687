import React,{useEffect,useState} from "react";
import { Button, Table, Input,Select,Space, List,Modal,Checkbox, Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { colors, parsCollections, placeholderMap } from "../../../../environments/environment";
import SetupAdd from "../setupAdd";
import PurchaseRequestAdd from "../../purchaseRequests/purchaseRequestAdd";
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";

const OpenOrderAddContinueMergeJobCodes = () => {
    const navigate = useNavigate();
   
    const location = useLocation();
    const record = location.state && location.state.record;
   // console.log("record",record);
    const jobCode = location.state && location.state.jobCode;
    
    const [checkedItemsStock, setCheckedItemsStock] = useState([]);
    const [checkedItemsWastage, setCheckedItemsWastage] = useState([]);

    const [checkedItemsStockExcess, setCheckedItemsStockExcess] = useState([]);

    const [inputValuesStock, setInputValuesStock] = useState({});
    const [inputValuesWastage, setInputValuesWastage] = useState({});


    const [companies, setCompanies] = useState([]); 
    const [itemsStock, setItemsStock] = useState([]);

    const [itemsWastage, setItemsWastage] = useState([]);

    const [filteredItemsStock, setFilteredItemsStock] = useState([]);
    const [filteredItemsWastage, setFilteredItemsWastage] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [excessValues, setExcessValues] = useState([]); 
  
    const [loading, setLoading] = useState(false);

    const handleSetup = (record) => {
     console.log("record handle setup",record)
      const updatedRecord = {
      ...record,
      projectCode: record.projectCode ? record.projectCode : null,
      stock: checkedItemsStock,
      wastage: checkedItemsWastage,
      checkedItemsStockExcess:checkedItemsStockExcess,
      jobCode: jobCode ? jobCode : null
    };
  
    console.log("updatedRecord",updatedRecord)
   setRecordForModal(updatedRecord);
   setIsModalVisible(true);
      
    };
    
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleSetupFinish = () => {
      setIsModalVisible(false); 
    };
  

    useEffect(() => {
      window.scrollTo(0, 0);
        const fetchData = async () => {
            const data = await FirebaseService.getStocksData();
            //console.log("record.warehouseName ",record.warehouseName )
             const dataWastage = await FirebaseService.getWastagesData();

            if(record.warehouseName === null || record.warehouseName === undefined){
              const filteredDataStock = data.filter(item => !item.isDeleted);
           //   console.log("filteredDataStock",filteredDataStock)
              setItemsStock(filteredDataStock);
              setFilteredItemsStock(filteredDataStock);

              const filteredDataWastage = dataWastage.filter(item => !item.isDeleted);
              //  console.log("filteredDataWastage",filteredDataWastage)
              setItemsWastage(filteredDataWastage);
               setFilteredItemsWastage(filteredDataWastage)
      
            }else{
              const filteredDataStock = data.filter(item => !item.isDeleted && item.companyName === record.warehouseName);
              // console.log("filteredDataStock",filteredDataStock)
              setItemsStock(filteredDataStock);
              setFilteredItemsStock(filteredDataStock);

              const filteredDataWastage = dataWastage.filter(item => !item.isDeleted  && item.companyName === record.warehouseName);
              //  console.log("filteredDataWastage",filteredDataWastage)
               setItemsWastage(filteredDataWastage);
              setFilteredItemsWastage(filteredDataWastage)
      
            }
          


        
            const companies = await FirebaseService.getCompaniesData();
          //  const filteredCompanies = companies.filter(item => !item.isDeleted);
            setCompanies(companies);

          };
      fetchData();
    }, []);
    const warehouseOptions = [{ label: 'Tümü', value: "all" }, ...companies.map(item => ({ label: item.companyName, value: item.companyName }))];
    
    function generateRandomCode(length) {
      const characters = '0123456789';
      let result = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    
      return result;
  }
   
    const handleCheckboxChangeWastage = (item,record) => {
        const isChecked = checkedItemsWastage.some((entry) => entry.id === item);

        if (inputValuesWastage[item]) {
          console.log("inputValuesWastage  geldi")
 
          setCheckedItemsWastage((items) => {
                 if (isChecked) {
                     const newItems = items.filter((entry) => entry.id !== item);
                     return newItems;
                 } else {
                     const newItem = { 
                       id:item,
                       oldPiece: record.piece, //mevucttaki piece
                       quality: record.quality,
                       height: record.height,
                       width: record.width,
                       thickness: record.thickness,
                       surface: record.surface,
                       isQrScan: false,
                       piece: inputValuesWastage[item] || '' };  //reserve edilen piece bu
                     return [...items, newItem];
                 }
             });
         } 
          
       
    };
        
    const handleCheckboxChange = (item,record) => {
     // console.log("record",record,item)
        const isChecked = checkedItemsStock.some((entry) => entry.id === item);
        if (inputValuesStock[item]) {
         // console.log("inputValuesStock  geldi")

            setCheckedItemsStock((items) => {
                if (isChecked) {
                    const newItems = items.filter((entry) => entry.id !== item);
                    return newItems;
                } else {
                    const newItem = { 
                      id:item,
                      oldPiece: record.piece, //mevucttaki piece
                      quality: record.quality,
                      height: record.height,
                      width: record.width,
                      thickness: record.thickness,
                      surface: record.surface,
                      isQrScan: false,
                      piece: inputValuesStock[item] || '' };  //reserve edilen piece bu
                    return [...items, newItem];
                }
            });
        } 
        
         if(excessValues[item]){
        //  console.log("exces valuese geldi",excessValues[item] )
          setCheckedItemsStock((items) => {
            if (isChecked) {
                const newItems = items.filter((entry) => entry.id !== item);
                return newItems;
            } else {
                const newItem = { 
                  id:item,
                  oldPiece: record.piece, //mevucttaki piece
                  quality: record.quality,
                  height: record.height,
                  width: record.width,
                  thickness: record.thickness,
                  surface: record.surface,
                  isQrScan: false,
                  piece: excessValues[item] || '' ,//reserve edilen piece bu
                  minPiece: record.minPiece,
                  code:record.code
                };  
                return [...items, newItem];
            }
        });
        setCheckedItemsStockExcess((items) => {
          if (isChecked) {
              const newItems = items.filter((entry) => entry.id !== item);
              return newItems;
          } else {
              const newItem = { 
                id:item,
                jobCode: jobCode,
                quality: record.quality,
                height: record.height,
                width: record.width,
                thickness: record.thickness,
                surface: record.surface,
                piece: excessValues[item] || '' , //reserve edilen piece bu
                minPiece: record.minPiece,
                code:record.code,
                currentPiece: record.piece,

              }; 
               
              return [...items, newItem];
          }
      });

        }
        
      
    };
 
    const onChangeFilterWarehouse = async(value) => {
      // console.log(`selected ${value}`);
       setSelectedWarehouse(value)
       try {
         if (value) {
           const data = await FirebaseService.getStocksData();
           const dataWastage = await FirebaseService.getWastagesData();
     
           if (value === "all") {
             const filteredDataStock = data.filter(item => !item.isDeleted);
             setItemsStock(filteredDataStock);
             setFilteredItemsStock(filteredDataStock);
             
             const filteredDataWastage = dataWastage.filter(item => !item.isDeleted);
             setItemsWastage(filteredDataWastage);
             setFilteredItemsWastage(filteredDataWastage);
           } else {
             const filteredDataStock = data.filter(item => !item.isDeleted && item.companyName === value);
             setItemsStock(filteredDataStock);
             setFilteredItemsStock(filteredDataStock);
             
             const filteredDataWastage = dataWastage.filter(item => !item.isDeleted && item.companyName === value);
             setItemsWastage(filteredDataWastage);
             setFilteredItemsWastage(filteredDataWastage);
           }
         } else {
          // console.log('Lütfen bir depo seçiniz.');
         }
       } catch (error) {
         console.error('Hata oluştu:', error);
         // Hata durumunda kullanıcıya bilgi verebilirsiniz
       }
    };

    const handleInputChange = (item, value) => {

    
      const maxLength = Number(itemsStock.find((record) => record.key === item)?.piece);
      const numericValue = Number(value);

      console.log("numericValue",numericValue)
      if (isNaN(numericValue)) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
          placement: 'topRight'
        });
        return; 
    }


      if (!isNaN(numericValue) && (maxLength === undefined || numericValue <= maxLength)) {
        setInputValuesStock((values) => ({ ...values, [item]: numericValue }));
       //console.log("input values stokc", inputValuesStock, checkedItemsStock,item);
    
        const checkedItemsStockArray = Array.isArray(checkedItemsStock) ? checkedItemsStock : [checkedItemsStock];
    
        const itemExists = checkedItemsStockArray.some(x => x.id === item);
    
        if (itemExists) {
            // itemExists doğruysa yapılacak işlemler
            console.log("Item exists.");
            const updatedItems = checkedItemsStockArray.map(x => {
                if (x.id === item) {
                    return { ...x, piece: numericValue };
                } else {
                    return x;
                }
            });
            setCheckedItemsStock(updatedItems);
        } else {
            // itemExists yanlışsa yapılacak işlemler
          //  console.log("Item does not exist.");
        }
    }
    
     else {
        //  console.log("burda fazla");
          setExcessValues((prevValues) => ({ ...prevValues, [item]: numericValue-maxLength }));
          setInputValuesStock((values) => ({ ...values, [item]: maxLength }));
      }
    }; 

    const handleInputChangeWastage = (item, value) => {
    
      const maxLength = Number(itemsWastage.find((record) => record.key === item)?.piece);
      const numericValue = Number(value);

      if (isNaN(numericValue)) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
          placement: 'topRight'
        });
        return; 
    }
    else{
      if (!isNaN(numericValue) && (maxLength === undefined || numericValue <= maxLength)) {
        setInputValuesWastage((values) => ({ ...values, [item]: numericValue }));
       console.log("input values wastge", inputValuesWastage, checkedItemsWastage,item);
    
        const checkedItemsWastagesArray = Array.isArray(checkedItemsWastage) ? checkedItemsWastage : [checkedItemsWastage];
    
        const itemExists = checkedItemsWastagesArray.some(x => x.id === item);
    
        if (itemExists) {
            // itemExists doğruysa yapılacak işlemler
            console.log("Item exists.");
            const updatedItems = checkedItemsWastagesArray.map(x => {
                if (x.id === item) {
                    return { ...x, piece: numericValue };
                } else {
                    return x;
                }
            });
            setCheckedItemsWastage(updatedItems);
        } else {
            // itemExists yanlışsa yapılacak işlemler
          //  console.log("Item does not exist.");
        }
      }
    
      else {

        setInputValuesWastage((values) => ({ ...values, [item]: maxLength }));
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Yeterli sayıda bulunmamaktadır. Lütfen güncel stoktan seçiniz.",
          placement: 'topRight'
        });
         console.log("burda fazla girmemeli");
      
      }
    }

    
    };

//stock için filtreleme
    const handleSearchStock = (selectedKeys, confirm, dataIndex) => {
        confirm();
        const filteredData = itemsStock.filter(item => {
          const value = selectedKeys[0]?.toLowerCase(); 
          const itemValue = item[dataIndex]?.toString().toLowerCase(); 
          return (
            !item.isDeleted &&
          // item.companyName === record.companyName &&
            (value ? itemValue.includes(value) : true) 
          );
        });
        setFilteredItemsStock(filteredData);
    };

    const handleResetStock = clearFilters => {
      clearFilters();
      setFilteredItemsStock(itemsStock);
    };
  
    const getColumnSearchPropsStock = dataIndex => ({
    
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
       <Input
      id="search-input"  
      placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
      value={selectedKeys[0]}
      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearchStock(selectedKeys, confirm, dataIndex)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
  
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearchStock(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90,background:colors.blue }}
            >
              Ara
            </Button>
            <Button onClick={() => handleResetStock(clearFilters)} size="small" style={{ width: 90 }}>
              Sıfırla
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => document.getElementById('search-input').select(), 100);
        }
      },
    });

    const columnsStock = [
     
        {
          title: 'Depo',
          dataIndex: 'companyName',
          key: 'companyName',
       
          ...getColumnSearchPropsStock('companyName'),
          sorter: (a, b) => a.companyName.localeCompare(b.companyName), 
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
    
      {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
      
        ...getColumnSearchPropsStock('quality'),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
  
        {
          title: 'Kalınlık',
          dataIndex: 'thickness',
          key: 'thickness',
        
          ...getColumnSearchPropsStock('thickness'),
          sorter: (a, b) => a.thickness - b.thickness,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
  
       
        {
          title: 'En',
          dataIndex: 'width',
          key: 'width',
        
          ...getColumnSearchPropsStock('width'),
          sorter: (a, b) => a.width - b.width,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
        {
          title: 'Boy',
          dataIndex: 'height',
          key: 'height',
       
          ...getColumnSearchPropsStock('height'),
          sorter: (a, b) => a.height - b.height,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
  
        {
          title: 'Yüzey',
          dataIndex: 'surface',
          key: 'surface',
        
          ...getColumnSearchPropsStock('surface'),
          sorter: (a, b) => a.surface.localeCompare(b.surface), 
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
   
        {
          title: 'Adet',
          dataIndex: 'piece',
          key: 'piece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        
          sorter: (a, b) => a.piece - b.piece,
          ...getColumnSearchPropsStock('piece'),
        },
        
        {
          title: 'Rezerve Adet',
          dataIndex: 'reservedPiece',
          key: 'reservedPiece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      
         
        
        },
        {
          title: 'Beklenen Adet',
          dataIndex: 'waitingPiece',
          key: 'waitingPiece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
       
         
        
        },
        {
          title: 'Açıklama',
          dataIndex: 'isProblem',
          key: 'isProblem',
          render: (text, record) => {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const formattedDate = record.problemDate ? new Date(record.problemDate.seconds * 1000).toLocaleString('tr-TR', options) : '';
            
            return (
              <Popover
  content={
    <div style={{ width: '200px', maxHeight: '700px' }}> {/* Sabit yükseklik ve genişlik */}
      <p><strong>Problem Zamanı:</strong> {formattedDate}</p>
      <p><strong>Problem Fotoğrafı:</strong> 
        {record.problemImage ?                 
        <img alt="" src={record.problemImage} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
        : 
        "Fotoğraf bulunmamaktadır."}</p>
            <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Problem Açıklaması:</strong> {record.problemDescription}</p>
          </div>
        }
        title="Sac levhada problem bulunmaktadır."
        trigger="click" // veya hover
      >
                <span style={{ color: itemsStock.isDeleted ? "#00000080" : "#000000" }}>
                  {record.isProblem ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                </span>
              </Popover>
            );
          },
        
        },
          
        {
            title: 'Kullanılacak Adet',
            dataIndex: '', 
            key: '',
          
            render: (text, record) => (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                  onChange={(e) => handleInputChange(record.key, e.target.value)}
                   className="input-style"
                   style={{width:"100%"}}

                />
              </span>
            ),
        },
        
        {
          title: '',
          dataIndex: '',
          key: '',
       
          render: (text, record) => (
            <Checkbox
            onChange={() => handleCheckboxChange(record.key,record)}
            checked={checkedItemsStock.some(item => item.id === record.key)}
         //   className="checkbox-style"
          />      
             
         
          ),
        }
             
             
      
    ];

    //wastage için filtelme
    const handleSearchWastage = (selectedKeys, confirm, dataIndex) => {
      confirm();
      const filteredData = itemsWastage.filter(item => {
        const value = selectedKeys[0]?.toLowerCase(); 
        const itemValue = item[dataIndex]?.toString().toLowerCase(); 
        return (
          !item.isDeleted &&
        // item.companyName === record.companyName &&
          (value ? itemValue.includes(value) : true) 
        );
      });
      setFilteredItemsWastage(filteredData);
    };

    const handleResetWastage = clearFilters => {
      clearFilters();
      setFilteredItemsWastage(itemsWastage);
    };

  

    const getColumnSearchPropsWastage = dataIndex => ({
      
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
      <Input
      id="search-input"  
      placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
      value={selectedKeys[0]}
      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearchWastage(selectedKeys, confirm, dataIndex)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
      />

          <Space>
            <Button
              type="primary"
              onClick={() => handleSearchWastage(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90,background:colors.blue }}
            >
              Ara
            </Button>
            <Button onClick={() => handleResetWastage(clearFilters)} size="small" style={{ width: 90 }}>
              Sıfırla
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => document.getElementById('search-input').select(), 100);
        }
      },
    });

    const columnsWastage = [
        {
          title: 'Depo',
          dataIndex: 'companyName',
          key: 'companyName',
         
          ...getColumnSearchPropsWastage('companyName'),
          sorter: (a, b) => a.companyName.localeCompare(b.companyName), 
          render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
      {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
       
        ...getColumnSearchPropsWastage('quality'),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
        render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
  
        {
          title: 'Kalınlık',
          dataIndex: 'thickness',
          key: 'thickness',
        
          ...getColumnSearchPropsWastage('thickness'),
          sorter: (a, b) => a.thickness - b.thickness,
          render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
  
       
        {
          title: 'En',
          dataIndex: 'width',
          key: 'width',
         
          ...getColumnSearchPropsWastage('width'),
          sorter: (a, b) => a.width - b.width,
          render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
        {
          title: 'Boy',
          dataIndex: 'height',
          key: 'height',
       
          ...getColumnSearchPropsWastage('height'),
          sorter: (a, b) => a.height - b.height,
          render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
  
        {
          title: 'Yüzey',
          dataIndex: 'surface',
          key: 'surface',
        
          ...getColumnSearchPropsWastage('surface'),
          sorter: (a, b) => a.surface.localeCompare(b.surface), 
          render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
   
        {
          title: 'Adet',
          dataIndex: 'piece',
          key: 'piece',
          render: (text, record) => (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {text !== undefined && text !== null && text !== "" ? text : 1}
            </span>
        ),         
          ...getColumnSearchPropsWastage('piece'),
          sorter: (a, b) => a.piece - b.piece,
        },
          
        {
          title: 'Rezerve Adet',
          dataIndex: 'reservedPiece',
          key: 'reservedPiece',
          render: (text,record) => <span style={{color: itemsWastage.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        
         
        
        },
          
        {
            title: 'Kullanılacak Adet',
            dataIndex: '', 
            key: '',
           
            render: (text, record) => (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                  onChange={(e) => handleInputChangeWastage(record.key, e.target.value)}
                  className="input-style"
                  style={{width:"100%"}}
                  rules={[{ pattern: /^\d+$/, message: "Sadece rakam girişi yapınız.",required: true, }]}

                 
                 />
              </span>
            ),


            /*
            render: (text, record) => (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                  value={1}
                 // onChange={(e) => handleInputChangeWastage(record.key, e.target.value)}
                />
              </span>
            ),
            */
        },
        
        {
          title: '',
          dataIndex: '',
          key: '',
        
          render: (text, record) => (
            <Checkbox
            onChange={() => handleCheckboxChangeWastage(record.key,record)}
            checked={checkedItemsWastage.some(item => item.id === record.key)}
            style={{ fontWeight: '500' }}
          />      
         
          ),
        },
             
             
      
    ];


    const [isModalVisiblePurchase, setIsModalVisiblePurchase] = useState(false);

    const handlePurchaseRequest = () => {
      setIsModalVisiblePurchase(true);
    };

    const handlePurchaseRequestAddFinish = () => {
      setIsModalVisiblePurchase(false); 
    };

    const handleModalCancelPurchase = () => {
      setIsModalVisiblePurchase(false);
    };

    const [isModalVisibleSetupName, setIsModalVisibleSetupName] = useState(false);
    const [setupName, setSetupName] = useState('');
    const [createJobCode, setCreateJobCode] = useState(null)
  
    const handleInputChangeSetupName =  (e) => {
      setSetupName(e.target.value);
    };

    const checkSendPurchase = (stockItem) => {
      const checkedItemsStockExcessx = checkedItemsStockExcess || [];
    
      return checkedItemsStockExcessx.some(checkedItem =>
        checkedItem.id === stockItem.id
      );
    };
    
  
  const handleSaveSetupName = async() => {
    setLoading(true);
    if (!setupName || setupName.includes(" ")) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Setup isminde boşluk bulunmamalıdır. Lütfen düzeltiniz.",
        placement: 'topRight'
      });
      setLoading(false);
      return;
  }
    const createJobCode = jobCode + "-" + setupName;
    setCreateJobCode(createJobCode);
    const excessValuesKeys = Object.keys(checkedItemsStockExcess);
    const excessValuesCount = excessValuesKeys.length;
    
    console.log("checkedItemsStock",checkedItemsStock)
    console.log("checkedItemsStockExcess",checkedItemsStockExcess)

    if( ! checkedItemsStock.length > 0){
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Seçili sac levha bulunammaktadır. Lütfen sac levha seçiniz",
        placement: 'topRight'
      });
      setIsModalVisibleSetupName(false);
      setLoading(false);
      return;
    }

    if (excessValuesCount > 0) {
       // console.log("Fazla talep edilen ürün var");
        excessValuesKeys.forEach((key) => {
            const value = checkedItemsStockExcess[key];
          //  console.log(`Key: ${key}, Value: ${value}`);
            
        });
      
        setVisibleContinue(true);
        setLoading(true);

    }
    else{
     
     // console.log("fazla ürün yok")
      //stoktan ve fireden reserved update oluyo
      let updatedCheckedItemsStock;
      if (checkedItemsStock.length > 0) {
        for (const record of checkedItemsStock) {
          const { id, piece } = record;
          const result = await FirebaseService.updateStockReservedPiece(id, piece);
          console.log(result);
        }
      
      updatedCheckedItemsStock = checkedItemsStock.map(({ oldPiece, ...rest }) => rest);
        console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
      }
      
      let updatedCheckedItemsWastage;
      if (checkedItemsWastage.length > 0) {
        for (const x of checkedItemsWastage) {
          const { id, piece } = x;
          const result = await FirebaseService.updateWastageReservedPiece(id, piece);
          console.log(result);
        }
      
        updatedCheckedItemsWastage = checkedItemsWastage.map(({ oldPiece, ...rest }) => rest);
        console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
      }


      //stocktaki itemlar isSendPurchase ekleniyor
      let updatedcheckedItemsStock = checkedItemsStock.map(stockItem => ({
            ...stockItem,
            isSendPurchase: checkSendPurchase(stockItem)
          })
        )
      
      
        console.log("updatedcheckedItemsStock",updatedcheckedItemsStock)
   
//burdan hiç emin depğilimmmm

     const currentDate =new Date()
      const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            programmerUserId:localStorage.getItem("uid"),
            isDeleted: false,
            programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
            jobCode : createJobCode, 
            companyName: record.companyName,
            projectCode: record.projectCode ? record.projectCode : null,
            orderType :record.orderType,
            stock: checkedItemsStock,
            wastage: checkedItemsWastage,
            deadline:null, //setup sonunda seçiliyor
            isSetup:false,
            isOffer:false,
            isWaiting: true,
            isOfferApproved:false,
            noOfferApprovedDescription:null,
            noQrScanDescription:null,
            isOfferSetupUpdate:false,
            isOfferChange:false,
            isOfferDenied:false,
           // priority:999999,
           isChangedProgrammer:false,
           isShipment: false,
           shipmentPiece: 0,
           invoiceNumber:null,
           setupName:setupName ? setupName : null
           
      };
      console.log("data",data)

      try {
          const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
          if (result.success) {
            const item = {
              isDeleted:false,
              item: data.createJobCode,
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
            }
            const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
          
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: "Bekleyen Siparişlere başarıyla kaydedilmitşir.",
              placement: 'topRight'
            });
            setLoading(false);
            setIsModalVisibleSetupName(false);
            setTimeout(() => {
              navigate("/programmerpage");
            }, 2000);
          } else {
           // console.log("hata",result)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });
            setLoading(false);
          }
          
          
      } 
      catch (error) {
          console.log("error",error)
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false);

      }
      

    }
    

    };
  
    const handleCancelSetupName = () => {
      setIsModalVisibleSetupName(false);
    };

    const showModal = () => {
      setIsModalVisibleSetupName(true);
    };
  
    //fazla ürün var satın alma talebi gidiyor
    const handleSendPurchaseRequest = async () => {
      //telif onaylanınca burayı düzenlee
      setLoading(true);
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(0, 0, 0, 0); 

      //console.log("satın alma talebi gidiyr",checkedItemsStockExcess,createJobCode)
      const updatedPurchaseList = checkedItemsStockExcess.map(item => ({
        ...item,
        jobCode: createJobCode,
        description:null
      }));

      console.log("updatedPurchaseList",updatedPurchaseList)

       //min stok kontrolü için satın alma isteği atılıyor
       for (const item of updatedPurchaseList) {
        //console.log("updatedPurchaseList",updatedPurchaseList)
        const updatedPurchaseListFixed = updatedPurchaseList.map(item => ({  //burda jobcode null olabilir ve currentpiece göndermeye gerek yok
            ...item,
            piece : item.minPiece- item.currentPiece,
            jobCode:null
          }));
        //  console.log("updatedPurchaseListFixed",updatedPurchaseListFixed)
        //  console.log(",item.currentPiece",item.currentPiece,item.minPiece,updatedPurchaseListFixed)
        if (item.currentPiece < item.minPiece) {
          try {
            const data = { 
                jobCode: jobCode,
                companyName: record.companyName,
                productType: "raw",
                unitName: "teknik ressam",
                deadline: currentDate,
              //  requestedProductInfo: checkedItemsStockExcess,
                requestedProductInfo:updatedPurchaseListFixed,
                offerJobCode: generateRandomCode(5),
                deadlineForPayment:null,
                paymentMethod:null,
                mailList:null,
                sentExcelList:null,
                incomingExcelList:null,
                acceptExcelDocumentUrl:null,
                description:"Minimum stok altına düşmüştür." ,
                isSendSetup: false
              };

             const result = await FirebaseService.addPurchaseRequest(data);
            if (result.success) {
              console.log(`Purchase request created successfully for item with id ${item.id}.`);
            } else {
              console.log(`Failed to create purchase request for item with id ${item.id}.`);
            }
            
          } catch (error) {
            console.error(`Error creating purchase request for item with id ${item.id}:`, error);
          }
        }
      }


   

      const data = { 
        jobCode: createJobCode,
        companyName: record.companyName,
        productType: "raw",
        unitName: "teknik ressam",
        deadline: currentDate,
      //  requestedProductInfo: checkedItemsStockExcess,
        requestedProductInfo:updatedPurchaseList,
        offerJobCode: generateRandomCode(5),
        deadlineForPayment:null,
        paymentMethod:null,
        mailList:null,
        sentExcelList:null,
        incomingExcelList:null,
        acceptExcelDocumentUrl:null,
        description:"Teknik ressam tarafından seçilmiştir, setupda kullanılacaktır." ,
        isSendSetup: true
      };
    //  console.log("satın alma talebi data",data)
      
      try {
        const result = await FirebaseService.addPurchaseRequest(data);
        if (result.success) {
          setVisibleContinue(false);
          setIsModalVisibleSetupName(false);

       //  console.log( "satın alma talebi gönderildi.")

          let updatedCheckedItemsStockExcess;
        //  console.log("checkedItemsStockExcess",checkedItemsStockExcess)

          if (checkedItemsStockExcess && checkedItemsStockExcess.length > 0) {
            for (const x of checkedItemsStockExcess) {
              const { id, piece } = x;
              const result = await FirebaseService.updateStockWaitingPiece(id, piece);
             // console.log(result);
            }
          //  console.log("Updated checkedItemsStock:", updatedCheckedItemsStockExcess);
          }



          const requestedItems = checkedItemsStock.filter(stockItem =>
            !checkedItemsStockExcess.some(item =>
              item.id === stockItem.id &&
              item.quality === stockItem.quality &&
              item.height === stockItem.height &&
              item.width === stockItem.width &&
              item.piece === stockItem.piece &&
              item.thickness === stockItem.thickness &&
              item.surface === stockItem.surface
            )
          );
          
         // console.log("stocktan talep edilenler çıktı",requestedItems);
    
          //stoktan ve fireden reserved update oluyo
          let updatedCheckedItemsStock;
          
          if (requestedItems && requestedItems.length > 0) {
            for (const x of requestedItems) {
              const { id, piece } = x;
              const result = await FirebaseService.updateStockReservedPiece(id, piece);
             // console.log(result);
            }
          // console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
          }
    

          let updatedCheckedItemsWastage;
          if (checkedItemsWastage && checkedItemsWastage.length > 0) {
            for (const x of checkedItemsWastage) {
              const { id, piece } = x;
              const result = await FirebaseService.updateWastageReservedPiece(id, piece);
             // console.log(result);
            }
          
            updatedCheckedItemsWastage = checkedItemsWastage.map(({ oldPiece, ...rest }) => rest);
           // console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
          }




      //stocktaki itemlar isSendPurchase ekleniyor
      let updatedcheckedItemsStock = checkedItemsStock.map(stockItem => ({
        ...stockItem,
        isSendPurchase: checkSendPurchase(stockItem)
      })
    )
  
  
      console.log("updatedcheckedItemsStock",updatedcheckedItemsStock)

          //açık siparişlere kayıt
         const currentDate =new Date()
          const data = {
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
                programmerUserId:localStorage.getItem("uid"),
                isDeleted: false,
                programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
                jobCode : createJobCode, 
                companyName: record.companyName,
                projectCode: record.projectCode ? record.projectCode : null,
                orderType :record.orderType,
                stock: updatedcheckedItemsStock,
                wastage: checkedItemsWastage,
                deadline:null, //setup sonunda seçiliyor
                isSetup:false,
                isOffer:false,
                isWaiting: true,
                isOfferApproved:false,
                noOfferApprovedDescription:null,
                noQrScanDescription:null,
                isOfferSetupUpdate:false,
                isOfferChange:false,
                isOfferDenied:false,
               // priority:999999,
               isChangedProgrammer:false,
               isShipment: false,
               shipmentPiece: 0,
               invoiceNumber:null,
               setupName:setupName ? setupName : null

               
          };
        console.log("data setupsız kayot",data)
    
          try {
              const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
              if (result.success) {
                const item = {
                  isDeleted:false,
                  item: data.createJobCode,
                  firstTransactionDate: currentDate,
                  firstUserId: localStorage.getItem("uid"),
                }
                const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
              
                NotificationService.openSuccessNotification({
                  title: 'İşlem Başarılı',
                  description: "Bekleyen Siparişlere başarıyla kaydedilmitşir.",
                  placement: 'topRight'
                });
                setLoading(false);
                setIsModalVisibleSetupName(false);
                setTimeout(() => {
                  navigate("/programmerpage");
                }, 500);
              } else {
               // console.log("hata",result)
                NotificationService.openErrorNotification({
                  title: 'İşlem Başarısız',
                  description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                  placement: 'topRight'
                });
                setLoading(false);
                return;
              }
              
              
          }

          catch (error) {
              console.log("error",error)
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Bilinmeyen bir hata ile karşılaşıldı.",
                placement: 'topRight'
              });
              setLoading(false);

          }
            
        }
        else{
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false);

        }
        
      } 
      catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);
      }
      
    };
  
    const [visibleContinue, setVisibleContinue] = useState(false);

    const handleCancelContinue = () => {
     setVisibleContinue(false);
    };

    const columnsStockExcess = [
        {
          title: 'Kalite',
          dataIndex: 'quality',
          key: 'quality',
        
         // ...getColumnSearchPropsStock('quality'),
          sorter: (a, b) => a.quality.localeCompare(b.quality), 
          render: (text) => <span style={{  }}>{text}</span>,
        },
    
          {
            title: 'Kalınlık',
            dataIndex: 'thickness',
            key: 'thickness',
           
          //  ...getColumnSearchPropsStock('thickness'),
            sorter: (a, b) => a.thickness - b.thickness,
            render: (text) => <span style={{ }}>{text}</span>,
          },
    
         
          {
            title: 'En',
            dataIndex: 'width',
            key: 'width',
           
           // ...getColumnSearchPropsStock('width'),
            sorter: (a, b) => a.width - b.width,
            render: (text) => <span style={{  }}>{text}</span>,
          },
          {
            title: 'Boy',
            dataIndex: 'height',
            key: 'height',
           // ...getColumnSearchPropsStock('height'),
            sorter: (a, b) => a.height - b.height,
            render: (text) => <span style={{  }}>{text}</span>,
          },
    
          {
            title: 'Yüzey',
            dataIndex: 'surface',
            key: 'surface',
           // ...getColumnSearchPropsStock('surface'),
            sorter: (a, b) => a.surface.localeCompare(b.surface), 
            render: (text) => <span style={{  }}>{text}</span>,
          },
     
          {
            title: 'Adet',
            dataIndex: 'piece',
            key: 'piece',
            render: (text,record) => <span style={{}}>{text}</span>,
           
            sorter: (a, b) => a.piece - b.piece,
           // ...getColumnSearchPropsStock('piece'),
          },         
        
    ];

    return ( 
    <div style={{padding:"10px 10px",}}>
        <div style={{ display: "flex", justifyContent: "space-between", textAlign:"center" }}>
            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Müşteri</p>
                {record.companyName}
            </div>
            
            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
              {record.projectCode ? record.projectCode : "-"}
            </div>

            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Depo</p>
                 <Select
                    onChange={onChangeFilterWarehouse}
                    placeholder="Depo Seçiniz"
                    style={{ width:"150px"}}
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={warehouseOptions}
                     className="input-style"
                   
                />
             
            </div>
        </div>

        {!selectedWarehouse ? (
  
  <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh", 
    color: colors.gray,
    fontWeight: "bold",
    fontSize: "18px",
  }}>
    Lütfen depo seçiniz
  </div>
) : (
  <>
        <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",textAlign:"left" }}>Fire Listesi</div>
      <Table
        style={{width:"100%", margin:"auto"}}
            locale={{
              emptyText: 'Henüz fire bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={filteredItemsWastage}
            columns={columnsWastage}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

    <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",textAlign:"left" }}>Güncel Stok Listesi</div>
      <Table
         style={{width:"100%", margin:"auto"}}
          locale={{
            emptyText: 'Henüz stok bulunmamaktadır...',
            filterReset: 'Sıfırla',
            filterTitle: 'Filtre Menüsü',
            selectAll: 'Hepsini Seç',
            selectInvert: 'Tersini Seç',
            selectionAll: 'Tümünü Seç',
            sortTitle: 'Sıralama',
            triggerDesc: 'Azalan sıralama için tıklayın',
            triggerAsc: 'Artan sıralama için tıklayın',
            cancelSort: 'Sıralamayı iptal etmek için tıklayın',
          }}
           dataSource={filteredItemsStock}
            columns={columnsStock}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      <div style={{display:"flex", justifyContent:"right"}}>

    
                <Button 
               style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} 
              onClick={handlePurchaseRequest}>
              Talep Et
                </Button>
                <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisiblePurchase}
                onCancel={handleModalCancelPurchase}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>
          

          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={() => handleSetup(record)} >
          Setup Yükle & Devam Et
          </Button>

          <Modal
            title="Setup Yükle"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
          >
            <SetupAdd recordx={recordForModal}  onClose={handleSetupFinish} />
          </Modal>
          {/* 
          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={showModal} >
          Kaydet ve Çık (Setupsız)
          </Button> */}

         

      <Modal
        title="Setup Name"
        visible={isModalVisibleSetupName}
        onCancel={handleCancelSetupName}
        footer={[
          <Button key="cancel" onClick={handleCancelSetupName}>
            İptal
          </Button>,
          <Button key="save"   style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}  onClick={handleSaveSetupName}>
            Kaydet
          </Button>,
        ]}
      >
        <Input
          placeholder="Setup name"
          value={setupName}
          onChange={handleInputChangeSetupName}
          style={{ marginTop: '10px' }}
        />
      </Modal>





      <div>           
          <Modal
                  title="Satın Alma Talebi Gönder"
                  visible={visibleContinue}
                  onOk={handleSendPurchaseRequest}
                  onCancel={handleCancelContinue}
                  okText="Evet, Gönder ve Açık Sipariş Oluşturmaya Devam Et"
                  cancelText="Vazgeç"
                  className="columnsStockExcess popup-modal-button"
                
              >
              <div> 
                <p>Satın alma talebi göndermek istediğinizden emin misiniz?</p>
                <Table
                    dataSource={checkedItemsStockExcess}
                    columns={columnsStockExcess}
                    showHeader={true}
                    pagination={false} 
                    className="custom-news-table"
                    rowClassName="custom-news-row"
                    locale={{
                      emptyText: 'Henüz eklemediniz...',
                      filterReset: 'Sıfırla',
                      filterTitle: 'Filtre Menüsü',
                      selectAll: 'Hepsini Seç',
                      selectInvert: 'Tersini Seç',
                      selectionAll: 'Tümünü Seç',
                      sortTitle: 'Sıralama',
                      triggerDesc: 'Azalan sıralama için tıklayın',
                      triggerAsc: 'Artan sıralama için tıklayın',
                      cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                    }}
                />
            </div>
        </Modal>

        </div>

           
      </div>
      
      </>
      )}
      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
    )

};


export default OpenOrderAddContinueMergeJobCodes;