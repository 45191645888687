import React,{useEffect,useState,useRef} from "react";
import { Button, Select, Modal,Table ,Checkbox,Input,DatePicker, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import StoregeService from "../../../services/storegeService";
import { useNavigate } from 'react-router-dom';
import  {colors, emailJs}  from "../../../environments/environment"
import emailjs from '@emailjs/browser';
import AddRequestAdminMail from "../addRequestAdmin/addRequestAdminMail";
import XLSX from 'sheetjs-style'; 
import ExportReportCardTemplate from "./Pars_TeklifExcel_Final.xlsx";
//import * as XLSX from "xlsx-js-style";
//import * as XLSX from 'xlsx';
import moment from 'moment';

const PurchaseRequestSendMail = () => {
    const navigate = useNavigate();
    const [mailList, setMailList] = useState([]);
    const [checkedMails, setCheckedMails] = useState([]);
    const form = useRef();
    const location = useLocation();
    const { selectedItemsDetails, record } = location.state;
    const [isModalVisibleMail, setIsModalVisibleMail] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]); 
    const [deadline, setDeadline] = useState(null);
    const [loading, setLoading] = useState(false);

    const serviceId = emailJs.serviceId;
    const templateId = emailJs.templateIdSatinAlma;
    const publicKey = emailJs.publicKey;
  // console.log("record",record);
 // console.log("selectedItemsDetails",selectedItemsDetails);

  useEffect(() => {
    const fetchData = async () => {
      const data = await FirebaseService.getMailsData();
      const filteredData = data.filter(item => !item.isDeleted);
      setMailList(filteredData);

      const paymentMethods = await FirebaseService.getPaymentMethodsData();
      const filteredpaymentMethods = paymentMethods.filter(item => !item.isDeleted);
      setPaymentMethods(filteredpaymentMethods);
     
    };
  
    fetchData();
  }, []);
  
  const handleCheckboxChange = (mailId) => {
    setCheckedMails((prevCheckedMails) => {
      const isChecked = prevCheckedMails.includes(mailId);
  
      if (isChecked) {
        return prevCheckedMails.filter((id) => id !== mailId);
      } else {
        return [...prevCheckedMails, mailId];
      }
    });
  };
  
  const generateColumns = (detailedSelectedItems) => {
    const hasProductString = detailedSelectedItems.length > 0 && 'Ürün' in detailedSelectedItems[0];
    if (hasProductString) {
        return [
            'Ürün',
            'Adet',
            'Fiyat'
        ];
    } else {
        return [
          'Ürün',
          'Kalite',
          'Yüzey',
            'Kalınlık',    
            'En',
            'Boy',
            'Adet',
            'Fiyat'
        ];
    }
  };

  const isDateBeforeToday = (dateString) => {
    const selectedDate = new Date(dateString);
    const today = new Date(); 
    selectedDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
      return selectedDate < today;
  };
  
  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    convertImageToBase64('/images/prosis-logo.svg');
  //  console.log("base64Image",base64Image)
  }, []);

  const convertImageToBase64 = (url) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result.split(',')[1]); // "data:image/jpeg;base64," kısmını kaldırmak için split kullanıyoruz
        };
        reader.readAsDataURL(blob);
      })
      .catch(error => console.error('Error:', error));
  };



  const goExcel = async () => { 
    try {
    
      if ( !paymentMethod) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Ödeme vaadesi boş olamaz",
          placement: 'topRight'
        });
        return;
      }
      if (isDateBeforeToday(deadline) || !deadline ){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Teslim tarihi bugünün tarihinden küçük veya boş olamaz.",
          placement: 'topRight'
        });
        return;
      }
      const binaryString = await readFile(ExportReportCardTemplate);
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

    
      const detailedSelectedItems = selectedItemsDetails.map(productInfo => {
        return {
          Ürün: "Sac Lehva",
          Kalite: productInfo.productDictionary?.quality || '-',
          Yüzey: productInfo.productDictionary?.surface || '-',
          Kalınlık: productInfo.productDictionary?.thickness || '-',  
          En: productInfo.productDictionary?.width || '-',
          Boy: productInfo.productDictionary?.height || '-',
          Adet: productInfo.piece || '-',
          Fiyat: ' ',

        };
      });

      const formattedDeadline = new Date(deadline);
      const formattedDeadlineString = `${formattedDeadline.getDate().toString().padStart(2, '0')}.${(formattedDeadline.getMonth() + 1).toString().padStart(2, '0')}.${formattedDeadline.getFullYear()}`;
      worksheet['H4'] = { t: 's', v: formattedDeadlineString }; // termin tarihi
      

     // worksheet['H3'] = { t: 's', v: record.companyName }; // şirket adı
     worksheet['F3'] = { t: 's', v:  "" }; // şirket adı

     // worksheet['H4'] = { t: 's', v: deadline }; // termin tarihi
      worksheet['H5'] = { t: 's', v: paymentMethod }; // ödeme vaadesi

      let rowIndex = 8; 
      const columnHeaders = ['Ürün', 'Kalite', 'Yüzey', 'Kalınlık',  'En', 'Boy', 'Adet', 'Fiyat'];

      detailedSelectedItems.forEach(item => {
        rowIndex++;
        columnHeaders.forEach((key, columnIndex) => {
            const value = item[key] || '-';
            worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex + 1 })] = { t: 's', v: value };
        });
    });
    
  //sağdaki basliklar
  /*
    worksheet['F3'].s = {
        font: {
          name: 'Helvetica',
            sz: 10,
            bold: true,
            color: "#000000",
        },
    }*/
    worksheet['F4'].s = {
        font: {
          name: 'Helvetica',
            sz: 10,
            bold: true,
            color: "#000000",
        },
    }
    worksheet['F5'].s = {
      font: {
        name: 'Helvetica',
          sz: 10,
          bold: true,
          color: "#000000",
      },
    }
    worksheet['F6'].s = {
      font: {
        name: 'Helvetica',
          sz: 10,
          bold: true,
          color: "#000000",
      },
    }

    //sağdaki cevaplar
    //tablodaki baslikklar
    worksheet['B9'].s = {
          font: {
            name: 'Tw Cen MT (Headings)',
              sz: 12,
              bold: true,
              color: "#000000",
              underline: true
          },
    }
    worksheet['C9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
    }
    worksheet['D9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['E9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['F9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['G9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['H9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['I9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
      
    //orta
    worksheet['B36'].s = {
        font: {
            name: 'arial',
            sz: 11,
            bold: true,
            color: "#000000"
        },
       
    }

    worksheet['B38'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B39'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B40'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
      
    }
    worksheet['B41'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B42'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B43'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
      
    }
    //en alt
    worksheet['B45'].s = {
        font: {
            name: 'Tw Cen MT (Headings)',
            sz: 16,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"center"
        },
    }
    worksheet['B46'].s = {
     
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B47'].s = {
     
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B48'].s = {
     
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B49'].s = {
      
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    // Kenarlık stili
    const borderStyle = {
      style: 'thin',
      color: { rgb: '000000' } // Siyah renk
    };

    // Belirtilen aralığa tam kenarlık uygulama
    for (let C = 0; C <= 21; ++C) {
      for (let R = 9; R <= 34; ++R) {
        const cellAddress = { r: R, c: C };
        const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
        if (cell) {
          cell.s = {
            border: {
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
              bottom: borderStyle
            }
          };
        }
      }
    }
      
  


    worksheet["!images"] = [{
      "!pos": {
        c: 1, r: 2, x: 0, y: 0, // B3 hücresinin sol üst köşesinde başla
        C: 1, R: 2, X: 0, Y: 0  // B3 hücresinin sol üst köşesinde bitir (B3 kapsayacak şekilde)
      },
      "!datatype": "base64",
      "!data": base64Image
    }];
    

     XLSX.writeFile(workbook, `Satın_Alma_Teklif_${record.offerJobCode}.xlsx`, { cellStyles: true, bookImages: true });
   
  } catch (error) {
      console.log("error:::", error);
    }
    
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      fetch(file)
        .then((response) => response.blob())
        .then((file) => reader.readAsBinaryString(file));
    });
  };

  function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
  }


  const goMail = async () => { 
    try {
      setLoading(true);
      const mailTo = Array.isArray(checkedMails) ? checkedMails.join(',') : '';
    //  console.log("checkedMails",checkedMails,mailList)
      if(checkedMails.length < 3){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Gönderici listesinden miminum 3 mail seçmelisiniz.",
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }

      
      if ( !paymentMethod) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Ödeme vaadesi boş olamaz",
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }
      if (isDateBeforeToday(deadline) || !deadline ){
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Teslim tarihi bugünün tarihinden küçük veya boş olamaz.",
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }
      const binaryString = await readFile(ExportReportCardTemplate);
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

    
      const detailedSelectedItems = selectedItemsDetails.map(productInfo => {
        return {
          Ürün: "Sac Lehva",
          Kalite: productInfo.productDictionary?.quality || '-',
          Yüzey: productInfo.productDictionary?.surface || '-',
          Kalınlık: productInfo.productDictionary?.thickness || '-',  
          En: productInfo.productDictionary?.width || '-',
          Boy: productInfo.productDictionary?.height || '-',
          Adet: productInfo.piece || '-',
          Fiyat: ' ',

        };
      });

      if (detailedSelectedItems.length === 0) {
        console.error('Excel dosyasını oluşturmak için veri bulunamadı.');
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Seçili ürün bulunmamaktadır.",
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }


      const formattedDeadline = new Date(deadline);
      const formattedDeadlineString = `${formattedDeadline.getDate().toString().padStart(2, '0')}.${(formattedDeadline.getMonth() + 1).toString().padStart(2, '0')}.${formattedDeadline.getFullYear()}`;
      worksheet['H4'] = { t: 's', v: formattedDeadlineString }; // termin tarihi
      

     // worksheet['H3'] = { t: 's', v: record.companyName }; // şirket adı
     worksheet['F3'] = { t: 's', v:  "" }; // şirket adı

     // worksheet['H4'] = { t: 's', v: deadline }; // termin tarihi
      worksheet['H5'] = { t: 's', v: paymentMethod }; // ödeme vaadesi

      let rowIndex = 8; 
      const columnHeaders = ['Ürün', 'Kalite', 'Yüzey', 'Kalınlık',  'En', 'Boy', 'Adet', 'Fiyat'];

      detailedSelectedItems.forEach(item => {
        rowIndex++;
        columnHeaders.forEach((key, columnIndex) => {
            const value = item[key] || '-';
            worksheet[XLSX.utils.encode_cell({ r: rowIndex, c: columnIndex + 1 })] = { t: 's', v: value };
        });
    });
    
  //sağdaki basliklar
  /*
    worksheet['F3'].s = {
        font: {
          name: 'Helvetica',
            sz: 10,
            bold: true,
            color: "#000000",
        },
    }*/
    worksheet['F4'].s = {
        font: {
          name: 'Helvetica',
            sz: 10,
            bold: true,
            color: "#000000",
        },
    }
    worksheet['F5'].s = {
      font: {
        name: 'Helvetica',
          sz: 10,
          bold: true,
          color: "#000000",
      },
    }
    worksheet['F6'].s = {
      font: {
        name: 'Helvetica',
          sz: 10,
          bold: true,
          color: "#000000",
      },
    }

    //sağdaki cevaplar
    //tablodaki baslikklar
    worksheet['B9'].s = {
          font: {
            name: 'Tw Cen MT (Headings)',
              sz: 12,
              bold: true,
              color: "#000000",
              underline: true
          },
    }
    worksheet['C9'].s = {
        font: {
          name: 'Tw Cen MT (Headings)',
            sz: 12,
            bold: true,
            color: "#000000",
            underline: true
        },
    }
    worksheet['D9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['E9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['F9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['G9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['H9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
    worksheet['I9'].s = {
      font: {
        name: 'Tw Cen MT (Headings)',
          sz: 12,
          bold: true,
          color: "#000000",
          underline: true
      },
    }
      
    //orta
    worksheet['B36'].s = {
        font: {
            name: 'arial',
            sz: 11,
            bold: true,
            color: "#000000"
        },
      
    }

    worksheet['B38'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B39'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B40'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B41'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B42'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    worksheet['B43'].s = {
      font: {
          name: 'Calibri',
          sz: 9,
          color: "#000000"
      },
     
    }
    //en alt
    worksheet['B45'].s = {
        font: {
            name: 'Tw Cen MT (Headings)',
            sz: 16,
            bold: true,
            color: "#000000"
        },
        alignment : {
          horizontal :"center"
        },
    }
    worksheet['B46'].s = {
     
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B47'].s = {
     
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B48'].s = {
     
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    worksheet['B49'].s = {
     
      font: {
        name: 'Tw Cen MT (Headings)',
        sz: 10,
        color: "#000000"
    },
    alignment : {
      horizontal :"center"
    },
    }
    // Kenarlık stili
    const borderStyle = {
      style: 'thin',
      color: { rgb: '000000' } // Siyah renk
    };

    // Belirtilen aralığa tam kenarlık uygulama
    for (let C = 0; C <= 21; ++C) {
      for (let R = 9; R <= 34; ++R) {
        const cellAddress = { r: R, c: C };
        const cell = worksheet[XLSX.utils.encode_cell(cellAddress)];
        if (cell) {
          cell.s = {
            border: {
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
              bottom: borderStyle
            }
          };
        }
      }
    }
      
  


    worksheet["!images"] = [{
      "!pos": {
        c: 1, r: 2, x: 0, y: 0, // B3 hücresinin sol üst köşesinde başla
        C: 1, R: 2, X: 0, Y: 0  // B3 hücresinin sol üst köşesinde bitir (B3 kapsayacak şekilde)
      },
      "!datatype": "base64",
      "!data": base64Image
    }];
    

    //XLSX.writeFile(workbook, `Satın_Alma_Teklif_${record.offerJobCode}.xlsx`, { cellStyles: true, bookImages: true });

  
    const checkedRequestedProductInfo = selectedItemsDetails.map(productInfo => {
      if (productInfo && productInfo.productDictionary) {
        const item = {
          product: "Sac Levha",
          thickness: productInfo.productDictionary.thickness || '-',
          quality: productInfo.productDictionary.quality || '-',
          surface: productInfo.productDictionary.surface || '-',
          width: productInfo.productDictionary.width || '-',
          height: productInfo.productDictionary.height || '-',
          piece: productInfo.piece || '-',
          jobCode: productInfo.jobCode || '-',
          id: productInfo.id || '-',
          minPiece: productInfo.minPiece || '-',
          code: productInfo.code || '-',

        };
      //  console.log(item); 
        return item;
      } else {
        const item = {
          product: productInfo.productString || '-',
          piece: productInfo.piece || '-',
          jobCode: productInfo.jobCode || '-',
          id: productInfo.id || '-',
          minPiece: productInfo.minPiece || '-',
          code: productInfo.code || '-',
        };
     //   console.log(item); 
        return item;
      }
    });
    let base64File; 
    const blob = new Blob([s2ab(XLSX.write(workbook, { type: 'binary' }))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
   
    const reader = new FileReader();
    
  //  console.log("checkedRequestedProductInfo",checkedRequestedProductInfo)

    const folderName = 'sentOffers';
    const fileName = `Satin_Alma_Talep_${record.offerJobCode}.xlsx`;
    const sentExcelDocumentUrl = await StoregeService.uploadDocumentAndGetURL(folderName, blob, fileName);
   
    console.log('Belge başarıyla yüklendi. İndirme URL\'si:', sentExcelDocumentUrl);
      
    const result = await FirebaseService.updatePurchaseRequestData(record.key,checkedMails,paymentMethod,deadline,sentExcelDocumentUrl,checkedRequestedProductInfo);
    if (result) {
      console.log("update oldu")
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: "Doküman başarıyla kaydedildi",
        placement: 'topRight'
      });
      setLoading(true);

     reader.onload = function(event) {
       const base64File = event.target.result.split(',')[1];
     
       checkedMails.forEach((email) => {
         const templateParams = {
           user_email: email,
           from_name: 'Pars Makine',
           message: 'webden gidiyor',
           file: base64File,
         };
         
        // console.log("templateParams", templateParams);
         
         emailjs.send(serviceId, templateId, templateParams, publicKey)
           .then(
             (response) => {
               console.log('Email sent successfully to ' + email + ':', response);
               NotificationService.openSuccessNotification({
                 title: 'İşlem Başarılı',
                 description: email + " adresine mail başarıyla gönderildi",
                 placement: 'topRight'
               }); 
               setLoading(false);
               setTimeout(() => {
                 navigate("/buyerpage");
               }, 500);         
             },
             (error) => {
               console.error('Failed to send email to ' + email + ':', error);
             }
           );
       });
     };
     
     reader.onloadend = function() {
       console.log("Dosya yükleme işlemi tamamlandı.");
     };
     
     reader.readAsDataURL(blob);


    } else {
      console.log("update olmadı")
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
        placement: 'topRight'
      });
      setLoading(false);
      return;
    }
     
   
  } catch (error) {
      console.log("error:::", error);
    }
    
  };
  const onChange = (value) => {
    setPaymentMethod(value)
     if (value === 'newPaymentMethod') {
      console.log('Yeni ödeme vaadesi Ekle seçildi');
     // setIsModalVisibleCompany(true);
    }
  };

  const handleMail = () => {
      setIsModalVisibleMail(true);
  };
  
  const handleModalCancelMail = () => {
      setIsModalVisibleMail(false);
  };
  
  const handleMailFinish = () => {
      setIsModalVisibleMail(false); 
  };

  const handleDateChange = (date, dateString) => {
    const formattedDate= new Date(date);
    //const formattedDate = moment(date).format('DD.MM.YYYY');
   // console.log("formattedDate", typeof formattedDate)
    setDeadline(formattedDate);
  };

  const columns = [
      {
        title: ' ',
        dataIndex: '',
        key: '',
        width: 10,
        render: (text, record) => (
            <Checkbox
            onChange={() => handleCheckboxChange(record.email)}
            checked={checkedMails.includes(record.email)}
            style={{ fontWeight: "500" }}
          />
            
       
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>   {text}</span>,
    },
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>   {text}</span>,
  },
    
  ];

  
  const [formData, setFormData] = useState({
    from_name: '',
    TEST: null, // Dosya için state
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      TEST: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const templateParams = {
        from_name: formData.from_name,
        user_email: "sena.ozturk@appelier.net",
        TEST: formData.TEST,
      };
      await emailjs.send(
        serviceId,
        templateId,
        templateParams,
        publicKey
      );
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email. Please try again later.');
    }
  };
  const today = moment().startOf('day'); 

  const disabledDate = (current) => {
    return current && current < today;
  }
  return ( 
    <div style={{ display: "flex", flexDirection: "column",padding:"10px 100px"}}>
  
      <h2 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}> Satın Alma Gelen Talep</h2>
          
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      
 
        <div style={{ textAlign: "left", flex: "1 0 25%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Sipariş Kodu</p>
          {record && record.offerJobCode !== null ? record.offerJobCode : '-'}
        </div>
       

        <div style={{ textAlign: "left", flex: "1 0 25%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Ürün</p>
          {record && record.productType === 'raw' ? 'Saç Levha' : 'Diğer'}
        </div>

        <div style={{ textAlign: "left", flex: "1 0 25%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Ödeme Vaadesi</p>
          <Select
                onChange={onChange}
                placeholder="Ödeme Vaadesi Seçiniz"
                style={{  width:"250px" }}
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
             /*   options={[
                  ...paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod })),
                  { label: 'Yeni Ödeme vaadesi Ekle', value: 'newPaymentMethod' },
                ]}*/
                options={paymentMethods.map(item => ({ label: item.paymentMethod, value: item.paymentMethod }))}
                className="input-style" 

              />

        </div>


        <div style={{ textAlign: "left", flex: "1 0 25%" }}>
          <p style={{ fontWeight: "bold", color: colors.blue }}>Termin Tarihi</p>
        
          <DatePicker  className="input-style"  style={{ width: '100%' }} placeholder="Termin Tarihi" format="DD-MM-YYYY" onChange={handleDateChange} disabledDate={disabledDate}
          />

        </div>
      </div>

{/* 
      
      <form onSubmit={handleSubmit}>
      <div className="field">
        <label htmlFor="from_name">from_name</label>
        <input
          type="text"
          name="from_name"
          id="from_name"
          value={formData.from_name}
          onChange={handleChange}
        />
      </div>
      <div className="field">
        <label htmlFor="user_email">user_email</label>
        <input
          type="text"
          name="user_email"
          id="user_email"
          value={formData.user_email}
          onChange={handleChange}
        />
      </div>
      <div className="field">
        <label htmlFor="TEST">TEST</label>
        <input
          type="file"
          name="TEST"
          id="TEST"
          onChange={handleChange}
        />
      </div>
      <input type="submit" value="Send Email" />
    </form>
  */}
        <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"75px", textAlign:"left"  }}>Gönderici Listesi</div>
        <Table
         style={{}}
            locale={{
              emptyText: 'Henüz email bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={mailList}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }} onClick={handleMail}>
          Yeni Mail Talep Et
        </Button>

             <Modal
                title="Mail Ekleme Talebi"
                visible={isModalVisibleMail}
                onCancel={handleModalCancelMail}
                footer={null}
              >
              <AddRequestAdminMail onClose={handleMailFinish} />
              </Modal> 

        <div>
          <Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }} onClick={goExcel}>
            Excel İndir
          </Button>

          <Button style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }} onClick={goMail}>
            Mail At
          </Button>
          {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
               
        </div>

        
      </div>
    



    </div>

  )};
export default PurchaseRequestSendMail;