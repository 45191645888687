import  {oneSignal}  from "../environments/environment"

    const sendNotification = async (title,desc,users) => {
      console.log("Users:", users);  // Log the users here
      const filteredUsers = users.filter(user => user.trim() !== '');
      console.log("Filtered Users:", filteredUsers);

      try {
        const options = {
          method: 'POST',
          headers: {
            accept: 'application/json',
            Authorization: `Basic ${oneSignal.apiKey}`,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            include_player_ids: filteredUsers,
            contents: {  en : desc },
            headings : { en : title },
            app_id:oneSignal.appId,
          })
        };
          fetch('https://onesignal.com/api/v1/notifications', options)
          .then(response => {
            console.log("Response Status:", response.status);
            return response.json();
          })
          .then(responseBody => {
            console.log("Response Body:", responseBody);
            return responseBody;
          })
          .then(response => 
            {
             return response;
            }
            
          ).catch(err => {
            console.log("errr", err);
            return err;
          });
      } catch (error) {
        console.log("errr", error);
        return error
      }
    };

    const OneSignalService = {
      sendNotification
    }
    
    export default OneSignalService;

