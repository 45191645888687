import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";
import  { colors}  from "../../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from "@ant-design/icons";


const OffersGivenList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [filteredItems, setFilteredItems] = useState([]); 
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);


 /* const fetchData = async () => {
    const data = await FirebaseService.getOpenOrderData();
    const filteredData = data.filter(item => !item.isDeleted && item.isWaiting );
    if (filteredData) {
      let updatedItems = filteredData.map(item => {
      
        let newStatus = "Unknown";
           if (item.isWaiting && !item.isSetup && item.orderType === "order" && item.stock != null ) {
          newStatus = "Sipariş,Setup Bekleniyor";
        } 
        else if (item.isWaiting && !item.isSetup && item.orderType === "order" &&  item.stock == null) {
          newStatus = "Sipariş, Güncel Stok Seçimi Bekleniyor";
        } 
        else if (item.isWaiting && !item.isSetup && item.orderType === "offer" && item.isOfferApproved && item.isOffer ) {
          newStatus = "Onaylandı, Setup Bekleniyor";
        }
        else if (item.isWaiting && item.isSetup && item.orderType === "offer" && !item.isOffer) {
          newStatus = "Teklif Bekleniyor";
        } 
        else if (item.isWaiting && !item.isSetup && item.orderType === "offer" && !item.isOffer) {
          newStatus = "Teklif, Setup Bekleniyor";
        } 
        else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && !item.isOfferSetupUpdate  && !item.isOfferDenied) {
          newStatus = "Teklif Verildi";
        }
        else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && item.isOfferApproved) {
          newStatus = "Onaylandı"; //bu durumun görünmeesi lazım onaylanan tekliflere düşcek
        }

        else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && item.isOfferChange  && !item.isOfferSetupUpdate  && !item.isOfferDenied) {
          newStatus = "Onaylanmadı,Teklif Değişikliği";
        }
        else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && item.isOfferSetupUpdate  && !item.isOfferDenied) {
          newStatus = "Onaylanmadı,Setup Düzenleme";
        }
        else if (item.isWaiting && item.isSetup && item.orderType === "offer" && item.isOffer && !item.isOfferApproved && !item.isOfferChange  && !item.isOfferSetupUpdate  && item.isOfferDenied) {
          newStatus = "Onaylanmadı,Reddedildi";
        }
        

        return { ...item, status: newStatus };
      });

      const desiredStatuses = [
        "Teklif Bekleniyor",
        "Teklif Verildi",
        "Onaylanmadı, Teklif Değişikliği",
        "Onaylanmadı, Reddedildi"
      ];
      
      const filteredStatusItems = updatedItems.filter(item => desiredStatuses.includes(item.status));
    //  const limitedData = filteredStatusItems.slice(0, 5); 
      setItems(filteredStatusItems);
      setFilteredItems(filteredStatusItems)
    }
  
  };*/
  
  useEffect(() => {
  
    window.scrollTo(0, 0);

    const fetchData = async () => {
      if (record) { 
        console.log("record", record);
        setItems(record.data);
      }
    };

    fetchData();
  }, [record]);
  
  

  const goEditPage = (record) => {
    navigate('/waitingopenorderdetail', { state: { record } })
  };

  const goDelete = async (record) => {
    console.log("delete", record);
    let success = true;
    setLoading(true);
  
    try {
      // Open Order tablosundaki ilgili teklifleri kriterlere göre silme (isDeleted true yapma)
      const allOpenOrder = await FirebaseService.getOpenOrderData();
      const filteredOpenOrder = allOpenOrder.filter(item => 
        item.isDeleted === false &&
        item.isWaiting === true &&
        item.jobCode === record.jobCode
      );
  
      if (filteredOpenOrder.length > 0) {
        for (const item of filteredOpenOrder) {
          const resultOpenOrder = await FirebaseService.deleteOpenOrderDataIsDeleted(item.key);
          if (!resultOpenOrder) throw new Error("Open order silinemedi.");
        }
      }
  
      // Setup verisinin tamamen silinmesi
      const setupData = await FirebaseService.getSetupData();
      const filteredSetup = setupData.filter(item => 
        item.jobCode === record.jobCode 
      );
  
      if (filteredSetup.length > 0) {
        
          const resultSetup = await FirebaseService.deleteSetupData(filteredSetup.key);
          if (!resultSetup) throw new Error("Setup silinemedi.");
        
      }
  
      // İş kodu verisinin tamamen silinmesi
      const jobCodes = await FirebaseService.getJobCodesData();
      const filteredJobCodes = jobCodes.filter(item => 
        item.jobCode && item.jobCode === record.jobCode && !item.isDeleted
      );
  
      if (filteredJobCodes.length > 0) {
     
          const resultJobCode = await FirebaseService.deleteJobCodeData(filteredJobCodes.key);
          if (!resultJobCode) throw new Error("İş kodu silinemedi.");
        
      }
  
      NotificationService.openSuccessNotification({
        title: 'İşlem Başarılı',
        description: 'Teklif başarıyla silindi.',
        placement: 'topRight'
      });
      setLoading(false);

      if (success) {
       // fetchData()
      }
      
    } catch (error) {
      console.error("Silme işlemi hatası:", error);
      success = false;
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: error.message || 'Teklif silme işlemi başarısız oldu.',
        placement: 'topRight'
      });
    } setLoading(false)
    
  };
  

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase();
      const itemValue = item[dataIndex]?.toString().toLowerCase();
      return (
        !item.isDeleted &&
        (value ? itemValue.includes(value) : true)
      );
    });
    setFilteredItems(filteredData);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setFilteredItems(items); // Tüm öğeleri yeniden ayarla
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          id="search-input"
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, background: colors.blue }}
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });


  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName'),
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      ...getColumnSearchProps('jobCode'),
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Proje Kodu',
      dataIndex: 'projectCode',
      key: 'projectCode',
      ...getColumnSearchProps('projectCode'),
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Programcı',
      dataIndex: 'programmerName',
      key: 'programmerName',
      ...getColumnSearchProps('programmerName'),
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
         { text }
        </span>
        
      )
 },
 {
  title: 'Setup İsmi',
  dataIndex: 'setupName',
  key: 'setupName',
  ...getColumnSearchProps('setupName'),
  render: (text, record) => (
    <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
     { text ? text : "-" }
    </span>
    
  )
  },
  {
    title: 'Durum',
    dataIndex: 'status',
    key: 'status',
    ...getColumnSearchProps('status'),
    render: (text, record) => {
      let statusColor = "#000000"; 

      switch (text) {
        case "Teklif Bekleniyor":
          statusColor = colors.orange; 
          break;
        case "Teklif Verildi":
          statusColor = colors.green; 
          break;
        case "Onaylanmadı, Teklif Değişikliği":
          statusColor = colors.gray
          break;
        case "Onaylanmadı, Reddedildi":
          statusColor = colors.gray
          break;
        default:
          statusColor = "#000000"; 
      }

      return <span style={{ color: record.isDeleted ? "#00000080" : statusColor }}>{text}</span>;
    },
  },

   
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
        width: 300,
        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
        sorter: (a, b) => {
          const deadlineA = a.deadline ? a.deadline.seconds : 0;
          const deadlineB = b.deadline ? b.deadline.seconds : 0;
          return deadlineA - deadlineB;
      },
      },
      
         
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      },
/*
      {
        title: ' ',
        dataIndex: 'delete',
        key: 'delete',
        render: (text, record) => (
          <img
          src="/images/delete.svg"
            alt="Detayı Gör"
            onClick={() => goDelete(record)}
            style={{ cursor: 'pointer', width: '20px', height: '20px' }} 
          />
        ),
      }*/
      
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Verilen Teklifler</h3>
        <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="İş Kodu Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filteredData = items.filter(item => {
            const jobCode = item.jobCode.toLowerCase() ;
            return jobCode.includes(searchValue);
          });
          setItems(filteredData);
        }}
      />

        <Table
            locale={{
              emptyText: 'Henüz verilen teklif bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
           {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>


  };
  export default OffersGivenList;
 