import React,{useState,useEffect} from "react";
import { Button, Form, Input, Space, Select, Checkbox,Spin, DatePicker } from 'antd';
import NotificationService from "../../services/antNotificationService";
import FirebaseService from "../../services/firebaseService";
import  {colors}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';
import { auth } from "../../services/authService";
import { useLocation  } from 'react-router-dom';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword,signInWithEmailAndPassword,signOut } from "firebase/auth";
import dayjs from 'dayjs';

const UserEdit = () => {
    const location = useLocation();
    const record = location.state && location.state.record;
    const [isManager, setIsManager] = useState(false);
    const [titles, setTitles] = useState([]); 
    const [units, setUnits] = useState([]); 
    const [loading, setLoading] = useState(false);
    const formattedBirthday = record.birthday ? dayjs(record.birthday, 'DD-MM-YYYY') : null;

    const navigate = useNavigate();

    useEffect(() => {
        console.log("redcor",record)
      const fetchData = async () => {
        try {
          const titles = await FirebaseService.getTitlesData();
          setTitles(titles);
          const units = await FirebaseService.getUnitsData();
          setUnits(units);

        } catch (error) {
          console.error('Firebase veri çekme hatası:', error);
        }
      };
      fetchData();
    }, []);
  

    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };
  
    const onFinish = async (values) => {
      setLoading(true)
      const currentUserEmail = localStorage.getItem('email');
      const currentUserPassword = localStorage.getItem('password');
      console.log("currentUserEmail",currentUserEmail,currentUserPassword)
      console.log("ismanager",isManager)
      
      if (record.password !== values.password) {
        console.log("Şifre farklı girmiş");
        
      try {
        console.log("Şifre farklı girmiş");
      
        // Şu anki kullanıcının oturumunu sonlandır
       // await signOut(auth);
       localStorage.clear();
      
        // Yeni kimlik bilgileriyle oturum aç
        const newUserCredential = await signInWithEmailAndPassword(auth, values.email, record.password);
        const newUser = newUserCredential.user;
      
        console.log("değitişrcek olan", values.email, record.password, values.password)
        await updatePassword(newUser, values.password);
      
        console.log("Şifre başarıyla değişti");
      
        await signOut(auth);
      
      

    console.log("mevcuttaki kullancı", currentUserEmail,currentUserPassword);
  
    await signInWithEmailAndPassword(auth,currentUserEmail, currentUserPassword)
    .then((userCredential) => {
      localStorage.setItem('accessToken',userCredential.user.accessToken)
      localStorage.setItem('email',userCredential.user.email)
      localStorage.setItem('uid',userCredential.user.uid)

        const data = FirebaseService.getUserByUid(userCredential.user.uid).then((data)=>{
          if(!data.userType.includes('admin') && !data.userType.includes('headAdmin') && !data.userType.includes('advisor') && !data.userType.includes("user"))
          {
            //console.log('data:', data);
            localStorage.clear();
            NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetkiniz bulunmamaktadır.',placement:'topRight'})
            return null;
          }

          
          localStorage.setItem('name',data.name)
          localStorage.setItem('surname',data.surname)
          localStorage.setItem('password',data.password)
          localStorage.setItem('userType',data.userType)
          localStorage.setItem('isManager',data.isManager)
          localStorage.setItem('title',data.title)
          localStorage.setItem('userUnit',data.userUnit)
          localStorage.setItem('authorizationPages',data.authorizationPages)
          

    //  NotificationService.openSuccessNotification({title:'',description:'Giriş Başarılı',placement:'topRight'})
/*
      setTimeout(() => {
        navigate("/userlist");
      }, 3000);
      */
     
        });
     
    })
    .catch((error) => {
      localStorage.clear();
      console.log("sorun var");


    });
    console.log("Eski kullanıcıyla oturum tekrar açıldı");
  
  } catch (error) {
    console.error("Şifre değiştirme hatası:", error.message);
    // Hata durumunda gerekli işlemleri gerçekleştir
  }
  
  
} 


        const currentDate =new Date()
        const data = {
          firstTransactionDate: record.firstTransactionDateEdit??currentDate,
          firstUserId: record.firstUserId??localStorage.getItem("uid"),
          lastTransactionDate: currentDate,
          lastUserId: localStorage.getItem("uid"),
          isDeleted: record.isDeleted,
        
          name: values.name,
          surname: values.surname,
          email: values.email ,
         password: values.password,
         userUnit: values.userUnit,
          isManager : isManager,
          title: values.title,
          birthday:values.birthday.format('DD-MM-YYYY')
        
        };
    
        try {
            
            const result = await FirebaseService.updateUser(record.key,data);
            if (result.success) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: "İşlem başarıyla kaydedildi.",
                placement: 'topRight'
              });
              setLoading(false)
              setTimeout(() => {
                navigate("/userlist");
              }, 2000);
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });setLoading(false)}
            
        }
        catch (error) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });setLoading(false)
        }
        
      };
   

   return ( 
     <div>
    
            <Form
          name="basic"
          labelCol={{
            span: 3,
            }}
            wrapperCol={{
            span: 8,
            }}
          initialValues={{
            name: record.name,
            surname: record.surname,
            email: record.email,
            password:record.password,
            title: record.title,
            userUnit: record.userUnit,
            isManager: record.isManager,
            birthday: formattedBirthday,

          }}
          style={{
            maxWidth: "100%",
            padding: "5% 0 0 5%",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >

     

            <Form.Item
             label="İsim"
            name="name"
            rules={[
                {
                required: true,
                message: 'Lütfen isim giriniz!',
                },
            ]}
         
            >
            <Input  className="input-style" style={{width:"100%"}} placeholder="İsim"/>
            </Form.Item>

            
          

            <Form.Item
             label="Soyisim"
            name="surname"
            rules={[
                {
                required: true,
                message: 'Lütfen soyisim giriniz!',
                },
            ]}
          
            >
              <Input className="input-style" style={{width:"100%"}}  placeholder="Soyisim"/>
            </Form.Item>

            <Form.Item
                    label="Doğum Tarihi"
                    name="birthday"
                    rules={[
                        {
                            required: true,
                            message: 'Lütfen doğum tarihi giriniz!',
                        },
                    ]}
                    value={record.birthday ? dayjs(record.birthday) : null}  // Ensure value is a dayjs object

                >
                    <DatePicker className="input-style" style={{ width: "100%" }} placeholder="Doğum Tarihi Seç" />
                </Form.Item>

            <Form.Item
             label="Telefon"
            name="email"
           
           
            >
              <Input className="input-style" style={{width:"100%"}}  placeholder="Telefon" disabled/>
            </Form.Item>

            <Form.Item
             label="Şifre"
            name="password"
            rules={[
                {
                required: true,
                message: 'Lütfen şifre giriniz!',
                },
            ]}
           
            >
              <Input className="input-style" style={{width:"100%"}}  placeholder="Parola"/>
            </Form.Item>


            <Form.Item
             label="Ünvan/Ünvanlar"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: 'Lütfen ünvan giriniz!',
                      },
                    ]}
                  
                  >
                    <Select
                      mode="tags"
                      placeholder="Ünvan Seç (Birden fazla seçilebilir.)"
                    
                      suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                      options={titles.map(item => ({ label: item.item, value: item.item }))}
                      className="input-style" style={{width:"100%"}} 
                    
                    />
           </Form.Item>


         <Form.Item
          
              name="isManager"
            >
              <Checkbox   defaultChecked={record.isManager} onChange={() => setIsManager(!record.isManager)} style={{fontWeight:"500"}}>
               Birim Sorumlusu
              </Checkbox>
            </Form.Item>
          
          
         <Form.Item
          label="Birim/Birimler"
            name="userUnit"
            rules={[
                {
                required: true,
                message: 'Lütfen birim giriniz!',
                },
            ]}
            
            >
          
            <Select
                    
                        mode="tags"
                        placeholder="Hangi Alanları Görebilir (Birden fazla seçilebilir.)"
                      
                        suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                        options={units.map(item => ({ label: item.unitName, value: item.unitName }))}
                        className="input-style" style={{width:"100%"}} 
                      />
         

         </Form.Item>

          
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:colors.blue,color:"white",fontWeight:"500"}}>Kaydet</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
   )};
   
export default UserEdit;