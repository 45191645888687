import { notification } from "antd";

export const environment = {
 
  //canlu
 
   firebase : {
    apiKey: "AIzaSyD78cGUn56qp989SRbAILpeiXzHukIJtBo",
    authDomain: "pars-58f51.firebaseapp.com",
    projectId: "pars-58f51",
    storageBucket: "pars-58f51.appspot.com",
    messagingSenderId: "248807110247",
    appId: "1:248807110247:web:da92cebcb34210aabf46c2",
    measurementId: "G-8NN2FL782Z"
   }


/*
//test
firebase : {
  apiKey: "AIzaSyByAkCaXB7pMHK_7yl9JcZTG8IDdN5DAb8",
  authDomain: "pars-test.firebaseapp.com",
  projectId: "pars-test",
  storageBucket: "pars-test.appspot.com",
  messagingSenderId: "161582962296",
  appId: "1:161582962296:web:c31e3ba982b1780bbcbf8d",
  measurementId: "G-SD8RZZN00E"
}
*/
   
}

 export const colors = {
  blue: "#2B7199",
  lightBlue:"#AFD3E7",
  green:"#84BB4C",
  gray :"#00000080",
  lightGray:"#D9D9D9",
  orange : "#DC8B2B",
  red:"#BB3213",
  lightRed: "#CF1D12",
};


export const emailJs = {
   serviceId : 'service_xjpa6no',
   templateIdSatisTeklif : 'template_nh5mhfl',
   templateIdSatinAlma : 'template_kb1l6dh',
   templateIdPdf : 'template_fyxufll',
   publicKey : 'lymT_VMfKGmDSBP1R'
};


export const notifications = [
  { key: '1', content: 'Lazer Kesim 5000KW Makinesi Arızalandı.' },
  { key: '2', content: 'Boya Hattı 3 Numaralı Fırın Sıcaklık Sorunu Yaşıyor.' },
  { key: '3', content: 'Montaj Hattı 2\'deki Robotik Kol Çalışmıyor.' },
  { key: '4', content: 'CNC Freze 2000\'de Kesici Alet Değişimi Gecikti.' },
  { key: '5', content: 'Paketleme Bölümünde Bant Sisteminde Duraksama Yaşandı.' },
  { key: '6', content: 'Depo Giriş Kontrolünde Stok Sayım Hatası Tespit Edildi.' },
  { key: '7', content: 'Enjeksiyon Makinesi 4 Numara Beklenmedik Durdu.' },
  { key: '8', content: 'Kalite Kontrol Bölümünde Sensör Arızası Oluştu.' },
  { key: '9', content: 'Makine Bakım Bölümünde Yağ Kaçağı Tespit Edildi.' },
  { key: '10', content: 'Soğutma Sistemi 2 Numaralı Kompresör Düşük Performans Gösteriyor.' }
];


export const permissionType = [
  { label: "Mazaret", value: "excuse" },
  { label: "Yıllık", value: "annual" },
];
export const placeholderMap = {
  height: 'Boy',
  width: 'En',
  quality: 'Kalite',
  thickness:"Kalınlık",
  surface:"Yüzey",
  piece:"Adet",
  totalKg:"Toplam Kilo",
  unitKg :"Birim Kilo",
  companyName: "Firma",
  statusText:"Durum",
  jobCode:"İş Kodu",
  projectCode:"Proje Kodu"

};

export const parsCollections = {
  titles: "titles",
  units:"units",
  users:"users",
  companies:"companies",
  allItems : "allItems",
  days : "days",
  purchaseRequests:"purchaseRequests",
  mails:"mails",
  contractManufacturing:"contractManufacturing",
  contractManufacturingProcesses:"contractManufacturingProcesses",
  suppliers:"suppliers",
  alarms:"alarms",
  stock:"stock",
  wastages:"wastages",
  openOrder:"openOrder",
  jobCodes : "jobCodes",
  checkCurrentPrice :"checkCurrentPrice",
  futureRaw:"futureRaw",
  addRequestsAdmin: "addRequestsAdmin",
  densities:"densities",
  returnRaw:"returnRaw",
  exitRaw:"exitRaw",
  setup:"setup",
  production:"production",
  paymentMethods :"paymentMethods",
  machines:"machines",
  fault:"fault",
  invoiceOpenOrder:"invoiceOpenOrder",
  parts:"parts",
  scraps: "scraps",
  notifications: "notifications",
  invoicePurchase:"invoicePurchase",
  invoiceCategory :"invoiceCategory",
  invoiceCategoryContents :"invoiceCategoryContents",
  expenses : "expenses",
  surfaces: "surfaces",
  news:"news",
  permissons:"permissions",
  announcements:"announcements",
  currency:"currency"

}

export const openweathermap = {
  apiKey: "50f4b5248488e9d0a7b0417228b1b4aa"
};


export const components = [
  { label: "Açık Sipariş", value: "openOrder" },
  { label: "Alarmlar", value: "alarms" },
  { label: "Gelecek Hammade", value: "futureRaw" },
  { label: "Güncel Stock", value: "stock" }

];

export const requestedProductType = [
  { label: "Saç Levha", value: "raw" },
  { label: "Diğer", value: "other" },
 
];
export const openOrderType = [
  { label: "Sipariş", value: "order" },
  { label: "Teklif", value: "offer" },
];
export const months = [
  { label: "Ocak", value: 1 },
  { label: "Şubat", value: 2 },
  { label: "Mart", value: 3 },
  { label: "Nisan", value: 4 },
  { label: "Mayıs", value: 5 },
  { label: "Haziran", value: 6 },
  { label: "Temmuz", value: 7},
  { label: "Ağustos", value: 8 },
  { label: "Eylül", value: 9 },
  { label: "Ekim", value: 10 },
  { label: "Kasım", value: 11},
  { label: "Aralık", value: 12 }
];

export const years = Array.from({ length: 2050 - 2024 + 1 }, (v, k) => {
  const year = 2024 + k;
  return { label: year, value: year };
});


export const qualityGroup = [
  { label: "Azot", value: "azot" },
  { label: "Oksijen", value: "oksijen" },
];

export const currency = [
  { label: "Euro", value: "EUR" },
  { label: "TL", value: "TL" },
  { label: "Dolar", value: "USD" },

 
];
export const machineType = [
  { label: "Lazer", value: "lazer" },
  { label: "Abkant", value: "abkant" },
];


export const adminMenu ={
 
  useredit:"Kullanıcı Düzenle",
  address:"Adresler",
  news:"Haberler",
  blogs:"Blog",
  services:"Hizmetler",
  trainings:"Eğitimler",
  // trainingrequestlist:"Eğitim Talepleri",
  notifications:"Bildirimler",
  trainerandauthor:"Eğitmen/Yazar",
  surveys:"Anketler",
  purchaseRequest:"Satın Alma Talepleri",
  story:"Hikaye",
  homePage:"Ana Sayfa",
  campaign:"Kampanyalar",
  webpage:"Web"

}


export const oneSignal ={
  apiKey:'NjBmMjZkNDQtZDhlOS00MWFmLTgwMmUtMWEzOTQ2YjY5Nzhh',
  appId:"e0c519bd-099e-483d-b0d7-06c56c2dae5f"
}

export const map ={
  apiKey:'AIzaSyB5KNg2z0mmF5so5NnYq6XiUE4cXRDTOnI'
}

export const Currencies = [
  { value: "USD", label: "United States Dollar" },
  { value: "EUR", label: "Euro" },
  { value: "JPY", label: "Japanese Yen" },
  { value: "GBP", label: "British Pound Sterling" },
  { value: "AUD", label: "Australian Dollar" },
  { value: "CAD", label: "Canadian Dollar" },
  { value: "CNY", label: "Chinese Yuan" },
  { value: "INR", label: "Indian Rupee" },
  { value: "BRL", label: "Brazilian Real" },
  { value: "RUB", label: "Russian Ruble" },
  { value: "TRY", label: "Turkish Lira" },
  { value: "ZAR", label: "South African Rand" },
  { value: "MXN", label: "Mexican Peso" },
  { value: "SGD", label: "Singapore Dollar" },
  { value: "NZD", label: "New Zealand Dollar" },
  { value: "SEK", label: "Swedish Krona" },
  { value: "CHF", label: "Swiss Franc" },
  { value: "NOK", label: "Norwegian Krone" },
  { value: "DKK", label: "Danish Krone" },
  { value: "KRW", label: "South Korean Won" },
  { value: "AED", label: "United Arab Emirates Dirham" },
];


export const consultingOptions = [
  { value: "3 Projeli Yıllık Danışmanlık", label: "3 Projeli Yıllık Danışmanlık" },
  { value: "2 Projeli Yıllık Danışmanlık" , label: "2 Projeli Yıllık Danışmanlık" },
  { value: "4 Projeli Yıllık Danışmanlık", label: "4 Projeli Yıllık Danışmanlık" },
  { value: "Sınırsız Projeli Yıllık Danışmanlık", label: "Sınırsız Projeli Yıllık Danışmanlık" },
];

export const adminMenuLogin = [
  {
    value: adminMenu.homePage,
    navigate: "'/adminpanel'",
  },
  {
  value: adminMenu.project,
  navigate: '/cdprojectlist',
  },
  {
    value:adminMenu.address,
    navigate:'/address'
  },
  {
    value:adminMenu.blogs,
    navigate:'/blogs'
  },
  {
    value:adminMenu.news,
    navigate:'/news'
  },
  {
    value:adminMenu.notifications,
    navigate:'/notifications'
  },
  {
    value:adminMenu.odsinnumber,
    navigate:'/odsinnumbers'
  },
  {
    value:adminMenu.services,
    navigate:'/services'
  },
  {
    value:adminMenu.surveys,
    navigate:'/surveys'
  },
  {
    value:adminMenu.trainerandauthor,
    navigate:'/trainerandauthor',
  },
  {
    value:adminMenu.trainings,
    navigate:'/trainings',
  },
  {
    value:adminMenu.useredit,
    navigate:'/useredit',
  },
  {
    value:adminMenu.purchaseRequest,
    navigate:'/purchaserequest',
  },
  {
    value:adminMenu.story,
    navigate:'/stories',
  },
  {
    value:adminMenu.campaign,
    navigate:'/campaignlist',
  },
  ]



   export const allCities = [
      { value: "Adana", label: "Adana" },
      { value: "Adıyaman", label: "Adıyaman" },
      { value: "Afyonkarahisar", label: "Afyonkarahisar" },
      { value: "Ağrı", label: "Ağrı" },
      { value: "Amasya", label: "Amasya" },
      { value: "Ankara", label: "Ankara" },
      { value: "Antalya", label: "Antalya" },
      { value: "Ardahan", label: "Ardahan" },
      { value: "Artvin", label: "Artvin" },
      { value: "Aydın", label: "Aydın" },
      { value: "Balıkesir", label: "Balıkesir" },
      { value: "Bartın", label: "Bartın" },
      { value: "Batman", label: "Batman" },
      { value: "Bayburt", label: "Bayburt" },
      { value: "Bilecik", label: "Bilecik" },
      { value: "Bingöl", label: "Bingöl" },
      { value: "Bitlis", label: "Bitlis" },
      { value: "Bolu", label: "Bolu" },
      { value: "Burdur", label: "Burdur" },
      { value: "Bursa", label: "Bursa" },
      { value: "Çanakkale", label: "Çanakkale" },
      { value: "Çankırı", label: "Çankırı" },
      { value: "Çorum", label: "Çorum" },
      { value: "Denizli", label: "Denizli" },
      { value: "Diyarbakır", label: "Diyarbakır" },
      { value: "Düzce", label: "Düzce" },
      { value: "Edirne", label: "Edirne" },
      { value: "Elazığ", label: "Elazığ" },
      { value: "Erzincan", label: "Erzincan" },
      { value: "Erzurum", label: "Erzurum" },
      { value: "Eskişehir", label: "Eskişehir" },
      { value: "Gaziantep", label: "Gaziantep" },
      { value: "Giresun", label: "Giresun" },
      { value: "Gümüşhane", label: "Gümüşhane" },
      { value: "Hakkâri", label: "Hakkâri" },
      { value: "Hatay", label: "Hatay" },
      { value: "Iğdır", label: "Iğdır" },
      { value: "Isparta", label: "Isparta" },
      { value: "İstanbul", label: "İstanbul" },
      { value: "İzmir", label: "İzmir" },
      { value: "Kahramanmaraş", label: "Kahramanmaraş" },
      { value: "Karabük", label: "Karabük" },
      { value: "Karaman", label: "Karaman" },
      { value: "Kars", label: "Kars" },
      { value: "Kastamonu", label: "Kastamonu" },
      { value: "Kayseri", label: "Kayseri" },
      { value: "Kırıkkale", label: "Kırıkkale" },
      { value: "Kırklareli", label: "Kırklareli" },
      { value: "Kırşehir", label: "Kırşehir" },
      { value: "Kilis", label: "Kilis" },
      { value: "Kocaeli", label: "Kocaeli" },
      { value: "Konya", label: "Konya" },
      { value: "Kütahya", label: "Kütahya" },
      { value: "Malatya", label: "Malatya" },
      { value: "Manisa", label: "Manisa" },
      { value: "Mardin", label: "Mardin" },
      { value: "Mersin", label: "Mersin" },
      { value: "Muğla", label: "Muğla" },
      { value: "Muş", label: "Muş" },
      { value: "Nevşehir", label: "Nevşehir" },
      { value: "Niğde", label: "Niğde" },
      { value: "Ordu", label: "Ordu" },
      { value: "Osmaniye", label: "Osmaniye" },
      { value: "Rize", label: "Rize" },
      { value: "Sakarya", label: "Sakarya" },
      { value: "Samsun", label: "Samsun" },
      { value: "Siirt", label: "Siirt" },
      { value: "Sinop", label: "Sinop" },
      { value: "Sivas", label: "Sivas" },
      { value: "Şanlıurfa", label: "Şanlıurfa" },
      { value: "Şırnak", label: "Şırnak" },
      { value: "Tekirdağ", label: "Tekirdağ" },
      { value: "Tokat", label: "Tokat" },
      { value: "Trabzon", label: "Trabzon" },
      { value: "Tunceli", label: "Tunceli" },
      { value: "Uşak", label: "Uşak" },
      { value: "Van", label: "Van" },
      { value: "Yalova", label: "Yalova" },
      { value: "Yozgat", label: "Yozgat" },
      { value: "Zonguldak", label: "Zonguldak" },
    ];