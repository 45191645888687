import React,{useEffect,useState} from "react";
import { Table, Button,Modal  } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import NewList from "../../../admin/News/newList";

const NewsList= () => {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
        try {
          const data = await FirebaseService.getNewData();
          const filteredData = data.filter(item => !item.isDeleted);
          const sortedData = filteredData.sort((a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate));

            setItems(sortedData);
            
        } catch (error) {
          console.error('Error fetching news data',error.message)
        }
      };
      
      
  
      fetchData();
    }, []);


      const columns = [
        {
            title: "",
            dataIndex: '',
            key: '',
            width: 100,
            render: (text, record) => (
                <div style={{ }}>
                    <img 
                        src={record.imageUrl} 
                        alt="" 
                        style={{ width: 200 , height: 150,borderRadius:"20px",}} 
                    />
                </div>
            ),
        },
        {
            title : "Başlık",
            dataIndex: 'title',
            key: 'title',
            width: 200,
            render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" , fontWeight:"bold" }}>{text}</span>,
          },
          {
            title : "Açıklama",
            dataIndex: 'description',
            key: 'description',
            width: 400,
            render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"  }}>{text}</span>,
          }
      ];

      return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Şirketten Haberler</h3>
         
          <Table
            locale={{
              emptyText: 'Henüz bir haber eklenmedi...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default NewsList;

  