import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';

const PurchaseRequestListLimited = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getPurchaseRequestData();
     
     // console.log("data",data)
     

      data.sort((a, b) => a.deadline.toMillis() - b.deadline.toMillis());  
      const limitedData = data.slice(0, 5); 
      setItemsLimited(limitedData)

      setItems(data)
    };

    fetchData();
  }, []);

  const goEditPage = (record) => {
    navigate('/purchaserequestdetail', { state: { record } })
  };


  const goPage = (record) => {
    navigate('/purchaserequestlist', { state: { record } })
  };

  const columns = [
   
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
   
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'Ürün',
      dataIndex: 'productType',
      key: 'productType',
    
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
      </span>,
    },
    /*
    {
      title: 'Birim',
      dataIndex: 'unitName',
      key: 'unitName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
    
    },
    */
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',

        render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
      },
      
      {
        title: 'Sipariş Kod',
        dataIndex: 'offerJobCode',
        key: 'offerJobCode',
        width: 200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
        </span>,
      },
      {
        title: 'Durum',
        dataIndex: '',
        key: '',
       
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}> 
        {record.productType === "raw" ?
        record.mailList?.length >= 3 ? "Teklif Gönderildi" : "Teklif Gönderilmedi"   :
        record.isPurchased ? "Satın Alındı" :   record.noPurchasedDescription ? 
        "Satın Alınmayacak" : "Satın Alınma Bekleniyor"
        }   
        </span>,
       
      },
      
      {
        title: ' ',
        dataIndex: 'important',
        key: 'important',
        render: (text, record) => {
          const deadlineDate = record.deadline.toDate(); 
          return moment(deadlineDate).isBefore(moment(), 'day') ? (
            <img width={30} height={20} alt="logo" src="/images/important.png" title="Termin Tarihi Geçmiştir" style={{ paddingBottom: "5%" }} />
          ) : null;
        },
      },
      /*
    {
        title: ' ',
        dataIndex: 'edit',
        key: 'edit',
        render: (text, record) => (
          <Button onClick={()=>goEditPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
          Detayı Gör
       </Button>
        ),
      }*/
  ];

  return  <div style={{
      //  justifyContent: 'center',
      //  alignItems: 'center',
     //   minHeight: '94vh',
       
      //  width:"50%",
        
      }}
      className="limited-list-border">
      <Button onClick={()=>goPage(items)} className="title-limited-list">
          Satın Alma Gelen Talepler 
       </Button>
          <Table
            locale={{
              emptyText: 'Henüz satın alma talebi bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default PurchaseRequestListLimited;

    