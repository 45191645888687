import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Space, Upload,Modal, Spin} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";
import AddRequestAdminCompany from "../addRequestAdmin/addRequestAdminCompany";
import AddRequestAdminUnit from "../addRequestAdmin/addRequestAdminUnit";
import OneSignalService from "../../../services/oneSignalService";


const AlarmAdd = ({ onClose }) => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]); 
    const [units, setUnits] = useState([]); 
    const [jobCodes, setJobCodes] = useState([]); 

    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
        const companies = await FirebaseService.getCompaniesData();
        const filteredCompanies = companies.filter(item => !item.isDeleted);
        setCompanies(filteredCompanies);
       
        const units = await FirebaseService.getUnitsData();
         const filteredUnits = units.filter(item => !item.isDeleted);
         setUnits(filteredUnits);

        

         const uniqueJobCodes = new Map();
         const jobCodes = await FirebaseService.getJobCodesData();

         jobCodes.forEach((item) => {
           if (!item.isDeleted && !uniqueJobCodes.has(item.item)) {
             uniqueJobCodes.set(item.item, item);
           }
       });
         const filteredJobCodes = Array.from(uniqueJobCodes.values());
         console.log("filteredJobCodes",filteredJobCodes)
         setJobCodes(filteredJobCodes);
        
      };
      fetchData();
    }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };

    const getOneSignalPlayerIds = async (unitName) => {
      try {
        const dataCollection = await FirebaseService.getUserData();

        const filteredUsers = dataCollection.filter(user => !user.isDeleted &&  user.userUnit.includes(unitName));
        
        console.log("filteredUsers", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.oneSignalPlayerId) {
            oneSignalPlayerIds.push(user.oneSignalPlayerId);
          }
        });
        
        console.log("oneSignalPlayerIds", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };

    const getOneSignalUserIds = async (unitName) => {
      try {
        const dataCollection = await FirebaseService.getUserData();

        const filteredUsers = dataCollection.filter(user => !user.isDeleted &&  user.userUnit.includes(unitName));
        
        console.log("filteredUsers user id", filteredUsers);
        
        const oneSignalPlayerIds = [];
        filteredUsers.forEach(user => {
          if (user.key) {
            oneSignalPlayerIds.push(user.key);
          }
        });
        
        console.log("oneSignalPlayerIds user id", oneSignalPlayerIds);
        
        return oneSignalPlayerIds;
      } catch (error) {
        console.error("Error getting OneSignal Player IDs:", error);
        return [];
      }
    };
//foto için
    const getBase64 = (file) =>
    new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    });
    const handleCancel = () => setPreviewOpen(false);
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
      };

    const uploadButton = (
        <div style={{width:"100px" }}>
          <img alt="plus" src="/images/uploadcamera.png"  width={30} height={30}/>
          <div
            style={{
              marginTop: 8,
              color: colors.blue
            }}
          >
            Fotoğraf Ekle
          </div>
        </div>
    );


    const [isModalVisibleUnit, setIsModalVisibleUnit] = useState(false);

    const handleModalCancelUnit = () => {
        setIsModalVisibleUnit(false);
    };
  
    const handleAddFinishUnit = () => {
        setIsModalVisibleUnit(false);
    };
  
  

  const [isModalVisibleCompany, setIsModalVisibleCompany] = useState(false);

  const handleModalCancelCompany = () => {
      setIsModalVisibleCompany(false);
  };

  const handleAddFinishCompany = () => {
      setIsModalVisibleCompany(false);
  };

        
    
    const onFinish = async (values) => {
      setLoading(true);
      if (values.unitName === "newUnit") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir birim seçiniz.',
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }
      if (values.companyName === "newCompany") {
        NotificationService.openErrorNotification({
          title: 'Hata',
          description: 'Lütfen var olan bir firma seçiniz.',
          placement: 'topRight'
        });
        setLoading(false);
        return;
      }

        const currentDate =new Date()
        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,

          companyName: values.companyName,
          title: values.title,
          unitName: values.unitName,
          jobCode: values.jobCode? values.jobCode: null,
          //imageUrl: values.imageUrl || null,
          description: values.description,
          
          isRequestRemoval:false,
          requestRemovalDescription:null,
          requestRemovalUserId:null,
          removalUserId:null,
          removalDescription: null
        };
        
        values.image=fileList.length > 0 ? values.image : null;
      try {
        if (values.image) {
          console.log("foto var")
            data.imageUrl = await FirebaseStorage.uploadImageAndGetURL(parsCollections.alarms, values.image.file);
        
        const result = await FirebaseService.addRecording(parsCollections.alarms,data);
        if (result.success) {
         
        
            //bildirim 
            const ids = await getOneSignalPlayerIds(values.unitName);
            const userIds = await getOneSignalUserIds(values.unitName);

            const sentUserIdsMap = new Map();
            userIds.forEach(userId => {
              sentUserIdsMap.set(userId, false);
            });
            
            const sentUserIds = Object.fromEntries(sentUserIdsMap);
            //console.log("sentUserIds mapppp", sentUserIds);

            if (ids.length > 0) {
              const oneSignalResult = await OneSignalService.sendNotification("", "Yeni Alarm Var", ids);
              console.log("OneSignal notification result:", oneSignalResult);
              const data = {
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
                isDeleted: false,
                sentUserIds:sentUserIds,
                firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                unitName:localStorage.getItem('userUnit'),
                title:"Yeni Alarm Var",
                companyName:null,
                jobCode:null,
                projectCode:null,
                machine:null,
                type:null,
                notice:"Lütfen alarmlarınızı kontrol ediniz.",
                description:values.title ? values.title : null
  
              };
              const result = await FirebaseService.addRecording(parsCollections.notifications,data);
              console.log("result",result)
            } else {
              console.log("No OneSignal Player IDs found.");
            }


          setLoading(false);

          onClose();
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false);

        }
        
        }
        
        else{
          console.log("foto yok")

          data.imageUrl = null;
          const result = await FirebaseService.addRecording(parsCollections.alarms,data);
          if (result.success) {
              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: result.message,
                placement: 'topRight'
              });
             
                //bildirim 
            const ids = await getOneSignalPlayerIds(values.unitName);
            const userIds = await getOneSignalUserIds(values.unitName);

            const sentUserIdsMap = new Map();
            userIds.forEach(userId => {
              sentUserIdsMap.set(userId, false);
            });
            
            const sentUserIds = Object.fromEntries(sentUserIdsMap);
            console.log("sentUserIds mapppp", sentUserIds);
            
            if (ids.length > 0) {
              const oneSignalResult = await OneSignalService.sendNotification("", "Yeni Alarm var", ids);
              console.log("OneSignal notification result:", oneSignalResult);
              const data = {
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
                isDeleted: false,
                sentUserIds:sentUserIds,
                firstUserNameSurname: localStorage.getItem('name') + " " +localStorage.getItem('surname'),
                unitName:localStorage.getItem('userUnit'),
                title:"Yeni Alarm Var",
                companyName:null,
                jobCode:null,
                projectCode:null,
                machine:null,
                type:null,
                notice:"Lütfen alarmlarınızı kontrol ediniz.",
                description:values.title ? values.title : null
  
              };
              const result = await FirebaseService.addRecording(parsCollections.notifications,data);
              console.log("result",result)
            } else {

              console.log("No OneSignal Player IDs found.");
            }
            
            setLoading(false);
             /*
            onClose();
              setTimeout(() => {
                window.location.reload();
              }, 500);
              */
            } else {
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);

            }
          /*
          NotificationService.openErrorNotification({
            title: 'Geçersiz Resim',
            description: "Lütfen resim seçiniz!",
            placement: 'topRight'
          });
          */
        }
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);

      }
    }; 


    const onChange = (value) => {
        console.log(`selected ${value}`);
        /* if (value === 'newUnit') {
          console.log('Yeni Birim Ekle seçildi');
          setIsModalVisibleUnit(true);
        }*/
       if (value === 'newCompany') {
          console.log('Yeni Şirket Ekle seçildi');
          setIsModalVisibleCompany(true);
        }
   };

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        
            <Form.Item
            name="companyName"
            rules={[
                {
                required: true,
                message: 'Lütfen firma adı giriniz!',
                },
            ]}
            >
            <Select
                onChange={onChange}
                placeholder="Firma Adı Seçiniz"
                suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                options={[
                  ...companies.map(item => ({ label: item.companyName, value: item.companyName })),
                  { label: 'Yeni Firma Ekle', value: 'newCompany' },
                ]}
                className="input-style" 
                            />
            </Form.Item>
            <Modal
                title="Firma Ekleme Talebi"
                visible={isModalVisibleCompany}
                onCancel={handleModalCancelCompany}
                footer={null}
              >
              <AddRequestAdminCompany onClose={handleAddFinishCompany} />
              </Modal>
            

            <Form.Item
                name="title"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen başlık giriniz!',
                    },
                ]}
               
                >
            <Input  className="input-style"  style={{width:"100%"}} placeholder="Alarm Başlığı"/>
            </Form.Item>

            <Form.Item
                name="unitName"
                rules={[
                    {
                    required: true,
                    message: 'Lütfen birim giriniz!',
                    },
                ]}
                >
                <Select
                    onChange={onChange}
                    placeholder="Birim Seçiniz"
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    /*options={[
                      ...units.map(item => ({ label: item.unitName, value: item.unitName })),
                      { label: 'Yeni Birim Ekle', value: 'newUnit' },
                    ]}  
                    */
                    options={units.map(item => ({ label: item.unitName, value: item.unitName }))}
                    className="input-style" 
                     />
            </Form.Item>

            <Modal
                title="Birim Ekleme Talebi"
                visible={isModalVisibleUnit}
                onCancel={handleModalCancelUnit}
                footer={null}
              >
              <AddRequestAdminUnit onClose={handleAddFinishUnit} />
              </Modal> 
            

            <Form.Item
                name="jobCode"
                
                >
                <Select
                    onChange={onChange}
                    placeholder="İş Kodu Seçiniz"
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={jobCodes.map(item => ({ label: item.item, value: item.item }))}
                    className="input-style" 
                />
            </Form.Item>

         
            <Form.Item 
            name="image"
          
            >
                    <Upload 
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                      //  className="generic-add-upload"
                        beforeUpload={() => false}
                        style={{width:"50px", height:"50px"}}
                        >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>                
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                      <img
                      alt="example"
                      style={{
                          width: '100%',
                      }}
                      src={previewImage}
                      />
            </Modal>
          

            <Form.Item
            name="description"
            rules={[
                {
                required: true,
                message: 'Lütfen açıklama giriniz!',
                },
            ]}
          
            >
           <Input  className="input-style"  style={{width:"100%"}} placeholder="Açıklama"/>
            </Form.Item>


        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

};

AlarmAdd.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

  export default AlarmAdd;