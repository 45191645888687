import React,{useEffect,useState} from "react";
import { Table, Button,Space,Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import  { colors}  from "../../../../environments/environment"
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../../../services/firebaseService";

const OrderOperationPrice = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.updatedRecord;
 const hasCompleteRequests = location.state && location.state.hasCompleteRequests;
 console.log("record",record)
 console.log("hasCompleteRequests",hasCompleteRequests)

  const [items, setItems] = useState([]);
  const [groupedParts, setGroupedParts] = useState({});

  const [abkantTwistShape, setAbkantTwistShape] = useState(false);
  const [abkantCounts, setAbkantCounts] = useState({}); 
  var abkantTotalNew = record.abkantTotalNew ;

  
  useEffect(() => {

    window.scrollTo(0, 0);

    const fetchData = async () => {
     
    //kalite için fiyat
    record.items.forEach(item => {
      item.parts.forEach(part => {
        const kgPrice = record.pricesQuality[part.quality];
        part.priceQuality = kgPrice * part.newWeight; 
      });
    });
            
   //makine için fiyat
    record.items.forEach(item => {
      const machine = item.machine;
      const fuel = item.fuel;
      item.parts.forEach(part => {
        const key = `${machine}-${fuel}`;
        const pricePerMinute = record.pricesTime[key];
        const partTimeInMinutes = convertToMinutes(part.newTime);
        const partCost = partTimeInMinutes * pricePerMinute;
        part.priceMachine = partCost; 
       // console.log(`Cost for part (${machine}-${matchedItem.fuel}):`, partCost);
       
      });
    });

    //taşıma ücreti
    const deliveryPrice = record.deliveryPrice
  
    //plazma için fiyat 
    record.items.forEach(item => {
      if (item.isPlazma) { // Eğer item'ın isPlazma alanı true ise
        item.parts.forEach(part => {
        //  console.log("part.newWeight", part.newWeight, record.pricesPlazma[true]);
          part.pricePlazma = record.pricesPlazma[true] * part.newWeight;
        //  console.log("part.pricePlazma", part.pricePlazma);
        });
      }
    });
    
    //abkant için fiyat
    if(record.abkantSelectedOperation === "Adet"){
        console.log("adet geldi")
        setAbkantTwistShape(true);
    }
   
    else{
      for (let i = 0; i < record.parts.length; i++) {
        const part = record.parts[i];
        if (part.operations.some(operation => operation.toLowerCase() === 'abkant')) {
          const abkantPrice = record.pricesAbkant && record.pricesAbkant.abkant ? record.pricesAbkant.abkant : 0;
          const totalAbkantPrice = part.newWeight * abkantPrice;
        //  console.log("abkanktpriceeee",abkantPrice,totalAbkantPrice,part.newWeight)
          part.pricesAbkant = totalAbkantPrice;
          record.parts[i] = part;
        }
      }
       
    }
   
    console.log("en sonki parts",record.parts)
   
    const groupedParts = hasCompleteRequests.reduce((acc, request) => {
      request.parts.forEach(part => {
        const key = part.partCode;
        const processName = request.processName; 
    
        if (!acc[key]) {
          acc[key] = {
            processData: {}, 
          };
        }
        if (!acc[key].processData[processName]) {
          acc[key].processData[processName] = {
            totalProfit: 0,
            totalUnitPrice: 0,
          };
        }

        acc[key].processData[processName].totalProfit = parseFloat(part.profit);
        acc[key].processData[processName].totalUnitPrice = parseFloat(part.unitPrice);
      });
      return acc;
    }, {});
    setGroupedParts(groupedParts);
    console.log("Grouped Parts:", groupedParts);
    
    const updatedItems = record.parts.map(item => {
      const partData = groupedParts[item.partNo]; 

      const unitPrice = parseFloat(calculateUnitPrice(item,partData));
      console.log("unitprice",unitPrice)
      const totalPrice = unitPrice * item.piece;
  
      return {
        ...item,
        unitPrice: unitPrice,
        totalPrice: totalPrice, 
        deliveryPrice : deliveryPrice
      };
    });


    
    if (record.abkantSelectedOperation === "Adet") {
      setAbkantTwistShape(true);
      console.log("Abkant seçilen işlem: Adet");
    } else {
      
      const abkantParts = record.parts.filter(part => part.operations.includes('ABKANT'));
      const totalWeightAbkant = abkantParts.reduce((sum, part) => sum + part.newWeight, 0);

      const totalTime = abkantParts.reduce((sum, part) => {
          const { hours, minutes, seconds } = parseTime(part.newTime);
          return sum + (hours * 3600) + (minutes * 60) + seconds;
      }, 0);

      console.log("Toplam abkant parçaların ağırlığı: totalWeightAbkant", totalWeightAbkant);
      console.log("Toplam abkant parçaların ağırlığı: record.abkantWeight.", record.abkantWeight);

      console.log("Toplam abkant parçaların kesim süresi (saniye bazında):", totalTime);

    
      for (let i = 0; i < record.parts.length; i++) {
          const part = record.parts[i];
          if (part.operations.some(operation => operation.toLowerCase() === 'abkant')) {
              let totalAbkantPrice = 0;
    
              if (record.abkantSelectedOperation === "Dakika") {
          
                  totalAbkantPrice = (record.totalPricesAbkant).Abkant
                  const partRatio = part.weightSetup / record.abkantWeight;
                  totalAbkantPrice = totalAbkantPrice * partRatio;
                     
              } else if (record.abkantSelectedOperation === "Kg") { 
                  const partRatio = part.weightSetup / record.abkantWeight;
                  totalAbkantPrice = abkantTotalNew * partRatio;
                  
              }
    
              console.log("Hesaplanan abkant fiyatı:", totalAbkantPrice, "Parça adedi:", part.quantity);
              part.pricesAbkant = totalAbkantPrice;
              record.parts[i] = part;
          }
      }
    }



    console.log("updatedItems",updatedItems)
    const filteredItems = updatedItems.filter(item => 
      item.customerName.toLowerCase() === record.companyName.toLowerCase()
    );
    console.log("Filtered Items:", filteredItems);

    setItems(filteredItems);

   
    
      
    };

    fetchData();
  }, []);

  const calculateTotalPrice = (items) => {
    let totalPrice = 0;
    items.forEach(item => {
        if (item.totalPrice) {
            totalPrice += item.totalPrice;
        }
    });

    // İlk item'dan deliveryPrice'ı al, eğer undefined ise 0 olarak ayarlayın
    const deliveryPrice = items.length > 0 ? items[0].deliveryPrice || 0 : 0;

    return totalPrice + deliveryPrice;
};


  

  const calculateTotalWeight = (items) => {
    let totalWeight = 0;
  
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const itemWeight = item.newWeight || 0;
      const itemPiece = item.piece || 0;
      totalWeight += itemWeight * itemPiece;
    }
  
    return totalWeight.toFixed(2);
  };
  
  // Toplam Kesim Sürelerinin Hesaplanması
  const calculateTotalTime = (items) => {
    let totalTimeInSeconds = 0;
  
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const itemTime = item.newTime || '00:00:00';
      const { hours, minutes, seconds } = parseTime(itemTime);
      const totalItemSeconds = hours * 3600 + minutes * 60 + seconds;
      const itemPiece = item.piece || 0;
      totalTimeInSeconds += totalItemSeconds * itemPiece;
    }
  
    return totalTimeInSeconds; // Saniye cinsinden toplam süreyi döndür
  };
  

  const handleContractManufacturingChange = (partKey, operation, value) => {
    setItems(prevParts => {
      return prevParts.map(part => {
        if (part.partNo === partKey) {
          const updatedOperationPrices = { ...(part.priceOperations || {}) };
          if (!updatedOperationPrices[operation]) {
            updatedOperationPrices[operation] = { profit: 0, contractManufacturing: 0 };
          }
          updatedOperationPrices[operation].contractManufacturing = Number(value);
          
          const updatedPart = { ...part, priceOperations: updatedOperationPrices };
          updatedPart.unitPrice = calculateUnitPrice(updatedPart);
          updatedPart.totalPrice = updatedPart.unitPrice * updatedPart.piece;
          
          return updatedPart;
        }
        return part;
      });
    });
  };

  const handleProfitChange = (partKey, operation, value) => {
    setItems(prevParts => {
      return prevParts.map(part => {
        if (part.partNo === partKey) {
          const updatedOperationPrices = { ...(part.priceOperations || {}) };
          if (!updatedOperationPrices[operation]) {
            updatedOperationPrices[operation] = { profit: 0, contractManufacturing: 0 };
          }
          updatedOperationPrices[operation].profit = Number(value);
          
          const updatedPart = { ...part, priceOperations: updatedOperationPrices };
          updatedPart.unitPrice = calculateUnitPrice(updatedPart);
          updatedPart.totalPrice = updatedPart.unitPrice * updatedPart.piece;
          
          return updatedPart;
        }
        return part;
      });
    });
  };


  const calculateUnitPrice = (item,partData) => {
    console.log("calculta unit price item",item)
    
    const priceMachine = item.priceMachine || 0;
    const pricePlazma = item.pricePlazma || 0;
    const priceQuality = item.priceQuality || 0;
    const pricesAbkant = item.pricesAbkant || 0;
    console.log("partData",partData)
    let totalUnitPriceProcess = 0;
    let totalProfitPriceProcess = 0;
    let totalOperationsPrice=0;
   
    if (partData && partData.processData) {
      for (const processName in partData.processData) {
        totalUnitPriceProcess = parseFloat(partData.processData[processName].totalUnitPrice) || 0;
        console.log("totalUnitPriceProcess",totalUnitPriceProcess)

        totalProfitPriceProcess = parseFloat(partData.processData[processName].totalProfit) || 0;
        console.log("totalProfitPriceProcess",totalProfitPriceProcess)

        totalOperationsPrice += totalProfitPriceProcess +totalUnitPriceProcess;
        console.log("totalOperationsPrice",totalOperationsPrice)
      }
    }
console.log(" son totalOperationsPrice",totalOperationsPrice)
    const totalUnitPrice = parseFloat(priceMachine + totalOperationsPrice + pricePlazma + priceQuality + pricesAbkant);
    return totalUnitPrice.toFixed(2);
  };

  const handleAbkantCountChange = (partNo, value) => {
    console.log("PARÇA partNo", partNo, "value", value);
    
    setAbkantCounts({
      ...abkantCounts,
      [partNo]: value
    });
  
    // Abkant fiyatını hesapla
    const calculatedPrice = value * record.pricesAbkant["Abkant"];
    console.log("PARÇA calculatedPrice", calculatedPrice);
  
    setItems(prevParts => {
      const updatedParts = prevParts.map(part => {
        if (part.partNo === partNo) {
          // Önceki fiyatı sıfırlayıp yeni fiyatı ekleyin
          const previousAbkantPrice = part.pricesAbkant || 0; // Önceki Abkant fiyatını alın
          return {
            ...part,
            pricesAbkant: calculatedPrice, // Yeni Abkant fiyatını güncelle
            unitPrice: part.unitPrice - previousAbkantPrice + calculatedPrice ,// Önceki Abkant fiyatını çıkar ve yenisini ekle
            totalPrice : (part.unitPrice - previousAbkantPrice + calculatedPrice) * part.piece
  
          };
        }
        return part;
      });
      console.log("PARÇA Updated part: abkant", updatedParts);
      return updatedParts;
    });
  };

  const handleOperationChange = (partKey, operation, value) => {
  // console.log(partKey, operation, value);
    setItems(prevParts => {
      return prevParts.map(part => {
        if (part.partNo === partKey) {
          const updatedOperationPrices = { ...(part.priceOperations || {}) };
          updatedOperationPrices[operation] = Number(value);
        //  console.log("Updated priceOperations for part:", partKey, updatedOperationPrices);
  
          const updatedPart = { ...part, priceOperations: updatedOperationPrices };
          console.log("Updated part:", updatedPart);
  
          return updatedPart;
        }
        return part;
      });
    });
  };



  const totalWithoutDelivery = calculateTotalPrice(items) - (items[0]?.deliveryPrice || 0);


  const calculateColumnTotal = (key) => {
    return items.reduce((sum, item) => {
      return sum + (parseFloat(item[key]) || 0);
    }, 0).toFixed(2);
  };
  

  

  
  function convertToMinutes(timeString) {
    const { hours, minutes, seconds } = parseTime(timeString);
    return hours * 60 + minutes + seconds / 60;
  }
  
  function parseTime(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return { hours, minutes, seconds };
  }
  
  function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  
  function addTimes(time1, time2) {
    const t1 = parseTime(time1);
    const t2 = parseTime(time2);
    
    const totalSeconds1 = t1.hours * 3600 + t1.minutes * 60 + t1.seconds;
    const totalSeconds2 = t2.hours * 3600 + t2.minutes * 60 + t2.seconds;
    
    const totalSeconds = totalSeconds1 + totalSeconds2;
    return formatTime(totalSeconds);
  }

  const goTotalPriceCustomerPage = () => {
    const record = location.state && location.state.updatedRecord;
    console.log("redcord customer",record)
    navigate('/totalpriceordercustomerpage', { state: { items, record,hasCompleteRequests} });
  };

  const goThicknessPriceCustomerPage = () => {
    const record = location.state && location.state.updatedRecord;
    console.log("redcord customer",record)
    navigate('/thicknesspriceordercustomerpage', { state: { items, record,hasCompleteRequests} });
  }; 

  const goUnitPriceCustomerPage = () => {
    const record = location.state && location.state.updatedRecord;
    console.log("redcord customer",items,record)
    navigate('/unitpriceordercustomerpage', { state: { items, record,hasCompleteRequests} });

  };
   // Birim Ağırlık, Fiyat gibi değerlerin toplamını hesaplayan genel fonksiyon
const calculateTotalRow = (items, key) => {
  return items.reduce((sum, item) => sum + (parseFloat(item[key]) || 0), 0).toFixed(2);
};

// Zaman değerlerini (HH:MM:SS) toplayan fonksiyon
const calculateTotalTimeRow = (items, key) => {
  return items.reduce((total, item) => {
    const timeParts = item[key]?.split(':').map(Number) || [0, 0, 0];
    const totalSeconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2];
    return total + totalSeconds;
  }, 0);
};

// Toplam saniye cinsinden olan zamanı HH:MM:SS formatına dönüştüren fonksiyon
const formatTotalTimeRow = (totalSeconds) => {
  const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
  const seconds = String(totalSeconds % 60).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

const calculateTotalWeightSetup = (items) => {
  return items.reduce((sum, item) => {
    const weight = parseFloat(item.weightSetup) || 0; // Birim Ağırlık
    const piece = parseFloat(item.piece) || 0; // Adet
    return sum + (weight * piece); // Ağırlık x Adet
  }, 0).toFixed(2); // Sonucu iki ondalık basamağa yuvarla
};


  
// Zamanlar
const totalCuttingTimeRow = formatTotalTimeRow(calculateTotalTimeRow(items, 'time')); // Kesim Süresi
const CalculatedCuttingTimeRow = formatTotalTimeRow(calculateTotalTimeRow(items, 'newTime')); // Hesaplanan Kesim Süresi
const totalCalculatedCuttingTimeRow = formatTime(calculateTotalTime(items)) // Toplam hesaplanan kesim süresi

// Ağırlıklar
const unitWeightSetupRow = calculateTotalRow(items, 'weightSetup'); // Birim Ağırlık
const unitCalculatedWeightRow = calculateTotalRow(items, 'newWeight'); // Hesaplanan Birim Ağırlık

const totalWeightSetupRow = calculateTotalWeightSetup(items)
const totalCalculatedWeightSetupRow = calculateTotalWeight(items)
 

// Fiyatlar
const totalPricesQualityRow = calculateTotalRow(items, 'priceQuality'); // Malzeme Fiyatı
const totalPricesMachineRow = calculateTotalRow(items, 'priceMachine'); // Lazer Fiyatı
const totalPricesPlasmaRow = calculateTotalRow(items, 'pricePlazma'); // Plazma Fiyatı
const totalPricesAbkantRow = calculateTotalRow(items, 'pricesAbkant'); // Abkant Fiyatı
const totalUnitPriceRow = calculateTotalRow(items, 'unitPrice'); // Birim Fiyat
const totalTotalPriceRow = calculateTotalRow(items, 'totalPrice'); // Toplam Fiyat

  const columns = [
    {
      title: 'No',
      dataIndex: 'partNo',
      key: 'partNo',
      width:300,
      render: (text, record, index) => <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{index + 1}</span>,
    },
    {
      title: 'Müşteri',
      dataIndex: 'customerName',
      key: 'customerName',    
      width : 200,
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
      title: 'Parça No',
      dataIndex: 'partNo',
      key: 'partNo', 
      width:200,   
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
    },
    {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
        width:200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
      },
      {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        width:200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
     },
    {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        width:200,
        render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
    
      },
      {
        title: 'Kesim Süresi',
        dataIndex: 'time',
        key: 'time',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap'  , color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
      },
      {
        title: 'Hesaplanan Kesim Süresi',
        dataIndex: 'newTime',
        key: 'newTime',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap'  , color: record.isDeleted ? "#00000080" : "#000000" }}> {text}</span>,
      },
      {
      title: 'Hesaplanan Toplam Kesim Süresi',
      dataIndex: '',
      key: '',
      width : 200,
      render: (text, record) => {
        const timeInMinutes = convertToMinutes(record.newTime) || 0;
    const piece = parseFloat(record.piece) || 0;
    const totalMinutes = timeInMinutes * piece;
    const totalSeconds = totalMinutes * 60;

    // HH:MM:SS formatında süreyi formatla
    const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(Math.floor(totalSeconds % 60)).padStart(2, '0');

    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return (
      <span style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap', color: record.isDeleted ? "#00000080" : "#000000" }}>
        {formattedTime}
      </span>
    );
 
      },
      
    },
      {
        title: 'Birim Ağırlık',
        dataIndex: 'weightSetup',
        key: 'weightSetup',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>{text} Kg</span>,

      },
      
      {
        title: 'Hesaplanan Birim Ağırlık',
        dataIndex: 'newWeight',
        key: 'newWeight',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>  {text && text.toFixed(2)} Kg </span>,

      },
      {
        title: 'Toplam Ağırlık',
        dataIndex: '',
        key: '',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
      {(record.weightSetup * record.piece).toFixed(2)} kg
          </span>
        ),
      },
      {
        title: 'Hesaplanan Toplam Ağırlık',
        dataIndex: '',
        key: '',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
      {(record.newWeight * record.piece).toFixed(2)} kg
          </span>
        ),
      },
   
      {
        title: 'Ebatlar',
        dataIndex: 'partNo',
        key: 'partNo',
        width:200,
        render: (text,record) => <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}> {record.width} x  {record.height} </span>,
      }, 
      {
        title: 'Malzeme Fiyatı',
        dataIndex: 'priceQuality',
        key: 'priceQuality',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} TL
          </span>
        ),
      },
      
      {
        title: 'Lazer Fiyatı',
        dataIndex: 'priceMachine',
        key: 'priceMachine',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.toFixed(2)} TL
          </span>
        ),
      },
      {
        title: 'Plazma Fiyatı',
        dataIndex: 'pricePlazma',
        key: 'pricePlazma',
        width:200,
        render: (text, record) => (
          <span style={{display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' , color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text !== null && text !== undefined ? `${text.toFixed(2)} TL` : '-'}
          </span>
        ),
      },
      
      /*
      
      {
        title: 'Operasyonlar',
        dataIndex: 'operations',
        key: 'operations',
        width: 450,
        render: (text, record) => (
          <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
            {text && text.length > 0 ? (
              text.map((operation) => (
                <div key={operation} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  {operation !== 'Abkant' && (
                    <>
                      <span style={{ width: '100px' }}>{operation}</span>
                      <Input
                      type="number"
                      value={record.priceOperations?.[operation]?.contractManufacturing || ''}
                      onChange={(e) => handleContractManufacturingChange(record.partNo, operation, e.target.value)}
                      placeholder="Fason Fiyatı"
                      style={{ marginLeft: '10px', width: '100px' }}
                    />
                    <Input
                      type="number"
                      value={record.priceOperations?.[operation]?.profit || ''}
                      onChange={(e) => handleProfitChange(record.partNo, operation, e.target.value)}
                      placeholder="Kar Marjı"
                      style={{ marginLeft: '10px', width: '100px' }}
                    />
                    </>
                  )}
                </div>
              ))
            ) : (
              '-'
            )}
          </span>
        ),
      },
*/
      {
        title: 'Operasyonlar',
        dataIndex: '',
        key: '',
       
        render: (text, record) => {
          const partData = groupedParts[record.partNo]; 
      if (partData) {
        return (
          <div>
             <div>
          {Object.keys(partData.processData).map(processName => (
            <div key={processName} style={{ marginBottom: '10px' }}>
                 <div style={{ display: 'flex', }}>
              <div style={{ fontWeight: "bold" ,width:"100px" }}>{processName}</div>
           
                <p style={{ margin: '0', width:"150px" }}>Fason Fiyatı: {partData.processData[processName].totalUnitPrice} TL</p>
                <p style={{ margin: '0',width:"150px"  }}>Kar: {partData.processData[processName].totalProfit} TL</p>
              </div>
            </div>
          ))}
        </div>
          </div>
        );
      }
          return '-'; // Eşleşme yoksa
        },
      }
      
      ,
      {
        title: 'Abkant Fiyatı',
        dataIndex: 'pricesAbkant',
        key: 'pricesAbkant',
        width:200,
        render: (text, item) => {
          const abkantSelectedOperation = record.abkantSelectedOperation;
      
          if (!abkantSelectedOperation) {
            return null; 
          }
      
          return (
            <span>
              {abkantTwistShape ? (
                <>
                  <Input
                    type="number"
                    placeholder="Büküm Sayısı"
                    style={{ marginLeft: '10px', width: '80px' }}
                    onChange={(e) => handleAbkantCountChange(item.partNo, e.target.value)}
                  />
                  <span>
                    {text !== null && text !== undefined ? `${text.toFixed(2)} TL` : '-'}
                  </span>
                </>
              ) : (
                <span>
                  {text !== null && text !== undefined ? `${text.toFixed(2)} TL` : '-'}
                </span>
              )}
            </span>
          );
        }

      },
      
      {
        title: 'Birim Fiyat',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
            <span>{text && Number(text).toFixed(2)} TL</span>
          </div>
        ),
      },
      {
        title: 'Toplam Fiyat',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>
            <span>{text && Number(text).toFixed(2)} TL</span>
          </div>
        ),
      },
      
      
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
      
       
      }}>
        <h3 style={{textAlign:"left", color:colors.blue}}>Operasyon Fiyatları Gir</h3>
       

        <Table
            locale={{
              emptyText: 'Henüz bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
            style={{width:"200px"}}
            summary={() => {
              if (!items || items.length === 0) {
                return null; // Eğer items boşsa toplam satırını render etme
              }
          
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}><strong>Toplamlar</strong></Table.Summary.Cell>
                  <Table.Summary.Cell index={1} /> {/* Müşteri için toplam yok */}
                  <Table.Summary.Cell index={2} /> {/* Parça No için toplam yok */}
                  <Table.Summary.Cell index={3} /> {/* Kalite için toplam yok */}
                  <Table.Summary.Cell index={4} /> {/* Kalınlık için toplam yok */}
                  <Table.Summary.Cell index={5} /> {/* Adet için toplam yok */}
                  <Table.Summary.Cell index={6}>{totalCuttingTimeRow}</Table.Summary.Cell> {/* Kesim Süresi */}
                  <Table.Summary.Cell index={7}>{CalculatedCuttingTimeRow}</Table.Summary.Cell> {/* Hesaplanan Kesim Süresi */}
                  <Table.Summary.Cell index={8}>{totalCalculatedCuttingTimeRow}</Table.Summary.Cell> {/* Hesaplanan Toplam Kesim Süresi */}
                  <Table.Summary.Cell index={9}>{unitWeightSetupRow} Kg</Table.Summary.Cell> {/* Birim Ağırlık */}
                  <Table.Summary.Cell index={10}>{unitCalculatedWeightRow} Kg</Table.Summary.Cell> {/* Hesaplanan Birim Ağırlık */}
                  <Table.Summary.Cell index={11}>{totalWeightSetupRow} kg</Table.Summary.Cell> {/* Toplam Ağırlık */}
                  <Table.Summary.Cell index={12}>{totalCalculatedWeightSetupRow} kg</Table.Summary.Cell> {/* Hesaplanan Toplam Ağırlık */}
                  <Table.Summary.Cell index={13} /> {/* Ebatlar için toplam yok */}
                  <Table.Summary.Cell index={14}>{totalPricesQualityRow} TL</Table.Summary.Cell> {/* Malzeme Fiyatı */}
                  <Table.Summary.Cell index={15}>{totalPricesMachineRow} TL</Table.Summary.Cell> {/* Lazer Fiyatı */}
                  <Table.Summary.Cell index={16}>{totalPricesPlasmaRow} TL</Table.Summary.Cell> {/* Plazma Fiyatı */}
                  <Table.Summary.Cell index={17} /> {/* Operasyonlar için toplam yok */}
                  <Table.Summary.Cell index={18}>{totalPricesAbkantRow} TL</Table.Summary.Cell> {/* Abkant Fiyatı */}
                  <Table.Summary.Cell index={19}>{totalUnitPriceRow} TL</Table.Summary.Cell> {/* Birim Fiyat */}
                  <Table.Summary.Cell index={20}>{totalTotalPriceRow} TL</Table.Summary.Cell> {/* Toplam Fiyat */}
                </Table.Summary.Row>
              );
            }}
           
        />
         

    <div style={{ marginTop: '5px', textAlign: 'left', marginBottom : '10px' }}>
        <div>
          <strong>Nakliye Fiyatı:</strong> {record.deliveryPrice} TL
        </div>
        <div>
            <strong>Nakliye Hariç Toplam Fiyat:</strong> 
            {(calculateTotalPrice(items) - record.deliveryPrice).toFixed(2)} TL
        </div>
        <div>
            <strong>Nakliye Dahil Toplam Fiyat:</strong> 
            {calculateTotalPrice(items).toFixed(2)} TL
        </div>
      </div>

        <div style={{float:"right"}}>
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"250px", marginLeft:"20px"}} onClick={() => goUnitPriceCustomerPage()}  >
       Birim Fiyat Müşteri Dökümanı
        </Button>
        </div>


        <div style={{float:"right"}}>
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"250px"}} onClick={() => goTotalPriceCustomerPage()}  >
        Takım Fiyat Müşteri Dökümanı
        </Button>
        </div>


        <div style={{float:"right"}}>
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500",float:"left",width:"250px"}} onClick={() => goThicknessPriceCustomerPage()}  >
        Kalınlık Fiyat Müşteri Dökümanı
        </Button>
        </div>
    </div>

  };
  export default OrderOperationPrice;
 
 