import React,{useEffect,useState} from "react";
import { Table, Button,Modal, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors, parsCollections}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import ExpenseAdd from "./expenseAdd";

const ExpenseDisplayList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState([]);

  useEffect(() => {
   
    window.scrollTo(0, 0);

    const fetchData = async () => {
        const allOpenOrder = await FirebaseService.getExpensesData();
        const filteredData = allOpenOrder.filter(item => !item.isDeleted);
        console.log("filteredData",filteredData)
        setItems(filteredData);
    };

    fetchData();
  }, []);


  const goEditPage = (record) => {
    navigate('/expenseedit', { state: { record } })
  };

  const goDisplayPage = (record) => {
    navigate('/expensedisplay', { state: { record } })
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleExpenseAdd = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleExpenseAddFinish = () => {
    setIsModalVisible(false); 
  };


  const columns = [
    {
      title: 'Ay',
      dataIndex: 'month',
      key: 'month',
      render: (text, record) => {
          const monthNames = [
              'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 
              'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
          ];
          return (
              <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                  {monthNames[text - 1]}
              </span>
          );
      },
  },
  
  
    {
      title: 'Yıl',
      dataIndex: 'year',
      key: 'year',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text}
      </span>,
      
    }
  ,
    {
      title: '',
      dataIndex: 'display',
      key: 'display',
      render: (text, record) => (
        <Button onClick={()=>goDisplayPage(record)} style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
       Görüntüle
     </Button>
      ),
    }
   
  ];


  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>Giderler</h3>
      
          <Table
            locale={{
              emptyText: 'Henüz bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
          
        />

          <Modal
            title="Yeni Ay Ekle"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}>
            <ExpenseAdd onClose={handleExpenseAddFinish} />
        </Modal>

       
    </div>

  };
  export default ExpenseDisplayList;

  