import React,{useEffect,useState} from "react";
import { Button, Form, Input,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import FirebaseService from "../../services/firebaseService";
import  { parsCollections}  from "../../environments/environment"
import { useNavigate } from 'react-router-dom';


 
const SupplierAdd = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const fetchData = async () => {
     
      };
      fetchData();
    }, []);

    const onFinish = async (values) => {

        setLoading(true)
        const currentDate =new Date()

        const allItem = await FirebaseService.getSuppliersData();
        const newItems = allItem.filter((entry) => entry.supplierName.toLowerCase() === values.supplierName.toLowerCase());

         console.log("newItems",newItems)
         if(newItems.length > 0){
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Böyle bir proses bulunmaktadır.",
            placement: 'topRight'
          });
          setLoading(false)
          return;
         }

        const data = {
          firstTransactionDate: currentDate,
          firstUserId: localStorage.getItem("uid"),
          isDeleted: false,
          supplierName: values.supplierName,
       
        };
        
      try {
        const result = await FirebaseService.addRecording(parsCollections.suppliers,data);
        if (result.success) {
          NotificationService.openSuccessNotification({
            title: 'İşlem Başarılı',
            description: result.message,
            placement: 'topRight'
          });
          setLoading(false)
          setTimeout(() => {
            navigate("/supplierlist");
          }, 2000);
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });setLoading(false)}
        
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });setLoading(false)
      }
    }; 

    const onFinishFailed = (errorInfo) => {
        NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
      };
    
   

   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 2,
            }}
            wrapperCol={{
            span: 8,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

        

            <Form.Item
            label="Tedarikçi"
            name="supplierName"
            rules={[
                {
                required: true,
                message: 'Lütfen tedarikçi giriniz!',
                },
            ]}
           
            >
           <Input  className="input-style" style={{width:"100%"}} placeholder="Tedarikçi"/>
            </Form.Item>      
          
            <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
            </Form.Item>

        </Form>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>)

        };
  export default SupplierAdd;