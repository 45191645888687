import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';

const Machine5ListLimited = () => {

  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchData = async () => {
      const productionData = await FirebaseService.getProductionData();
      const filteredData = productionData.filter(item => !item.isDeleted);
      
      const setupData = await FirebaseService.getSetupData();
      
      const updatedData = filteredData.map(item => {
        const jobCode = item.jobCode;
        const setupItem = setupData.find(setup => setup.jobCode === jobCode && setup.machine === "5000");
  
        console.log("setupItem", setupItem);
  
        // Eşleşme varsa özellikleri güncelle
        if (setupItem && Array.isArray(setupItem.plates)) {
          // Check if any plate has isFinish: false
          const hasUnfinishedPlate = setupItem.plates.some(plate => plate.isFinish === false);
  
          // Only include the item if there is at least one unfinished plate
          if (hasUnfinishedPlate) {
            return {
              ...item,
              companyName: setupItem.companyName,
              description: setupItem.description,
              expectedWastages: setupItem.expectedWastages,
              firstTransactionDate: setupItem.firstTransactionDate,
              documentUrl: setupItem.documentUrl,
              firstUserId: setupItem.firstUserId,
              isDeleted: setupItem.isDeleted,
              isScrapDelivery: setupItem.isScrapDelivery,
              key: setupItem.key,
              machine: setupItem.machine,
              parts: setupItem.parts,
              plateCount: setupItem.plateCount,
              plates: setupItem.plates,
              priorityAbkant: setupItem.priorityAbkant,
              priorityMachine: setupItem.priorityMachine,
              projectCode: setupItem.projectCode,
              quality: setupItem.quality,
              setupName: setupItem.setupName,
              setupTime: setupItem.setupTime,
              stock: setupItem.stock,
              surface: setupItem.surface,
              thickness: setupItem.thickness,
              wastage: setupItem.wastage,
            };
          }
        }
        return null;
      }).filter(Boolean);
  
      console.log("filteredData machine 5", updatedData);
      
      const sortedItems = [...updatedData].sort((a, b) => a.priorityMachine - b.priorityMachine);
      const limitedData = sortedItems.slice(0, 5);
      
      setItemsLimited(limitedData);
      setItems(sortedItems);
    };
  
    fetchData();
  }, []);
  



  const goPage = (record) => {
    navigate('/machine5list', { state: { record } })
  };

  const columns = [
    {
      title: 'Sırası',
      dataIndex: 'priorityMachine',
      key: 'priorityMachine',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
    },
    
    {
      title:"Firma",
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
    },
  {
    title: 'Proje Kodu',
    dataIndex: 'projectCode',
    key: 'projectCode',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"} </span>,
  },
  {
    title:"İş Kodu",
    dataIndex: 'jobCode',
    key: 'jobCode',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
},
{
  title: 'Dakika',
  dataIndex: 'setupTime',
  key: 'setupTime',
  render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text} </span>,
},
/*
{
  title: '   ',
  dataIndex: '',
  key: '',
  render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> 
  <img width={20} height={20} alt="logo" src="/images/sort.svg" />
</span>,
},*/


  ];

  return  <div style={{
  
      }}
      className="limited-list-border">
           <Button onClick={()=>goPage(items)} style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left",marginLeft:"10px"}}>
           Lazer 5KW
       </Button>

        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}></h3>
          <Table
            locale={{
              emptyText: 'Henüz 5kw lazer bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

       
    </div>

  };
  export default Machine5ListLimited;

  