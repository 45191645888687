import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, Modal, Spin } from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import PropTypes from 'prop-types';
import AddRequestAdminDensities from "./addRequestAdminDensities";
import { parsCollections } from "../../../environments/environment";

const AddRequestAdminRaw = ({ onClose }) => {
  const [qualities, setQualities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [surfaces, setSurfaces] = useState([]);
  const [existingItems, setExistingItems] = useState([]);  // Mevcut sac levhaları tutmak için
  const [isModalVisibleDensities, setIsModalVisibleDensities] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const qualitiesData = await FirebaseService.getDensitiesData();
      const filteredQualities = qualitiesData.filter(item => !item.isDeleted);
      setQualities(filteredQualities);

      const surfacesData = await FirebaseService.getSurfacesData();
      const filteredSurfaces = surfacesData.filter(item => !item.isDeleted);
      setSurfaces(filteredSurfaces);

      // Firebase'den mevcut sac levhaları alıyoruz
      const itemsData = await FirebaseService.getAllItemsData();
      setExistingItems(itemsData.filter(item => !item.isDeleted && item.productType === "raw"))

      
    };
    fetchData();
  }, []);

  function generateRandomCode(length) {
    const characters = '0123456789';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
  
    return result;
  }

  const onFinish = async (values) => {
    setLoading(true)
    if (values.quality === "newQuality") {
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Lütfen var olan bir kalite seçiniz.',
        placement: 'topRight'
      });
      setLoading(false)
      return;
    }

    const customValues = {
      width: Number(values.width),
      height: Number(values.height),
      quality: values.quality,
      thickness: Number(values.thickness),
      surface: values.surface,
    };

    // Mevcut itemlerle karşılaştırma yapıyoruz
    const isDuplicate = existingItems.some(item => {
      const { productDictionary } = item;
      return (
        productDictionary.width === customValues.width &&
        productDictionary.height === customValues.height &&
        productDictionary.quality === customValues.quality &&
        productDictionary.thickness === customValues.thickness &&
        productDictionary.surface === customValues.surface
      );
    });

    if (isDuplicate) {
      NotificationService.openErrorNotification({
        title: 'Hata',
        description: 'Bu özelliklere sahip bir sac levha zaten mevcut.',
        placement: 'topRight'
      });
      setLoading(false);
      return;
    }

    const currentDate =new Date()

    const data = {
      firstTransactionDate: currentDate,
      firstUserId: localStorage.getItem("uid"),
      isDeleted: false,
      productType: "raw",
      productDictionary: customValues,
      productString : null ,
      code: generateRandomCode(5),
      minPiece: values.minPiece || 0
    };

    try {
      const result = await FirebaseService.addRecording(parsCollections.allItems, data);
      if (result.success) {
        NotificationService.openSuccessNotification({
          title: 'İşlem Başarılı',
          description: 'Sac levha başarıyla eklendi.',
          placement: 'topRight'
        });
        setLoading(false);
        onClose();
      } else {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    } catch (error) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Bilinmeyen bir hata ile karşılaşıldı.",
        placement: 'topRight'
      });
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    NotificationService.openErrorNotification({
      title: 'Geçersiz Form',
      description: 'Lütfen form bilgilerini kontrol ediniz.',
      placement: 'topRight'
    });
  };

  const handleModalCancelDensities = () => {
    setIsModalVisibleDensities(false);
  };

  const handleAddFinishDensities = () => {
    setIsModalVisibleDensities(false);
  };

  const onChange = (value) => {
    if (value === 'newQuality') {
      setIsModalVisibleDensities(true);
    }
  };

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        style={{ maxWidth: "100%", padding: "5% 0 0 5%" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Kalite"
          name="quality"
          rules={[{ required: true, message: 'Lütfen kalite giriniz!' }]}
        >
          <Select
            onChange={onChange}
            placeholder="Kalite Seçiniz"
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={[...qualities.map(item => ({ label: item.quality, value: item.quality })), { label: 'Yeni Kalite Ekle', value: 'newQuality' }]}
            className="input-style"
          />
        </Form.Item>

        <Modal
          title="Kalite Ekleme Talebi"
          visible={isModalVisibleDensities}
          onCancel={handleModalCancelDensities}
          footer={null}
        >
          <AddRequestAdminDensities onClose={handleAddFinishDensities} />
        </Modal>

        <Form.Item
          label="Kalınlık"
          name="thickness"
          rules={[{ required: true, message: 'Lütfen kalınlık giriniz!' }, { pattern: /^\d+$/, message: 'Sadece rakam girişi yapınız.' }]}
        >
          <Input className="input-style" style={{ width: "100%" }} placeholder="Kalınlık Giriniz" />
        </Form.Item>

        <Form.Item
          label="Yüzey"
          name="surface"
          rules={[{ required: true, message: 'Lütfen Yüzey Giriniz!' }]}
        >
          <Select
            placeholder="Yüzey Seçiniz"
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={[...surfaces.map(item => ({ label: item.surface, value: item.surface }))]}
            className="input-style"
          />
        </Form.Item>

        <Form.Item
          label="En"
          name="width"
          rules={[{ required: true, message: 'Lütfen en giriniz!' }, { pattern: /^\d+$/, message: 'Sadece rakam girişi yapınız.' }]}
        >
          <Input className="input-style" style={{ width: "100%" }} placeholder="En Giriniz" />
        </Form.Item>

        <Form.Item
          label="Boy"
          name="height"
          rules={[{ required: true, message: 'Lütfen boy giriniz!' }, { pattern: /^\d+$/, message: 'Sadece rakam girişi yapınız.' }]}
        >
          <Input className="input-style" style={{ width: "100%" }} placeholder="Boy Giriniz" />
        </Form.Item>

        <Form.Item
          wrapperCol={{ offset: 15, span: 18 }}
        >
          <Button htmlType="submit" style={{ backgroundColor: "#1A446C", color: "white", fontWeight: "500" }}>Gönder</Button>
        </Form.Item>
      </Form>

      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin />
        </div>
      )}
    </div>
  );
};

AddRequestAdminRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddRequestAdminRaw;
