import React,{useEffect,useState} from "react";
import { Button, Table, Input,Select,Space, List,Modal,Checkbox, Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocation  } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { colors, parsCollections, placeholderMap } from "../../../../environments/environment";
import SetupAdd from "../setupAdd";
import PurchaseRequestAdd from "../../purchaseRequests/purchaseRequestAdd";
import FirebaseService from "../../../../services/firebaseService";
import NotificationService from "../../../../services/antNotificationService";

const OpenOrderAddContinueMergeJobCodes = () => {
    const navigate = useNavigate();
   
    const location = useLocation();
    const record = location.state && location.state.record;
   // console.log("record",record);
    const jobCode = location.state && location.state.jobCode;
    
    const [checkedItemsStock, setCheckedItemsStock] = useState([]);
    const [checkedItemsWastage, setCheckedItemsWastage] = useState([]);

    const [checkedItemsStockExcess, setCheckedItemsStockExcess] = useState([]);

    const [inputValuesStock, setInputValuesStock] = useState({});
    const [inputValuesWastage, setInputValuesWastage] = useState({});


    const [companies, setCompanies] = useState([]); 
    const [itemsStock, setItemsStock] = useState([]);

    const [itemsWastage, setItemsWastage] = useState([]);

    const [filteredItemsStock, setFilteredItemsStock] = useState([]);
    const [filteredItemsWastage, setFilteredItemsWastage] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [recordForModal, setRecordForModal] = useState(null);

    const [selectedWarehouse, setSelectedWarehouse] = useState(null);
    const [excessValues, setExcessValues] = useState([]); 
  
    const [loading, setLoading] = useState(false);
    const [isWarehouseSelectedAll, setIsWarehouseSelectedAll] = useState(false);

    const [filtersStock, setFiltersStock] = useState({
      thickness: '',
      quality: '',
      width: '',
      height: '',
      surface: '',
      piece: '',
      reservedPiece: '',
      unitkg: '',
      totalkg: '',
    });

      const [filtersWastage, setFiltersWastage] = useState({
        thickness: '',
        quality: '',
        width: '',
        height: '',
        surface: '',
        piece: '',
        reservedPiece: '',
        unitkg: '',
        totalkg: '',
    });

    const [isReserved, setIsReserved] = useState(false);

    const handleSetup = (record) => {
      const savedCheckedItemsStock = JSON.parse(localStorage.getItem("checkedItemsStock"));
     const savedCheckedItemsWastage = JSON.parse(localStorage.getItem("checkedItemsWastage"));
     console.log("setup add ekranı açılıyor savedlar",savedCheckedItemsStock,savedCheckedItemsWastage)
     console.log("setup add ekranı açılıyor normaller",checkedItemsStock,checkedItemsWastage)

     console.log("record handle setup",record)
      const updatedRecord = {
        ...record,
        projectCode: record.projectCode ? record.projectCode : null,
        stock: (setupName || record.setupName) ? savedCheckedItemsStock : checkedItemsStock,
        wastage: (setupName || record.setupName) ? savedCheckedItemsWastage : checkedItemsWastage,
        checkedItemsStockExcess:checkedItemsStockExcess,
        jobCode: createJobCode ? createJobCode : (jobCode ? jobCode : null) ,
        isReserved:isReserved,
        setupName: setupName ? setupName : (record.setupName ? record.setupName : null),    
  
    };
  
    console.log("updatedRecord",updatedRecord)
   setRecordForModal(updatedRecord);
   setIsModalVisible(true);
      
    };
    
    const handleModalCancel = () => {
      setIsModalVisible(false);
    };
  
    const handleSetupFinish = () => {
      setIsModalVisible(false); 
    };
    const fetchData = async () => {
      const data = await FirebaseService.getStocksData();
      //console.log("record.warehouseName ",record.warehouseName )
       const dataWastage = await FirebaseService.getWastagesData();

      if(record.warehouseName === null || record.warehouseName === undefined){
        const filteredDataStock = data.filter(item => !item.isDeleted);
     //   console.log("filteredDataStock",filteredDataStock)
        setItemsStock(filteredDataStock);
        setFilteredItemsStock(filteredDataStock);

        const filteredDataWastage = dataWastage.filter(item => !item.isDeleted);
        //  console.log("filteredDataWastage",filteredDataWastage)
        setItemsWastage(filteredDataWastage);
         setFilteredItemsWastage(filteredDataWastage)

      }else{
        const filteredDataStock = data.filter(item => !item.isDeleted && item.companyName === record.warehouseName);
        // console.log("filteredDataStock",filteredDataStock)
        setItemsStock(filteredDataStock);
        setFilteredItemsStock(filteredDataStock);

        const filteredDataWastage = dataWastage.filter(item => !item.isDeleted  && item.companyName === record.warehouseName);
        //  console.log("filteredDataWastage",filteredDataWastage)
         setItemsWastage(filteredDataWastage);
        setFilteredItemsWastage(filteredDataWastage)

      }
    


  
      const companies = await FirebaseService.getCompaniesData();
      const filteredCompanies = companies.filter(item => !item.isDeleted);
      setCompanies(filteredCompanies);

    };


    useEffect(() => {
      window.scrollTo(0, 0);

      const existingStockItems = Array.isArray(record.stock) ? record.stock : JSON.parse(record.stock || '[]');
      //const updatedStockItems = [...existingStockItems, ...checkedItemsStock];
      localStorage.setItem("checkedItemsStock", JSON.stringify(existingStockItems)); 
      console.log("existingStockItems LOKALDE", existingStockItems);

      // Check if record.wastage is a valid JSON string or already an object
      const existingWastageItems = Array.isArray(record.wastage) ? record.wastage : JSON.parse(record.wastage || '[]');
     //const updatedWastageItems = [...existingWastageItems, ...checkedItemsWastage];
      localStorage.setItem("checkedItemsWastage", JSON.stringify(existingWastageItems)); 
      console.log("existingStockItems LOKALDE", existingWastageItems);

        
      fetchData();
    }, []);
    const warehouseOptions = [{ label: 'Tümü', value: "all" }, ...companies.map(item => ({ label: item.companyName, value: item.companyName }))];
    
    function generateRandomCode(length) {
      const characters = '0123456789';
      let result = '';
    
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    
      return result;
    }
   
    const handleCheckboxChangeWastage = (item,record) => {
        const isChecked = checkedItemsWastage.some((entry) => entry.id === item);

        if (inputValuesWastage[item]) {
          console.log("inputValuesWastage  geldi")
 
          setCheckedItemsWastage((items) => {
                 if (isChecked) {
                     const newItems = items.filter((entry) => entry.id !== item);
                     return newItems;
                 } else {
                     const newItem = { 
                       id:item,
                       oldPiece: record.piece, //mevucttaki piece
                       quality: record.quality,
                       height: record.height,
                       width: record.width,
                       thickness: record.thickness,
                       surface: record.surface,
                       isQrScan: false,
                       piece: inputValuesWastage[item] || '' };  //reserve edilen piece bu
                     return [...items, newItem];
                 }
             });
         } 
          
       
    };
        
    const handleCheckboxChange = (item,record) => {
     // console.log("record",record,item)
        const isChecked = checkedItemsStock.some((entry) => entry.id === item);
        if (inputValuesStock[item]) {
         // console.log("inputValuesStock  geldi")

            setCheckedItemsStock((items) => {
                if (isChecked) {
                    const newItems = items.filter((entry) => entry.id !== item);
                    return newItems;
                } else {
                    const newItem = { 
                      id:item,
                      oldPiece: record.piece, //mevucttaki piece
                      quality: record.quality,
                      height: record.height,
                      width: record.width,
                      thickness: record.thickness,
                      surface: record.surface,
                      isQrScan: false,
                      piece: inputValuesStock[item] || '', //reserve edilen piece bu,
                      code:record.code
                     }; 
                      
                    return [...items, newItem];
                }
            });
        } 
        
         if(excessValues[item]){
        //  console.log("exces valuese geldi",excessValues[item] )
          setCheckedItemsStock((items) => {
            if (isChecked) {
                const newItems = items.filter((entry) => entry.id !== item);
                return newItems;
            } else {
                const newItem = { 
                  id:item,
                  oldPiece: record.piece, //mevucttaki piece
                  quality: record.quality,
                  height: record.height,
                  width: record.width,
                  thickness: record.thickness,
                  surface: record.surface,
                  isQrScan: false,
                  piece: excessValues[item] || '' ,//reserve edilen piece bu
                  minPiece: record.minPiece,
                  code:record.code
                };  
                return [...items, newItem];
            }
        });
        setCheckedItemsStockExcess((items) => {
          if (isChecked) {
              const newItems = items.filter((entry) => entry.id !== item);
              return newItems;
          } else {
              const newItem = { 
                id:item,
                jobCode: jobCode,
                quality: record.quality,
                height: record.height,
                width: record.width,
                thickness: record.thickness,
                surface: record.surface,
                piece: excessValues[item] || '' , //reserve edilen piece bu
                minPiece: record.minPiece,
                code:record.code,
                currentPiece: record.piece,
                

              }; 
               
              return [...items, newItem];
          }
      });

        }
        
      
    };
 
    const onChangeFilterWarehouse = async(value) => {
      // console.log(`selected ${value}`);
       setSelectedWarehouse(value)
       setIsWarehouseSelectedAll(value === "all")
       try {
         if (value) {
           const data = await FirebaseService.getStocksData();
           const dataWastage = await FirebaseService.getWastagesData();
     
           if (value === "all") {
             const filteredDataStock = data.filter(item => !item.isDeleted);
             setItemsStock(filteredDataStock);
             setFilteredItemsStock(filteredDataStock);
             
             const filteredDataWastage = dataWastage.filter(item => !item.isDeleted);
             setItemsWastage(filteredDataWastage);
             setFilteredItemsWastage(filteredDataWastage);
           } else {
             const filteredDataStock = data.filter(item => !item.isDeleted && item.companyName === value);
             setItemsStock(filteredDataStock);
             setFilteredItemsStock(filteredDataStock);
             
             const filteredDataWastage = dataWastage.filter(item => !item.isDeleted && item.companyName === value);
             setItemsWastage(filteredDataWastage);
             setFilteredItemsWastage(filteredDataWastage);
           }
         } else {
          // console.log('Lütfen bir depo seçiniz.');
         }
       } catch (error) {
         console.error('Hata oluştu:', error);
       }
    };

    const handleInputChange = (item, value) => {
        const maxLength = Number(itemsStock.find((record) => record.key === item)?.piece);
        const numericValue = Number(value);
    
        console.log("numericValue stock", numericValue);
    
        if (isNaN(numericValue)) {
            NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
                placement: 'topRight'
            });
            return; 
        }
    
        if (numericValue <= maxLength) {
            setInputValuesStock((values) => {
                const newValues = { ...values, [item]: numericValue };
                console.log("input values stokc", newValues, checkedItemsStock, item);
    
                // checkedItemsStock'ı güncelle
                const updatedCheckedItems = checkedItemsStock.map(x => {
                    if (x.id === item) {
                        return { ...x, piece: numericValue };
                    }
                    return x;
                });
                setCheckedItemsStock(updatedCheckedItems);
    
                return newValues;
            });
        } else {
            setExcessValues((prevValues) => ({ ...prevValues, [item]: numericValue - maxLength }));
            setInputValuesStock((values) => ({ ...values, [item]: maxLength }));
            console.log("Burada fazla", numericValue - maxLength);
        }
    };

    const handleInputChangeWastage = (item, value) => {
    
      const maxLength = Number(itemsWastage.find((record) => record.key === item)?.piece);
      const numericValue = Number(value);

      if (isNaN(numericValue)) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Hatalı giriş: Sayısal bir değer girmelisiniz.",
          placement: 'topRight'
        });
        return; 
    }
    else{
      if (!isNaN(numericValue) && (maxLength === undefined || numericValue <= maxLength)) {
        setInputValuesWastage((values) => ({ ...values, [item]: numericValue }));
       console.log("input values wastge", inputValuesWastage, checkedItemsWastage,item);
    
        const checkedItemsWastagesArray = Array.isArray(checkedItemsWastage) ? checkedItemsWastage : [checkedItemsWastage];
    
        const itemExists = checkedItemsWastagesArray.some(x => x.id === item);
    
        if (itemExists) {
            // itemExists doğruysa yapılacak işlemler
            console.log("Item exists.");
            const updatedItems = checkedItemsWastagesArray.map(x => {
                if (x.id === item) {
                    return { ...x, piece: numericValue };
                } else {
                    return x;
                }
            });
            setCheckedItemsWastage(updatedItems);
        } else {
            // itemExists yanlışsa yapılacak işlemler
          //  console.log("Item does not exist.");
        }
      }
    
      else {

        setInputValuesWastage((values) => ({ ...values, [item]: maxLength }));
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Yeterli sayıda bulunmamaktadır. Lütfen güncel stoktan seçiniz.",
          placement: 'topRight'
        });
         console.log("burda fazla girmemeli");
      
      }
    }

    
    };

  
    //stock için filtreleme
    const handleSearchStock = (value, dataIndex) => {
      setFiltersStock(prevFilters => {
        // Filtre değerini güncelle
        const updatedFilters = {
          ...prevFilters,
          [dataIndex]: value, // Sadece bu filtreyi günceller
        };
    
        // Tüm filtrelere göre filtreleme yap
        const filteredData = itemsStock.filter(item => {
          return (
            !item.isDeleted && // Silinmemiş öğeleri dahil et
            Object.keys(updatedFilters).every(key => {
              const filterValue = updatedFilters[key]?.toLowerCase(); // Aktif filtreyi al
              const itemValue = item[key] !== undefined ? item[key].toString().toLowerCase() : ''; // İlgili değer varsa kontrol et
              return filterValue ? itemValue === filterValue : true; // Tam eşleşme kontrolü
            })
          );
        });
    
        setFilteredItemsStock(filteredData); // Filtrelenmiş veriyi güncelle
        return updatedFilters; // Yeni filtreleri geri döndür
      });
    };
    const handleResetStock = () => {
      setFilteredItemsStock(itemsStock); // Tüm listeyi geri döndürüyoruz
    };

    const getColumnSearchPropsStock = dataIndex => ({
      title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{placeholderMap[dataIndex] || dataIndex}</span>
          <Input
            id={`search-input-${dataIndex}`}
            placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
            onChange={e => handleSearchStock(e.target.value, dataIndex)} // Arama her değişiklikte yapılacak
            style={{ width: 80, height:30, marginTop: 8  }}
            suffix={<SearchOutlined />} // Search ikonu ekleme
          />
        </div>
      ),
      sorter: (a, b) => {
        const aValue = a[dataIndex] !== undefined ? a[dataIndex].toString().toLowerCase() : '';
        const bValue = b[dataIndex] !== undefined ? b[dataIndex].toString().toLowerCase() : '';
        return aValue.localeCompare(bValue); // Sıralama işlemi
      },
    });
   
    const columnsStock = [
     
      ...(isWarehouseSelectedAll ? [{
        title: 'Depo',
        dataIndex: 'companyName',
        key: 'companyName',
        ...getColumnSearchPropsStock('companyName'),
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        render: (text) => (
            <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>
        ),
    }] : []),
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
    
      ...getColumnSearchPropsStock('thickness'),
      sorter: (a, b) => a.thickness - b.thickness,
      render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}mm</span>,
    },
    
      {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
      
        ...getColumnSearchPropsStock('quality'),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
        render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
  
       
  
       
        {
          title: 'En',
          dataIndex: 'width',
          key: 'width',
        
          ...getColumnSearchPropsStock('width'),
          sorter: (a, b) => a.width - b.width,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
        {
          title: 'Boy',
          dataIndex: 'height',
          key: 'height',
       
          ...getColumnSearchPropsStock('height'),
          sorter: (a, b) => a.height - b.height,
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
  
        {
          title: 'Yüzey',
          dataIndex: 'surface',
          key: 'surface',
        
          ...getColumnSearchPropsStock('surface'),
          sorter: (a, b) => a.surface.localeCompare(b.surface), 
          render: (text) => <span style={{ color: itemsStock.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
   
        {
          title: 'Adet',
          dataIndex: 'piece',
          key: 'piece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        
          sorter: (a, b) => a.piece - b.piece,
          ...getColumnSearchPropsStock('piece'),
        },
        
        {
          title: 'Rezerve Adet',
          dataIndex: 'reservedPiece',
          key: 'reservedPiece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
      
         
        
        },
        {
          title: 'Beklenen Adet',
          dataIndex: 'waitingPiece',
          key: 'waitingPiece',
          render: (text,record) => <span style={{color: itemsStock.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
       
         
        
        },
        {
          title: 'Açıklama',
          dataIndex: 'isProblem',
          key: 'isProblem',
          render: (text, record) => {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const formattedDate = record.problemDate ? new Date(record.problemDate.seconds * 1000).toLocaleString('tr-TR', options) : '';
            
            return (
              <Popover
              content={
                <div style={{ width: '200px', maxHeight: '700px' }}> {/* Sabit yükseklik ve genişlik */}
                  <p><strong>Problem Zamanı:</strong> {formattedDate}</p>
                  <p><strong>Problem Fotoğrafı:</strong> 
                    {record.problemImage ?                 
                    <img alt="" src={record.problemImage} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
                    : 
                    "Fotoğraf bulunmamaktadır."}</p>
                        <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Problem Açıklaması:</strong> {record.problemDescription}</p>
                      </div>
                    }
                    title="Sac levhada problem bulunmaktadır."
                    trigger="click" // veya hover
                  >
                <span style={{ color: itemsStock.isDeleted ? "#00000080" : "#000000" }}>
                  {record.isProblem ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                </span>
              </Popover>
            );
          },
        
        },
          
        {
            title: 'Kullanılacak Adet',
            dataIndex: '', 
            key: '',
          
            render: (text, record) => (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                      
                  onChange={(e) => handleInputChange(record.key, e.target.value)}
                   className="input-style"
                   style={{width:"100%"}}

                />
              </span>
            ),
        },
        
        {
          title: ' ',
          dataIndex: '',
          key: '',
       
          render: (text, record) => (
            <Checkbox
            onChange={() => handleCheckboxChange(record.key,record)}
            checked={checkedItemsStock.some(item => item.id === record.key)}
            style={{ fontWeight: '500' }}
          />      
             
         
          ),
        }
             
             
      
    ];
    //wastage için filtelme
    const handleSearchWastage = (value, dataIndex) => {
      setFiltersWastage(prevFilters => {
        // Filtre değerini güncelle
        const updatedFilters = {
          ...prevFilters,
          [dataIndex]: value, // Sadece bu filtreyi günceller
        };
    
        // Tüm filtrelere göre filtreleme yap
        const filteredData = itemsWastage.filter(item => {
          return (
            !item.isDeleted && // Silinmemiş öğeleri dahil et
            Object.keys(updatedFilters).every(key => {
              const filterValue = updatedFilters[key]?.toLowerCase(); // Aktif filtreyi al
              const itemValue = item[key] !== undefined ? item[key].toString().toLowerCase() : ''; // İlgili değer varsa kontrol et
              return filterValue ? itemValue === filterValue : true; // Tam eşleşme kontrolü
            })
          );
        });
    
        setFilteredItemsWastage(filteredData); // Filtrelenmiş veriyi güncelle
        return updatedFilters; // Yeni filtreleri geri döndür
      });
    };

    const handleResetWastage = () => {
      setFilteredItemsWastage(itemsWastage); // Tüm listeyi geri döndürüyoruz
    };

    const getColumnSearchPropsWastage = dataIndex => ({
      title: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{placeholderMap[dataIndex] || dataIndex}</span>
          <Input
            id={`search-input-${dataIndex}`}
            placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
            onChange={e => handleSearchWastage(e.target.value, dataIndex)} // Her değişiklikte arama işlemi yapılacak
            style={{ width: '100%', marginTop: 8 }} // Başlığın altında input alanı olacak
            suffix={<SearchOutlined />} // Search ikonu ekleme
          />
        </div>
      ),
      sorter: (a, b) => {
        const aValue = a[dataIndex] !== undefined ? a[dataIndex].toString().toLowerCase() : '';
        const bValue = b[dataIndex] !== undefined ? b[dataIndex].toString().toLowerCase() : '';
        return aValue.localeCompare(bValue); // Sıralama işlemi
      },
    });


    const columnsWastage = [
      ...(isWarehouseSelectedAll ? [{
        title: 'Depo',
        dataIndex: 'companyName',
        key: 'companyName',
        ...getColumnSearchPropsWastage('companyName'),
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
        render: (text) => (
            <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>
        ),
    }] : []),
    {
      title: 'Kalınlık',
      dataIndex: 'thickness',
      key: 'thickness',
    
      ...getColumnSearchPropsWastage('thickness'),
      sorter: (a, b) => a.thickness - b.thickness,
      render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}mm </span>,
    },

      {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
       
        ...getColumnSearchPropsWastage('quality'),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
        render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      },
  
       
       
        {
          title: 'En',
          dataIndex: 'width',
          key: 'width',
         
          ...getColumnSearchPropsWastage('width'),
          sorter: (a, b) => a.width - b.width,
          render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
        {
          title: 'Boy',
          dataIndex: 'height',
          key: 'height',
       
          ...getColumnSearchPropsWastage('height'),
          sorter: (a, b) => a.height - b.height,
          render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
  
        {
          title: 'Yüzey',
          dataIndex: 'surface',
          key: 'surface',
        
          ...getColumnSearchPropsWastage('surface'),
          sorter: (a, b) => a.surface.localeCompare(b.surface), 
          render: (text) => <span style={{ color: itemsWastage.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        },
   
        {
          title: 'Adet',
          dataIndex: 'piece',
          key: 'piece',
          render: (text, record) => (
            <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>
                {text !== undefined && text !== null && text !== "" ? text : 1}
            </span>
        ),         
          ...getColumnSearchPropsWastage('piece'),
          sorter: (a, b) => a.piece - b.piece,
        },
          
        {
          title: 'Rezerve Adet',
          dataIndex: 'reservedPiece',
          key: 'reservedPiece',
          render: (text,record) => <span style={{color: itemsWastage.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
        },
          
       
        
        {
          title: 'Açıklama',
          dataIndex: 'description',
          key: 'description',
          render: (text, record) => {
            return (
              <Popover
                  content={
                    <div style={{ width: '200px', maxHeight: '700px' }}> 
                      <p><strong>Fotoğrafı:</strong> 
                        {record.imageUrl ?                 
                        <img alt="" src={record.imageUrl} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
                        : 
                        "Fotoğraf bulunmamaktadır."}</p>
                            <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Açıklaması:</strong> {record.description}</p>
                          </div>
                        }
                        title="Firede açıklama bulunmaktadır."
                        trigger="click" 
                      >
                    <span >
                      {record.description ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                    </span>
              </Popover>
            );
          },
        
        },
        {
            title: 'Kullanılacak Adet',
            dataIndex: '', 
            key: '',
           
            render: (text, record) => (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                  onChange={(e) => handleInputChangeWastage(record.key, e.target.value)}
                  className="input-style"
                  style={{width:"100%"}}
                  rules={[{ pattern: /^\d+$/, message: "Sadece rakam girişi yapınız.",required: true, }]}

                 
                 />
              </span>
            ),


            /*
            render: (text, record) => (
              <span style={{ color: record.isDeleted ? '#00000080' : '#000000' }}>
                <Input
                  value={1}
                 // onChange={(e) => handleInputChangeWastage(record.key, e.target.value)}
                />
              </span>
            ),
            */
        },
        
        {
          title: ' ',
          dataIndex: '',
          key: '',
        
          render: (text, record) => (
            <Checkbox
            onChange={() => handleCheckboxChangeWastage(record.key,record)}
            checked={checkedItemsWastage.some(item => item.id === record.key)}
            style={{ fontWeight: '500' }}
          />      
         
          ),
        },
             
             
      
    ];


    const [isModalVisiblePurchase, setIsModalVisiblePurchase] = useState(false);

    const handlePurchaseRequest = () => {
      setIsModalVisiblePurchase(true);
    };

    const handlePurchaseRequestAddFinish = () => {
      setIsModalVisiblePurchase(false); 
    };

    const handleModalCancelPurchase = () => {
      setIsModalVisiblePurchase(false);
    };

    const [isModalVisibleSetupName, setIsModalVisibleSetupName] = useState(false);
    const [setupName, setSetupName] = useState('');
    const [createJobCode, setCreateJobCode] = useState(null)
  
    const handleInputChangeSetupName =  (e) => {
      setSetupName(e.target.value);
    };

    const checkSendPurchase = (stockItem) => {
      const checkedItemsStockExcessx = checkedItemsStockExcess || [];
    
      return checkedItemsStockExcessx.some(checkedItem =>
        checkedItem.id === stockItem.id
      );
    };
    
  
    const handleUpdate = async() => {
      // setLoading(true);
     const savedSetupName = localStorage.getItem("setupName") ? localStorage.getItem("setupName")  : record.setupName;
     console.log("hande update   savedSetupName",savedSetupName)
     const savedCheckedItemsStock = JSON.parse(localStorage.getItem("checkedItemsStock"));
     const savedCheckedItemsWastage = JSON.parse(localStorage.getItem("checkedItemsWastage"));
     
     console.log("savedCheckedItemsStock", savedCheckedItemsStock);
     console.log("savedCheckedItemsWastage", savedCheckedItemsWastage);
     
     console.log("checkedItemsStock",checkedItemsStock)
     console.log("checkedItemsStockExcess",checkedItemsStockExcess)
 
     if (!(checkedItemsStock.length > 0 || checkedItemsWastage.length > 0)) {
       NotificationService.openErrorNotification({
         title: 'İşlem Başarısız',
         description: "Seçili sac levha ya da fire bulunammaktadır. Lütfen sac levha yada fire seçiniz",
         placement: 'topRight'
       });
       setLoading(false);
       return;
     }

     const createJobCode =  record.setupName ?   jobCode : `${jobCode}-${savedSetupName}`;
     console.log("createJobCode 2",createJobCode)

     setCreateJobCode(createJobCode);
     
   
   
     const arraysEqual = (arr1, arr2) => {
       if (arr1.length !== arr2.length) return false;
       return arr1.every((item, index) => 
           item.id === arr2[index].id &&
           item.oldPiece === arr2[index].oldPiece &&
           item.quality === arr2[index].quality &&
           item.height === arr2[index].height &&
           item.width === arr2[index].width
       );
     };

 
     const data = await FirebaseService.getOpenOrderData();
     const filteredData = data.filter(item => 
       !item.isDeleted && 
       (record.setupName ? item.jobCode === record.jobCode : item.jobCode === createJobCode) &&
       item.companyName === record.companyName &&
       item.orderType === record.orderType &&
       item.projectCode === record.projectCode &&
       arraysEqual(item.stock, savedCheckedItemsStock) &&
       arraysEqual(item.wastage, savedCheckedItemsWastage)
     
       /*
       (record.setupName 
         ? arraysEqual(item.stock, record.stock) && arraysEqual(item.wastage, record.wastage) 
         : arraysEqual(item.stock, savedCheckedItemsStock) && arraysEqual(item.wastage, savedCheckedItemsWastage)
       )*/
     );
     

     console.log(" record.stock ", record.stock,record.wastage)
     console.log("filteredData",filteredData)
     
     if (filteredData.length === 0) {
       NotificationService.openErrorNotification({
         title: 'İşlem Başarısız',
         description: "Böyle bir açık sipariş bulunamadı.",
         placement: 'topRight'
       });
       setLoading(false);
       console.log("No matching item found.");
       return;
     }

 
     const excessValuesKeys = Object.keys(checkedItemsStockExcess);
     const excessValuesCount = excessValuesKeys.length;
     
   
      

 
     if (excessValuesCount > 0) {
        // console.log("Fazla talep edilen ürün var");
         excessValuesKeys.forEach((key) => {
             const value = checkedItemsStockExcess[key];
           //  console.log(`Key: ${key}, Value: ${value}`);
             
         });
       
         setVisibleContinue(true);
        
 
     }
     else{
console.log("buraya geldi alooooo")
      
      // console.log("fazla ürün yok")
       //stoktan ve fireden reserved update oluyo
       let updatedCheckedItemsStock;
       if (checkedItemsStock.length > 0) {
         for (const record of checkedItemsStock) {
           const { id, piece } = record;
           console.log("piece stock",piece)
           if (piece === 0) {
             NotificationService.openErrorNotification({
               title: 'İşlem Başarısız',
               description: "Adet 0 seçilemez",
               placement: 'topRight'
             });
             setLoading(false);
             return;
   
           }
           const result = await FirebaseService.updateStockReservedPiece(id, piece);
           console.log(result);
         }
       
       updatedCheckedItemsStock = checkedItemsStock.map(({ oldPiece, ...rest }) => rest);
         console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
       }
       
       let updatedCheckedItemsWastage;
       if (checkedItemsWastage.length > 0) {
         for (const x of checkedItemsWastage) {
           const { id, piece } = x;
           console.log("piece wastage",piece)
           if (piece === 0) {
             NotificationService.openErrorNotification({
               title: 'İşlem Başarısız',
               description: "Adet 0 seçilemez",
               placement: 'topRight'
             });
             setLoading(false);
             return;
           }
           const result = await FirebaseService.updateWastageReservedPiece(id, piece);
           console.log(result);
         }
       
         updatedCheckedItemsWastage = checkedItemsWastage.map(({ oldPiece, ...rest }) => rest);
         console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
       }
 
 
       //stocktaki itemlar isSendPurchase ekleniyor
       let updatedcheckedItemsStock = checkedItemsStock.map(stockItem => ({
             ...stockItem,
             isSendPurchase: checkSendPurchase(stockItem)
           })
         )
       
       
         console.log("updatedcheckedItemsStock",updatedcheckedItemsStock)
   
           // checkedItemsStock için
        const existingStockItems = JSON.parse(localStorage.getItem("checkedItemsStock")) || []; 
        const updatedStockItems = [...existingStockItems, ...updatedcheckedItemsStock]; 
        localStorage.setItem("checkedItemsStock", JSON.stringify(updatedStockItems));
      console.log("updatedStockItems LOKALDE ",updatedStockItems)
        // checkedItemsWastage için
        const existingWastageItems = JSON.parse(localStorage.getItem("checkedItemsWastage")) || []; 
        const updatedWastageItems = [...existingWastageItems, ...checkedItemsWastage]; 
        localStorage.setItem("checkedItemsWastage", JSON.stringify(updatedWastageItems)); 
        console.log("updatedWastageItems LOKALDE ",updatedWastageItems)

      

       const updateOpenOrderItem = {
             stock: checkedItemsStock,
             wastage: checkedItemsWastage,   
       };
       console.log("daupdateOpenOrderItemta",updateOpenOrderItem)
 
       try {
         const result = await FirebaseService.updateOpenOrderStockAndWastage(filteredData[0].key, updateOpenOrderItem);
         if (result.success) {
            
           
             NotificationService.openSuccessNotification({
               title: 'İşlem Başarılı',
               description: "Seçilenler başarıyla rezerve edilmiştir.",
               placement: 'topRight'
             });
             setLoading(false);
             setIsModalVisibleSetupName(false);
             setTimeout(() => {
               setCheckedItemsStock([]);
               setCheckedItemsStockExcess([]);
               setCheckedItemsWastage([]);
               setInputValuesStock({});
               setInputValuesWastage({});
               fetchData();
               setIsReserved(true);
             //  window.location.reload();
             }, 2000);
           } else {
            // console.log("hata",result)
             NotificationService.openErrorNotification({
               title: 'İşlem Başarısız',
               description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
               placement: 'topRight'
             });
             setLoading(false);
           }
           
           
       } 
       catch (error) {
           console.log("error 7",error)
           NotificationService.openErrorNotification({
             title: 'İşlem Başarısız',
             description: "Bilinmeyen bir hata ile karşılaşıldı.",
             placement: 'topRight'
           });
           setLoading(false);
 
       }
       
 
     }
     
 
   };


  const handleSaveSetupName = async() => {
      
    console.log("setupName",setupName)
    setLoading(true);
    if (!setupName || setupName.includes(" ")) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Setup isminde boşluk bulunmamalıdır. Lütfen düzeltiniz.",
        placement: 'topRight'
      });
      setLoading(false);
      return;
  }
  console.log("checkedItemsWastage",checkedItemsWastage)
  
  localStorage.setItem("setupName", setupName);
  
   
    const createJobCode =  record.setupName ? jobCode :  `${jobCode}-${setupName}`;
    console.log("createJobCode 3",createJobCode)

    setCreateJobCode(createJobCode);
    
  

    const excessValuesKeys = Object.keys(checkedItemsStockExcess);
    const excessValuesCount = excessValuesKeys.length;
    
    console.log("checkedItemsStock",checkedItemsStock)
    console.log("checkedItemsStockExcess",checkedItemsStockExcess)

    if (!(checkedItemsStock.length > 0 || checkedItemsWastage.length > 0)) {
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Seçili sac levha ya da fire bulunammaktadır. Lütfen sac levha yada fire seçiniz",
        placement: 'topRight'
      });
      setIsModalVisibleSetupName(false);
      setLoading(false);
      return;
    }
    
        // checkedItemsStock için
      const existingStockItems = JSON.parse(localStorage.getItem("checkedItemsStock")) || []; 
      const updatedStockItems = [...existingStockItems, ...checkedItemsStock]; 
      localStorage.setItem("checkedItemsStock", JSON.stringify(updatedStockItems)); 

      // checkedItemsWastage için
      const existingWastageItems = JSON.parse(localStorage.getItem("checkedItemsWastage")) || []; 
      const updatedWastageItems = [...existingWastageItems, ...checkedItemsWastage]; 
      localStorage.setItem("checkedItemsWastage", JSON.stringify(updatedWastageItems));


      

    if (excessValuesCount > 0) {
       // console.log("Fazla talep edilen ürün var");
        excessValuesKeys.forEach((key) => {
            const value = checkedItemsStockExcess[key];
          //  console.log(`Key: ${key}, Value: ${value}`);
            
        });
      
        setVisibleContinue(true);
        setLoading(false);

    }
    else{
     
     // console.log("fazla ürün yok")
      //stoktan ve fireden reserved update oluyo
      let updatedCheckedItemsStock;
      if (checkedItemsStock.length > 0) {
        for (const record of checkedItemsStock) {
          const { id, piece } = record;
          if (piece === 0) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Adet 0 seçilemez",
              placement: 'topRight'
            });
            setLoading(false);
            return;
  
          }
          const result = await FirebaseService.updateStockReservedPiece(id, piece);
          console.log(result);
        }
      
      updatedCheckedItemsStock = checkedItemsStock.map(({ oldPiece, ...rest }) => rest);
        console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
      }
      
      let updatedCheckedItemsWastage;
      if (checkedItemsWastage.length > 0) {
        for (const x of checkedItemsWastage) {
          const { id, piece } = x;
          if (piece === 0) {
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Adet 0 seçilemez",
              placement: 'topRight'
            });
            setLoading(false);
            return;
  
          }
          const result = await FirebaseService.updateWastageReservedPiece(id, piece);
          console.log(result);
        }
      
        updatedCheckedItemsWastage = checkedItemsWastage.map(({ oldPiece, ...rest }) => rest);
        console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
      }


      //stocktaki itemlar isSendPurchase ekleniyor
      let updatedcheckedItemsStock = checkedItemsStock.map(stockItem => ({
            ...stockItem,
            isSendPurchase: checkSendPurchase(stockItem)
          })
        )
      
      
        console.log("updatedcheckedItemsStock",updatedcheckedItemsStock)
   
//burdan hiç emin depğilimmmm
console.log("buraya geldi kayıt atılıyor yeni open order")
     const currentDate =new Date()
      const data = {
            firstTransactionDate: currentDate,
            firstUserId: localStorage.getItem("uid"),
            programmerUserId:localStorage.getItem("uid"),
            isDeleted: false,
            programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
            jobCode : createJobCode, 
            companyName: record.companyName,
            projectCode: record.projectCode ? record.projectCode : null,
            orderType : (record.orderType === "offer" && record.isOfferApproved) ? "order" : record.orderType,
            stock: checkedItemsStock,
            wastage: checkedItemsWastage,
            deadline:null, //setup sonunda seçiliyor
            isSetup:false,
            isOffer:record.isOffer ? record.isOffer : false,
            isWaiting: true,
            isOfferApproved:record.isOfferApproved ? record.isOfferApproved : false,
            noOfferApprovedDescription:record.noOfferApprovedDescription ? record.noOfferApprovedDescription : null,
            noQrScanDescription:record.noQrScanDescription ? record.noQrScanDescription : null,
            isOfferSetupUpdate: record.isOfferSetupUpdate ? record.isOfferSetupUpdate : false,
            isOfferChange:record.isOfferChange ? record.isOfferChange : false,
            isOfferDenied:record.isOfferDenied ? record.isOfferDenied : false,
           // priority:999999,
           isChangedProgrammer:false,
           isShipment: false,
           shipmentPiece: 0,
           invoiceNumber:null,
           setupName:setupName ? setupName : null
           
      };
      console.log("data",data)

      try {
          const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
          if (result.success) {
            const item = {
              isDeleted:false,
              item: data.createJobCode,
              firstTransactionDate: currentDate,
              firstUserId: localStorage.getItem("uid"),
            }
            const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
          


          //açık siparişler silme kısmı
              
          const openOrderData = await FirebaseService.getOpenOrderData();
          const filteredOpenOrder = openOrderData.filter(item => 
            item.jobCode && item.jobCode === createJobCode.substring(0, 5)
          );
          
          console.log("filteredOpenOrder", filteredOpenOrder);
          
          for (const item of filteredOpenOrder) {
            try {
              const result = await FirebaseService.deleteOpenOrderData(item.key);
              if (result) {
                console.log(`Item with key ${item.key} deleted successfully.`);
              } else {

                console.log(`Failed to delete item with key ${item.key}.`);
              }
            } catch (error) {
             
              console.error(`Error deleting item with key ${item.key}:`, error);
            }
          }


            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: "Açık siparişiniz başarıyla kaydedilmitşir. Rezerve işlemlerine devam edebilirsiniz.",
              placement: 'topRight'
            });
        

            setLoading(false);
            setIsModalVisibleSetupName(false);
            setTimeout(() => {
             // navigate("/programmerpage");
             setCheckedItemsStock([]);
             setCheckedItemsStockExcess([]);
             setCheckedItemsWastage([]);
             setInputValuesStock({});
             setInputValuesWastage({});
             fetchData();
             setIsReserved(true);
            // window.location.reload();

            }, 2000);
          } else {
           // console.log("hata",result)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });
            setLoading(false);
          }
          
          
      } 
      catch (error) {
          console.log("error 2",error)
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false);

      }
      

    }
    

    };
  
  
    const handleCancelSetupName = () => {
      setIsModalVisibleSetupName(false);
    };

    const showModal = () => {
      console.log("record.setupName",record.setupName)
 
       const savedSetupName = localStorage.getItem("setupName");
       console.log("savedSetupName", savedSetupName);
     
       const checkZeroPiece = (items) => {
         for (const record of items) {
           const { piece } = record;
           if (piece === 0) {
             NotificationService.openErrorNotification({
               title: 'İşlem Başarısız',
               description: "Adet 0 seçilemez",
               placement: 'topRight'
             });
             setLoading(false);
             return true; // Return true if error found
           }
         }
         return false; // No errors found
       };
     
       if (checkedItemsStock.length > 0 && checkZeroPiece(checkedItemsStock)) {
         return;
       }
     
       if (checkedItemsWastage.length > 0 && checkZeroPiece(checkedItemsWastage)) {
         return;
       }
     
        if(record.setupName === null && savedSetupName ===null) { //record.setupname null ve savedSetupName null
         console.log("buraya girdi hiçbiri yok 1 ")
         if (!(checkedItemsStock.length > 0 || checkedItemsWastage.length > 0)) {
           NotificationService.openErrorNotification({
             title: 'İşlem Başarısız',
             description: "Seçili sac levha ya da fire bulunammaktadır. Lütfen sac levha yada fire seçiniz",
             placement: 'topRight'
           });
           setLoading(false);
           return;
         }
         setIsModalVisibleSetupName(true); 
       }
      else if (savedSetupName !== null  ||   record.setupName !== undefined  ) {
         console.log("nedense buraya girdi",savedSetupName, record.setupName )
         console.log("Saved setupName from localStorage:", savedSetupName);
         handleUpdate();
       }
       else if(record.setupName === undefined || savedSetupName ===undefined){
         console.log("buraya girdi hiçbiri yok2 ")
         setIsModalVisibleSetupName(true); 
       }
     
      
 
     
     };
  
     //fazla ürün var satın alma talebi gidiyor
     const handleSendPurchaseRequest = async () => {
      setLoading(true);
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(0, 0, 0, 0); 

      const updatedPurchaseList = checkedItemsStockExcess.map(item => ({
        ...item,
        jobCode: createJobCode,
        description:null
      }));

       //min stok kontrolü için satın alma isteği atılıyor
       for (const item of updatedPurchaseList) {
        //console.log("updatedPurchaseList",updatedPurchaseList)
        const updatedPurchaseListFixed = updatedPurchaseList.map(item => ({  //burda jobcode null olabilir ve currentpiece göndermeye gerek yok
            ...item,
            piece : item.minPiece- item.currentPiece,
            jobCode:null
          }));
        //  console.log("updatedPurchaseListFixed",updatedPurchaseListFixed)
        //  console.log(",item.currentPiece",item.currentPiece,item.minPiece,updatedPurchaseListFixed)
        if (item.currentPiece < item.minPiece) {
          try {
            const data = { 
                jobCode: jobCode,
                companyName: record.companyName,
                productType: "raw",
                unitName: "teknik ressam",
                deadline: currentDate,
              //  requestedProductInfo: checkedItemsStockExcess,
                requestedProductInfo:updatedPurchaseListFixed,
                offerJobCode: generateRandomCode(5),
                deadlineForPayment:null,
                paymentMethod:null,
                mailList:null,
                sentExcelList:null,
                incomingExcelList:null,
                acceptExcelDocumentUrl:null,
                description:"Minimum stok altına düşmüştür." ,
                isSendSetup: false
              };

             const result = await FirebaseService.addPurchaseRequest(data);
            if (result.success) {
              console.log(`Purchase request created successfully for item with id ${item.id}.`);
            } else {
              console.log(`Failed to create purchase request for item with id ${item.id}.`);
            }
            
          } catch (error) {
            console.error(`Error creating purchase request for item with id ${item.id}:`, error);
          }
        }
      }

      const data = { 
        jobCode: createJobCode,
        companyName: record.companyName,
        productType: "raw",
        unitName: "teknik ressam",
        deadline: currentDate,
      //  requestedProductInfo: checkedItemsStockExcess,
        requestedProductInfo:updatedPurchaseList,
        offerJobCode: generateRandomCode(5),
        deadlineForPayment:null,
        paymentMethod:null,
        mailList:null,
        sentExcelList:null,
        incomingExcelList:null,
        acceptExcelDocumentUrl:null,
        description:"Teknik ressam tarafından seçilmiştir, setupda kullanılacaktır." ,
        isSendSetup: true
      };
    //  console.log("satın alma talebi data",data)
      
      try {
        const result = await FirebaseService.addPurchaseRequest(data);
        if (result.success) {
          setVisibleContinue(false);
          setIsModalVisibleSetupName(false);

       //  console.log( "satın alma talebi gönderildi.")

       const savedSetupName = localStorage.getItem("setupName");

         
       const savedCheckedItemsStock = JSON.parse(localStorage.getItem("checkedItemsStock"));
       const savedCheckedItemsWastage = JSON.parse(localStorage.getItem("checkedItemsWastage"));
       
       console.log("savedCheckedItemsStock satın alma talebi ", savedCheckedItemsStock);
       console.log("savedCheckedItemsWastage satın alma talebi", savedCheckedItemsWastage);
       
 
       const createJobCode =  record.setupName ?  jobCode : `${jobCode}-${savedSetupName}`;
       console.log("createJobCode 1",createJobCode)
       setCreateJobCode(createJobCode);
       
     
      


       let updatedCheckedItemsStockExcess;
       //  console.log("checkedItemsStockExcess",checkedItemsStockExcess)
         if (checkedItemsStockExcess && checkedItemsStockExcess.length > 0) {
           for (const x of checkedItemsStockExcess) {
             const { id, piece } = x;
             if (piece === 0) {
               NotificationService.openErrorNotification({
                 title: 'İşlem Başarısız',
                 description: "Adet 0 seçilemez",
                 placement: 'topRight'
               });
               setLoading(false);
               return;
     
             }
             const result = await FirebaseService.updateStockWaitingPiece(id, piece);
            // console.log(result);
           }
         //  console.log("Updated checkedItemsStock:", updatedCheckedItemsStockExcess);
         }

         const requestedItems = checkedItemsStock.filter(stockItem =>
           !checkedItemsStockExcess.some(item =>
             item.id === stockItem.id &&
             item.quality === stockItem.quality &&
             item.height === stockItem.height &&
             item.width === stockItem.width &&
             item.piece === stockItem.piece &&
             item.thickness === stockItem.thickness &&
             item.surface === stockItem.surface
           )
         );
         
        // console.log("stocktan talep edilenler çıktı",requestedItems);
   
         //stoktan ve fireden reserved update oluyo
         let updatedCheckedItemsStock;
         
         if (requestedItems && requestedItems.length > 0) {
           for (const x of requestedItems) {
             const { id, piece } = x;
             if (piece === 0) {
               NotificationService.openErrorNotification({
                 title: 'İşlem Başarısız',
                 description: "Adet 0 seçilemez",
                 placement: 'topRight'
               });
               setLoading(false);
               return;
     
             }
             const result = await FirebaseService.updateStockReservedPiece(id, piece);
            // console.log(result);
           }
         // console.log("Updated checkedItemsStock:", updatedCheckedItemsStock);
         }
   

         let updatedCheckedItemsWastage;
         if (checkedItemsWastage && checkedItemsWastage.length > 0) {
           for (const x of checkedItemsWastage) {
             const { id, piece } = x;
             if (piece === 0) {
               NotificationService.openErrorNotification({
                 title: 'İşlem Başarısız',
                 description: "Adet 0 seçilemez",
                 placement: 'topRight'
               });
               setLoading(false);
               return;
     
             }
             const result = await FirebaseService.updateWastageReservedPiece(id, piece);
            // console.log(result);
           }
         
           updatedCheckedItemsWastage = checkedItemsWastage.map(({ oldPiece, ...rest }) => rest);
          // console.log("Updated updatedCheckedItemsWastage:", updatedCheckedItemsWastage);
         }

       //stocktaki itemlar isSendPurchase ekleniyor
       let updatedcheckedItemsStock = checkedItemsStock.map(stockItem => ({
         ...stockItem,
         isSendPurchase: checkSendPurchase(stockItem)
       })
       )
       console.log("updatedcheckedItemsStock",updatedcheckedItemsStock)
     
       
     
       const arraysEqual = (arr1, arr2) => {
         if (arr1.length !== arr2.length) return false;
         return arr1.every((item, index) => 
             item.id === arr2[index].id &&
             item.oldPiece === arr2[index].oldPiece &&
             item.quality === arr2[index].quality &&
             item.height === arr2[index].height &&
             item.width === arr2[index].width
         );
       };
   
       const openOrderData = await FirebaseService.getOpenOrderData();
       const filteredData = openOrderData.filter(item => 
         !item.isDeleted && 
         item.jobCode === createJobCode 
         && item.companyName === record.companyName 
         && item.orderType === record.orderType 
         && item.projectCode === record.projectCode
       &&  arraysEqual(item.stock, savedCheckedItemsStock) &&
         arraysEqual(item.wastage, savedCheckedItemsWastage)
       );
        console.log("data",openOrderData)
        console.log("filteredData",filteredData)
          
        if (filteredData.length === 0) {
             //açık siparişlere kayıt
         const currentDate =new Date()
         const data = {
               firstTransactionDate: currentDate,
               firstUserId: localStorage.getItem("uid"),
               programmerUserId:localStorage.getItem("uid"),
               isDeleted: false,
               programmerName: localStorage.getItem("name") + " " + localStorage.getItem("surname"),
               jobCode : createJobCode, 
               companyName: record.companyName,
               projectCode: record.projectCode ? record.projectCode : null,
               orderType :record.orderType,
               stock: updatedcheckedItemsStock,
               wastage: checkedItemsWastage,
               deadline:null, //setup sonunda seçiliyor
               isSetup:false,
               isOffer:false,
               isWaiting: true,
               isOfferApproved:false,
               noOfferApprovedDescription:null,
               noQrScanDescription:null,
               isOfferSetupUpdate:false,
               isOfferChange:false,
               isOfferDenied:false,
              // priority:999999,
              isChangedProgrammer:false,
              isShipment: false,
              shipmentPiece: 0,
              invoiceNumber:null,
              setupName:setupName ? setupName : null

              
         };
       console.log("data setupsız kayot",data)
          try {
            const result = await FirebaseService.addRecording(parsCollections.openOrder,data);
            if (result.success) {
              const item = {
                isDeleted:false,
                item: data.createJobCode,
                firstTransactionDate: currentDate,
                firstUserId: localStorage.getItem("uid"),
              }
              const result = await FirebaseService.addRecording(parsCollections.jobCodes,item);
            
               //açık siparişler silme kısmı
              
              const openOrderData = await FirebaseService.getOpenOrderData();
              const filteredOpenOrder = openOrderData.filter(item => 
                item.jobCode && item.jobCode === createJobCode.substring(0, 5)
              );
              
              console.log("filteredOpenOrder", filteredOpenOrder);
              
              for (const item of filteredOpenOrder) {
                try {
                  const result = await FirebaseService.deleteOpenOrderData(item.key);
                  if (result) {
                    console.log(`Item with key ${item.key} deleted successfully.`);
                  } else {

                    console.log(`Failed to delete item with key ${item.key}.`);
                  }
                } catch (error) {
                
                  console.error(`Error deleting item with key ${item.key}:`, error);
                }
              }

              NotificationService.openSuccessNotification({
                title: 'İşlem Başarılı',
                description: "Açık siparişiniz başarıyla kaydedilmitşir. Rezerve işlemlerine devam edebilirsiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              setIsModalVisibleSetupName(false);
              setTimeout(() => {
               // navigate("/programmerpage");
               setCheckedItemsStock([]);
               setCheckedItemsStockExcess([]);
               setCheckedItemsWastage([]);
               setInputValuesStock({});
               setInputValuesWastage({});
               fetchData();
               setIsReserved(true);
              // window.location.reload();
  
              }, 2000);
            } else {
             // console.log("hata",result)
              NotificationService.openErrorNotification({
                title: 'İşlem Başarısız',
                description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
                placement: 'topRight'
              });
              setLoading(false);
              return;
            }
            
            
        } 
        catch (error) {
            console.log("error 3",error)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "Bilinmeyen bir hata ile karşılaşıldı.",
              placement: 'topRight'
            });
            setLoading(false);

        }
          
        }
        else{

        // checkedItemsStock için
        const existingStockItems = JSON.parse(localStorage.getItem("checkedItemsStock")) || []; // Mevcut veriyi al veya boş dizi kullan
        const updatedStockItems = [...existingStockItems, ...updatedcheckedItemsStock]; // Mevcut ve yeni verileri birleştir
        localStorage.setItem("checkedItemsStock", JSON.stringify(updatedStockItems)); // Güncellenmiş veriyi kaydet
        console.log("updatedStockItems LOKALDE ",updatedStockItems)
        // checkedItemsWastage için
        const existingWastageItems = JSON.parse(localStorage.getItem("checkedItemsWastage")) || []; // Mevcut veriyi al veya boş dizi kullan
        const updatedWastageItems = [...existingWastageItems, ...checkedItemsWastage]; // Mevcut ve yeni verileri birleştir
        localStorage.setItem("checkedItemsWastage", JSON.stringify(updatedWastageItems)); // Güncellenmiş veriyi kaydet
        console.log("updatedWastageItems LOKALDE ",updatedWastageItems)

          const updateOpenOrderItem = {
            stock: updatedcheckedItemsStock,
            wastage: checkedItemsWastage,   
      };
      console.log("daupdateOpenOrderItemta",updateOpenOrderItem)

      try {
        const result = await FirebaseService.updateOpenOrderStockAndWastage(filteredData[0].key, updateOpenOrderItem);
        if (result.success) {
           
          
            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: "Seçilenler başarıyla rezerve edilmiştir.",
              placement: 'topRight'
            });
            setLoading(false);
            setIsModalVisibleSetupName(false);
            setTimeout(() => {
              setCheckedItemsStock([]);
              setCheckedItemsStockExcess([]);
              setCheckedItemsWastage([]);
              setInputValuesStock({});
              setInputValuesWastage({});
              fetchData();
              setIsReserved(true);
            //  window.location.reload();
            }, 2000);
          } else {
           // console.log("hata",result)
            NotificationService.openErrorNotification({
              title: 'İşlem Başarısız',
              description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
              placement: 'topRight'
            });
            setLoading(false);
          }
          
          
      } 
      catch (error) {
          console.log("error 4",error)
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false);

      }
        }
         
        }
        else{
          console.log("error 5")
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "Bilinmeyen bir hata ile karşılaşıldı.",
            placement: 'topRight'
          });
          setLoading(false);

        }
        
      } 
      catch (error) {
        console.log("error 6",error)
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);
      }
      
    };
  
  
    const [visibleContinue, setVisibleContinue] = useState(false);

    const handleCancelContinue = () => {
     setVisibleContinue(false);
    };

    const columnsStockExcess = [
        {
          title: 'Kalite',
          dataIndex: 'quality',
          key: 'quality',
        
         // ...getColumnSearchPropsStock('quality'),
          sorter: (a, b) => a.quality.localeCompare(b.quality), 
          render: (text) => <span style={{  }}>{text}</span>,
        },
    
          {
            title: 'Kalınlık',
            dataIndex: 'thickness',
            key: 'thickness',
           
          //  ...getColumnSearchPropsStock('thickness'),
            sorter: (a, b) => a.thickness - b.thickness,
            render: (text) => <span style={{ }}>{text}</span>,
          },
    
         
          {
            title: 'En',
            dataIndex: 'width',
            key: 'width',
           
           // ...getColumnSearchPropsStock('width'),
            sorter: (a, b) => a.width - b.width,
            render: (text) => <span style={{  }}>{text}</span>,
          },
          {
            title: 'Boy',
            dataIndex: 'height',
            key: 'height',
           // ...getColumnSearchPropsStock('height'),
            sorter: (a, b) => a.height - b.height,
            render: (text) => <span style={{  }}>{text}</span>,
          },
    
          {
            title: 'Yüzey',
            dataIndex: 'surface',
            key: 'surface',
           // ...getColumnSearchPropsStock('surface'),
            sorter: (a, b) => a.surface.localeCompare(b.surface), 
            render: (text) => <span style={{  }}>{text}</span>,
          },
     
          {
            title: 'Adet',
            dataIndex: 'piece',
            key: 'piece',
            render: (text,record) => <span style={{}}>{text}</span>,
           
            sorter: (a, b) => a.piece - b.piece,
           // ...getColumnSearchPropsStock('piece'),
          },         
        
    ];

    return ( 
    <div style={{padding:"10px 10px",}}>
        <div style={{ display: "flex", justifyContent: "space-between", textAlign:"center" }}>
            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Müşteri</p>
                {record.companyName}
            </div>
            
            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
              <p style={{ fontWeight: "bold", color: colors.blue }}>Proje Kodu</p>
              {record.projectCode ? record.projectCode : "-"}
            </div>

            <div style={{ textAlign: "left", flex: "1 0 33%" }}>
                <p style={{ fontWeight: "bold", color: colors.blue }}>Depo</p>
                 <Select
                    onChange={onChangeFilterWarehouse}
                    placeholder="Depo Seçiniz"
                    style={{ width:"150px"}}
                    suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
                    options={warehouseOptions}
                     className="input-style"
                   
                />
             
            </div>
        </div>

        {!selectedWarehouse ? (
  
  <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh", 
    color: colors.gray,
    fontWeight: "bold",
    fontSize: "18px",
  }}>
    Lütfen depo seçiniz
  </div>
) : (
  <>
        <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",textAlign:"left" }}>Fire Listesi</div>
      <Table
          style={{width:"100%", margin:"auto"}}
          scroll={{ y: 500 }}           
          locale={{
              emptyText: 'Henüz fire bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
           dataSource={filteredItemsWastage}
            columns={columnsWastage}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

    <div style={{ fontWeight: "bold", color: colors.blue, marginTop:"50px",textAlign:"left" }}>Güncel Stok Listesi</div>
      <Table
          style={{width:"100%", margin:"auto"}}
          scroll={{ y: 500 }}         
          locale={{
            emptyText: 'Henüz stok bulunmamaktadır...',
            filterReset: 'Sıfırla',
            filterTitle: 'Filtre Menüsü',
            selectAll: 'Hepsini Seç',
            selectInvert: 'Tersini Seç',
            selectionAll: 'Tümünü Seç',
            sortTitle: 'Sıralama',
            triggerDesc: 'Azalan sıralama için tıklayın',
            triggerAsc: 'Artan sıralama için tıklayın',
            cancelSort: 'Sıralamayı iptal etmek için tıklayın',
          }}
           dataSource={filteredItemsStock}
            columns={columnsStock}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
      <div style={{display:"flex", justifyContent:"right"}}>

    
                <Button 
               style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} 
              onClick={handlePurchaseRequest}>
              Talep Et
                </Button>
                <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisiblePurchase}
                onCancel={handleModalCancelPurchase}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>
          

          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={() => handleSetup(record)} >
          Setup Yükle & Devam Et
          </Button>

          <Modal
            title="Setup Yükle"
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
          >
            <SetupAdd recordx={recordForModal}  onClose={handleSetupFinish} />
          </Modal>

          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={showModal} >
          Kaydet
          </Button>
          {/* 
          <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}   onClick={showModal} >
          Kaydet ve Çık (Setupsız)
          </Button> */}

         

      <Modal
        title="Setup Name"
        visible={isModalVisibleSetupName}
        onCancel={handleCancelSetupName}
        footer={[
          <Button key="cancel" onClick={handleCancelSetupName}>
            İptal
          </Button>,
          <Button key="save"   style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}  onClick={handleSaveSetupName}>
            Kaydet
          </Button>,
        ]}
      >
        <Input
          placeholder="Setup name"
          value={setupName}
          onChange={handleInputChangeSetupName}
          style={{ marginTop: '10px' }}
        />
      </Modal>





      <div>           
          <Modal
                  title="Satın Alma Talebi Gönder"
                  visible={visibleContinue}
                  onOk={handleSendPurchaseRequest}
                  onCancel={handleCancelContinue}
                  okText="Evet, Gönder ve Açık Sipariş Oluşturmaya Devam Et"
                  cancelText="Vazgeç"
                  className="columnsStockExcess popup-modal-button"
                
              >
              <div> 
                <p>Satın alma talebi göndermek istediğinizden emin misiniz?</p>
                <Table
                    dataSource={checkedItemsStockExcess}
                    columns={columnsStockExcess}
                    showHeader={true}
                    pagination={false} 
                    className="custom-news-table"
                    rowClassName="custom-news-row"
                    locale={{
                      emptyText: 'Henüz eklemediniz...',
                      filterReset: 'Sıfırla',
                      filterTitle: 'Filtre Menüsü',
                      selectAll: 'Hepsini Seç',
                      selectInvert: 'Tersini Seç',
                      selectionAll: 'Tümünü Seç',
                      sortTitle: 'Sıralama',
                      triggerDesc: 'Azalan sıralama için tıklayın',
                      triggerAsc: 'Artan sıralama için tıklayın',
                      cancelSort: 'Sıralamayı iptal etmek için tıklayın',
                    }}
                />
            </div>
        </Modal>

        </div>

           
      </div>
      
      </>
      )}
      {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}
    </div>
    )

};


export default OpenOrderAddContinueMergeJobCodes;