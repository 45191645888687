import React,{useEffect,useState} from "react";
import { Table, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';

const ContractManufacturingList = () => {
  const [items, setItems] = useState([]);
  const [itemsLimited, setItemsLimited] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getContractManufacturingData();

     // console.log("data",data)
     // data.sort((a, b) => a.deadline.toMillis() - b.deadline.toMillis());  
      const limitedData = data.slice(0, 5); 
      setItemsLimited(limitedData)

      setItems(data)
    };

    fetchData();
  }, []);

  const goPage = (record) => {
    navigate('/contractmanufacturinglist', { state: { record } })
  };

  const columns = [
    {
      title: 'Firma',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
    {
      title: 'İş Kodu',
      dataIndex: 'jobCode',
      key: 'jobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text ? text : "-"}
      </span>,
    },
    {
      title: 'Tedarikçi',
      dataIndex: 'supplierName',
      key: 'supplierName',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{text}</span>,
     
    },
    {
      title: 'Proses',
      dataIndex: 'processName',
      key: 'processName',
      render: (text, record) => (
        <span style={{ color: record.isDeleted ? "#00000080" : "#000000", maxWidth: '200px', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
           {text}
        </span>
      ),
     // width: '200px' // max width için alternatif yöntem
    },
    
    {
        title: 'Termin Tarihi',
        dataIndex: 'deadline',
        key: 'deadline',
       render: (text, record) => {
          const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
          return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
        },
      },
         
  
  ];

  return  <div style={{
      //  justifyContent: 'center',
      //  alignItems: 'center',
     //   minHeight: '94vh',
       
       // width:"50%",
      
      }}
      className="limited-list-border"
      >
      <Button onClick={()=>goPage(items)} className="title-limited-list">
        Fason Üretim
       </Button>
          <Table
            locale={{
              emptyText: 'Henüz fason üretim talebi  eklemediniz...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default ContractManufacturingList;

   