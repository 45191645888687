import React, { useState, useEffect } from "react";
import { Space, Typography } from 'antd';
import { ThunderboltOutlined, CloudOutlined, CompassOutlined } from '@ant-design/icons'; // Icons
import { colors,openweathermap } from "../../environments/environment";

const { Text } = Typography;

const WeatherData = () => {
  const apiKey = openweathermap.apiKey;  
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const getWeatherData = async (cityName) => {
      try {
        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${apiKey}`);
        const data = await response.json();
        setWeatherData(data);
      } catch (error) {
        console.error('Error fetching weather data:', error.message);
      }
    };
    getWeatherData('Izmir');
  }, []); 

  const getWeatherIconUrl = (iconCode) => {
    return `http://openweathermap.org/img/w/${iconCode}.png`;
  };

  const kelvinTemp = weatherData?.main?.temp;
  const celciusTemp = Math.round(kelvinTemp - 273.15); 
  

  const messages01 = [
    "Bugün hava güneşli, dışarıda vakit geçirmeyi unutmayın.",
    "Hava açık, güzel bir gün geçirin.",
    "Bugün dışarısı güneşli, sıcaklara karşı dikkatli olun."
  ];
  const messages02 = [
   "Üstünüze bir şey almadan dışarı çıkmayın, bugün hava rüzgârlı",
    "Bugün rüzgâr hızı yüksek, dikkatli olun.",
    "Rüzgârlı havalarda üşütmemeye dikkat edin."
  ];
  const messages03 = [
    "Hava bulutlu, bugün hava değişikliklerine hazırlıklı olun.",
    "Hava kapalı, Bugün nemli olabilir.",
    "Bugün bulutlu, hava kapanabilir"    
   ];
   const messages09 = [
    "Şemsiyenizi almayı unutmayın, bugün yağmur yağacak.",
    "Bugün yağmur yağıyor, dikkatli olun.",
    "Hava kapalı olacak, yağmur yağma ihtimali yüksek."
   ];
   const messages11d = [
    "Bugün dışarı çıkarken dikkatli olun, fırtına olacak.",
    "Bugün hava fırtınalı olacak, güçlü rüzgarlara karşı dikkatli olun.",
   ];
 
   const messages13d = [
    "Sıkı giyindiğinize emin olun, bugün kar yağacak.",
    "Bugün hava soğuk olacak, kar yağma ihtimali var.",
   ];
   

   const messages50 = [
    "Bugün sis olacak, dışarda dikkatli olun",
   ];
   
   

  const getRandomMessage = (messages) => {
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  };


  return (
    <>
    {weatherData && weatherData && (
      <div style={{  padding: '10px', border: `2px solid #AFD3E7`, borderRadius: '15px' , display:"flex",alignItems:"center"}}>
    <div style={{   display:"flex", marginTop:"10px"}}>
<div style={{display:"block"}}>
<div style={{fontWeight:"500"}}>Bugün</div>
<img src={getWeatherIconUrl(weatherData.weather[0].icon)} alt="Hava Durumu İkonu" width={70} height={70}  />
</div>
<div style={{display:"block", marginLeft:"40px"}}>
        <div  style={{fontWeight:"500", marginBottom:"5px"}}>{celciusTemp.toFixed(2)}°C</div>
        <p style={{fontSize:"14px", width:"200px", fontFamily:"futur-medium"}}>
        {weatherData.weather[0].icon === "01d"  ? getRandomMessage(messages01) : ""}
        {weatherData.weather[0].icon === "02d"  ? getRandomMessage(messages02): ""}
        {weatherData.weather[0].icon === "03d"  ? getRandomMessage(messages03) : ""}
        {weatherData.weather[0].icon === "04d"  ? getRandomMessage(messages03)  : ""}
        {weatherData.weather[0].icon === "09d"  ? getRandomMessage(messages09)  : ""}
        {weatherData.weather[0].icon === "10d"  ? getRandomMessage(messages09) : ""}
        {weatherData.weather[0].icon === "11d"  ? getRandomMessage(messages11d)  : ""}
        {weatherData.weather[0].icon === "13d"  ? getRandomMessage(messages13d)  : ""}
        {weatherData.weather[0].icon === "50d"  ? getRandomMessage(messages50)  : ""}
        </p>
        </div>
      </div>
      </div>
    )}
  </>
  );
};

export default WeatherData;

/*
 <Space>
          <Text strong>Rüzgar: {weatherData.wind.speed} m/s</Text>
          <CompassOutlined style={{ transform: `rotate(${weatherData.wind.deg}deg)`, color: colors.blue }} />
        </Space>
        <Space>
          <Text strong>Nem: {weatherData.main.humidity}%</Text>
          <Text strong>Basınç: {weatherData.main.pressure} hPa</Text>
        </Space>
*/