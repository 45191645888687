import React, { useState } from "react";

import { Button, Modal } from 'antd';
import GenerateQr from "./dashboardpages/generateQr";
import {  colors } from "../environments/environment"

const Dashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlePurchaseRequest = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div
    style={{
     // justifyContent: 'center',
    //  alignItems: 'center',
    //  marginLeft:"25px"
    }}
      >
  
  <>
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500", float:"left"}} onClick={handlePurchaseRequest}>
         Qr Oluştur
        </Button>
  
        <Modal
          title="Qr Oluştur"
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <GenerateQr />
        </Modal>
      </>  
        {/* <div>
          <LastRecord />
        </div> 
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: "45px" }}>
            <LastUploads />
          </div>
          <div style={{ marginRight: "25px" }}>
            <OurServices />
          </div>
        </div>
        <div style={{display: "flex"}}>
          <div  style={{ marginRight: "25px" }}>
            <OurPartner />
            <OurInvestors />
          </div>
          <div style={{flex: 1,marginLeft:"7px"}}>
            <CategoryAdd />
          </div>
        </div>
        */}
      </div>
  )
  
      };
  export default Dashboard;