import React from "react";
import PropTypes from 'prop-types';
import { colors } from "../../../../environments/environment";

const WaitingOpenOrderUpdateDetail = ({ record,onClose }) => {
   return ( 
   <div>

        <h3 style={{ fontWeight: "bold", color: colors.blue, textAlign:"left" }}>Açıklama: </h3> 
        <div style={{ textAlign:"left", padding:"20px 10px", background:colors.gray }} >
             {record && record.noOfferApprovedDescription !== null ? record.noOfferApprovedDescription : '-'}
         </div> 
            
    </div>
    )

};

WaitingOpenOrderUpdateDetail.propTypes = {
  onClose: PropTypes.func.isRequired, 
};

export default WaitingOpenOrderUpdateDetail;