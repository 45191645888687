import React,{useEffect,useState} from "react";
import { Table, Button,Modal  } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';

const NewsListLimited = () => {
    const [items, setItems] = useState([]);
    const [itemsLimited, setItemsLimited] = useState([]);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
  
      const fetchData = async () => {
        try {
          const data = await FirebaseService.getNewData();
            const filteredData = data.filter(items => !items.isDeleted)
            const sortedData = filteredData.sort((a, b) => new Date(b.firstTransactionDate) - new Date(a.firstTransactionDate));
            const limitedData = sortedData.slice(0, 2); 

            setItemsLimited(limitedData);
            setItems(sortedData);

        } catch (error) {
          console.error('Error fetching news data',error.message)
        }
      };
      
      
  
      fetchData();
    }, []);

    const goPage = (record) => {
        navigate('/newsList', { state: { record } })
      };


      const columns = [
        {
            title: "",
            dataIndex: '',
            key: '',
            width: 100,
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img 
                        src={record.imageUrl} 
                        alt="" 
                        style={{ width: 150, height: 100, marginRight: 30 , borderRadius:'20px', objectFit:"cover"}} 
                    />
                    <div>
                        
                       
                            {record.title}
                        
                    </div>
                </div>
            ),
        },
      ];

      return  <div style={{
        //  justifyContent: 'center',
        //  alignItems: 'center',
       //   minHeight: '94vh',
         
        //  width:"50%",
          border:"2px solid #AFD3E7", 
          borderRadius:"15px",
          height:"410px"
        }}>
        <Button onClick={()=>goPage(items)} className="title-limited-list">
           Şirketten Haberler
         </Button>
         <Table
            locale={{
              emptyText: 'Henüz bir haber eklenmedi...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={itemsLimited}
            columns={columns}
            showHeader={false}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />
    </div>

  };
  export default NewsListLimited;

  