import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import WebMenu from '../web/layouts/webMenu';

const PrivateWebPageComponent = () => {

    return (
        <div style={{ display: "flex" }}>
            <WebMenu />
            <div style={{ marginTop: '20px', width: 'calc(100% - 160px)' }}>
              <Outlet />
            </div>
        </div>
    );
};

export default PrivateWebPageComponent;
