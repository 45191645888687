import React,{useEffect,useState} from "react";
import { Button, Form, Input,Select,Space, Upload,Modal} from 'antd';
import NotificationService from "../../../services/antNotificationService";
import FirebaseService from "../../../services/firebaseService";
import  { parsCollections, colors}  from "../../../environments/environment"
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FirebaseStorage from "../../../services/storegeService";
import StoregeService from "../../../services/storegeService";
import { UploadOutlined } from '@ant-design/icons';

const StockFilter = () => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]); 

    useEffect(() => {
        const fetchData = async () => {
          const companies = await FirebaseService.getCompaniesData();
          const filteredCompanies = companies.filter(item => !item.isDeleted);
          setCompanies(filteredCompanies);
          
        };
        fetchData();
      }, []);
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };

    const onFinish = async (record) => {
        navigate('/stocklist', { state: { record } })

    }; 
    const onChange = (value) => {
        console.log(`selected ${value}`);
   };




   return ( <div>
    
         <Form
            name="basic"
            labelCol={{
            span: 4,
            }}
            wrapperCol={{
            span: 20,
            }}
            style={{
            maxWidth: "100%",
            padding:"5% 0 0 5%"
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

          
        <Form.Item
          label="Firma"
          name="companyName"
          rules={[
            {
              required: true,
              message: 'Herhangi bir firma seçmediniz. Lütfen firma seçiniz.',
            },
          ]}
        >
          <Select
            onChange={onChange}
            placeholder="Firma seçiniz"
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={companies.map(item => ({ label: item.companyName, value: item.companyName }))}
            className="input-style" 
            />
            </Form.Item>

         

        <Form.Item
            wrapperCol={{
                offset: 15,
                span: 18,
            }}
            >
            <Button htmlType="submit" style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Devam Et</Button>
            </Form.Item>

        </Form>
    </div>)

};



  export default StockFilter;