


import React,{useEffect,useState} from "react";
import { Button, Form, Input, Card, Modal } from 'antd';
import NotificationService from "../../services/antNotificationService";
import { auth } from "../../services/authService";
import { signInWithEmailAndPassword } from "firebase/auth";
import {useNavigate} from "react-router-dom"
import FirebaseService from "../../services/firebaseService";
import { adminMenuLogin,advisorMenuLogin,colors } from "../../environments/environment"
import Item from "antd/es/list/Item";


const Login = () => {
  const navigate=useNavigate();
  const [filteredItems, setFilteredItems] = useState([]);

  const findUserByEmailAndPassword = async (email) => {
    try {
      const data = await FirebaseService.getUserData();
      const user = data.find(user => user.email === email);
      if (user && user.isDeleted) {
        console.log("Hesabınız silinmiştir.");
        return null; // Eğer kullanıcı bulunduysa ve hesabı silinmişse null döndür
      }
      else{
        console.log("Hesabınız var.");
        return user; // Aksi takdirde kullanıcıyı döndür
      }
      
    } catch (error) {
      console.error('Error fetching user data:', error.message);
      return null; // Bir hata olursa null döndür
    }
  };
  
  

  const onFinish = async (values) => {

    const userEmail = values.email + '@pars-makina.com.tr';

    const user = await findUserByEmailAndPassword(userEmail);
  
    // Kullanıcının varlığını ve silinip silinmediğini kontrol etme
    if (user === null) {
      console.log('Hesabınız yok.');
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Hesabınıza giriş yapmanız kısıtlanmıştır. ',placement:'topRight'})
      return;
    }


   await signInWithEmailAndPassword(auth, userEmail, values.password)
      .then((userCredential) => {
        localStorage.setItem('accessToken',userCredential.user.accessToken)
        localStorage.setItem('email',userCredential.user.email)
        localStorage.setItem('uid',userCredential.user.uid)

          const data = FirebaseService.getUserByUid(userCredential.user.uid).then((data)=>{
            if( !data.userType.includes('headAdmin') && !data.userType.includes("user"))
            {
              //console.log('data:', data);
              localStorage.clear();
              NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetkiniz bulunmamaktadır.',placement:'topRight'})
              return null;
            }
            localStorage.setItem('name',data.name)
            localStorage.setItem('surname',data.surname)
            localStorage.setItem('userType',data.userType)
            localStorage.setItem('isManager',data.isManager)
            localStorage.setItem('title',data.title)
            localStorage.setItem('userUnit',data.userUnit)
            localStorage.setItem('password',data.password)
           // localStorage.setItem('authorizationPages',data.authorizationPages)
           // setFilteredItems(data.authorizationPages);
            
 
        NotificationService.openSuccessNotification({title:'',description:'Giriş Başarılı',placement:'topRight'})
        const userType= localStorage.getItem('userType')

        if(data.userType.includes('headAdmin'))
        {
          setTimeout(() => {
            navigate("/adminpanel");
          }, 3000);
        }
       
      
        else if( data.userType.includes("user"))
        {
          if (data.userUnit.includes("teknik ressam")) {
            setTimeout(() => {
              navigate("/programmerpage");
            }, 3000);
          } else if (data.userUnit.includes("satın alma")) {
            setTimeout(() => {
              navigate("/buyerpage");
            }, 3000);
          } else if (data.userUnit.includes("satış")) {
            setTimeout(() => {
              navigate("/salesmanpage");
            }, 3000);
          } else if (data.userUnit.includes("yönetici")) {
            setTimeout(() => {
              navigate("/managerpage");
            }, 3000);
          }else if (data.userUnit.includes("üretim müdürü")) {
            setTimeout(() => {
              navigate("/productionmanagerpage");
            }, 3000);
          }  else{
            
           console.log("hata")
          }
  
        }
        else{
          localStorage.clear();
          NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetki bilgisi alınamadı',placement:'topRight'})
          return null;
        }
          });
       
      })
      .catch((error) => {
        localStorage.clear();
        if (error.code === 'auth/wrong-password') {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: 'Şifre yanlış. Lütfen şifreyi doğru girin.', placement: 'topRight' });
        } else if (error.code === 'auth/configuration-not-found') {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: 'Kullanıcı bulunamadı. Firebase konfigürasyon hatası.', placement: 'topRight' });
        } else if (error.code === 'auth/invalid-login-credentials') {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: 'Geçersiz giriş bilgileri. Lütfen telefon ve şifreyi kontrol edin.', placement: 'topRight' });
        } 
        else if (error.code === 'auth/invalid-credential') {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: 'Geçersiz giriş bilgileri. Lütfen telefon ve şifreyi kontrol edin.', placement: 'topRight' });
        } 
        else {
          NotificationService.openErrorNotification({ title: 'Giriş Başarısız', description: `${error.message}`, placement: 'topRight' });
        }

      });
      
  };
  

  const onFinishFailed = (errorInfo) => {
    localStorage.clear();
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
  };

  useEffect(()=>{
    const auth=localStorage.getItem('accessToken');
    const userType=localStorage.getItem('userType');
    const userUnit=localStorage.getItem('userUnit');
    

    if(auth && userType)
    {
      if(userType.includes('headAdmin'))
      {
        setTimeout(() => {
          navigate("/adminpanel");
        }, 3000);
      }
      else if(userType.includes("user"))
      {
        if (userUnit.includes("teknik ressam")) {
          setTimeout(() => {
            navigate("/programmerpage");
          }, 3000);
        } else if (userUnit.includes("satın alma")) {
          setTimeout(() => {
            navigate("/buyerpage");
          }, 3000);
        } else if (userUnit.includes("satış")) {
          setTimeout(() => {
            navigate("/salesmanpage");
          }, 3000);
        } else if (userUnit.includes("yönetici")) {
          setTimeout(() => {
            navigate("/managerpage");
          }, 3000);
        }else if (userUnit.includes("üretim müdürü")) {
          setTimeout(() => {
            navigate("/productionmanagerpage");
          }, 3000);
        }  else{
          /*
          setTimeout(() => {
            navigate("/home");
          }, 3000);
          */
         console.log("hata")
        }

        
      }
     
      else{
        localStorage.clear();
        NotificationService.openErrorNotification({title:'Giriş Başarısız',description:'Yetki bilgisi alınamadı',placement:'topRight'})
        return null;
      }
    }
  })

  const handleButtonClick = async () => {
    try {
      const data = await FirebaseService.getUserData();

      const userWithAdminRights = data.find(user => user.userType && user.userType.includes("headAdmin")); // headAdmin yetkisine sahip ilk kullanıcı

      if (userWithAdminRights) {
        const formattedName = userWithAdminRights.name.charAt(0).toUpperCase() + userWithAdminRights.name.slice(1).toLowerCase();
        const formattedSurname = userWithAdminRights.surname.charAt(0).toUpperCase() + userWithAdminRights.surname.slice(1).toLowerCase();

        Modal.info({
          title: 'Şifremi Unuttum',
          content: (
            <div>
              <p>
                Şifreniz için {formattedName} {formattedSurname} (Admin) ile iletişime geçiniz.
              </p>
            </div>
          ),
          okText: 'Tamam',
          onOk() {},
          className: 'popup-modal-button',
        });
      } else {
        console.log("headAdmin yetkisine sahip kullanıcı bulunamadı.");
        // headAdmin yetkisine sahip kullanıcı bulunamadı, isteğe göre bir işlem yapabilirsiniz
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

   return  <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
      }}
    >
        <Card style={{borderRadius:0, border: "none",
        //borderBottom:"1px solid",
        // borderColor:"#A47B5A",
         width:"80%" }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <img width={450} height={200} alt="logo" src="/images/prosis-logo.svg"  style={{marginBottom:"50px"}}/>
          <Form
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                 // type: 'email',
                  required: true,
                 message: 'Lütfen telefon numaranızı giriniz!',
                },
              ]}
            >
              <Input   className="input-style"
              placeholder="Telefon" style={{ width: '450px', height: '50px' }} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Lütfen şifrenizi giriniz!',
                },
              ]}
            >
              <Input.Password   className="input-style"
               placeholder="Şifre" style={{ width: '450px', height: '50px' }} />
            </Form.Item>

            <Button
              onClick={handleButtonClick}
              style={{
                float:"left",
                border:"none",
                color: colors.blue,
                fontWeight: "500", 
              }}
            >
              Şifremi Unuttum
            </Button> 
{/* 
            <Form.Item
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 5,
              }}
            >
            <Button type="link" href="/forgotpassword" htmlType="button" style={{ color: '#A47B5A', alignSelf: 'flex-start' }}>Şifremi unuttum</Button>
            </Form.Item>

            */} 
            <Form.Item      
              style={{ marginBottom: 5, textAlign: 'right',float:"right" }}
            >
               
              <Button htmlType="submit" style={{background:colors.blue ,color:"white"}}>
                Giriş Yap
              </Button>

            
            </Form.Item>
            
          
          </Form>

       
          </div>
         
        </Card>
  </div>

};
  export default Login;

