import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors, placeholderMap } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

const StockList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [itemsWastage, setItemsWastage] = useState([]);
  const [filteredItemsWastage, setFilteredItemsWastage] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getStocksData();
      const filteredData = data.filter(item => !item.isDeleted && item.companyName === record.companyName);

      //console.log("filteredData", filteredData)
      setItems(filteredData);
      setFilteredItems(filteredData);

      const dataWastage = await FirebaseService.getWastagesData();
      const filteredDataWastage = dataWastage.filter(item => !item.isDeleted && item.companyName === record.companyName);

      //console.log("filteredDataWastage", filteredDataWastage)
      setItemsWastage(filteredDataWastage);
      setFilteredItemsWastage(filteredDataWastage);
    };

    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = items.filter(item => {
      const value = selectedKeys[0]?.toLowerCase(); 
      const itemValue = item[dataIndex]?.toString().toLowerCase(); 
      return (
        !item.isDeleted &&
        item.companyName === record.companyName &&
        (value ? itemValue.includes(value) : true) 
      );
    });
    setFilteredItems(filteredData);
  };
  
  const handleReset = clearFilters => {
    clearFilters();
    setFilteredItems(items);
  };



  const getColumnSearchProps = dataIndex => ({
    
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
     <Input
    id="search-input"  
    placeholder={`${placeholderMap[dataIndex] || dataIndex} Ara`}
    value={selectedKeys[0]}
    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    style={{ width: 188, marginBottom: 8, display: 'block' }}
    />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90,background:colors.blue }}
         
          >
            Ara
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => document.getElementById('search-input').select(), 100);
      }
    },
  });

  const columns = [
    {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
        ...getColumnSearchProps('quality'),
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
         (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
      },
      
    {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
        ...getColumnSearchProps('thickness'),
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),       
         sorter: (a, b) => a.thickness - b.thickness,
      },
    
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('width'),
        sorter: (a, b) => a.width - b.width,

      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('height'),
        sorter: (a, b) => a.height - b.height,

      },
    
      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('surface'),
        sorter: (a, b) => a.surface.localeCompare(b.surface), 

      },
    
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text}
          </span>
        ),        ...getColumnSearchProps('piece'),
        sorter: (a, b) => a.piece - b.piece,

      },
         
      {
        title: 'Rezerve Adet',
        dataIndex: 'reservedPiece',
        key: 'reservedPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),   
        ...getColumnSearchProps('reservedPiece'),
        sorter: (a, b) => a.piece - b.piece,  
       },
           
      {
        title: 'Beklenen Adet',
        dataIndex: 'waitingPiece',
        key: 'waitingPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),  ...getColumnSearchProps('waitingPiece'),
        sorter: (a, b) => a.piece - b.piece,
        },
    
    
      {
        title: 'Birim Kilo',
        dataIndex: 'unitKilo',
        key: 'unitKg',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text && text.toFixed(2)} Kg
          </span>
        ),  
        ...getColumnSearchProps('unitKg'),
        sorter: (a, b) => a.unitKg - b.unitKg,

      },
    
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKilo',
        key: 'totalKg',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text && text.toFixed(2)} Kg
          </span>
        ),  
        ...getColumnSearchProps('totalKg'),
        sorter: (a, b) => a.totalKg - b.totalKg,

      },
      {
        title: 'Minimum Adet',
        dataIndex: 'minPiece',
        key: 'minPiece',
        render: (text, record) => (
<span style={{ 
  color: (record.piece - record.minPiece >= 5 && record.piece > record.minPiece) ? colors.black : 
  (record.piece - record.minPiece <= 5  && record.minPiece > record.piece ? colors.red : colors.orange),
  fontFamily: "futura" 
}}>
{text ? text : "-"}
          </span>
        ),  
            },
            {
              title: 'Açıklama',
              dataIndex: 'isProblem',
              key: 'isProblem',
              render: (text, record) => {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                const formattedDate = record.problemDate ? new Date(record.problemDate.seconds * 1000).toLocaleString('tr-TR', options) : '';
                
                return (
                  <Popover
      content={
        <div style={{ width: '200px', maxHeight: '700px' }}> {/* Sabit yükseklik ve genişlik */}
          <p><strong>Problem Zamanı:</strong> {formattedDate}</p>
          <p><strong>Problem Fotoğrafı:</strong> 
            {record.problemImage ?                 
            <img alt="" src={record.problemImage} style={{  width: '150px',height:"150px",objectFit:"contain" }} />
            : 
            "Fotoğraf bulunmamaktadır."}</p>
                <p style={{ width: '200px', maxHeight: '500px', overflow:"hidden" }}><strong>Problem Açıklaması:</strong> {record.problemDescription}</p>
              </div>
            }
            title="Sac levhada problem bulunmaktadır."
            trigger="click" // veya hover
          >
                    <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>
                      {record.isProblem ? <img width={30} height={20} alt="logo" src="/images/important.png" style={{ paddingBottom: "5%" }} /> : null}
                    </span>
                  </Popover>
                );
              },
            
            },
    
  ];

  const columnsWastage = [
    {
        title: 'Kalite',
        dataIndex: 'quality',
        key: 'quality',
      //  ...getColumnSearchProps('quality'),
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        sorter: (a, b) => a.quality.localeCompare(b.quality), 
      },
      
    {
        title: 'Kalınlık',
        dataIndex: 'thickness',
        key: 'thickness',
      //  ...getColumnSearchProps('thickness'),
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
        sorter: (a, b) => a.thickness - b.thickness,
      },
    
      {
        title: 'En',
        dataIndex: 'width',
        key: 'width',
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
       // ...getColumnSearchProps('width'),
        sorter: (a, b) => a.width - b.width,

      },
      {
        title: 'Boy',
        dataIndex: 'height',
        key: 'height',
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
      //  ...getColumnSearchProps('height'),
        sorter: (a, b) => a.height - b.height,

      },
    
      {
        title: 'Yüzey',
        dataIndex: 'surface',
        key: 'surface',
        render: (text) => <span style={{ color: items.isDeleted ? '#00000080' : '#000000' }}>{text}</span>,
       // ...getColumnSearchProps('surface'),
        sorter: (a, b) => a.surface.localeCompare(b.surface), 

      },
    
      {
        title: 'Adet',
        dataIndex: 'piece',
        key: 'piece',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
      //  ...getColumnSearchProps('piece'),
        sorter: (a, b) => a.piece - b.piece,

      },
         
      {
        title: 'Rezerve Adet',
        dataIndex: 'reservedPiece',
        key: 'reservedPiece',
        render: (text,record) => <span style={{color: items.isDeleted ? "#00000080" : "#000000"}}>{text ? text : "-"}</span>,
      },
  ];


  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '94vh',
    padding: "3%",
  }}>
    <h3 style={{ textAlign: "left", color: colors.blue }}>Güncel Stok</h3>
    <h4 style={{ textAlign: "left", color: colors.blue }}>{record.companyName}</h4>

    <Table
      locale={{
        emptyText: 'Henüz stok bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItems}
      columns={columns}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
    />




<h3 style={{ textAlign: "left", color: colors.blue }}>Güncel Fire</h3>
    <h4 style={{ textAlign: "left", color: colors.blue }}>{record.companyName}</h4>

    <Table
        locale={{
        emptyText: 'Henüz stok bulunmamaktadır...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={itemsWastage}
      columns={columnsWastage}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
    />

  </div>
};

export default StockList;
