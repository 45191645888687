import React,{useEffect,useState} from "react";
import { Button, Form, Input, Select,Spin} from 'antd';
import NotificationService from "../../services/antNotificationService";
import { useLocation  } from 'react-router-dom';
import FirebaseService from "../../services/firebaseService";
import { useNavigate } from 'react-router-dom';

import  { parsCollections, qualityGroup}  from "../../environments/environment"


const DensityEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const record = location.state && location.state.record;
    const [paymentMethods, setPaymentMethods] = useState([]); 
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
      const fetchData = async () => {
        const paymentMethods = await FirebaseService.getPaymentMethodsData();
        const filteredpaymentMethods = paymentMethods.filter(item => !item.isDeleted);
        setPaymentMethods(filteredpaymentMethods);
      };
      fetchData();
    }, []);

  
   
    const onFinish = async (values) => {
      setLoading(true);
      const currentDate = new Date();
      const paymentMethodValue = values.paymentMethod ? values.paymentMethod : record.paymentMethod;
      const densityValue = parseFloat(values.density);

      const data = {
        firstTransactionDate: currentDate,
                    firstUserId: localStorage.getItem("uid"),
                    isDeleted: false,
                    density: densityValue,
                    quality: values.quality,
                    fuel: values.fuel
      };
    
      try {
        const result = await FirebaseService.updateDensity(record.key, data);
        console.log("result",result)
       
        if (result.success) {
          console.log("data",data)
             /*
          const updateSuccess = await FirebaseService.updateDensityInAllTables(record.density, data.densityValue);

              if (!updateSuccess) {
                NotificationService.openErrorNotification({
                  title: 'Güncelleme Hatası',
                  description: "Tüm tablolar güncellenemedi. Lütfen tekrar deneyiniz.",
                  placement: 'topRight'
                });
                setLoading(false);
                return; 
              }
            

            NotificationService.openSuccessNotification({
              title: 'İşlem Başarılı',
              description: result.message,
              placement: 'topRight'
            });

            setLoading(false);
            setTimeout(() => {
              navigate("/densitylist");
            }, 2000);*/
            
        } else {
          NotificationService.openErrorNotification({
            title: 'İşlem Başarısız',
            description: "İşlem gerçekleştirilemedi. Lütfen daha sonra tekrar deneyiniz.",
            placement: 'topRight'
          });
          setLoading(false);
        }
    
        
      } catch (error) {
        NotificationService.openErrorNotification({
          title: 'İşlem Başarısız',
          description: "Bilinmeyen bir hata ile karşılaşıldı.",
          placement: 'topRight'
        });
        setLoading(false);
      }
    };
    
    const onFinishFailed = (errorInfo) => {
      NotificationService.openErrorNotification({title:'Geçersiz Form',description:'Lütfen form bilgilerini kontrol ediniz.',placement:'topRight'})
    };
  
    const onChange = (value) => {
      console.log("value",value)
      setPaymentMethod(value)

    };

   return ( 
    <div>
    
    <Form
       name="basic"
       labelCol={{
       span: 2,
       }}
       wrapperCol={{
       span: 8,
       }}
       style={{
       maxWidth: "100%",
       padding:"5% 0 0 5%"
       }}
       initialValues={{
        quality: record?.quality || "",
        density: record?.density || "",
        fuel: record?.fuel || "",
                  
       }}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
       autoComplete="off"
   >

      
<Form.Item
                label="Kalite"
                 name="quality"
                 rules={[
                   {
                     required: true,
                     message: 'Lütfen kalite giriniz!',
                   },
                 ]}
              
               >
                 <Input   className="input-style" style={{width:"100%"}} placeholder="Kalite Giriniz" />
               </Form.Item>

            <Form.Item
            label="Özkütle"
            name="density"
            rules={[
                {
                    required: true,
                    message: 'Lütfen özkütle giriniz!',
                },
                {
                    pattern: /^[0-9.]+$/,
                    message: 'Sadece rakam ve nokta (.) girişi yapınız.',
                },
               
            ]}
            
          
            >
           <Input className="input-style" style={{width:"100%"}} placeholder="Özkütle"/>
            </Form.Item>


            <Form.Item
            label="Tür"
          name="fuel"
          rules={[
            {
              required: true,
              message: 'Lütfen seçiniz!',
            },
          ]}
       
        >
          <Select
            onChange={onChange}
            placeholder="Kalite Grupu Seçiniz"
            className="input-style" 
            style={{width:"100%"}}
            suffixIcon={<img style={{ width: "20px" }} alt="edit" src="/images/downarrow.png" />}
            options={qualityGroup.map(item => ({ label: item.label, value: item.value }))}
          />
            </Form.Item>
            


      
    
     
    
       <Form.Item
       wrapperCol={{
           offset: 15,
           span: 18,
       }}
       >
       <Button htmlType="submit" loading={loading} style={{backgroundColor:"#1A446C",color:"white",fontWeight:"500"}}>Gönder</Button>
       </Form.Item>

   </Form>
   {loading && (
           <div style={{
               position: 'fixed',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
               backgroundColor: 'rgba(0, 0, 0, 0.5)',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               zIndex: 1000,
           }}>
               <Spin/>
           </div>
       )}

    </div>
    )

        };
  export default DensityEdit;