import React, { useEffect, useState } from "react";
import { Table, Input, Button, Checkbox ,Space,Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import { colors,parsCollections } from "../../../environments/environment";
import { useLocation } from 'react-router-dom';
import NotificationService from "../../../services/antNotificationService";

const CheckCurrentPriceList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const [checkedItemsStock, setCheckedItemsStock] = useState([]);
  const [inputValuesStock, setInputValuesStock] = useState({});
  const [editingKey, setEditingKey] = useState('');
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getCheckCurrentPriceData();
      setItems(data);
      setFilteredItems(data);
    };

    fetchData();
  }, []);


  
  
  const handleInputChange = (key, fieldName, value) => {
    const updatedItems = filteredItems.map((item) => {
      if (item.key === key) {
        let parsedValue = value; 
        
        if (fieldName === 'thickness' || fieldName === 'width' || fieldName === 'height') {
          parsedValue = parseFloat(value); 
          if (isNaN(parsedValue)) {
            parsedValue = 0; // Geçerli bir sayı değilse 0 olarak ayarla
          }
        } else if (fieldName === 'piece') {
          parsedValue = parseInt(value, 10); 
          if (isNaN(parsedValue)) {
            parsedValue = 0; // Geçerli bir sayı değilse 0 olarak ayarla
          }
        }
  
        return { ...item, [fieldName]: parsedValue };
      } else {
        return item;
      }
    });
  
    setFilteredItems(updatedItems);
  };

  
  const cancel = () => {
    setEditingKey('');
  };
  
  const handleCheckboxChange = (item, record) => {
    if (item === 'selectAll') {
      setSelectAllChecked((prev) => !prev);
      setCheckedItemsStock((items) => {
        if (!selectAllChecked) {
          const newItems = filteredItems.map((record) => ({
            id: record.key,
            quality: record.quality,
            height: record.height,
            width: record.width,
            thickness: record.thickness,
            surface: record.surface,
            piece: record.piece,
          }));
          return newItems;
        } else {
          return [];
        }
      });
    } else {
      const isChecked = checkedItemsStock.some((entry) => entry.id === item);
      setCheckedItemsStock((items) => {
        if (isChecked) {
          const newItems = items.filter((entry) => entry.id !== item);
          return newItems;
        } else {
          const newItem = {
            id: item,
            quality: record.quality,
            height: record.height,
            width: record.width,
            thickness: record.thickness,
            surface: record.surface,
            piece: record.piece,
          };
          return [...items, newItem];
        }
      });
    }
  };
  /*
  const handleCheckboxChange = (item,record) => {
    //console.log("record",record)
    const isChecked = checkedItemsStock.some((entry) => entry.id === item);
    setCheckedItemsStock((items) => {
        if (isChecked) {
            const newItems = items.filter((entry) => entry.id !== item);
            return newItems;
        } else {
            const newItem = { 
              id:item,
              quality: record.quality,
              height: record.height,
              width: record.width,
              thickness: record.thickness,
              surface: record.surface,
              piece: record.piece
            };
            return [...items, newItem];
        }
    });  
      
  };
  */
  
  const renderEditableColumn = (key, dataIndex, title) => ({
    title,
    dataIndex,
    key,
    width: 200,
    render: (text, record) => (
      <Input
        value={inputValuesStock[record.key] !== undefined ? inputValuesStock[record.key] : text}
        onChange={(e) => handleInputChange(record.key, dataIndex, e.target.value)}
        onPressEnter={() => save(record.key)}
        onBlur={() => save(record.key)}
      />
    ),
  });

  const columns = [
    
    {
        title: '',
        dataIndex: '',
        key: '',
        width: 200,
        render: (text, record) => (
          <Checkbox
          onChange={() => handleCheckboxChange(record.key,record)}
          checked={checkedItemsStock.some(item => item.id === record.key)}
          style={{ fontWeight: '500' }}
        />      
           
       
        ),
      },
      renderEditableColumn('quality', 'quality', 'Kalite'),
      renderEditableColumn('thickness', 'thickness', 'Kalınlık'),
      renderEditableColumn('width', 'width', 'En'),
      renderEditableColumn('height', 'height', 'Boy'),
      renderEditableColumn('surface', 'surface', 'Yüzey'),
      renderEditableColumn('piece', 'piece', 'Adet'),

      /*
      {
        title: 'Birim Kilo',
        dataIndex: 'unitKg',
        key: 'unitKg',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        width: 200,
      },
    
      {
        title: 'Toplam Kilo',
        dataIndex: 'totalKg',
        key: 'totalKg',
        render: (text, record) => <span style={{ color: items.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
        width: 200,
      },*/
  ];

  const save = async (key) => {
    try {
      const newData = [...filteredItems];
      const index = newData.findIndex((item) => key === item.key);
  
      if (index > -1) {
        const updatedItem = {
          ...newData[index],
          ...inputValuesStock[key],
        };
  
        newData.splice(index, 1, updatedItem);
        setFilteredItems(newData);
        setEditingKey('')  
       
      } else {
       
      }
    } catch (error) {
    }
  };

  const saveChanges = async () => {
    setLoading(true)
    try {
      const updatePromises = filteredItems.map(async (item) => {
        const { key, ...updatedData } = item;
        const result = await FirebaseService.updateCheckCurrentPrice(key, updatedData);
        return result;
      });
  
      const updateResults = await Promise.all(updatePromises);
      const allUpdatesSuccessful = updateResults.every((result) => result.success);
  
      if (allUpdatesSuccessful) {
        NotificationService.openSuccessNotification({
          title: '',
          description: 'Değişiklikler kaydedildi.',
          placement: 'topRight',
        })
        window.location.reload();
        ;setLoading(false)
      } else {
        NotificationService.openErrorNotification({
          title: '',
          description: 'Bazı değişiklikler kaydedilemedi.',
          placement: 'topRight',
        });setLoading(false)
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      NotificationService.openErrorNotification({
        title: '',
        description: 'Değişiklikler kaydedilemedi.',
        placement: 'topRight',
      });setLoading(false)
    }
  };
  
    
  const goPage = () => {
    console.log("checkedItemsStock",checkedItemsStock)
    navigate('/checkcurrentpricesendmail', { state: {checkedItemsStock  } })
  };
  
  return <div style={{
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '94vh',
    padding: "3%",
  }}>
    <h3 style={{ textAlign: "left", color: colors.blue }}>Güncel Fiyat Kontrol</h3>
   
    <Input
        style={{
          borderRadius: 0,
          border: 'none',
          borderBottom: '1px solid #1A446C80',
          width: '100%',
          maxWidth: '400px',
          textAlign: 'left',
          outline: 'none',
        }}
        placeholder="Kalite Ara"
        suffix={
          <Space>
              <img width={15} alt="edit" src="/images/searchicon.png" />
          </Space>
        }
        onChange={(e) => {
          const searchValue = e.target.value.toUpperCase();
         // const searchValue = e.target.value;
          const filteredData = items.filter(item => {
          const quality = item.quality ;
          return quality.includes(searchValue);
          });
          setFilteredItems(filteredData);
        }}
      />
 <div style={{display:"flex", justifyContent:"left", padding:"3%"}}>

<Checkbox
    onChange={() => handleCheckboxChange('selectAll')}
    checked={selectAllChecked}
    style={{ marginBottom: '10px', fontWeight: '500'}}>
    Tümünü Seç
  </Checkbox>
</div>    

    <Table
      locale={{
        emptyText: 'Henüz eklemediniz...',
        filterReset: 'Sıfırla',
        filterTitle: 'Filtre Menüsü',
        selectAll: 'Hepsini Seç',
        selectInvert: 'Tersini Seç',
        selectionAll: 'Tümünü Seç',
        sortTitle: 'Sıralama',
        triggerDesc: 'Azalan sıralama için tıklayın',
        triggerAsc: 'Artan sıralama için tıklayın',
        cancelSort: 'Sıralamayı iptal etmek için tıklayın',
      }}
      dataSource={filteredItems}
      columns={columns}
      showHeader={true}
      pagination={false}
      className="custom-news-table"
      rowClassName="custom-news-row"
    />
   
    <div style={{display:"flex", justifyContent:"right"}}>
            
        <Button
            style={{ backgroundColor: colors.blue, color: "white", fontWeight: "500" }}
            onClick={() => saveChanges()}>
          Kaydet
        </Button>
        
        <Button style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}} onClick={()=>goPage()} >
        Mail At
        </Button>
        </div>
        {loading && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}>
                    <Spin/>
                </div>
            )}

  </div>
};

export default CheckCurrentPriceList;
