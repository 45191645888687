import React, { useState } from "react";
import { Button, Dropdown, Menu, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import UserInfo from "../userInfo";
import WeatherData from "../weatherData";
import PurchaseRequestAdd from "../purchaseRequests/purchaseRequestAdd";
import AlarmListLimited from "../alarms/alarmListLimited";
import  { colors, notifications}  from "../../../environments/environment"
import ForeignCurrency from "../foreignCurrency";
import Pdf from './ProgrammerPage.pdf';
import PermissionAdd from "../permissions/permissionAdd";
import BirthdayListLimited from "../birthdays/birthdayListLimited";
import NewsListLimited from "../news/newsListLimited";
import AnnouncementsListLimited from "../announcements/announcementsListLimited"
import Notifications from "../notifications";
import WebNavbar from "../webNavbar";


const HomePage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlePurchaseRequest = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
    setIsModalVisible(false);
  };

  const handlePurchaseRequestAddFinish = () => {
    setIsModalVisible(false); 
  };


  const [isModalVisiblePermission, setIsModalVisiblePermission] = useState(false);

  const handlePermission = () => {
    setIsModalVisiblePermission(true);
  };

  const handleModalCancelPermission = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
    setIsModalVisible(false);
  };

  const handlePermissionAddFinishFirst = () => {
    setIsModalVisiblePermission(false); 
  };



  const navigate = useNavigate();
  const handlePersonnelList = () => {
    navigate('/personnellist')
  };
  

  
  

  const menu = (
    <Menu>
      {notifications.map((notification, index) => (
        <Menu.Item
          key={notification.key}
          style={{
            backgroundColor: index % 2 === 0 ? colors.lightBlue : 'white',
            color: index % 2 === 0 ? 'black' : 'black',
          }}
        >
          {notification.content}
          {index < notifications.length - 1}
        </Menu.Item>
      ))}
    </Menu>
  );


  const handleMenusList = () => {
    navigate('/menuslist')
  }
  return (
    <div style={{padding:"3%"}}>
    <WebNavbar />
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <NewsListLimited/>
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
     <AnnouncementsListLimited/>
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px" }}>
      <BirthdayListLimited />
      </div>
      <div style={{ flex: "1 0 33.33%", margin: "10px", display:"block" }}>
        <div>
        <AlarmListLimited />
        </div>
      
        <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "10px" }}>
            <div style={{ gridColumn: "span 1", gridRow: "span 1" , margin:"10px"}}>
                <Button
                className="classic-button"
                onClick={handlePurchaseRequest}>
                Satın Alma Talep Et
                </Button>
                <Modal
                title="Satın Alma Talep Et"
                visible={isModalVisible}
                onCancel={handleModalCancel}
                footer={null}
                >
                <PurchaseRequestAdd onClose={handlePurchaseRequestAddFinish} />
                </Modal>
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
                <Button 
                className="classic-button"
               onClick={handlePermission}>
                İzin Al
                </Button>
                <Modal
                title="İzin Al"
                visible={isModalVisiblePermission}
                onCancel={handleModalCancelPermission}
                footer={null}
                >
                <PermissionAdd onClose={handlePermissionAddFinishFirst} />
                </Modal>
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
            <Button 
              className="classic-button"
              onClick={handlePersonnelList}>
             Personel Listesi
              </Button>
            </div>

            <div style={{ gridColumn: "span 1", gridRow: "span 1", margin:"10px" }}>
           
        <Button 
                className="classic-button"
               onClick={handleMenusList}>
                Yemek Listesi
                </Button>

            </div>
            
        </div>

   
   
      </div>
    </div>
 
   
    </div>
  );
};

export default HomePage;


