import React,{useEffect,useState} from "react";
import { Table, Button,Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import FirebaseService from "../../../services/firebaseService";
import NotificationService from "../../../services/antNotificationService";
import  { colors}  from "../../../environments/environment"
import moment from 'moment';
import { useLocation  } from 'react-router-dom';
import WantedOfferAdd from "./wantedOfferAdd";

const WantedOfferList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const record = location.state && location.state.record;
  const [items, setItems] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordForModal, setRecordForModal] = useState(null);

  useEffect(() => {
    console.log("items",items);
  
    window.scrollTo(0, 0);

    const fetchData = async () => {
      const data = await FirebaseService.getPurchaseRequestData();
      const filteredData = data.filter(item => item.mailList && item.mailList.length >= 3);
    setItems(filteredData)
    };

    fetchData();
  }, []);



  const handleAddOffer = (record) => {
    setRecordForModal(record);
    setIsModalVisible(true);
  };


  const handleModalCancel = () => {
    console.log("bastı",)
    window.location.reload();
    setIsModalVisible(false);
  };

  const handleAddOfferFinish = () => {
    window.location.reload();

    setIsModalVisible(false); 
  };
  
  const goDisplay = (record) => {
    console.log("record",record)

    /*
    NotificationService.openErrorNotification(
      {title:'',description:'Beta sürümünde bulunmamaktadır.',placement:'topRight'})
    return;
    */

    if ((record.incomingExcelList?.length || 0) === record.mailList.length) {
      navigate('/wantedofferview', { state: { record } })
    }
    else{
      NotificationService.openErrorNotification({
        title: 'İşlem Başarısız',
        description: "Tüm teklifler yüklendikten sonra görebilirsiniz.",
        placement: 'topRight'
      });
      return;
     

    }
    
    
  };

  const columns = [
    
    {
      title:"Sipariş Kod",
      dataIndex: 'offerJobCode',
      key: 'offerJobCode',
      render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{text}</span>,
  },
  {
    title: 'Ürün',
    dataIndex: 'productType',
    key: 'productType',
 
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}> {text === 'raw' ? 'Saç Levha' : text === 'other' ? 'Diğer' : text}
    </span>,
  },
  /*
  {
    title: 'Durum',
    dataIndex: '',
    key: '',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000"}}>{record.acceptExcelDocumentUrl ?  "Teklif Yüklendi" : "Teklif Yüklenmedi" }</span>,
   
  },*/
  
  {
    title:"Durum",
    dataIndex: '',
    key: '',
    render: (text,record) => <span style={{color: record.isDeleted ? "#00000080" : "#000000" }}>{record.incomingExcelList ?  record.incomingExcelList.length : 0 } / {record.mailList.length} teklif yüklendi , {record.acceptExcelDocumentUrl ? "Seçildi" : "Seçilmedi"}</span>,
},
{
  title: 'Termin Tarihi',
  dataIndex: 'deadline',
  key: 'deadline',

  render: (text, record) => {
    const formattedDeadline = record.deadline ? new Date(record.deadline.seconds * 1000).toLocaleDateString('tr-TR') : '';
    return <span style={{ color: record.isDeleted ? "#00000080" : "#000000" }}>{formattedDeadline}</span>;
  },
},




{
  title: '',
  dataIndex: 'edit',
  key: 'edit',
  render: (text, record) => (
    <>
    <Button 
    disabled = { (record.incomingExcelList?.length || 0) === record.mailList.length ? true : false }
    onClick={()=>handleAddOffer(record)} 
    style={{
      backgroundColor: ( (record.incomingExcelList?.length || 0) === record.mailList.length ? true : false ) ? "gray" : colors.blue,
      color:"white",fontWeight:"500", }}>
    Teklif Yükle
   </Button>

      <Modal
      title="Teklif Yükle"
      visible={isModalVisible}
      onCancel={handleModalCancel}
      footer={null}
      >
      <WantedOfferAdd   record={recordForModal} onClose={handleAddOfferFinish} />
      </Modal>
      </>

  ),
},
{
    title: '',
    dataIndex: 'edit',
    key: 'edit',
    render: (text, record) => (
      <Button
      
       onClick={()=>goDisplay(record)} 
      style={{backgroundColor:colors.blue ,color:"white",fontWeight:"500"}}>
      Teklifleri Gör
   </Button>
    ),
  }
    
      
  ];

  return  <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '94vh',
        padding:"3%",
       
      }}>
        <h3  style={{backgroundColor:"white" ,color:colors.blue,fontWeight:"700", border:"none",float:"left"}}>İstenen Teklif Listesi</h3>
          <Table
            locale={{
              emptyText: 'Henüz istenen teklif bulunmamaktadır...',
              filterReset: 'Sıfırla',
              filterTitle: 'Filtre Menüsü',
              selectAll: 'Hepsini Seç',
              selectInvert: 'Tersini Seç',
              selectionAll: 'Tümünü Seç',
              sortTitle: 'Sıralama',
              triggerDesc: 'Azalan sıralama için tıklayın',
              triggerAsc: 'Artan sıralama için tıklayın',
              cancelSort: 'Sıralamayı iptal etmek için tıklayın',
            }}
            dataSource={items}
            columns={columns}
            showHeader={true}
            pagination={false} 
            className="custom-news-table"
            rowClassName="custom-news-row"
        />

       
    </div>

  };
  export default WantedOfferList;

  