import { initializeApp } from 'firebase/app';
import { environment } from "../environments/environment"
import { getStorage, ref, uploadBytes, getDownloadURL,uploadBytesResumable } from 'firebase/storage';

const app = initializeApp(environment.firebase);

const storage = getStorage();

const uploadImageAndGetURL = async (folderName,selectedImage) => {
    try {
      const storageRef = ref(storage, `${folderName}/${selectedImage.name}`); 
      const snapshot = await uploadBytes(storageRef, selectedImage);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      //console.error("Hata:", error);
      return ""; 
    }
  };

  
  const uploadDocumentAndGetURL = async (folderName,selectedDocument,fileName) => {
    try {
      const storageRef = ref(storage, `${folderName}/${fileName}`); 
      const snapshot = await uploadBytesResumable(storageRef, selectedDocument);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
     // console.error("Hata:", error);
      return error; 
    }
  };

  const uploadImageAndGetURLParts = async (folderName, selectedImage, fileName) => {
    try {
      // Use the provided fileName for naming the file in Firebase Storage
      const storageRef = ref(storage, `${folderName}/${fileName}`); 
      const snapshot = await uploadBytes(storageRef, selectedImage);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      return ""; 
    }
  };
  
const FirebaseStorage = {
  uploadImageAndGetURL,
  uploadDocumentAndGetURL,
  uploadImageAndGetURLParts
}

export default FirebaseStorage;
